//***************Transaction Orders Services*******************//

import axiosInstance from "../axios";

// List Orders
export const getOrders = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/order/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Delete a Order
export const deleteOrderservice = async (id) => {
  try {
    const response = await axiosInstance.delete(`/order/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
// Add Order
export const addNewOrder = async (payload) => {
  try {
    const response = await axiosInstance.post(`/order`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Get Order By Id
export const getOrderByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/order/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Update Order
export const updateOrderservice = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/order/${payload.id}`,
      payload.payload
    );
    return response;
  } catch (e) {
    return { error: "Error" };
  }
};

//***************Transaction Billers Services*******************//

// List Billers
export const getBillers = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/biller/list`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Delete a Biller
export const deleteBillerservice = async (id) => {
  try {
    const response = await axiosInstance.delete(`/biller/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
// Add Biller
export const addNewBiller = async (payload) => {
  try {
    const response = await axiosInstance.post(`/biller`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Get Biller By Id
export const getBillerByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/biller/list`, criteria);
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Update Biller
export const updateBillerservice = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/biller/${payload.id}`,
      payload.payload
    );
    return response;
  } catch (e) {
    return { error: "error hai bhyi" };
  }
};

//***************Transaction  Payment terms Services*******************//

// List Transactions
export const getTransactions = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/transaction/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Delete a Transaction
export const deleteTransactionservice = async (id) => {
  try {
    const response = await axiosInstance.delete(`/transaction/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
// Add Transaction
export const addNewTransaction = async (payload) => {
  try {
    const response = await axiosInstance.post(`/transaction`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Get Transaction By Id
export const getTransactionByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/transaction/list`, criteria);
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Update Transaction
export const updateTransactionservice = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/transaction/${payload.id}`,
      payload.payload
    );
    return response;
  } catch (e) {
    return { error: "Error" };
  }
};
