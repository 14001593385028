import { CircularProgress } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "util/Helper";
import { getDocVerfList, getFilteredDocsDetails } from "../Redux/actionCreator";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  updateFilterState,
  updateStartDate,
  updateEndDate,
} from "../Redux/reducer";
import moment from "moment";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };
const DocVerification = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [docsStateValue, setDocsStateValue] = useState("");

  function getCreatedAT(params) {
    var a;
    const rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    // return dateNOW;
  }

  function getUpdatedAT(params) {
    var a;
    const rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.updatedAt);
    // // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    // return dateNOW;
  }

  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        // console.log("cell>>>>>", cellValues);
        const Id = cellValues?.row?._id;
        // return Id;
        return <Link to={`/users/documentverifications/${Id}`}>{Id}</Link>;
      },
      flex: 1,
      // valueGetter: getPermApplicationId,
    },
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        var a = "NA";
        var rowName = cellValues?.row?.userDetails;
        // console.log("rowName-->", rowName);
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          //   console.log("2-->", rowName[0]);
          a = rowName[0]?.name?.full;
          const rowId = rowName[0]._id;
          return <Link to={`/users/name/${rowId}`}>{a}</Link>;
          //   return a;
        }
      },
      valueGetter: (cellValues) => {
        var a = "NA";
        var rowName = cellValues?.row?.userDetails;
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          //   console.log("2-->", rowName[0]);
          a = rowName[0]?.name?.full;
          return a;
        }
      },
      flex: 1.5,
    },
    {
      field: "documentType",
      headerName: `DocumentType`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.document?.documentType;
        return rowName;
      },
      valueGetter: (cellValues) => cellValues?.row?.document?.documentType,
      flex: 1.5,
    },
    {
      field: "documentUID",
      headerName: `DocumentUID`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.document?.documentUID;
        return rowName;
      },
      valueGetter: (cellValues) => cellValues?.row?.document?.documentUID,
      flex: 1.5,
    },
    {
      field: "document.requestState",
      headerName: `VerificationStatus`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.document?.requestState;
        return rowName;
      },
      valueGetter: (cellValues) => cellValues?.row?.document?.requestState,
      flex: 1.5,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

        // return updated;
      },
      type: "date",
      valueGetter: getUpdatedAT,
      // flex: 1.5,
    },
  ];

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const [loading, setLoading] = useState(false);

  const { DocVerifaction_List_Data } = useSelector((state) => state.common);
  const docVerificationData = DocVerifaction_List_Data?.docverificationListInfo;

  const { DocVerifaction_Filtered_List_Data } = useSelector(
    (state) => state.common
  );
  const filteredDocsData =
    DocVerifaction_Filtered_List_Data?.docverificationFilteredListInfo;
  // console.log("1-->", filteredDocsData)

  //   console.log("DocVerifaction_List_Data", DocVerifaction_List_Data);
  const totalCount = DocVerifaction_List_Data?.total;
  const currentPage = useSelector((state) => state.common.currentPage);
  const pageSize = useSelector((state) => state.common.pageSize);
  const searchKey = useSelector((state) => state.common.searchKey);
  const filterState = useSelector((state) => state.common.filterState);
  const startDate = useSelector((state) => state.common.startDate);
  const endDate = useSelector((state) => state.common.endDate);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getDocVerfList({
  //         currentPage: currentPage,
  //         pageSize: pageSize,
  //         searchKey: searchKey,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, currentPage, pageSize, searchKey]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getDocVerfList({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          filterState: filterState,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterState,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getFilteredDocsDetails({
          searchKey: searchKey,
          startDate: startDate,
          endDate: endDate,
          filterState: filterState,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [dispatch, searchKey, startDate, endDate,  filterState]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getDocVerfList({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          filterState: filterState,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterState,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const hasAnyFilter =
        // searchKey.trim() !== "" ||
        // filterState.trim() !== "" ||
        startDate.trim() !== "" ||
        endDate.trim() !== "";

      try {
        if (hasAnyFilter) {
          await dispatch(
            getFilteredDocsDetails({
              searchKey: searchKey,
              filterState: filterState,
              startDate: startDate,
              endDate: endDate,
            })
          );
        } else {
          // If nothing is specified, you might want to handle this case
          await dispatch(
            getDocVerfList({
              currentPage: currentPage,
              pageSize: pageSize,
              searchKey: searchKey,
              filterState: filterState,
              startDate: startDate,
              endDate: endDate,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterState,
    startDate,
    endDate,
  ]);

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadData = async () => {
    try {
      // console.log("filter-->", filteredUserData)

      // if(filteredJobData && filteredJobData.length > 0){
      const response = filteredDocsData;
      // console.log("1405-->",response)
      const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // link.download = 'Jobizo Super Admin.csv';
      link.download = `DocumentVerification-${formatDate()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const selectStateOptions = [
    {
      key: "Failed",
      value: "Failed",
    },
    {
      key: "Success",
      value: "Success",
    },
  ];

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate));
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate));
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    dispatch(searchKeyword(sanitizedSearchKey));
  };
  // console.log("inputValue>>", inputValue)

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleDocsStateFilterChange = (e) => {
    const selectedApplicationState = e.target.value;
    let selectedApplicationStateValue;

    selectStateOptions.forEach((appState) => {
      if (appState.value === selectedApplicationState) {
        selectedApplicationStateValue = appState.value;
      }
    });

    setDocsStateValue(selectedApplicationState);

    dispatch(updateFilterState(selectedApplicationStateValue));
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    //   <FilterBar
    //     showDialog={false}
    //     title1="Document Verification"
    //     showisDeleted={false}
    //   />
    //   <Select
    //     value={selectedOption}
    //     onChange={(e) => setSelectedOption(e.target.value)}
    //     displayEmpty
    //     style={{ marginLeft: "20px", height: "44px" }}
    //   >
    //     <MenuItem value="">Choose Filters</MenuItem>
    //     <MenuItem value="requestState">State</MenuItem>
    //   </Select>
    //   {selectedOption && (
    //     <>
    //       {selectedOption === "requestState" ? (
    //         <FormControl
    //           fullWidth
    //           style={{ marginLeft: "20px", width: "100px", height: "44px" }}
    //         >
    //           <InputLabel
    //             id="role-select-label"
    //             style={{ textAlign: "center" }}
    //           >
    //             Select State
    //           </InputLabel>
    //           <Select
    //             labelId="role-select-label"
    //             label="Select State"
    //             // value={filterAll.Role || ""}
    //             value={docsStateValue || ""}
    //             onChange={handleDocsStateFilterChange}
    //             fullWidth
    //             // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
    //             style={{ width: "150px", height: "44px" }}
    //           >
    //             <MenuItem value="">Reset</MenuItem>
    //             {selectStateOptions.map((appState) => (
    //               <MenuItem key={appState.value} value={appState.value}>
    //                 {appState.value}
    //               </MenuItem>
    //             ))}
    //           </Select>
    //         </FormControl>
    //       ) : null}
    //     </>
    //   )}
    //   <IconButton
    //     onClick={handleResetFilters}
    //     color="primary"
    //     style={{
    //       position: "absolute",
    //       right: "650px",
    //     }}
    //   >
    //     <RefreshIcon style={{ fontSize: 30 }} />
    //   </IconButton>
    //   <>
    //     <TextField
    //       label="Start Date"
    //       type="date"
    //       value={startDate}
    //       onChange={(e) => handleStartDateChange(e.target.value)}
    //       // onChange={(e) => updateStartDate(e.target.value)}
    //       InputLabelProps={{
    //         shrink: true,
    //       }}
    //       style={{
    //         position: "absolute",
    //         right: "525px",
    //         width: "130px",
    //         marginTop: "1px",
    //       }}
    //     />
    //     <TextField
    //       label="End Date"
    //       type="date"
    //       value={endDate}
    //       onChange={(e) => handleEndDateChange(e.target.value)}
    //       // onChange={(e) => updateEndDate(e.target.value)}
    //       InputLabelProps={{
    //         shrink: true,
    //       }}
    //       style={{
    //         position: "absolute",
    //         right: "385px",
    //         width: "130px",
    //         marginTop: "1px",
    //       }}
    //     />
    //   </>
    //   {/* </div> */}
    //   <Button
    //     onClick={handleDownloadData}
    //     variant="contained"
    //     color="primary"
    //     style={{
    //       position: "absolute",
    //       right: "280px",
    //       height: "44px",
    //       // transition: 'background-color 0.3s',
    //       // backgroundColor: 'primary',
    //     }}
    //     // onMouseOver={(e) => e.target.style.backgroundColor = '#1976D2'} // Change to your primary color
    //     // onMouseOut={(e) => e.target.style.backgroundColor = 'primary'}
    //   >
    //     Export
    //   </Button>
    //   <TextField
    //     label={`Search by Name`}
    //     value={inputValue}
    //     onChange={(e) => setInputValue(e.target.value)}
    //     onKeyPress={handleKeyPress}
    //     style={{ position: "absolute", right: "80px", width: "190px" }}
    //     // style={{ position: "absolute", right: "20px", width: "250px" }}
    //   />
    //   <Button
    //     variant="contained"
    //     color="primary"
    //     onClick={handleSearch}
    //     style={{
    //       position: "absolute",
    //       right: "10px",
    //       width: "50px",
    //       height: "44px",
    //     }}
    //   >
    //     Search
    //   </Button>
    //   <MDBox sx={{ marginTop: "4rem" }}>
    //     <DataGrid
    //       sortingMode="server"
    //       getRowId={(row) => row._id}
    //       components={{ Toolbar: GridToolbar }}
    //       componentsProps={{
    //         toolbar: {
    //           // showQuickFilter: true,
    //           csvOptions: { disableToolbarButton: true },
    //           quickFilterProps: { debounceMs: 500 },
    //           printOptions: { disableToolbarButton: true },
    //         },
    //       }}
    //       initialState={{
    //         columns: {
    //           columnVisibilityModel: {
    //             createdAt: true,
    //             updatedAt: true,
    //           },
    //         },
    //       }}
    //       slots={{
    //         loadingOverlay: <CircularProgress disableShrink />,
    //       }}
    //       // loading={docVerificationData?.length > 0 ? false : true}
    //       loading={loading && <CircularProgress />}
    //       columns={columns}
    //       rows={[...docVerificationData]}
    //       // rows={docVerificationData.map((row, index) => ({ ...row, id: index }))}
    //       pageSize={pageSize}
    //       rowCount={totalCount}
    //       paginationMode="server"
    //       pagination
    //       page={currentPage}
    //       onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
    //       onPageChange={(newPage) => dispatch(pageChanged(newPage))}
    //       rowsPerPageOptions={[10, 25, 50, 100]}
    //       // pagination
    //       // pageSize={100}
    //       // rowsPerPageOptions={[100]}
    //       //isRowSelectable
    //       sx={{
    //         backgroundColor: "#ffff",
    //         color: "grey",
    //         height: "35rem",
    //         margin: "1rem",
    //         borderRadius: "0.5rem",
    //         "& .MuiDataGrid-cell": {
    //           padding: "15px",
    //           fontSize: "12px",
    //         },
    //         borderColor: "light-grey",
    //         "& .MuiDataGrid-cell:hover": {
    //           color: "info",
    //         },
    //       }}
    //       disableColumnFilter
    //     />{" "}
    //   </MDBox>{" "}
    // </DashboardLayout>

    <>
      {" "}
      <DashboardLayout>
        {" "}
        <DashboardNavbar />{" "}
        <FilterBar
          showDialog={false}
          title1="Document Verification"
          showisDeleted={false}
        />{" "}
        <Select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          displayEmpty
          style={{ marginLeft: "20px", height: "44px" }}
        >
          <MenuItem value="">Choose Filters</MenuItem>
          <MenuItem value="requestState">PAN Status</MenuItem>
        </Select>
        {selectedOption && (
          <>
            {selectedOption === "requestState" ? (
              <FormControl
                fullWidth
                style={{ marginLeft: "20px", width: "100px", height: "44px" }}
              >
                <InputLabel
                  id="role-select-label"
                  style={{ textAlign: "center" }}
                >
                  Select PAN Status
                </InputLabel>
                <Select
                  labelId="role-select-label"
                  label="PANStatus"
                  // value={filterAll.Role || ""}
                  value={docsStateValue || ""}
                  onChange={handleDocsStateFilterChange}
                  fullWidth
                  // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                  style={{ width: "150px", height: "44px" }}
                >
                  <MenuItem value="">Reset</MenuItem>
                  {selectStateOptions.map((appState) => (
                    <MenuItem key={appState.value} value={appState.value}>
                      {appState.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </>
        )}
        <IconButton
          onClick={handleResetFilters}
          color="primary"
          style={{
            position: "absolute",
            right: "650px",
          }}
        >
          <RefreshIcon style={{ fontSize: 30 }} />
        </IconButton>
        <>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => handleStartDateChange(e.target.value)}
            // onChange={(e) => updateStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              position: "absolute",
              right: "525px",
              width: "130px",
              marginTop: "1px",
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => handleEndDateChange(e.target.value)}
            // onChange={(e) => updateEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              position: "absolute",
              right: "385px",
              width: "130px",
              marginTop: "1px",
            }}
          />
        </>
        {/* </div> */}
        <Button
          onClick={handleDownloadData}
          variant="contained"
          color="primary"
          style={{
            position: "absolute",
            right: "280px",
            height: "44px",
            // transition: 'background-color 0.3s',
            // backgroundColor: 'primary',
          }}
          // onMouseOver={(e) => e.target.style.backgroundColor = '#1976D2'} // Change to your primary color
          // onMouseOut={(e) => e.target.style.backgroundColor = 'primary'}
        >
          Export
        </Button>
        <TextField
          label={`Search by Name`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ position: "absolute", right: "80px", width: "190px" }}
          // style={{ position: "absolute", right: "20px", width: "250px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
        <MDBox sx={{ marginTop: "2rem" }}>
          {" "}
          <DataGrid
            density="compact"
            getRowId={(row) => row._id}
            components={{ Toolbar: GridToolbar }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // name: false,
                  createdAt: true,
                  updatedAt: true,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                // showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
              },
            }}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            // loading={docVerificationData?.length > 0 ? false : true}
            loading={loading && <CircularProgress />}
            columns={columns}
            rows={[...docVerificationData]}
            pageSize={pageSize}
            rowCount={totalCount}
            paginationMode="server"
            pagination
            page={currentPage}
            onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
            onPageChange={(newPage) => dispatch(pageChanged(newPage))}
            rowsPerPageOptions={[10, 25, 50, 100]}
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
            disableColumnFilter
          />{" "}
        </MDBox>{" "}
      </DashboardLayout>{" "}
    </>
  );
};
export default DocVerification;
