import { createSlice } from "@reduxjs/toolkit";
import { getreduxAvalibilityList } from "./actionCreator";
import { getPermanentJobList } from "./actionCreator";    
import { getPermanentApplicationList } from "./actionCreator";    
import { updatePermanentJobs } from "./actionCreator";    
import { getGCCJobList } from "../../gccJob/Redux/actionCreator";    


import initialState from "./state";
import { toast } from "react-toastify";

export const AvailabilitySlice = createSlice({
  name: "List",
  initialState,
  // reducers: {
  //   pageChanged(state, action) {
  //     state.currentPage = action.payload;
  //   },
  //   pageLimit(state, action) {
  //     state.pageSize = action.payload;
  //   },
    
  // },
  extraReducers: (builder) => {
    //Availabilitys Reducers
    //GET Availability
    builder.addCase(getreduxAvalibilityList.pending, (state) => {});
    builder.addCase(getreduxAvalibilityList.fulfilled, (state, action) => {
      state.Availabilitys_Data.Availabilitys_DataLoading = false;
      state.Availabilitys_Data.Availabilitys_DataInfo =
        action?.payload?.data?.docs;
      // state.Availabilitys_Data.total = action?.payload?.data?.total;
    });
    builder.addCase(getreduxAvalibilityList.rejected, (state, action) => {
      // console.log(action?.payload, "rrrrrrr");
    });
    //GET permanent job
    builder.addCase(getPermanentJobList.pending, (state) => {});
    builder.addCase(getPermanentJobList.fulfilled, (state, action) => {
      // console.log("pkkk");
      // console.log("action------->", action);
      state.Permanent_Job_Data.Permanent_DataLoading = false;
      state.Permanent_Job_Data.Permanent_DataInfo =
        action?.payload?.data?.docs;
      // state.Availabilitys_Data.total = action?.payload?.data?.total;
    });
    builder.addCase(getPermanentJobList.rejected, (state, action) => {
      // console.log(action?.payload, "rrrrrrr");
    });

    //Update Job Jobs
    builder.addCase(updatePermanentJobs.pending, (state) => {});
    builder.addCase(updatePermanentJobs.fulfilled, (state, payload) => {
      
      toast.success("Updated Successfully", { position: "top-right" });
    });
    builder.addCase(updatePermanentJobs.rejected, (state, action) => {
      
      toast.error(action?.payload?.msg, { position: "top-right" });
    });

    //GET permanent Application
    builder.addCase(getPermanentApplicationList.pending, (state) => {});
    builder.addCase(getPermanentApplicationList.fulfilled, (state, action) => {
      // console.log("pkkk");
      // console.log("action------->", action);
      state.Permanent_Application_Data.Permanent_Application_DataLoading = false;
      state.Permanent_Application_Data.Permanent_Application_DataInfo =
        action?.payload?.data?.data;
      // state.Availabilitys_Data.total = action?.payload?.data?.total;
    });
    builder.addCase(getPermanentApplicationList.rejected, (state, action) => {
      // console.log(action?.payload, "rrrrrrr");
    });

    //get gcc
    builder.addCase(getGCCJobList.pending, (state) => {});
    builder.addCase(getGCCJobList.fulfilled, (state, action) => {
      state.GCC_Job_Data.GCC_DataLoading = false;
      state.GCC_Job_Data.GCC_DataInfo =
        action?.payload?.data?.docs;
      // state.Availabilitys_Data.total = action?.payload?.data?.total;
    });
    builder.addCase(getGCCJobList.rejected, (state, action) => {
      // console.log(action?.payload, "rrrrrrr");
    });

    //Delete Availability
    // builder.addCase(deleteAvailability.pending, (state) => {});
    // builder.addCase(deleteAvailability.fulfilled, (state, action) => {

    //   toast.success("Deleted Successfully !", {
    //     position: "top-right",
    //   });
    // });
    // builder.addCase(deleteAvailability.rejected, (state, action) => {

    //   toast.error(action?.payload?.msg, {
    //     position: "top-right",
    //   });
    // });
    //Add Availability
    // builder.addCase(addAvailability.pending, (state) => {});
    // builder.addCase(addAvailability.fulfilled, (state, payload) => {

    // });
    // builder.addCase(addAvailability.rejected, (state, action) => {

    // });
    //Update Availability
    // builder.addCase(updateAvailability.pending, (state) => {});
    // builder.addCase(updateAvailability.fulfilled, (state, payload) => {

    //   toast.success("Updated Successfully !", { position: "top-right" });
    // });
    // builder.addCase(updateAvailability.rejected, (state, action) => {

    //   toast.error(action?.payload?.msg, { position: "top-right" });
    // });

    //photo upload
    // builder.addCase(setProfilePhoto.pending, (state, action) => {

    //   const loadingToast = toast.loading("Image Uploading", {
    //     position: "top-right",
    //   });
    //   //Dismissing toast after 3 seconds
    //   setTimeout(() => {
    //     toast.dismiss(loadingToast);
    //   }, 3000);
    // });
    // builder.addCase(setProfilePhoto.rejected, (state, action) => {
    //   toast.error("Error Upoading Image", {
    //     position: "top-right",
    //   });
    // });
    // builder.addCase(setProfilePhoto.fulfilled, (state, action) => {

    //   state.Availability_profile_image_Data.Availability_profile_image_info =
    //     action?.payload?.data?.data;
    //   if (action?.payload?.status == 200) {
    //     toast.success("Image Uploaded", {
    //       position: "top-right",
    //     });
    //   }
    // });
  },
});



// export const {
//   pageChanged,
//   pageLimit,
// } = AvailabilitySlice.actions;

export default AvailabilitySlice.reducer;
