import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  Link,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Moment from 'moment';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SelectDropDown from "examples/DropDown";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import QuestionForm from "examples/UserDetailsDynamicForm/QuestionsForm";
import { setQualificationType } from "layouts/events/Redux/actionCreator";
import { setSpecialisationsType } from "layouts/events/Redux/actionCreator";
import { setExperience } from "layouts/events/Redux/actionCreator";
//import { setDepartmentType } from "layouts/events/Redux/actionCreator";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRateByIdService } from "services/hospitalServices";
import { HospitalBranchList, getDepartmentsList, updateRate } from "../Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import { getDepartments } from "services/hospitalServices";

const HospitalDetailsForm = () => {
  const ID = window.location.href.split("rates")[1].split("/")[1];
  const [responseData, setResponseData] = useState([]);

  //Fetching Drop Downs Data from Events Redux Rest will be created in Hospital Redux
  const {
    Experience_Data,
    Profession_Type_Data,
    Qualification_Type_Data,
    Specialization_Type_Data,
    Department_Type_Data,
   
  } = useSelector((state) => state.events);
  const { hospital_department_Data } = useSelector((state) => state.hospital);
  
  //Branch Dropown Data
  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  //USer Options
  const { UsersData } = useSelector((state) => state.common);

  const experienceData = Experience_Data?.experienceInfo;
  const professionData = Profession_Type_Data?.professionInfo;
  const qualificationsData = Qualification_Type_Data?.qualificationInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;
  const departmentData =hospital_department_Data?.departmentInfo ;
  
  const branchData = hospital_branch_Data?.branchInfo;
  const userInfo = UsersData?.usersInfo;

  const experienceOptions = [];
  const professionOptions = [];
  const qualificationOptions = [];
  const specializationOptions = [];
  const departmentOptions = [];
  const branchOptions = [];
  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });
  departmentData.map((ele) => {
    let obj = {
      key: ele?._id,
      value: ele?.name,
    };

    departmentOptions.push(obj);
  });



  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  qualificationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    qualificationOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  const getData = async () => {
    let response = await getRateByIdService(ID);
    setResponseData(response);
   
    return response[0];
  };

 

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });

  //Handler Functions

  //IDS
  const [professionpayloadID, setProfessionID] = useState("");
  const [qualificationPayloadID, setQualificationID] = useState("");
  const [branchPayloadID, setBranchID] = useState("");
  const [specializationPayloadID, setSpecializationID] = useState("");
  const [departmentPayloadID, setDepartmentID] = useState("");
  const [experiencePayloadID, setExperienceID] = useState("");

  //Handler Functions
  const handleProfessionChange = (e) => {
    setValue("profession.name", e.target.value);
    let professionID;

    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        professionID = obj.key;
      }
    });

    setProfessionID(professionID);
  };

  const handleQualificationChange = (e) => {
    setValue("qualification.name", e.target.value);
    let qualificationID;

    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        qualificationID = obj.key;
      }
    });

    setQualificationID(qualificationID);
  };
  const[count1,setCount1]=useState(0);
  const handleBranchChange = (e) => {
    setValue("branch.name", e.target.value);
  setCount1(1);
    setValue("department.name", "none");
  
    let branchID;

    branchOptions.map((obj) => {
      if (obj.value == e.target.value) {
        branchID = obj.key;
      }
    });

    setBranchID(branchID);
  };

  const handleDepartmentChange = (e) => {
    setValue("department.name", e.target.value);
    let departmentID;

    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        departmentID = obj.key;

      }
    });

    setDepartmentID(departmentID);
  };

  const handleSpecializationChange = (e) => {
    setValue("specialization.name", e.target.value);
    let specializationID;

    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        specializationID = obj.key;
      }
    });

    setSpecializationID(specializationID);
  };

  const handleExperienceChange = (e) => {
    setValue("experience.name", e.target.value);
    let experienceID;
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        experienceID = obj.key;
      }
    });
    setExperienceID(experienceID);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    let payload = {
      marginCandidate: data?.marginCandidate,
      marginHospital: data?.marginHospital,
      minRate: data?.minRate,
      experience: experiencePayloadID
        ? experiencePayloadID
        : data?.experience?._id,
      specialization: specializationPayloadID
        ? specializationPayloadID
        : data?.specialization?._id,
      department: departmentPayloadID,
        // ? departmentPayloadID
        // : data?.department?._id,
      branch: branchPayloadID ? branchPayloadID : data?.branch?._id,
      qualification: qualificationPayloadID
        ? qualificationPayloadID
        : data?.qualification?._id,
      profession: professionpayloadID
        ? professionpayloadID
        : data?.profession?._id,
     
    };
  

    dispatch(updateRate({ payload: payload, id: ID })).then((res) => {
     
      if (!res?.error) {
        navigate("/hospital/rates");
      }
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const[branchID,setBranchhID]=useState();
  //Dispatching DropDown APIS
  useEffect(() => {
    dispatch(setProfessionType());
    dispatch(setSpecialisationsType());
    dispatch(setQualificationType());
    dispatch(setExperience());
    dispatch(setUsersDropdown());
    // dispatch(setDepartmentType());
   
    dispatch(
      HospitalBranchList([true])
    );
  }, []);
const[count,setCount]=useState(0);


  useEffect(()=>{
    if(count==1)
    dispatch(getDepartmentsList([branchID]));
  },[branchID]);
  useEffect(()=>{
    if(count1==1)
    dispatch(getDepartmentsList([branchPayloadID]));
  },[branchPayloadID]);

const[created1,setCreated1]=useState(false);
const[updated1,setUpdated1]=useState(false);

  useEffect(()=>{

    const created=getValues("createdAt");
    const updated=getValues("updatedAt");
    const branchID=getValues("branch._id");


    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }
    setBranchhID(branchID);
    setCount(1);
    if(created!==undefined && created != "")
    {
        if(!created1)
        {
          setValue("createdAt",Moment(created).format("DD/MM/YYYY"))
        }
        setCreated1(true)
    }
  


    if(updated!==undefined && updated != "")
    {
        if(!updated1)
        {
          setValue("updatedAt",Moment(updated).format("DD/MM/YYYY"))
        }
        setUpdated1(true)
    }
   


  },[getValues("createdAt"),getValues("updatedAt"),getValues("branch._id")])

  const formValues = getValues();
 
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ width: "100%", marginBottom: "20px" }}>
                  <Typography variant="h3">{ID}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profession</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={professionOptions}
                    placeholder="Select"
                    name="profession.name"
                    getValues={getValues}
                    control={control}
                    customOnChange={handleProfessionChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Qualification</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={qualificationOptions}
                    placeholder="Select"
                    name="qualification.name"
                    control={control}
                    getValues={getValues}
                    customOnChange={handleQualificationChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Branch</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={branchOptions}
                    placeholder="Select"
                    name="branch.name"
                    getValues={getValues}
                    control={control}
                    customOnChange={handleBranchChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Department</Typography>
                </Grid>
                <Grid item xs={9}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Department Name"
                          sx={{ width: "100%" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={responseData[0]?.department?.name}
                        />
                      </Grid>
                {/* <Grid item xs={9}>
                  <SelectDropDownNew
                    options={departmentOptions}
                    name="department.name"
                    control={control}
                    getValues={getValues}
                    defaultValue="none"
                    placeholder="Select"
                    customOnChange={handleDepartmentChange}
                  />
                </Grid> */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Specialization</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={specializationOptions}
                    placeholder="Select"
                    name="specialization.name"
                    control={control}
                    getValues={getValues}
                    customOnChange={handleSpecializationChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Experience</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={experienceOptions}
                    placeholder="Select"
                    name="experience.name"
                    control={control}
                    getValues={getValues}
                    customOnChange={handleExperienceChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Min Rate</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    {...register("minRate")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Margin Hospital</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    {...register("marginHospital")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Margin Candidate</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    {...register("marginCandidate")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created At</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                  disabled
                    fullWidth
                    {...register("createdAt")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated At</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                  disabled
                    fullWidth
                    {...register("updatedAt")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created By</Typography>
                </Grid>
                <Grid item xs={9}>
                <MDInput
                    disabled
                    fullWidth
                    {...register("createdBy.name.full")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated By</Typography>
                </Grid>
                <Grid item xs={9}>
                <MDInput
                 disabled
                    fullWidth
                    {...register("updatedBy.name.full")}
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid xs={12} sx={{ width: "100%", margin: "10px" }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="contained" color="info" type="submit">
                    Save
                  </MDButton>
                  {/* <MDButton variant="text" color="info">
                    Reset
                  </MDButton> */}
                </Grid>
              </Grid>
            </form>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default HospitalDetailsForm;
