import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { getEventList } from "services/eventServices";
import { getInterviewList } from "services/eventServices";
import { formatDate } from "util/Helper";
import { eventDeletion, eventList, interviewList } from "./Redux/actionCreator";
import moment from "moment";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InterviewListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //VIEW
  const handleView = (id) => {
    navigate(`/eventDetails/update/${id}`);
  };

  //VALUE GETTERS

  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getJob(params) {
    return `${params?.row?.job?.name || "NA"}`;
    //return `${params?.row?.job?._id|| "NA"}`;
  }

  function getApplication(params) {
    return `${params?.row?.application?._id || "NA"}`;
  }

  function getCandidate(params) {
    return `${params?.row?.candidate?.name?.full || "NA"}`;
  }

  function getInterviewstatus(params) {
    return `${params?.row?.interviewStatus || "NA"}`;
  }

  function getEmployer(params) {
    return `${params?.row?.employer?.name?.full || "NA"}`;
  }

  // function getDate(params) {
  //   return `${params?.row?.date || "NA"}`;
  // }

  function getDate(params) {
    var rowName = formatDate(params?.row?.date);
    var dateNOW = new Date(rowName);
    var day = dateNOW.getDate();
    var month = dateNOW.getMonth() + 1;
    var year = dateNOW.getFullYear();
  
    var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;

    return formattedDate;
  }

  function getInterviewLength(params) {
    return `${params?.row?.interviewLength || "NA"}`;
  }

  function getStartTime(params) {
    return `${params?.row?.startTime || "NA"}`;
  }

  function getSlot(params) {
    const rowarray = params?.row?.suggestedSlot;
    const values = [];
    if (rowarray) {
      rowarray.map((obj) => {
        values.push(
          `${obj?.start}-${obj?.date}-${obj?.interviewLength}`
        );
      });
    }

    return values;
  }

  // function getCreatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   console.log("rowName: ", rowName);
  //   var dateNOW = new Date(rowName);
  //   console.log("dateNOW: ", dateNOW);
  //   return dateNOW;
  // }
  function getCreatedAT(params) {
    var rowName = formatDate(params?.row?.createdAt);
    var dateNOW = new Date(rowName);
    var day = dateNOW.getDate();
    var month = dateNOW.getMonth() + 1;
    var year = dateNOW.getFullYear();
  
    var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;

    return formattedDate;
  }
  

//   function getUpdatedAT(params) {
//     var rowName = formatDate(params?.row?.createdAt);
//     var dateNOW = new Date(rowName);
//     return dateNOW;
//   }

  //InterviewsSlot Listing Columns
  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowId = cellValues?.row?._id;
        return <Link to={`/id/${rowId}`}>{rowId}</Link>;
        //return rowId;
      },

      valueGetter: getID,
      flex: 1.2,
    },
    {
      field: "job",
      headerName: `Job Code`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.job?.name;
        const rowId = cellValues?.row?.job?._id;
        return (
          <Link to={`/jobDetails/code/${rowName}/${rowId}`}>{rowName}</Link>
        );
      },
      valueGetter: getJob,
      flex: 1,
    },

    {
      field: "application",
      headerName: `Application ID`,
      renderCell: (cellValues) => {
        const Id = cellValues?.row?.application?._id;
        return <Link to={`/jobs/application/${Id}`}>{Id}</Link>;
        // return rowCode;
        //console.log("Application-->", cellValues.row);
      },
      valueGetter: getApplication,
      flex: 1,
    },

    {
      field: "candidate",
      headerName: "Candidate Name",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.candidate?.name?.full;
        const rowId = cellValues?.row?.candidate?._id;
        return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
        //console.log("Candidate-->", cellValues.row._id);
      },

      valueGetter: getCandidate,
      flex: 1,
    },
    {
      field: "interviewStatus",
      headerName: "InterviewStatus",
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.interviewStatus;
        //return <Link to={`job/${rowCode}`}>{rowCode}</Link>;
        return rowCode;
      },

      valueGetter: getInterviewstatus,
      flex: 1,
    },
    {
      field: "employer",
      headerName: "Employer Name",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.employer?.name?.full;
        const rowId = cellValues?.row?.employer?._id;
        return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
        //console.log("Employer-->", cellValues.row);
      },

      valueGetter: getEmployer,
      flex: 1,
    },
    {
      field: "suggestedSlot",

      headerName: "SuggestedSlot",

      renderCell: (cellValues) => {
        const rowarray = cellValues?.row?.suggestedSlot;

        const values = [];
        if (rowarray) {
          rowarray.map((obj) => {
            values.push(
              `${obj?.start}-${obj?.date}-${obj?.interviewLength}`
            );
          });
        }

        return values;
      },
      flex: 2,
      valueGetter: getSlot,
    },
    {
      field: "date",
      headerName: "Date",
      renderCell: (cellValues) => {
        // const rowCode = cellValues?.row?.date;
        // return rowCode;
        var a;
        var rowName = cellValues?.row?.date;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    //     var rowName = formatDate(cellValues?.row?.date);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;

    // return formattedDate;
      },
      type: "date",
      valueGetter: getDate,
      flex: 1,
    },
    {
      field: "interviewLength",
      headerName: `InterviewLength`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.interviewLength;
        return rowCode;
      },
      valueGetter: getInterviewLength,
      flex: 1,
    },
    {
      field: "startTime",
      headerName: `StartTime`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.startTime;
        return rowCode;
      },
      valueGetter: getStartTime,
      flex: 1,
    },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        var rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    //     var rowName = formatDate(cellValues?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;

    // return formattedDate;
      },
      type: "date",
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },
    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    //   flex: 1.5,
    // },
  ];
  const [loading, setLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const { Interview_Details_Data } = useSelector((state) => state.events);
  const eventListData = Interview_Details_Data?.interviewDetailsInfo;
  // console.log("eventListData-->", eventListData);
  
  var eventLoading = Interview_Details_Data?.interviewDetailsLoading;

  useEffect(() => {
    setLoading(eventLoading);
  }, [eventLoading]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  //   const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  //   const [deletedRecords, setDeletedRecords] = useState([]);
  //   const [notDeletedRecords, setNotDeletedRecords] = useState([]);

  //   const handleDeleteApi = async (checked) => {
  //     if (checked) {
  //       setShowDeletedRecords(true);
  //       //API CALL for Delted Records
  //       const response = await getInterviewList(checked);
  //       setDeletedRecords(response?.data?.docs);
  //     } else {
  //       setShowDeletedRecords(false);
  //       //API CALL for Not Delted Records
  //       const response = await getInterviewList(checked);
  //       setNotDeletedRecords(response?.data?.docs);
  //     }
  //   };

  useEffect(() => {
    dispatch(interviewList(false));
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        //title="Create Event"
        title1="Interview Details"
        isOpen={isOpen}
        showDialog={false}
        showisDeleted={false}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        //handleDeleteApi={handleDeleteApi}
        // customDialogOnClick={() => {
        //   navigate(`/eventDetails/create`);
        // }}
      />

      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `Interview-${formatDate()}`,
            },
          },
        }}
        density="compact"
        initialState={{
          columns: {
            // columnVisibilityModel: {
            //   isDeleted: false,
            //   professionalType: false,
            //   qualification: false,
            //   specialization: false,
            //   numExperience: false,
            //   workCity: false,
            //   createdAt: false,
            //   updatedAt: false,
            //   createdBy: false,
            //   updatedBy: false,
            //},
          },
        }}
        //isRowSelectable
        loading={eventLoading ? true : false}
        columns={columns}
        rows={[...eventListData]}
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
};
export default InterviewListing;
