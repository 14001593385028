import { Delete } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getCompany } from "services/hospitalServices";
import { formatDate } from "util/Helper";
import HospitalCorporateDialog from "./HospitalCorporateDialog";
import axiosInstance from "../../../axios";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

import {
  deleteCompany,
  deleteCorporate,
  deletehospitalCompany,
  getCompanyList,
} from "../Redux/actionCreator";

const HospitalCorporate = () => {
  const { state } = useLocation();

  const dispatch = useDispatch();

  //Handler Functions
  const handleDelete = (ID) => {
    dispatch(deleteCorporate(ID));
  };

  //Value Getters
  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getName(params) {
    return `${params?.row?.name || "NA"}`;
  }

  function getCompanyTypeName(params) {
    return `${params?.row?.name || "NA"}`;
  }

  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }

  // function getCreatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dateNOW = new Date(rowName);
  //   return dateNOW;
  // }

  // function getUpdatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dateNOW = new Date(rowName);
  //   return dateNOW;
  // }

  function getCreatedAT(params) {
    var a
    const rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;
    // return formattedDate;
  }

  function getUpdatedAT(params) {
    var a
    const rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;
    // return formattedDate;
  }

  function getBillingAddressAddress(params) {
    return `${params?.row?.billingAddress?.address?.address || "NA"}`;
  }

  function getBillingAddressCoords(params) {
    return `${params?.row?.billingAddress?.address?.coords || "NA"}`;
  }
  function getBillingAddressCity(params) {
    return `${params?.row?.billingAddress?.address?.city || "NA"}`;
  }
  function getBillingAddressState(params) {
    return `${params?.row?.billingAddress?.address?.state || "NA"}`;
  }
  function getBillingAddressPin(params) {
    return `${params?.row?.billingAddress?.address?.pin || "NA"}`;
  }
  function getBillingAddressCountry(params) {
    return `${params?.row?.billingAddress?.address?.country || "NA"}`;
  }

  function getlogoLocation(params) {
    return `${params?.row?.logo?.location || "NA"}`;
  }
  function getlogoFileName(params) {
    return `${params?.row?.logo?.fileName || "NA"}`;
  }

  function getlogoUri(params) {
    return `${params?.row?.logo?.uri || "NA"}`;
  }

  function getlogoBucket(params) {
    return `${params?.row?.logo?.bucket || "NA"}`;
  }

  function getlogoRegion(params) {
    return `${params?.row?.logo?.region || "NA"}`;
  }

  function getlogoMimeType(params) {
    return `${params?.row?.logo?.mimeType || "NA"}`;
  }

  function getlogoSecureUrl(params) {
    return `${params?.row?.logo?.secureUrl || "NA"}`;
  }

  function getlogoUrl(params) {
    return `${params?.row?.logo?.url || "NA"}`;
  }

  function getlogoCdnUrl(params) {
    return `${params?.row?.logo?.cdnUrl || "NA"}`;
  }

  function getlogoisSecured(params) {
    return `${params?.row?.logo?.isSecured || "NA"}`;
  }

  //Hospital Company Columns
  const columns = [
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name;
        const rowId = cellValues?.row?._id;
        return <Link to={`${rowId}`}>{rowName}</Link>;
      },
      valueGetter: getName,
      flex: 1,
    },
    {
      field: "corporateType",
      headerName: `Corporate Type`,
      renderCell: (cellValues) => {
        const companyTypeName = cellValues?.row?.companyType?.name;
        return companyTypeName;
      },
      valueGetter: getCompanyTypeName,
      flex: 1,
    },

    // {
    //   field: "createdAt",
    //   headerName: `Created At`,
    //   type: "date",

    //   valueGetter: getCreatedAT,
    // },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.createdAt);
        // var dateNOW = new Date(rowName);
        // var day = dateNOW.getDate();
        // var month = dateNOW.getMonth() + 1;
        // var year = dateNOW.getFullYear();
  
        // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;

        // return formattedDate;
      },
      type: "date",

      valueGetter: getCreatedAT,
    },
    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    // },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.updatedAt);
        // var dateNOW = new Date(rowName);
        // var day = dateNOW.getDate();
        // var month = dateNOW.getMonth() + 1;
        // var year = dateNOW.getFullYear();
  
        // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;

        // return formattedDate;
      },
      type: "date",

      valueGetter: getUpdatedAT,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,

      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
    },
    {
      field: "billingAddressAddress",
      headerName: `Billing Address Address`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.address;
      },
      valueGetter: getBillingAddressAddress,
    },
    {
      field: "billingAddressCity",
      headerName: `Billing Address City`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.city;
      },
      valueGetter: getBillingAddressCity,
    },
    {
      field: "billingAddressState",
      headerName: `Billing Address State`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.state;
      },
      valueGetter: getBillingAddressState,
    },
    {
      field: "billingAddressCountry",
      headerName: `Billing Address Country`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.country;
      },
      valueGetter: getBillingAddressCountry,
    },
    {
      field: "billingAddressPin",
      headerName: `Billing Address Pin`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.pin;
      },
      valueGetter: getBillingAddressPin,
    },
    {
      field: "billingAddressCoords",
      headerName: `Billing Address Coords`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.coords;
      },
      valueGetter: getBillingAddressCoords,
    },
    {
      field: "logoLocation",
      headerName: `Logo Location`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.location;
      },
      valueGetter: getlogoLocation,
    },
    {
      field: "logoFilename",
      headerName: `Logo File Name`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.fileName;
      },
      valueGetter: getlogoFileName,
    },

    {
      field: "logoUri",
      headerName: `Logo Uri`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.uri;
      },
      valueGetter: getlogoUri,
    },
    {
      field: "logoBucket",
      headerName: `Logo Bucket`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.bucket;
      },
      valueGetter: getlogoBucket,
    },
    {
      field: "logoRegion",
      headerName: `Logo Region`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.region;
      },
      valueGetter: getlogoRegion,
    },
    {
      field: "logoMimeType",
      headerName: `Logo Mime Type`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.mimeType;
      },
      valueGetter: getlogoMimeType,
    },

    {
      field: "logoSecureUrl",
      headerName: `Logo  Secured URl`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.securedUrl;
      },
      valueGetter: getlogoSecureUrl,
    },
    {
      field: "logoUrl",
      headerName: `Logo   URl`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.url;
      },
      valueGetter: getlogoUrl,
    },
    {
      field: "logiIsSecured",
      headerName: `Logo Is Secured`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.isSecured;
      },
      valueGetter: getlogoisSecured,
    },

    {
      field: "logoCdnUrl",
      headerName: `Logo CDN URL`,

      renderCell: (cellValues) => {
        return cellValues?.row?.logo?.cdnUrl;
      },
      valueGetter: getlogoCdnUrl,
    },

    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
    },
  ];

  const rows = [
    {
      id: 1,
      name: "Aman Super specialty Hospital",
      companyType: "Hospital",
      website: "",
    },
  ];

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const { hospital_company_Data } = useSelector((state) => state?.hospital);
  var hospitalCompanies = hospital_company_Data?.companyInfo;

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getCompany({
        criteria: {
          isDeleted: checked,
        },
      });

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getCompany({
        criteria: {
          isDeleted: checked,
        },
      });
      hospitalCompanies = response?.data?.docs;
    }
  };

  useEffect(() => {
    //Getting Grid Data
    dispatch(getCompanyList({ isDeleted: false }));
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Create Corporate"
        title1="Corporate"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        dialogContent={
          <HospitalCorporateDialog handleDialogClose={handleDialogClose} />
        }
      />
      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                fileName: `Corporate-${formatDate()}`,
              },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          loading={hospitalCompanies.length > 0 ? false : true}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                logoLocation: false,
                logoFilename: false,
                logoUri: false,
                logoBucket: false,
                logoRegion: false,
                logoMimeType: false,
                logoSecureUrl: false,
                logoUrl: false,
                logiIsSecured: false,
                logoCdnUrl: false,

                billingAddressCoords: false,
                billingAddressCountry: false,
                billingAddressState: false,
                billingAddressCity: false,
                billingAddressPin: false,
                billingAddressAddress: false,
                createdAt: false,
                updatedAt: false,
                createdBy: false,
                updatedBy: false,
                Delete: false,
              },
            },
          }}
          rows={
            showDeltedRecords
              ? [...deletedRecords].reverse()
              : [...hospitalCompanies].reverse()
          }
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          density="compact"
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default HospitalCorporate;
