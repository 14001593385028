import { createSlice } from "@reduxjs/toolkit";
import { fetchInvoiceData, fetchInvoiceDetailsData,getFilteredInvoiceDetails, fetchBankDetailsData, fetchTaxInvoiceDetailsData, fetchTaxInvoiceData, getFilteredInvoiceTaxDetails, getInvoiceFinanceByDetails, getInvoiceOperationByDetails } from "./actionCreator";
import initialState from "./state";

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    pageLimit(state, action) {
      state.pageSize = action.payload;
    },
    searchKeyword(state, action){
      state.searchKey = action.payload;
    },
    filterBranch(state, action){
      state.branch = action.payload;
    },
    updateStartDate(state, action){
      state.startDate = action.payload;
    },
    updateEndDate(state, action){
      state.endDate = action.payload;
    },
    updateStartDueDate(state, action){
      state.startDueDate = action.payload;
    },
    updateEndDueDate(state, action){
      state.endDueDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvoiceDetailsData.pending, (state) => {
      state.Invoice_Details_Data.invoiceDetailsLoading = true;
    });
    builder.addCase(fetchInvoiceDetailsData.fulfilled, (state, action) => {
      state.Invoice_Details_Data.invoiceDetailsLoading = false;

      const responseArray = JSON.stringify(
        action?.payload?.response?.data?.data?.data
      );
      
      state.Invoice_Details_Data.invoiceDetailsInfo = JSON.parse(responseArray);
      state.Invoice_Details_Data.total = action?.payload?.response?.data?.data?.total;
    });
    builder.addCase(fetchInvoiceDetailsData.rejected, (state, action) => {
      state.Invoice_Details_Data.invoiceDetailsLoading = false;
      state.Invoice_Details_Data.invoiceDetailsError = "Invoice Details Error";
    });

    //GET Filtered Invoice

    builder.addCase(getFilteredInvoiceDetails.pending, (state) => {
      state.Invoice_Filtered_Data.invoiceFilteredLoading = true;
    });
    builder.addCase(getFilteredInvoiceDetails.fulfilled, (state, action) => {
      state.Invoice_Filtered_Data.invoiceFilteredLoading = false;

      // console.log("action", action)
      const responseArray = JSON.stringify(
        action?.payload?.data
      );

      
      state.Invoice_Filtered_Data.invoiceFilteredInfo = JSON.parse(responseArray);
      // state.Invoice_Details_Data.total = action?.payload?.response?.data?.data?.total;
    });
    builder.addCase(getFilteredInvoiceDetails.rejected, (state, action) => {
      state.Invoice_Filtered_Data.invoiceFilteredLoading = false;
      state.Invoice_Filtered_Data.invoiceFilteredError = "Invoice Filtered Error";
    });

    // getInvoiceFinanceByDetails get data for finance invoices
    builder.addCase(getInvoiceFinanceByDetails.pending, (state) => {
      state.Invoice_FinanceBy_Data.invoiceFinanceByLoading = true;
    });
    builder.addCase(getInvoiceFinanceByDetails.fulfilled, (state, action) => {
      state.Invoice_FinanceBy_Data.invoiceFinanceByLoading = false;

      // console.log("action", action)
      const responseArray = JSON.stringify(
        action?.payload?.data
      );

      
      state.Invoice_FinanceBy_Data.invoiceFinanceByInfo = JSON.parse(responseArray);
      // state.Invoice_Details_Data.total = action?.payload?.response?.data?.data?.total;
    });
    builder.addCase(getInvoiceFinanceByDetails.rejected, (state, action) => {
      state.Invoice_FinanceBy_Data.invoiceFinanceByLoading = false;
      state.Invoice_FinanceBy_Data.invoiceFinanceByError = "Invoice Financebydata Error";
    });

    // getInvoiceOperationByDetails  get data for operation invoices
    builder.addCase(getInvoiceOperationByDetails.pending, (state) => {
      state.Invoice_OperationBy_Data.invoiceOperationByLoading = true;
    });
    builder.addCase(getInvoiceOperationByDetails.fulfilled, (state, action) => {
      state.Invoice_OperationBy_Data.invoiceOperationByLoading = false;

      // console.log("action", action)
      const responseArray = JSON.stringify(
        action?.payload?.data
      );

      
      state.Invoice_OperationBy_Data.invoiceOperationByInfo = JSON.parse(responseArray);
      // state.Invoice_Details_Data.total = action?.payload?.response?.data?.data?.total;
    });
    builder.addCase(getInvoiceOperationByDetails.rejected, (state, action) => {
      state.Invoice_OperationBy_Data.invoiceOperationByLoading = false;
      state.Invoice_OperationBy_Data.invoiceOperationByError = "Invoice Operationbydata Error";
    });


     // get details by id
     builder.addCase(fetchInvoiceData.pending, (state) => {
      state.Invoice_Data.invoiceDataLoading = true;
    });
    builder.addCase(fetchInvoiceData.fulfilled, (state, action) => {
      state.Invoice_Data.invoiceDataLoading = false;

      // console.log("action", action)

      const responseArray = JSON.stringify(
        action?.payload?.response?.data?.data?.data
      );

      // console.log("responseArray", responseArray)

      
      state.Invoice_Data.invoiceDataInfo = JSON.parse(responseArray);
    });
    builder.addCase(fetchInvoiceData.rejected, (state, action) => {
      state.Invoice_Data.invoiceDataLoading = false;
      state.Invoice_Data.invoiceDataError = "Invoice Error";
    });


    //bank list data
    builder.addCase(fetchBankDetailsData.pending, (state) => {
      state.Bank_Details_Data.bankDetailsLoading = true;
    });
    builder.addCase(fetchBankDetailsData.fulfilled, (state, action) => {
      state.Bank_Details_Data.bankDetailsLoading = false;
      state.Bank_Details_Data.bankDetailsInfo = action?.payload?.response?.data?.docs;
    });
    builder.addCase(fetchBankDetailsData.rejected, (state, action) => {
      state.Bank_Details_Data.bankDetailsLoading = false;
      state.Bank_Details_Data.bankDetailsError = "Bank Details Error";
    });

    //tax invoice list
    builder.addCase(fetchTaxInvoiceDetailsData.pending, (state) => {
      state.Invoice_Tax_Details_Data.invoiceTaxDetailsLoading = true;
    });
    builder.addCase(fetchTaxInvoiceDetailsData.fulfilled, (state, action) => {
      state.Invoice_Tax_Details_Data.invoiceTaxDetailsLoading = false;

      const responseArray = JSON.stringify(
        action?.payload?.response?.data?.data?.data
      );
      
      state.Invoice_Tax_Details_Data.invoiceTaxDetailsInfo = JSON.parse(responseArray);
      state.Invoice_Tax_Details_Data.total = action?.payload?.response?.data?.data?.total;
    });
    builder.addCase(fetchTaxInvoiceDetailsData.rejected, (state, action) => {
      state.Invoice_Tax_Details_Data.invoiceTaxDetailsLoading = false;
      state.Invoice_Tax_Details_Data.invoiceTaxDetailsError = "Invoice  Details Error";
    });

    // get tax details by id
    builder.addCase(fetchTaxInvoiceData.pending, (state) => {
      state.Invoice_Tax_Data.invoiceTaxDataLoading = true;
    });
    builder.addCase(fetchTaxInvoiceData.fulfilled, (state, action) => {
      state.Invoice_Tax_Data.invoiceTaxDataLoading = false;

      // console.log("action", action)

      const responseArray = JSON.stringify(
        action?.payload?.response?.data?.data?.data
      );

      // console.log("responseArray", responseArray)

      
      state.Invoice_Tax_Data.invoiceTaxDataInfo = JSON.parse(responseArray);
    });
    builder.addCase(fetchTaxInvoiceData.rejected, (state, action) => {
      state.Invoice_Tax_Data.invoiceTaxDataLoading = false;
      state.Invoice_Tax_Data.invoiceTaxDataError = "Invoice Tax Error";
    });

    //GET Filtered Invoice Tax

    builder.addCase(getFilteredInvoiceTaxDetails.pending, (state) => {
      state.Invoice_Tax_Filtered_Data.invoiceTaxFilteredLoading = true;
    });
    builder.addCase(getFilteredInvoiceTaxDetails.fulfilled, (state, action) => {
      state.Invoice_Tax_Filtered_Data.invoiceTaxFilteredLoading = false;

      // console.log("action", action)
      const responseArray = JSON.stringify(
        action?.payload?.data
      ); 
      state.Invoice_Tax_Filtered_Data.invoiceTaxFilteredInfo = JSON.parse(responseArray);
      // state.Invoice_Details_Data.total = action?.payload?.response?.data?.data?.total;
    });
    builder.addCase(getFilteredInvoiceTaxDetails.rejected, (state, action) => {
      state.Invoice_Tax_Filtered_Data.invoiceTaxFilteredLoading = false;
      state.Invoice_Tax_Filtered_Data.invoiceTaxFilteredError = "Invoice Tax Filtered Error";
    });
  
  },
});
export const {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterBranch,
  updateStartDate,
  updateEndDate,
  updateStartDueDate,
  updateEndDueDate
} = invoiceSlice.actions;
export default invoiceSlice.reducer;
