import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../../axios";

const cookies = new Cookies();

export const clearError = (state) => {
  state.error = "";
};

// Login Action
export const login = createAsyncThunk(`auth/login`, async (payload) => {
  if (payload.email && payload.password) {
    const response = await axiosInstance.post("/adminuser/login/", payload);

    const t_id = response.data.token;
    window.localStorage.setItem("t_id", t_id);

    cookies.set("t_id", t_id);
    // window.location.reload();
    return t_id;
  } else {
    alert("Please enter valid credentials");
  }
});

// Logout Action
export const logout = createAsyncThunk("auth/logout", () => {
  try {
    cookies.remove("t_id");
    window.localStorage.removeItem("t_id");

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    function deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++)
        deleteCookie(cookies[i].split("=")[0]);
    }

    function setCookie(name, value, expirydays) {
      var d = new Date();
      d.setTime(d.getTime() + expirydays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = name + "=" + value + "; " + expires;
    }
    function deleteCookie(name) {
      setCookie(name, "", -1);
    }
    deleteAllCookies();
    return true;
  } catch (e) {
    
  }
});
