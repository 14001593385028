// UserTaxInvoiceDetails
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import authoritySignature from "assets/images/authoritySignature.png";
import IfanFlexLogo from "assets/images/ifanFlexLogo.png";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import {
  fetchInvoiceDetailsData,
  getFilteredInvoiceDetails,
  uploadPaidInvoice,
  updateInvoiceById,
  fetchTaxInvoiceDetailsData,
  getFilteredInvoiceTaxDetails
} from "./Redux/actionCreator";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterBranch,
  updateStartDate,
  updateEndDate,
} from "./Redux/reducer";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";
import Menu from "@material-ui/core/Menu";
import jsPDF from "jspdf";
import JSZip from "jszip";
import UploadDialog from "./UploadPaidDialog";
import axiosInstance from "../../axios";
// const RecordsPerPage = 50;
const UserTaxInvoiceDetails = (handleUploadDialogClose) => {
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  // const [currentPage, setCurrentPage] = useState(0);
  const [currentFilterPage, setCurrentFilterPage] = useState(0);
  const [totalFilterRecords, setFilterTotalRecords] = useState(0);
  const [filterRecordsPerPage, setFilterRecordsPerPage] = useState(10);
  const [showPagination, setShowPagination] = useState(false);
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedOptionPaid, setSelectedOptionPaid] = useState('Unpaid');
  const [selectedOptionPaid, setSelectedOptionPaid] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  const [inputValue, setInputValue] = useState("");

  // const [invoiceData, setInvoiceData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { Invoice_Details_Data, Invoice_Tax_Details_Data } = useSelector(
    (state) => state.invoice
  );
  var invoiceTaxData = Invoice_Tax_Details_Data?.invoiceTaxDetailsInfo;
  // console.log("invoiceTaxData", invoiceTaxData);

  const { Invoice_Filtered_Data , Invoice_Tax_Filtered_Data} = useSelector((state) => state.invoice);
  const invoiceFilteredData = Invoice_Tax_Filtered_Data?.invoiceTaxFilteredInfo;
  // console.log("filtered invoice>>>", invoiceFilteredData);

  const totalCount = Invoice_Tax_Details_Data?.total;
  const currentPage = useSelector((state) => state.invoice.currentPage);
  const pageSize = useSelector((state) => state.invoice.pageSize);
  const searchKey = useSelector((state) => state.invoice.searchKey);
  const filterBranchData = useSelector((state) => state.invoice.branch);
  const startDate = useSelector((state) => state.invoice.startDate);
  const endDate = useSelector((state) => state.invoice.endDate);

  var invoiceLoading = Invoice_Tax_Details_Data?.invoiceTaxDetailsLoading;

  const [branchValue, setBranchValue] = useState("");

  const branchOptions = [];

  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  const branchData = hospital_branch_Data?.branchInfo;

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  useEffect(() => {
    setLoading(invoiceLoading);
  }, [invoiceLoading]);
 

  function getCreatedAt(params) {
    var a;
    const roleName = params?.row?.createdAt;
    // const a = moment(roleName).format("YYYY/MM/DD");
    // return a;
    if (roleName) {
      a = moment(roleName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  const handleView = (id) => {
    navigate(`/professionalTaxInvoice/view/${id}`);
    // const url = `/userInvoice/view/${id}`;
    // window.open(url, "_blank");
  };

  const columns = [
    {
      field: "invoiceNumber",
      headerName: `Invoice Number`,
      renderCell: (cellValues) => {
        // console.log(">>>", cellValues);
        var invoiceNumber = cellValues?.row?.invoiceNumber;
        return invoiceNumber;
      },
      flex: 2,
    },
    {
      field: "professionalName",
      headerName: `Professional Name`,
      renderCell: (cellValues) => {
        // var rowName = cellValues?.row?.userDetails?.name;
        // return rowName;
        var a;
        var rowName = cellValues?.row?.userDetails;
        a = rowName?.name;
        const rowId = rowName?._id;
        return <Link to={`/users/name/${rowId}`}>{a}</Link>;
      },
      flex: 2,
    },
    {
      field: "pan",
      headerName: `Pan Number`,
      renderCell: (cellValues) => {
        var rowName = cellValues?.row?.userDetails?.pan;
        return rowName;
      },
      flex: 1.5,
    },
    {
      field: "bankStatus",
      headerName: `Bank Status`,
      renderCell: (cellValues) => {
        var rowName;
        const rowData = cellValues?.row?.bank?.status;
        if (rowData) {
          rowName = rowData;
          return `${rowName}`;
        } else {
          rowName = "NA";
          return `${rowName}`;
        }
      },
      flex: 1.5,
    },
    {
      field: "total",
      headerName: `Taxable Amount`,
      renderCell: (cellValues) => {
        var rowName = cellValues?.row?.total;
        return rowName.toFixed(2);
      },
      flex: 1.5,
    },

    {
      field: "preTax",
      headerName: `Pre Tax`,
      renderCell: (cellValues) => {
        var rowName = cellValues?.row?.totalConvenienceFee;
        return rowName.toFixed(2);
      },
      flex: 1,
    },

    {
      field: "stateCode",
      headerName: `State Code`,
      renderCell: (cellValues) => {
        var rowName;
        const rowData = cellValues?.row?.stateCode;
        if (rowData) {
          rowName = rowData;
          return `${rowName}`;
        } else {
          rowName = "NA";
          return `${rowName}`;
        }
      },
      flex: 1.5,
    },

    {
      field: "address",
      headerName: `Address State`,
      renderCell: (cellValues) => {
        var rowName;
        const rowData = cellValues?.row?.userDetails?.address?.state;
        if (rowData) {
          rowName = rowData;
          return `${rowName}`;
        } else {
          rowName = "NA";
          return `${rowName}`;
        }
      },
      flex: 1.5,
    },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.createdAt;
        if (roleName) {
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const a = moment(roleName).format("YYYY/MM/DD");
        // aa
        // return a;
      },
      // flex: 2.5,
      type: "date",
      valueGetter: getCreatedAt,
    },
    {
      field: "invoicesTax",
      headerName: `View`,

      renderCell: ({ row }) => {
        return (
          <IconButton
            aria-label="view"
            size="small"
            onClick={() => handleView(row._id)}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        );
      },
      // flex: 2,
    },
  ];

  //for display with paginated wise
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        fetchTaxInvoiceDetailsData({
          currentPage: currentPage,
          pageSize: pageSize,
            searchKey: searchKey,
            startDate: startDate,
            endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    startDate,
    endDate,
  ]);

    //for export all csv in one click
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        await dispatch(
          getFilteredInvoiceTaxDetails({
            searchKey: searchKey,
            startDate: startDate,
            endDate: endDate,
          })
        );
        setLoading(false);
      };

      fetchData();
    }, [dispatch, searchKey, startDate, endDate]);

    //With filter and searching for exporting as pdf and csv
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const hasAnyFilter =
          searchKey.trim() !== "" ||
          startDate.trim() !== "" ||
          endDate.trim() !== "";

        try {
          if (hasAnyFilter) {
            await dispatch(
              getFilteredInvoiceTaxDetails({
                searchKey: searchKey,
                startDate: startDate,
                endDate: endDate,
              })
            );
          } else {
            // If nothing is specified, you might want to handle this case
            await dispatch(
              fetchTaxInvoiceDetailsData({
                currentPage: currentPage,
                pageSize: pageSize,
                searchKey: searchKey,
                startDate: startDate,
                endDate: endDate,
              })
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        setLoading(false);
      };

      fetchData();
    }, [
      dispatch,
      currentPage,
      pageSize,
      searchKey,
      startDate,
      endDate,
    ]);

  useEffect(() => {
    dispatch(HospitalBranchList([true]));
  }, []);

  const handleClose = () => {
    setCompletionMessage("");
  };

  const handlePageChange = (newPage) => {
    setCurrentFilterPage(newPage);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFClose = () => {
    setOpen(false);
  };

  const handleDownloadDataWithConfirmation = () => {
    setOpen(true);
  };

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  // const handleDownloadData = async () => {
  //   // console.log("in", invoiceData)
  //   setLoading1(true);
  //   setOpen(false);
  //   const zip = new JSZip();
  //   Promise.all(
  //       invoiceTaxData.map((invoiceTaxItem, index) => {
  //       return new Promise((resolve, reject) => {
  //         console.log("invoiceTaxItem",invoiceTaxItem);
  //         const pdf = new jsPDF({
  //           orientation: "portrait",
  //           unit: "px",
  //           hotfixes: ["px_scaling"],
  //           format: [1200, 800],
  //         });
  //         const pdfContent = `
        
  //         <!DOCTYPE html>
  //         <html lang="en">
          
  //         <head>
  //           <meta charset="UTF-8">
  //           <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //           <title>Your Page Title</title>
  //           <style>
  //         .invoiceHeader{
  //             display: flex;
  //             justify-content: space-between;
  //             align-content: center;
  //         }
  //         .headerContent{
  //             text-align-last: end;
  //         }
  //         table, th, td {
  //             border: 1px solid black;
  //             border-collapse: collapse;
  //             padding: 5px;
  //             height: 5px;
  //           }
  //           th {
  //             text-align: left;
  //             height: 5px;
          
  //           }
  //           .textAlignmentCenter{
  //             text-align-last: center;
  //           }
  //           .textAlignmentRight{
  //             text-align-last: end;
  //           }
  //           .textFit{
  //             white-space: nowrap;
  //             text-overflow: ellipsis;
  //             overflow: hidden;
  //           }
  //           .notePoint{
  //             font-weight: bold;
  //           }
  //           * {
  //                 margin:0;
  //                 padding:0;
  //                 font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  //                 color:  #001f3f;
  //               }
  //               .hr-line {
  // width: 100%;
  // margin: 0.5em 0;
  // border-top: 1px solid #000;
  // }
    
          
  //           </style>
  //           <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js"></script>
  //         </head>
          
  //         <body >
  //           <div style="max-width: fit-content; font-size: 9.7px;">
  //             <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
  //               <div class="invoiceHeader">
  //                 <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
  //                 <div class="headerContent">
  //                   <h4>Duplicate Copy</h4><br />
  //                   <small>Plot No.89, 3Floor, Sector 44</small><br />
  //                   <small>Gurgaon - 122003, Haryana (India)</small><br />
  //                   <small>Email: info@ifanglobal.com</small>
  //                   <hr />
  //                   <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
  //                   <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
  //                   <small>Saket Delhi New Delhi DI 110030 In</small>
  //                 </div>
  //               </div>
  //               <br />
  //               <table>
  //                 <tbody>
  //                   <tr>
  //                     <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
  //                       TAX INVOICE
  //                     </th>
  //                   </tr>
  //                   <tr>
  //                     <th>Service Provider</th>
  //                     <th>Invoice No.</th>
  //                     <th>Dated</th>
  //                   </tr>
  //                   <tr>
  //                     <td>IFAN Flex Pvt. Ltd.</td>
  //                     <td>${invoiceTaxItem?.invoiceNumber}</td>
  //                     <td>
  //                       ${moment(invoiceTaxItem?.createdAt).format("DD-MM-YYYY")}
                        
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td>
  //                       Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
  //                       122003
  //                     </td>
  //                     <td colSpan="2">
  //                       Tax Registration
  //                       <br />
  //                       PAN:AAFCI9183G
  //                       <br />
  //                       GSTN:06AAFCI9183G1ZB
  //                       <br />
  //                       CIN:U74999DL2020PTC373866
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <th>Buyer:</th>
  //                     <th colSpan="2">Instructions:</th>
  //                   </tr>
  //                   <tr>
  //                     <td>
  //                       ${invoiceTaxItem?.userDetails?.name}
  //                       <br />
  //                       ${invoiceTaxItem?.userDetails?.address?.address}
  //                     </td>
          
  //                     <td rowSpan="2" colSpan="2">
  //                       IfanFlex Pvt. Ltd
  //                       <br />
  //                       ICICI Bank Ltd.
  //                       <br />
  //                       Account No. : 402105000512
  //                       <br />
  //                       Branch: NDSE-I, South Extension New Delhi-110049
  //                       <br />
  //                       IFSC:ICIC0004021
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td>
  //                       State Code: ${invoiceTaxItem?.stateCode ? invoiceTaxItem?.stateCode : "NA"}
  //                       <br />
  //                       Reverse charges applicable:${
  //                           invoiceTaxItem?.reverseChargesApplicable ? invoiceTaxItem?.reverseChargesApplicable : "NA"
  //                       }
  //                       <br />
  //                       GSTIN: ${invoiceTaxItem?.gst ? invoiceTaxItem?.gst : "NA"}
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <th className="textAlignmentCenter">S.no.</th>
  //                     <th className="textAlignmentCenter">Description of Service</th>
  //                     <th  className="textAlignmentCenter">Amount</th>
  //                   </tr>
  //                   ${invoiceTaxItem?.invoices?.map((invoice, index) => (
  //                       `<tr key=${index}>
  //                           <td class="textAlignmentCenter">${index + 1}</td>
  //                           <td class="textFit">
  //                               Convenience Fee Against Job Id ${invoice.jobId}
  //                           </td>
  //                           <td class="textAlignmentRight">
  //                               ${invoice.convenienceFee}
  //                           </td>
  //                       </tr>`
  //                   )).join('')}
                    
          
  //                   ${
  //                     !invoiceTaxItem?.cgst || !invoiceTaxItem?.sgst
  //                       ? `
  //                     <tr>
  //                         <td colSpan="2">
  //                           <b>Add: CGST @ 9%</b>
  //                         </td>
  //                         <td class="textAlignmentRight">- </td>
  //                       </tr>
  //                       <tr>
  //                         <td colSpan="2">
  //                           <b>Add: SGST @ 9%</b>
  //                         </td>
  //                         <td class="textAlignmentRight">- </td>
  //                       </tr>
  //                     <tr>
  //                       <td colSpan="2">
  //                         <b>Add: IGST @ 18%</b>
  //                       </td>
  //                       <td  class="textAlignmentRight">${invoiceTaxItem?.gst}</td>
  //                     </tr>
  //                     </>
                      
  //                   `
  //                       : `
                      
  //                       <tr>
  //                         <td colSpan="2">
  //                           <b>Add: CGST @ 9%</b>
  //                         </td>
  //                         <td  class="textAlignmentRight">${invoiceTaxItem?.cgst}</td>
  //                       </tr>
  //                       <tr>
  //                         <td colSpan="2">
  //                           <b>Add: SGST @ 9%</b>
  //                         </td>
  //                         <td  class="textAlignmentRight">${invoiceTaxItem?.sgst}</td>
  //                       </tr>
  //                       <tr>
  //                       <td colSpan="2">
  //                         <b>Add: IGST @ 18%</b>
  //                       </td>
  //                       <td class="textAlignmentRight">-</td>
  //                     </tr>
  //                   `
  //                   }
          
  //                   <tr>
  //                     <td colSpan="2">
  //                       <b>Total</b>
  //                     </td>
  //                     <td  class="textAlignmentRight">${invoiceTaxItem?.total}</td>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //               <div style="margin-top: 10px;">
                  
  //                  <p>Terms & Conditions</p>
  //                  <div style="padding: 20px;">
                  
  //                  <p>1. Subject to 'Delhi' Jurisdiction only</p>
  //                  <p>
  //                    2. Service Accounting Code:${invoiceTaxItem?.sac ? invoiceTaxItem : "_____"}
  //                  </p>
  //                 </ol>
  //                  </div>
  //                  <div>
  //                    <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
  //                    <img
  //                      src=${authoritySignature}
  //                      alt="logosign"
  //                      style="width: 10%;height: 10%;align-self: end;margin: 6px;"
  //                    /><br/>
  //                    <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
  //                     No Signature required as it's a system generated Invoice
  //                    </p>
  //                  </div>
  //                  <hr />
  //                  <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
  //                    <p>Office: +91 124.487 5800</p>
  //                    <p>Fax : +91 124.401 4035</p>
  //                    <p>CIN: U74999DL2020PTC373866</p>
  //                  </div>
  //                </div>
  //               <br />
  //             </div>
  //           </div>
  //         </body>
          
  //         </html>
          
  //                   `;

  //         pdf.html(pdfContent, {
  //           callback: (pdf) => {
  //             // pdf.save("User Invoice.pdf");
  //             // const pdfBlob = window.open(pdf.output("bloburl"));
  //             const pdfBlob = pdf.output("blob");
  //             resolve({ pdfBlob, index });
  //           },
  //         });
  //       });
  //     })
  //   )
  //     .then((pdfResults) => {
  //       pdfResults.forEach(({ pdfBlob, index }) => {
        
  //         // console.log(">>>invxData", invoiceTaxData)
        
  //         const invoiceTaxNumbers = invoiceTaxData[index]?.invoiceNumber;
  //         const lastPart = invoiceTaxNumbers.split("/").pop();
          
  //         zip.file(`${lastPart}.pdf`, pdfBlob);
  //       //   zip.file(`Invoice_Tax${index + 1}.pdf`, pdfBlob);
  //       });

  //       // Generate ZIP file
  //       zip.generateAsync({ type: "blob" }).then(function (content) {
  //         const a = document.createElement("a");
  //         a.href = URL.createObjectURL(content);
  //         // a.download = "Invoices.zip";
  //         a.download = `BulkInvoiceTax_${formatDate()}.zip`;
  //         a.click();
  //         setCompletionMessage(
  //           "All PDFs downloaded and wrapped in ZIP successfully!!"
  //         );
  //         setLoading1(false);
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDFs:", error);
  //       setLoading1(false);
  //     });
  // };


    const handleDownloadData = async () => {
    setLoading1(true);
    setOpen(false);
    const zip = new JSZip();
    const pdfResults = [];

    try {
      for (const [index, invoiceTaxItem] of invoiceTaxData.entries()) {

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          hotfixes: ["px_scaling"],
          format: [1200, 800],
        });

        const pdfContent = `
          <!DOCTYPE html>
          <html lang="en">
          
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Your Page Title</title>
            <style>
          .invoiceHeader{
              display: flex;
              justify-content: space-between;
              align-content: center;
          }
          .headerContent{
              text-align-last: end;
          }
          table, th, td {
              border: 1px solid black;
              border-collapse: collapse;
              padding: 5px;
              height: 5px;
            }
            th {
              text-align: left;
              height: 5px;
          
            }
            .textAlignmentCenter{
              text-align-last: center;
            }
            .textAlignmentRight{
              text-align-last: end;
            }
            .textFit{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .notePoint{
              font-weight: bold;
            }
            * {
                  margin:0;
                  padding:0;
                  font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                  color:  #001f3f;
                }
                .hr-line {
  width: 100%;
  margin: 0.5em 0;
  border-top: 1px solid #000;
  }
    
          
            </style>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js"></script>
          </head>
          
          <body >
            <div style="max-width: fit-content; font-size: 9.7px;">
              <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
                <div class="invoiceHeader">
                  <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
                  <div class="headerContent">
                    <h4>Duplicate Copy</h4><br />
                    <small>Plot No.89, 3Floor, Sector 44</small><br />
                    <small>Gurgaon - 122003, Haryana (India)</small><br />
                    <small>Email: info@ifanglobal.com</small>
                    <hr />
                    <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
                    <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
                    <small>Saket Delhi New Delhi DI 110030 In</small>
                  </div>
                </div>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
                        TAX INVOICE
                      </th>
                    </tr>
                    <tr>
                      <th>Service Provider</th>
                      <th>Invoice No.</th>
                      <th>Dated</th>
                    </tr>
                    <tr>
                      <td>IFAN Flex Pvt. Ltd.</td>
                      <td>${invoiceTaxItem?.invoiceNumber}</td>
                      <td>
                        ${moment(invoiceTaxItem?.createdAt).format("DD-MM-YYYY")}
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                        122003
                      </td>
                      <td colSpan="2">
                        Tax Registration
                        <br />
                        PAN:AAFCI9183G
                        <br />
                        GSTN:06AAFCI9183G1ZB
                        <br />
                        CIN:U74999DL2020PTC373866
                      </td>
                    </tr>
                    <tr>
                      <th>Buyer:</th>
                      <th colSpan="2">Instructions:</th>
                    </tr>
                    <tr>
                      <td>
                        ${invoiceTaxItem?.userDetails?.name || "NA"}
                        <br />
                        ${invoiceTaxItem?.userDetails?.address?.address || "NA"}
                        <br />
                        ${invoiceTaxItem?.userDetails?.address?.state || "NA"}
                      </td>
          
                      <td rowSpan="2" colSpan="2">
                        IfanFlex Pvt. Ltd
                        <br />
                        ICICI Bank Ltd.
                        <br />
                        Account No. : 402105000512
                        <br />
                        Branch: NDSE-I, South Extension New Delhi-110049
                        <br />
                        IFSC:ICIC0004021
                      </td>
                    </tr>
                    <tr>
                      <td>
                        State Code: ${invoiceTaxItem?.stateCode ? invoiceTaxItem?.stateCode : "NA"}
                        <br />
                        Reverse charges applicable:${
                            invoiceTaxItem?.reverseChargesApplicable ? invoiceTaxItem?.reverseChargesApplicable : "NA"
                        }
                        <br />
                        GSTIN: ${invoiceTaxItem?.GSTIN ? invoiceTaxItem?.GSTIN : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th className="textAlignmentCenter">S.no.</th>
                      <th className="textAlignmentCenter">Description of Service</th>
                      <th  className="textAlignmentCenter">Amount</th>
                    </tr>
                    ${invoiceTaxItem?.invoices?.map((invoice, index) => (
                      `<tr key=${index}>
                          <td class="textAlignmentCenter">${index + 1}</td>
                          <td class="textFit">
                              Platform Fee Against Job Id ${invoice.jobId}
                          </td>
                          <td class="textAlignmentRight">
                              ${invoice.convenienceFee ? (invoice.convenienceFee).toFixed(2) : -invoice?.costSaved.toFixed(2)}
                          </td>
                      </tr>
                      <tr key=${index + 'a'}>
                          <td className="textAlignmentCenter"> </td>
                          <td className="textFit">
                              Premium Plan Benefit ${invoice.jobId}
                          </td>
                          <td style="text-align: right;">
                              ${invoice?.costSaved ? invoice?.costSaved.toFixed(2) : '0'}
                          </td>
                      </tr>`
                  )).join('')}
                  

                    <tr>
                      <td colSpan="2">
                        <b>Total Cost Saved</b>
                      </td>
                      <td  class="textAlignmentRight">${ invoiceTaxItem?.totalCostSaved ? -(invoiceTaxItem?.totalCostSaved).toFixed(2) : "0"}</td>
                    </tr>
                    
          
                    ${
                      !invoiceTaxItem?.cgst || !invoiceTaxItem?.sgst
                        ? `
                      <tr>
                          <td colSpan="2">
                            <b>Add: CGST @ 9%</b>
                          </td>
                          <td class="textAlignmentRight">- </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Add: SGST @ 9%</b>
                          </td>
                          <td class="textAlignmentRight">- </td>
                        </tr>
                      <tr>
                        <td colSpan="2">
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td  class="textAlignmentRight">${(invoiceTaxItem?.gst).toFixed(2)}</td>
                      </tr>
                      </>
                      
                    `
                        : `
                      
                        <tr>
                          <td colSpan="2">
                            <b>Add: CGST @ 9%</b>
                          </td>
                          <td  class="textAlignmentRight">${(invoiceTaxItem?.cgst).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <b>Add: SGST @ 9%</b>
                          </td>
                          <td  class="textAlignmentRight">${(invoiceTaxItem?.sgst).toFixed(2)}</td>
                        </tr>
                        <tr>
                        <td colSpan="2">
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td class="textAlignmentRight">-</td>
                      </tr>
                    `
                    } 
                    
                    <tr>
                      <td colSpan="2">
                        <b>Total</b>
                      </td>
                      <td  class="textAlignmentRight">${(invoiceTaxItem?.total).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
                <div style="margin-top: 10px;">
                  
                   <p>Terms & Conditions</p>
                   <div style="padding: 20px;">
                  
                   <p>1. Subject to 'Delhi' Jurisdiction only</p>
                   <p>
                     2. Service Accounting Code:${invoiceTaxItem?.sac ? invoiceTaxItem : "_____"}
                   </p>
                  </ol>
                   </div>
                   <div>
                     <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
                     <img
                       src=${authoritySignature}
                       alt="logosign"
                       style="width: 10%;height: 10%;align-self: end;margin: 6px;"
                     /><br/>
                     <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
                      No Signature required as it's a system generated Invoice
                     </p>
                   </div>
                   <hr />
                   <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
                     <p>Office: +91 124.487 5800</p>
                     <p>Fax : +91 124.401 4035</p>
                     <p>CIN: U74999DL2020PTC373866</p>
                   </div>
                 </div>
                <br />
              </div>
            </div>
          </body>
          
          </html>
          
                    `;

        const pdfBlob = await new Promise((resolve, reject) => {
          pdf.html(pdfContent, {
            callback: (pdf) => {
              const blob = pdf.output("blob");
              resolve({ pdfBlob: blob, index });
            },
          });
        });

        pdfResults.push(pdfBlob);
      }

      pdfResults.forEach(({ pdfBlob, index }) => {
        const invoiceTaxNumbers = invoiceTaxData[index]?.invoiceNumber;
          const lastPart = invoiceTaxNumbers.split("/").pop();
          zip.file(`${lastPart}.pdf`, pdfBlob);
        // zip.file(`Bill_${index + 1}.pdf`, pdfBlob);
      });

      // Generate ZIP file
      const content = await zip.generateAsync({ type: "blob" });

      const a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      // a.download = "Bills.zip";
      a.download = `BulkInvoiceTax_${formatDate()}.zip`;
      a.click();

      setCompletionMessage("All PDFs downloaded and wrapped in ZIP successfully!!");
      setLoading1(false);
    } catch (error) {
      console.error("Error generating PDFs:", error);
      setLoading1(false);
    }
  };

  const handleDownloadDataCSV = async () => {
    try {
      // console.log("filter-->", filteredUserData)

      // if(filteredJobData && filteredJobData.length > 0){
      const response = invoiceFilteredData;
      // console.log("1405-->",response)
      const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // link.download = 'Jobizo Super Admin.csv';
      link.download = `Taxinvoices-${formatDate()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };

  const handleResetFilter = () => {
    window.location.reload();
    dispatch(updateStartDate(""));
    dispatch(updateEndDate(""));
    // setStartDate("");
    // setEndDate("");
    setShowPagination(false);
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    setFilterReset(true);
  };

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    let selectedBranchObjectID;

    branchOptions.forEach((branch) => {
      if (branch.value === selectedBranch) {
        selectedBranchObjectID = branch.key;
      }
    });

    setBranchValue(selectedBranch);
    dispatch(filterBranch(selectedBranchObjectID));
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate));
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate));
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadOptionClick = (option) => {
    setSelectedOption1(option);
    handleCloseMenu();
    // Add logic for PDF or CSV download based on the selected option
    console.log("Downloading as", option);
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    dispatch(searchKeyword(sanitizedSearchKey));
  };
  // console.log("inputValue>>", inputValue)

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value.trim()) {
      // Check if the input value is empty or contains only whitespace
      // window.location.reload();
      setInputValue("");
      dispatch(fetchInvoiceDetailsData()); // Dispatch action to reset search
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title1="Professional Tax Invoice"
        showDialog={false}
        showisDeleted={false}
      />
      <Snackbar
        open={completionMessage !== ""}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
        >
          {completionMessage}
        </MuiAlert>
      </Snackbar>
      {/* <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        displayEmpty
        style={{ marginLeft: "20px", height: "44px" }}
      >
        <MenuItem value="">Choose Filters</MenuItem>
        <MenuItem value="HospitalBranch">Hospital Branch</MenuItem>
      </Select>
      {selectedOption && (
        <>
          {selectedOption === "HospitalBranch" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select HospitalBranch
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select HospitalBranch"
                // value={filterAll.Role || ""}
                value={branchValue || ""}
                onChange={handleBranchFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {branchOptions.map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
            :
           null}
        </>
      )} */}
      <IconButton
        onClick={handleResetFilters}
        color="primary"
        style={{
          position: "absolute",
          right: "650px",
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </IconButton>
      <>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => handleStartDateChange(e.target.value)}
          // onChange={(e) => updateStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "525px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => handleEndDateChange(e.target.value)}
          // onChange={(e) => updateEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "385px",
            width: "130px",
            marginTop: "1px",
          }}
        />
      </>

      <>
        <Button
          aria-controls="export-menu"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          variant="contained"
          color="primary"
          style={{
            position: "absolute",
            right: "280px",
            height: "44px",
          }}
        >
          {selectedOption1 ? `Download as ${selectedOption1}` : "Export"}
        </Button>

        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleDownloadDataWithConfirmation}>
            Download as PDF
          </MenuItem>
          <MenuItem onClick={() => handleDownloadDataCSV()}>
            Download as CSV
          </MenuItem>
        </Menu>
      </>

      <Dialog open={open} onClose={handleFClose}>
        <DialogTitle>Export Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Only 50 exports are allowed at a time. This might take some time as
            the data requested is large.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownloadData} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <>
        <TextField
          label={`Search by invoiceNumber`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          // onChange={handleInputChange}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
      </>
      {/* <TextField
        label={`Search by invoiceNumber,JobId`}
        value={searchKey}
        onChange={(e) => dispatch(searchKeyword(e.target.value))}
        // fullWidth
        style={{ position: "absolute", right: "20px", width: "250px" }}
      /> */}
      {loading1 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <span style={{ fontSize: "15px", marginRight: "5px" }}>
            Please wait a few seconds...
          </span>
          <CircularProgress size={30} thickness={5} />
        </div>
      ) : completionMessage && !loading1 ? (
        <div style={{ marginLeft: "10px", color: "green" }}>
          {completionMessage}
        </div>
      ) : null}

      {/* <div style={{ position: "absolute", right: "20px", marginTop: "1rem" }}>
        <input type="file" onChange={handleFileUpload} accept=".xlsx" />
      </div> */}

      <MDBox mt={8}>
        <DataGrid
          getRowId={(row) => row?._id}
          loading={invoiceLoading ? true : false}
          columns={columns}
          // rows={[...invoiceData, ...filteredInvoiceData].reverse()}
          rows={[...invoiceTaxData]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              // showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              // csvOptions: {
              //   fileName: `UserInvoice-${formatDate()}`,
              // },
            },
          }}
          getRowHeight={() => "auto"}
          sx={{
            backgroundColor: "#ffff",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },

            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          // pagination
          // density="compact"
          // pageSize={100}
          // rowsPerPageOptions={[100]}
          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          //isRowSelectable
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                invoiceNumber: true,
                jobId: true,
                professionalName: true,
                branchName: true,
                department: true,
                shiftDate: true,
                finalAmount: true,
                pan: true,
                accountNumber: true,
                bankName: true,
                ifsc: true,
                createdAt: true,
              },
            },
          }}
          disableColumnFilter
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default UserTaxInvoiceDetails;
