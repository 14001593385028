import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLanguages } from "services/eventServices";
import { fetchAri } from "services/eventServices";
import { updateEventService } from "services/eventServices";
import { fetchDocumentType } from "services/eventServices";
import { getEventList } from "services/eventServices";
import { getInterviewList } from "services/eventServices";
import { getReferralList } from "services/eventServices";
import { fetchProfessionalTypes } from "services/eventServices";
import { fetchRoles } from "services/eventServices";
import { fetchDepartments } from "services/eventServices";
import {
  fetchCityList,
  fetchCompanyType,
  fetchExperience,
  fetchProfessionType,
  fetchQualificationType,
  fetchSpecialisations,
  postEventDetails,
  deleteEvent,
} from "services/eventServices";
import { uploadPhoto } from "services/eventServices";
export const setCityList = createAsyncThunk(
  "events/city/listCity",
  async (payload) => {
    const { response, error } = await fetchCityList(payload);

    return response;
  }
);

export const setCompanyType = createAsyncThunk(
  "events/companyTypes/listCompanyType",
  async (payload) => {
    const { response, error } = await fetchCompanyType(payload);

    if (error) {
      return error;
    }
    return response;
  }
);

export const setExperience = createAsyncThunk(
  "events/experience/listNumExperience",
  async (payload) => {
    const { response, error } = await fetchExperience(payload);

    if (error) {
      return error;
    }
    return response;
  }
);
export const setProfessionType = createAsyncThunk(
  "events/professionalTypes/listProfessionalType",
  async (payload) => {
    const { response, error } = await fetchProfessionType(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

export const setQualificationType = createAsyncThunk(
  "events/qualificationTypes/listQualificationType",
  async (payload) => {
    const { response, error } = await fetchQualificationType(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

//Document Type Options
export const setDocumentType = createAsyncThunk(
  "events/documentType/listDocumentType",
  async (payload) => {
    const { response, error } = await fetchDocumentType(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

export const setSpecialisationsType = createAsyncThunk(
  "events/specialization/listSpecialisations",
  async (payload) => {
    const { response, error } = await fetchSpecialisations(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

export const setDepartmentType = createAsyncThunk(
  "events/department/listDepartment",
  async (payload) => {
    const { response, error } = await fetchDepartments(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

export const setRoleType = createAsyncThunk(
  "events/role/listRoles",
  async (payload) => {
    const { response, error } = await fetchRoles(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

export const setAriType = createAsyncThunk(
  "events/ari/listAri",
  async (payload) => {
    const { response, error } = await fetchAri(payload);
    if (error) {
      return error;
    }
    return response;
  }
);
export const setLanguageType = createAsyncThunk(
  "events/language/listLanguages",
  async (payload) => {
    const { response, error } = await fetchLanguages(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

//Profession ID PAYLOAD
export const setProfessionIDPayload = createAsyncThunk(
  "events/setProfessionPayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

//Qulaification ID PAYLOAD
export const setQualificationIDPayload = createAsyncThunk(
  "events/setQualificationPayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

//Specialization ID PAYLOAD
export const setSpecializationIDPayload = createAsyncThunk(
  "events/setSpecializationPayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

//Experience ID PAYLOAD
export const setExperienceIDPayload = createAsyncThunk(
  "events/setExperiencePayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

//City TYPE PAYLOAD
export const setCityIDPayload = createAsyncThunk(
  "events/setCityPayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

//Premium TYPE PAYLOAD
export const setPremiumIDPayload = createAsyncThunk(
  "events/setPremiumPayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

//Company TYPE PAYLOAD
export const setCompanyIDPayload = createAsyncThunk(
  "events/setCompanyPayload",
  async (payload) => {
    if (payload) {
      return payload;
    }
  }
);

export const setProfessionalRegistrationType = createAsyncThunk(
  "events/professionalRegistration/listProfessionalRegistration",
  async (payload) => {
    const { response, error } = await fetchProfessionalTypes(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

export const setPhoto = createAsyncThunk(
  "upcomingEvents/imageUpload",
  async (payload, thunkAPI) => {
    const response = await uploadPhoto(payload);

    return response;
  }
);

export const createEvent = createAsyncThunk(
  "upcomingEvents/createEvent",
  async (payload, thunkAPI) => {
    const { response, error } = await postEventDetails(payload);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateEvent = createAsyncThunk(
  "/upcomingEvents/update",
  async (payload, thunkAPI) => {
    const { response, error } = await updateEventService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const eventList = createAsyncThunk(
  "upcomingEvents/list",
  async (payload) => {
    const response = await getEventList(payload);

    return response;
  }
);
export const eventDeletion = createAsyncThunk(
  "upcomingEvents/delete",
  async (payload, thunkAPI) => {
    const { response, error } = await deleteEvent(payload);

    if (response) {
      thunkAPI.dispatch(eventList());
    }

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//Interviews
export const interviewList = createAsyncThunk(
  "interviewslot/list",
  async () => {
    const response = await getInterviewList();
    // console.log("2-->", response);
    return response;
  }
);

export const updateInterview = createAsyncThunk(
  "/interviewslot/update",
  async (payload, thunkAPI) => {
    const { response, error } = await getInterviewList(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

// export const interviewListById = createAsyncThunk(
//   "upcomingEvents/list",
//   async (payload) => {
//     const response = await getInterviewList(payload);

//     return response;
//   }
// );
export const getInterviewListId = createAsyncThunk(
  "/interviewList/list",
  async (payload, thunkAPI) => {
    const response = await getInterviewList(payload);
    console.log("int list by id:", response);
    return response;
  }
);

//Referral List
export const referralList = createAsyncThunk("referral/list", async (payload) => {
  const response = await getReferralList(payload);
  console.log("2-->", response);
  return response;
});

export const getReferralListId = createAsyncThunk(
  "/referralList/list",
  async (payload, thunkAPI) => {
    const response = await getReferralList(payload);
    console.log("referralList by id:", response);
    return response;
  }
);
