import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInvoiceDetails,getInvoiceFilteredService, getInvoiceDetailsById, getBankDetails,uploadInvoicePaidFile, updateInvoiceService , getTaxInvoiceDetails, getTaxInvoiceDetailsById, getInvoiceTaxFilteredService, getInvoiceServiceByFinance, getInvoiceServiceByOperation} from "./../../../services/invoiceService";

export const fetchInvoiceDetailsData = createAsyncThunk(
  "/invoice",
  async (payload) => {
    const response = await getInvoiceDetails(payload);
    return response;
  }
);
//filter Invoice service
export const getFilteredInvoiceDetails = createAsyncThunk(
  "/invoice/filteredInvoice",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getInvoiceFilteredService(payload);
    // console.log("17>>>>", response)
    return response;
  }
);

//filter invocie by finance
export const getInvoiceFinanceByDetails = createAsyncThunk(
  "/invoice/getInvoiceByFinance",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getInvoiceServiceByFinance(payload);
    // console.log("17>>>>", response)
    return response;
  }
);

//invoices data for operations    
export const getInvoiceOperationByDetails = createAsyncThunk(
  "/invoice/getInvoiceByOperation",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getInvoiceServiceByOperation(payload);
    // console.log("17>>>>", response)
    return response;
  }
);

export const fetchInvoiceData = createAsyncThunk("invoice/id", async (id) => {
  const response = await getInvoiceDetailsById(id);
  return response;
});

export const fetchBankDetailsData = createAsyncThunk(
  "/bank",
  async () => {
    const response = await getBankDetails();
    // console.log("InAction>>>>",response);
    
    return response;
  }
);

export const uploadPaidInvoice = createAsyncThunk(
  "uploadPaid/invoices",
  async (payload, thunkAPI) => {
    const response = await uploadInvoicePaidFile(payload);

    return response;
  }
);

//update invoice
export const updateInvoiceById = createAsyncThunk(
  "/invoice/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updateInvoiceService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);


//tax invoice actionCreator
export const fetchTaxInvoiceDetailsData = createAsyncThunk(
  "/invoiceTax",
  async (payload) => {
    const response = await getTaxInvoiceDetails(payload);
    return response;
  }
);

//tax invoice by id
export const fetchTaxInvoiceData = createAsyncThunk("invoiceTax/id", async (id) => {
  const response = await getTaxInvoiceDetailsById(id);
  return response;
});

//filter Invoice Tax service
export const getFilteredInvoiceTaxDetails = createAsyncThunk(
  "/invoice/filteredInvoiceTax",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getInvoiceTaxFilteredService(payload);
    // console.log("17>>>>", response)
    return response;
  }
);