import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { InputBase, MenuItem, Select } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { Controller } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    height: 40,
    width: "100%",
    border: "1px solid #ccc",
    color: "#7b809f",
    borderRadius: "6px",
    outline: "none",
    cursor: "pointer",
    textAlign: "left",
  },
  selectDisabled: {
    color: grey[400],
  },
  menuitem: {
    color: "#7b809f",
    fontSize: "15px",
    fontWeight: "bold",
  },
  menuitemhidden: {
    display: "none",
  },
}));
const SelectDropDownNew = ({
  options,
  placeholder,
  customOnChange,
  control,
  disabled,
  name,
  getValues,
  defaultValue,
}) => {
  //handle Custom Change for the custom On change Function

  const handleCustomChange = (e) => {
    if (typeof e === "object") {
      setDropDownValue(e.target.value);
      customOnChange(e);
      return;
    }
  };

  const valueNew = getValues(name);

  const classes = useStyles();
  const [value, setDropDownValue] = useState("none");
  const [open, setOpen] = useState(true);
  const [showPlaceholder, setShowPlaceholder] = useState(value === "none");

  useEffect(() => {
    
    if (!valueNew) {
      return;
    }

    if (valueNew == "none") {
      setDropDownValue("none");
    }
    if (valueNew !== undefined && options.length > 0) {
      options.map((obj) => {
        
        if (obj.value == valueNew) {
          
          setDropDownValue(valueNew);
        }
      });
    }
  }, [valueNew, options]);

  return (
    <div className={classes.container}>
      {" "}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            disabled={disabled === true}
            value={value}
            onOpen={() => setOpen(!open)}
            onClose={(e) => {
              setShowPlaceholder(e.target.value === undefined);
              setOpen(!open);
            }}
            IconComponent={() =>
              open ? (
                <KeyboardArrowDownIcon fontSize="medium" />
              ) : (
                <KeyboardArrowUpIcon fontSize="medium" />
              )
            }
            input={
              <InputBase
                sx={{
                  fontSize: 15,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              />
            }
            //If custom onchange is there apply that else just set the value
            onChange={
              customOnChange
                ? handleCustomChange
                : (e) => setDropDownValue(e.target.value)
            }
            onFocus={(e) => setShowPlaceholder(false)}
            // onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
            className={clsx(
              classes.select,
              value === "none" ? classes.selectDisabled : null
            )}
          >
            {" "}
            <MenuItem
              className={clsx(
                classes.menuitem,
                !showPlaceholder ? classes.menuitemhidden : null
              )}
              key="0"
              disabled
              value="none"
            >
              {" "}
              {placeholder}
            </MenuItem>{" "}
            {options.map((option) => (
              <MenuItem
                className={classes.menuitem}
                key={option.key}
                value={option.value}
              >
                {" "}
                {option.value}
              </MenuItem>
            ))}
          </Select>
        )}
      />{" "}
    </div>
  );
};
export default SelectDropDownNew;
