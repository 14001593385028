import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addAdditionalTrainings,
  addCity,
  addCompany,
  addDepartmentType,
  addDocumentType,
  addExperience,
  addLanguages,
  addProfession,
  addProfessionRegistrationType,
  addQualification,
  addSingleJobDetails,
  addSoftware,
  addSpecializations,
  delAdditional,
  delCompany,
  deleteDepartmentType,
  deleteDocumentType,
  deleteExperience,
  DeleteJobs,
  deleteLanguage,
  deleteProfession,
  deleteProfessionRegistrationType,
  deleteQualifications,
  deleteSoftware,
  deleteSpecializations,
  EditJobs,
  fetchJobDetailsData,
  GetAdditionalTrainingById,
  getAdditionalTrainingList,
  getCitybyId,
  getCityList,
  getCompanyList,
  getDepartmentType,
  getDocumentType,
  getExperienceList,
  getLanguagesList,
  getProfessionRegistrationTypeList,
  getProfessionsList,
  getQualificationsList,
  getSoftwaresList,
  getSpecializationsList,
  setOrganizationsDropdown,
  setUsersDropdown,
  UpdateAdditionall as UpdateAdditionally,
  updateCityy,
  updateDepartmentType,
  updateDocumentType,
  updateExperience,
  updateLanguage,
  updateProfession,
  updateProfessionRegistrationType,
  updateQualification,
  updateSoftware,
  updateSpecializations,
  uploadFile,
  addSkill,
  getSkillList,
  updateSkillList,
  getSkillbyId,
  getDocVerfList,
  updateDocVerificationList,
  getFilteredDocsDetails,
  getGCCDepartment,
  getGCCExperience
} from "./actionCreator";
import initialState from "./state";

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    pageLimit(state, action) {
      state.pageSize = action.payload;
    },
    searchKeyword(state, action){
      state.searchKey = action.payload;
    },
    updateFilterState(state, action){
      state.filterState = action.payload;
    },
    updateStartDate(state, action){
      state.startDate = action.payload;
    },
    updateEndDate(state, action){
      state.endDate = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobDetailsData.pending, (state) => {
      state.Job_Details_Data.jobDetailsLoading = true;
    });
    builder.addCase(fetchJobDetailsData.fulfilled, (state, action) => {
      state.Job_Details_Data.jobDetailsLoading = false;
      const responseArray = JSON.stringify(action?.payload?.data?.jdRulesList);
      state.Job_Details_Data.jobDetailsInfo = JSON.parse(responseArray);
    });
    builder.addCase(fetchJobDetailsData.rejected, (state, action) => {
      state.Job_Details_Data.jobDetailsLoading = false;
      state.Job_Details_Data.jobDetailsError = "Job Details Error";
    });

    //file upload
    builder.addCase(uploadFile.pending, (state) => {});
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      toast.success("File uploaded Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //Delete Action
    builder.addCase(DeleteJobs.pending, (state) => {});
    builder.addCase(DeleteJobs.fulfilled, (state, action) => {
    });
    builder.addCase(DeleteJobs.rejected, (state, action) => {
  
    });

    //Edit Action
    builder.addCase(EditJobs.pending, (state) => {});
    builder.addCase(EditJobs.fulfilled, (state, action) => {
    
    });
    builder.addCase(EditJobs.rejected, (state, action) => {
      
    });

    //Adding single job detail
    builder.addCase(addSingleJobDetails.pending, (state) => {});
    builder.addCase(addSingleJobDetails.fulfilled, (state, payload) => {
      state.Job_Details_Data.jobDetailsLoading = false;
      
      state.Job_Details_Data.jobDetailsInfo = payload;
    
      toast.success("Job Detail Added", {
        position: "top-right",
      });
    });
    builder.addCase(addSingleJobDetails.rejected, (state, action) => {
      
     state.Job_Details_Data.jobDetailsCount++;
      if(state.Job_Details_Data.jobDetailsCount==1)
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //add city
    builder.addCase(addCity.pending, (state) => {});
    builder.addCase(addCity.fulfilled, (state, payload) => {
      state.Cities_Data.cityLoading = false;
     
      state.Cities_Data.cityInfo = payload;
     
      toast.success("Created Successfuly", {
        position: "top-right",
      });
    });
    builder.addCase(addCity.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //list city
    builder.addCase(getCityList.pending, (state) => {});
    builder.addCase(getCityList.fulfilled, (state, action) => {
      state.City_List_Data.cityListLoading = false;

      state.City_List_Data.cityListInfo = action?.payload?.data?.docs;
      
    });
    builder.addCase(getCityList.rejected, (state, action) => {
      
    });

   // update city
    builder.addCase(updateCityy.pending, (state) => {});
    builder.addCase(updateCityy.fulfilled, (state, action) => {
     

     
      toast.success("Updated Successfully",{
        position:"top-right"
      })
    });
    builder.addCase(updateCityy.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg,{
        position:"top-right",
        fontSize:"12px"
      });
    });

    //get city list by id
    builder.addCase(getCitybyId.pending, (state) => {});
    builder.addCase(getCitybyId.fulfilled, (state, action) => {
      state.City_Data.cityLoadingData = false;
      state.City_Data.cityInfoData = action?.payload;
     
    });
    builder.addCase(getCitybyId.rejected, (state, action) => {
    
    });

    //GET Qualifications
    builder.addCase(getQualificationsList.pending, (state) => {});
    builder.addCase(getQualificationsList.fulfilled, (state, action) => {
      state.QualificationsData.qualificationsLoading = false;
      state.QualificationsData.qualificationsInfo = action?.payload?.data?.docs;
    
    });
    builder.addCase(getQualificationsList.rejected, (state, action) => {
     
    });


    //update qualification
    builder.addCase(updateQualification.pending, (state) => {});
    builder.addCase(updateQualification.fulfilled, (state, action) => {
      toast.success("Updated successfully", {
        position: "top-right",
        fontSize: "12px",
      });
    
    });
    builder.addCase(updateQualification.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });


    //add  qualification
    builder.addCase(addQualification.pending, (state) => {});
    builder.addCase(addQualification.fulfilled, (state, action) => {
      toast.success("Created successfully", {
        position: "top-right",
        fontSize: "12px",
      });
    
    });
    builder.addCase(addQualification.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //Delete Qualification
    builder.addCase(deleteQualifications.pending, (state) => {});
    builder.addCase(deleteQualifications.fulfilled, (state, action) => {
     
    });
    builder.addCase(deleteQualifications.rejected, (state, action) => {
      
    });

    //GET Professions
    builder.addCase(getProfessionsList.pending, (state) => {});
    builder.addCase(getProfessionsList.fulfilled, (state, action) => {
      state.ProfessionsData.professionsLoading = false;
      state.ProfessionsData.professionsInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getProfessionsList.rejected, (state, action) => {
   
    });

    //Delete Profession
    builder.addCase(deleteProfession.pending, (state) => {});
    builder.addCase(deleteProfession.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteProfession.rejected, (state, action) => {
      
    });

    //Add Profession
    builder.addCase(addProfession.pending, (state) => {});
    builder.addCase(addProfession.fulfilled, (state, payload) => {
     
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addProfession.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //Update Profession
    builder.addCase(updateProfession.pending, (state) => {});
    builder.addCase(updateProfession.fulfilled, (state, payload) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateProfession.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    // ------------------------------------------ Languages ---------------------------------------- //

    //GET Languages
    builder.addCase(getLanguagesList.pending, (state) => {});
    builder.addCase(getLanguagesList.fulfilled, (state, action) => {
      state.LanguagesData.languagesLoading = false;
      state.LanguagesData.languagesInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getLanguagesList.rejected, (state, action) => {
      
    });

    //Delete Language
    builder.addCase(deleteLanguage.pending, (state) => {});
    builder.addCase(deleteLanguage.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteLanguage.rejected, (state, action) => {
      
    });

    //Add Language
    builder.addCase(addLanguages.pending, (state) => {});
    builder.addCase(addLanguages.fulfilled, (state, payload) => {
     
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addLanguages.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //Update Profession
    builder.addCase(updateLanguage.pending, (state) => {});
    builder.addCase(updateLanguage.fulfilled, (state, payload) => {
      
      
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateLanguage.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //*******Softwares************ */
    //GET Software
    builder.addCase(getSoftwaresList.pending, (state) => {});
    builder.addCase(getSoftwaresList.fulfilled, (state, action) => {
      state.SoftwaresData.softwaresLoading = false;
      state.SoftwaresData.softwaresInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getSoftwaresList.rejected, (state, action) => {
     
    });
    //Delete Software
    builder.addCase(deleteSoftware.pending, (state) => {});
    builder.addCase(deleteSoftware.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteSoftware.rejected, (state, action) => {
      
    });
    //Add Software
    builder.addCase(addSoftware.pending, (state) => {});
    builder.addCase(addSoftware.fulfilled, (state, payload) => {
      
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addSoftware.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //Update Software
    builder.addCase(updateSoftware.pending, (state) => {});
    builder.addCase(updateSoftware.fulfilled, (state, payload) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateSoftware.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //USER DROPDOWN
    builder.addCase(setUsersDropdown.pending, (state) => {});
    builder.addCase(setUsersDropdown.fulfilled, (state, payload) => {
      
      state.UsersData.usersInfo = payload?.payload?.data;
     
    });
    builder.addCase(setUsersDropdown.rejected, (state, action) => {
     
    });

    //ORGANIZATION DROPDOWN
    builder.addCase(setOrganizationsDropdown.pending, (state) => {});
    builder.addCase(setOrganizationsDropdown.fulfilled, (state, payload) => {
      
      state.OrganizationsData.organizationsInfo = payload?.payload?.data?.docs;
     
    });
    builder.addCase(setOrganizationsDropdown.rejected, (state, action) => {
      
    });

    // company list
    builder.addCase(getCompanyList.pending, (state) => {});
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.Company_List_Data.companyListLoading = false;

      state.Company_List_Data.companyListInfo = action?.payload?.data?.docs;
    
    });
    builder.addCase(getCompanyList.rejected, (state, action) => {
      
    });
    //add company
    builder.addCase(addCompany.pending, (state) => {});
    builder.addCase(addCompany.fulfilled, (state, payload) => {
      state.Company_Data.companyLoading = false;
     
      state.Company_Data.companyInfo = payload;
      
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addCompany.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //delete company
    builder.addCase(delCompany.pending, (state) => {});
    builder.addCase(delCompany.fulfilled, (state, payload) => {
      
    });
    builder.addCase(delCompany.rejected, (state, action) => {
     
    });

    //*******Experiences************ */
    //GET Experiences
    builder.addCase(getExperienceList.pending, (state) => {});
    builder.addCase(getExperienceList.fulfilled, (state, action) => {
      state.Experience_Data.experienceLoading = false;
      state.Experience_Data.experienceInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getExperienceList.rejected, (state, action) => {
      
    });
    //Delete Experiences
    builder.addCase(deleteExperience.pending, (state) => {});
    builder.addCase(deleteExperience.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteExperience.rejected, (state, action) => {
      
    });
    //Add Experiences
    builder.addCase(addExperience.pending, (state) => {});
    builder.addCase(addExperience.fulfilled, (state, payload) => {
     
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addExperience.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //Update Experiences
    builder.addCase(updateExperience.pending, (state) => {});
    builder.addCase(updateExperience.fulfilled, (state, payload) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateExperience.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //*******Profession Registration Type************ */
    //GET Profession Registration Type
    builder.addCase(getProfessionRegistrationTypeList.pending, (state) => {});
    builder.addCase(
      getProfessionRegistrationTypeList.fulfilled,
      (state, action) => {
        state.ProfessionRegistrationData.professionRegistrationLoading = false;
        state.ProfessionRegistrationData.professionRegistrationInfo =
          action?.payload?.data?.docs;
       
      }
    );
    builder.addCase(
      getProfessionRegistrationTypeList.rejected,
      (state, action) => {
        
      }
    );
    //Delete Profession Registration Type
    builder.addCase(deleteProfessionRegistrationType.pending, (state) => {});
    builder.addCase(
      deleteProfessionRegistrationType.fulfilled,
      (state, action) => {
     
      }
    );
    builder.addCase(
      deleteProfessionRegistrationType.rejected,
      (state, action) => {
       
      }
    );
    //Add Profession Registration Type
    builder.addCase(addProfessionRegistrationType.pending, (state) => {});
    builder.addCase(
      addProfessionRegistrationType.fulfilled,
      (state, action) => {
        toast.success("Created Successfully", {
          position: "top-right",
        });
      }
    );
    builder.addCase(addProfessionRegistrationType.rejected, (state, action) => {

      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //Update Profession Registration Type
    builder.addCase(updateProfessionRegistrationType.pending, (state) => {});
    builder.addCase(
      updateProfessionRegistrationType.fulfilled,
      (state, payload) => {
       
        toast.success("Updated Successfully", {
          position: "top-right",
        });
      }
    );
    builder.addCase(
      updateProfessionRegistrationType.rejected,
      (state, action) => {
       
        toast.error(action?.payload?.msg, {
          position: "top-right",
          fontSize: "12px",
        });
      }
    );

    // Specialization //

    //GET Specialization Type
    builder.addCase(getSpecializationsList.pending, (state) => {});
    builder.addCase(getSpecializationsList.fulfilled, (state, action) => {
      state.Specialization_Data.specializationLoading = false;
      state.Specialization_Data.specializationInfo =
        action?.payload?.data?.docs;
    
    });
    builder.addCase(getSpecializationsList.rejected, (state, action) => {
     
    });
    //Delete Specialization Type
    builder.addCase(deleteSpecializations.pending, (state) => {});
    builder.addCase(deleteSpecializations.fulfilled, (state, action) => {
    
    });
    builder.addCase(deleteSpecializations.rejected, (state, action) => {
      
    });
    //Add Specialization Type
    builder.addCase(addSpecializations.pending, (state) => {});
    builder.addCase(addSpecializations.fulfilled, (state, payload) => {
     
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addSpecializations.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //Update Specialization Type
    builder.addCase(updateSpecializations.pending, (state) => {});
    builder.addCase(updateSpecializations.fulfilled, (state, payload) => {
   
      toast.success("Updated Sucessfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateSpecializations.rejected, (state, action) => {
   
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    // DocumentType //

    //GET DocumentType
    builder.addCase(getDocumentType.pending, (state) => {});
    builder.addCase(getDocumentType.fulfilled, (state, action) => {
      state.DocumentType_Data.documentTypeLoading = false;
      state.DocumentType_Data.documentTypeInfo = action?.payload?.data?.docs;
      
    });
    builder.addCase(getDocumentType.rejected, (state, action) => {
    
    });
    //Delete DocumentType
    builder.addCase(deleteDocumentType.pending, (state) => {});
    builder.addCase(deleteDocumentType.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteDocumentType.rejected, (state, action) => {
      
    });
    //Add DocumentType
    builder.addCase(addDocumentType.pending, (state) => {});
    builder.addCase(addDocumentType.fulfilled, (state, payload) => {
      
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addDocumentType.rejected, (state, action) => {
  
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //Update DocumentType
    builder.addCase(updateDocumentType.pending, (state) => {});
    builder.addCase(updateDocumentType.fulfilled, (state, payload) => {
   
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateDocumentType.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    // DepartmentType //

    //GET DepartmentType
    builder.addCase(getDepartmentType.pending, (state) => {});
    builder.addCase(getDepartmentType.fulfilled, (state, action) => {
      state.DepartmentType_Data.departmentTypeLoading = false;
      state.DepartmentType_Data.departmentTypeInfo =
        action?.payload?.data?.docs;
   
    });
    builder.addCase(getDepartmentType.rejected, (state, action) => {
    
    });
    //Delete DepartmentType
    builder.addCase(deleteDepartmentType.pending, (state) => {});
    builder.addCase(deleteDepartmentType.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteDepartmentType.rejected, (state, action) => {
      
    });
    //Add DepartmentType
    builder.addCase(addDepartmentType.pending, (state) => {});
    builder.addCase(addDepartmentType.fulfilled, (state, payload) => {
      
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addDepartmentType.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //Update DepartmentType
    builder.addCase(updateDepartmentType.pending, (state) => {});
    builder.addCase(updateDepartmentType.fulfilled, (state, payload) => {
      
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateDepartmentType.rejected, (state, action) => {
      
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });
    //get Additional Training list
    builder.addCase(getAdditionalTrainingList.pending, (state) => {});
    builder.addCase(getAdditionalTrainingList.fulfilled, (state, action) => {
      state.Additional_Training.additionalTrainingLoading = false;

      state.Additional_Training.additionalTrainingInfo =
        action?.payload?.data?.docs;
    
    });
    builder.addCase(getAdditionalTrainingList.rejected, (state, action) => {
    
    });

    //add additional training
    builder.addCase(addAdditionalTrainings.pending, (state) => {});
    builder.addCase(addAdditionalTrainings.fulfilled, (state, payload) => {
      state.Additional_Data.additionalLoading = false;
     
      state.Additional_Data.additionalInfo = payload;
      toast.success("Created Successfully", {
        position: "top-right",
      });
    
    });
    builder.addCase(addAdditionalTrainings.rejected, (state, action) => {
      
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //delete Additional
    builder.addCase(delAdditional.pending, (state) => {});
    builder.addCase(delAdditional.fulfilled, (state, payload) => {
     
    });
    builder.addCase(delAdditional.rejected, (state, action) => {
      
    });

    //get Additional Training By Id
    builder.addCase(GetAdditionalTrainingById.pending, (state) => {});
    builder.addCase(GetAdditionalTrainingById.fulfilled, (state, action) => {
     
      state.Additional_Id_Data.additionalIdLoading = false;
      

      state.Additional_Id_Data.additionalIdInfo = action?.payload;
     
    });
    builder.addCase(GetAdditionalTrainingById.rejected, (state, action) => {
      
    });

    //put additional training
    builder.addCase(UpdateAdditionally.pending, (state) => {});
    builder.addCase(UpdateAdditionally.fulfilled, (state, action) => {
      
      toast.success("Updated Sucessfully", {
        position: "top-right",
      });
    });
    builder.addCase(UpdateAdditionally.rejected, (state, action) => {
 
      toast.error(action?.payload?.msg, {
        position: "top-right",
        fontSize: "12px",
      });
    });

    //skills
     //add skills
    //  builder.addCase(addSkill.pending, (state) => {});
    //  builder.addCase(addSkill.fulfilled, (state, payload) => {
    //    state.Skills_Data.skillLoading = false;
      
    //    state.Skills_Data.skillInfo = payload;
      
    //    toast.success("Created Successfuly", {
    //      position: "top-right",
    //    });
    //  });
    //  builder.addCase(addSkill.rejected, (state, action) => {
      
    //    toast.error(action?.payload?.msg, {
    //      position: "top-right",
    //      fontSize: "12px",
    //    });
    //  });
 
     //list skill
     builder.addCase(getSkillList.pending, (state) => {});
    builder.addCase(getSkillList.fulfilled, (state, action) => {
      state.Skill_List_Data.skillListLoading = false;
     // console.log("action", action);
      state.Skill_List_Data.skillListInfo = action?.payload;
      
    });
    builder.addCase(getSkillList.rejected, (state, action) => {
      
    });
     
 
    // update skills
    //  builder.addCase(updateSkillList.pending, (state) => {});
    //  builder.addCase(updateSkillList.fulfilled, (state, action) => {
      
 
      
    //    toast.success("Updated Successfully",{
    //      position:"top-right"
    //    })
    //  });
    //  builder.addCase(updateSkillList.rejected, (state, action) => {
     
    //    toast.error(action?.payload?.msg,{
    //      position:"top-right",
    //      fontSize:"12px"
    //    });
    //  });
 
     //get skill list by id
    //  builder.addCase(getSkillbyId.pending, (state) => {});
    //  builder.addCase(getSkillbyId.fulfilled, (state, action) => {
    //    state.Skill_Data.skillLoadingData = false;
    //    state.Skill_Data.skillInfoData = action?.payload;
      
    //  });
    //  builder.addCase(getSkillbyId.rejected, (state, action) => {
     
    //  });

    //list docverification
    builder.addCase(getDocVerfList.pending, (state) => {});
     builder.addCase(getDocVerfList.fulfilled, (state, action) => {
       state.DocVerifaction_List_Data.docverificationListLoading = false;
      // console.log("action", action);
       state.DocVerifaction_List_Data.docverificationListInfo = action?.payload?.data;
       state.DocVerifaction_List_Data.total = action?.payload?.total;
       
     });
     builder.addCase(getDocVerfList.rejected, (state, action) => {
       
     });


    
    //list docverification filtered
    builder.addCase(getFilteredDocsDetails.pending, (state) => {});
     builder.addCase(getFilteredDocsDetails.fulfilled, (state, action) => {
       state.DocVerifaction_Filtered_List_Data.docverificationFilteredListLoading = false;
      // console.log("action", action);
       state.DocVerifaction_Filtered_List_Data.docverificationFilteredListInfo = action?.payload?.data;
       
     });
     builder.addCase(getFilteredDocsDetails.rejected, (state, action) => {
       
     });

     //GET Department  GCC
    builder.addCase(getGCCDepartment.pending, (state) => {});
    builder.addCase(getGCCDepartment.fulfilled, (state, action) => {
      state.GCC_Department_Data.departmentGCCLoading = false;
      state.GCC_Department_Data.departmentGCCInfo =
        action?.payload?.data?.docs;
   
    });
    builder.addCase(getGCCDepartment.rejected, (state, action) => {
    
    });

       //GET Experience  GCC
       builder.addCase(getGCCExperience.pending, (state) => {});
       builder.addCase(getGCCExperience.fulfilled, (state, action) => {
         state.GCC_Experience_Data.experienceGCCLoading = false;
         state.GCC_Experience_Data.experienceGCCInfo =
           action?.payload?.data?.docs;
      
       });
       builder.addCase(getGCCExperience.rejected, (state, action) => {
       
       });
    
  },
});
export const {
  pageChanged,
  pageLimit,
  searchKeyword,
  updateFilterState,
  updateStartDate,
  updateEndDate
} = commonSlice.actions;
export default commonSlice.reducer;
