import * as yup from "yup";
//adminUser
export const adminUserDialogValidation = yup.object().shape({
  password: yup.string().required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup.string().email().required("Email is Required"),
  role: yup.string().required("Role is Required"),
});

export const adminUserFormValidation = yup.object().shape({
  password: yup.string(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),

  email: yup.string().email().required("Email is Required"),
});

//ari

export const AriDialogValidation = yup.object().shape({
  ari: yup
    .string()
    .required("Ari is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
  namespace: yup.string().required("role is required"),
});

export const AriFormValidation = yup.object().shape({
  ari: yup
    .string()
    .required("Ari is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

//config

export const ConfigDialogValidation = yup.object().shape({
  configName: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
  configType: yup.string().required(),
  value: yup
    .string()
    .required("Value is Required")
    .matches(/^[0-9\s]+$/, "Invalid Value"),
});

export const ConfigFormValidation = yup.object().shape({
  value: yup
    .string()
    .required("Value is Required")
    .matches(/^[0-9\s]+$/, "Invalid Value"),
});

//role

export const RoleDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});
