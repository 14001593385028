import { useForm, Controller } from "react-hook-form";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import SelectDropDown from "./../../../examples/DropDown/index";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import adminPanelValidation, {
  AriFormValidation,
} from "../../../util/validations/adminPanelValidation";
import {
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Link
} from "@mui/material";
import { getOrderByIdService } from "services/transactionServices";
import { updateOrder } from "../Redux/actionCreator";
import { useDispatch } from "react-redux";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const PaymentDetailsForm = () => {
  const selectOptions = [
    {
      key: "paid",
      value: "paid",
    },

    {
      key: "created",
      value: "created",
    },
  ];

  const ID = window.location.href.split("order")[1].split("/")[1];
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
    
    let response = await getOrderByIdService(ID);
    
    setResponseData(response);
    
    return response[0];
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
   

    let payload = {
      status: data.status,
      currency: data.currency,
      amount_paid: data.amount_paid,
      user: data.user.name.full,
      permanentJobId: data.permanentJobId,
      amount: data.amount,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      receipt: data.receipt,
      OrderId: data.OrderId,
      amount_due: data.amount_due,
      ordercreated_at: data.ordercreated_at,
    };

   
    dispatch(updateOrder({ payload: payload, id: ID })).then(() => {
      navigate("/transaction/order");
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleReset = () => {
    reset();
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox margin={2}>
            <Grid item xs={12}>
           
              <div style={{ display: "flex",width:"70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                 <ArrowBackIos fontSize="small" />
                
                   
                    back
                 
                </div>
                </Link>
              </div>
             
            </Grid>
          {/* <Divider /> */}
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                {/* Id */}
                <Grid item xs={12}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    disabled
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 25, fontWeight: "bold" },
                    }}
                    {...register("_id")}
                  />
                </Grid>

                {/* Currency */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "1rem" }}
                  >
                    Currency
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("currency")}
                  />
                </Grid>

                {/* User */}
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ marginTop: "1rem" }}>
                    {" "}
                    User Details
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Salutation (Mr. / Mrs.)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.salutation")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    First Name{" "}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.first")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Middle Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.middle")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Full Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.full")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Last Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.last")}
                  />
                </Grid>

                {/* Permanent Job ID */}

                <Grid item xs={4}>
                  <Typography variant="subtitle1">Permnent Job ID</Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("permanentJobId")}
                  />
                </Grid>
                {/* User */}
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Amount Details
                  </Typography>
                </Grid>
                {/* Amount */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("amount")}
                  />
                </Grid>

                {/* amount paid */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Amount Paid
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("amount_paid")}
                  />
                </Grid>

                {/* Amout Due*/}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Amount Due
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("amount_due")}
                  />
                </Grid>
                {/* User */}
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Order Details
                  </Typography>
                </Grid>
                {/* Order ID */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Order ID
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("OrderId")}
                  />
                </Grid>

                {/* Order Crated At */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Order Created At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("ordercreated_at")}
                  />
                </Grid>

                {/*Receipt */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    receipt
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("receipt")}
                  />
                </Grid>

                {/* Status */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Select"
                    control={control}
                    name="status"
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue("status", e.target.value);
                    }}
                  />
                </Grid>

                {/* CreatedAt */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Created At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("createdAt")}
                  />
                </Grid>

                {/* Updated At */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Updated At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updatedAt")}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: "2rem" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  Save
                </Button>

                {/* <Button
                  variant="outlined"
                  sx={{ color: "#111", marginLeft: "1rem" }}
                  onClick={handleReset}
                >
                  Reset
                </Button> */}
              </div>
            </form>
          </DialogContent>
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default PaymentDetailsForm;
