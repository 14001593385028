import MDInput from "components/MDInput";
import React from "react";
import { useFieldArray } from "react-hook-form";

import MDButton from "components/MDButton";
import { Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";

export default ({ nestIndex, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `contactDetails[${nestIndex}].address.coords`,
  });



  return (
    <ul style={{ listStyleType: "none" }}>
      {fields.map((item, k) => {
        return (
          <li>
            <Grid>
              <MDInput
              type='number'
                placeholder=""
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register(`contactDetails[${nestIndex}].address.coords[${k}]`)}
                // name={`contact[${nestIndex}].nestedArray[${k}].field1`}

                style={{ marginRight: "25px" }}
              />

              <MDBox
                sx={{
                  textAlign: "right",
                  background: "transparent",
                  width: "100%",
                }}
              >
                <MDButton
                  variant="outlined"
                  sx={{ border: "none", height: "10px", width: "30px" }}
                  onMouseHover={{ color: "black" }}
                  onClick={(e) => {
                    e.preventDefault();
                    remove(k);
                  }}
                >
                  <Typography
                    color="error"
                    sx={{ fontSize: "12px" }}
                    onHover={{ color: "black" }}
                  >
                    Remove
                  </Typography>
                </MDButton>
              </MDBox>
            </Grid>
          </li>
        );
      })}

      <MDButton
        variant="contained"
        color="info"
        type="button"
        onClick={() =>
          append({})
        }
      >
        +Add
      </MDButton>
    </ul>
  );
};
