import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { CircularProgress, Paper } from "@mui/material";
import SelectDropDown from "./../../../examples/DropDown/index";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Moment from 'moment';
import adminPanelValidation, {
  AriFormValidation,
} from "../../../util/validations/adminPanelValidation";
import {
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Link
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentTypeByIdService } from "services/commonServices";

import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { setUsersDropdown, updateDocumentType } from "../Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";
import { create } from "yup/lib/Reference";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const DocumentTypeForm = () => {
  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];

  //Fetching User Dropdown Details
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;
  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  const ID = window.location.href.split("documentTypes")[1].split("/")[1];
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
  
    let response = await getDocumentTypeByIdService(ID);

    setResponseData(response);

    return response[0];
  };

  //Icon Location
  const iconLocationOptions = [
    {
      key: "AWS_S3",
      value: "AWS_S3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];

  //Namespace Options
  const namespaceOptions = [
    {
      key: "ORGANIZATION",
      value: "ORGANIZATION",
    },
    {
      key: "USER",
      value: "USER",
    },
    {
      key: "COMPANY",
      value: "COMPANY",
    },
  ];

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: () => getData() });

  const onSubmit = (data) => {
    const payload = {
      icon: {
        location:data?.icon?.location,
        isSecured: data?.icon?.isSecured,
        bucket: data?.icon?.bucket,
        cdnUrl: data?.icon?.cdnUrl,
        fileName: data?.icon?.fileName,
        mimeType: data?.icon?.mimeType,
        region: data?.icon?.region,
        secureUrl: data?.icon?.secureUrl,
        uri: data?.icon?.uri,
        url: data?.icon?.url,
      },
      profession: data?.profession,
      namespaces: data?.namespaces,
      name: data?.name,
      isDeleted: data?.isDeleted,
      description: data?.description,
      // createdAt: data?.createdAt,
      // updatedAt: data?.updatedAt,
      // createdBy: data.createdBy,
      // updatedBy: data.updatedBy,
    };
    dispatch(updateDocumentType({ payload: payload, id: ID })).then(() => {
      navigate("/common/documentTypes");
    });
  };
  const handleReset = () => {
    reset();
  };

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

  
  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

   
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setUsersDropdown());
  }, []);

  const formValues = getValues();


  const {
    fields: namespaceField,
    append: namespaceAppend,
    remove: namespaceRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "namespaces", // unique name for your Field Array
  });


  const[updateCreated,setUpdateCreated]=useState(false);
  const[updateUpdated,setUpdateUpdated]=useState(false);
    useEffect(()=>{
      const created=getValues("createdAt");
      const updated=getValues("updatedAt");

      if(created=="Invalid date")
    {
      setValue("createdAt","");
      
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }
      
      if(created!==undefined && created!=="")
      {
       if(!updateCreated)
       {
         // create=Moment(created).format("DD/MM/YYYY")
          setValue("createdAt",Moment(created).format("DD/MM/YYYY"));
         setUpdateCreated(true);
       }
      }
      if(updated!==undefined && updated!=="")
      {
       if(!updateUpdated)
       {
         setValue("updatedAt",Moment(created).format("DD/MM/YYYY"));
         setUpdateUpdated(true);
       }
      }
    },[getValues("createdAt"),getValues("updatedAt")])
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Id */}
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name")}
                      // value="HOSPITAL"
                    />
                  </Grid>
                  {/* ID */}
                  <Grid item xs={12}>
                    <Typography variant="body">ID:{ID}</Typography>
                  </Grid>

                  {/* checkbox */}
                  {/* is Deleted */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isDeleted"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Is Deleted"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  {/* Description */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("description")}
                    />
                  </Grid>

                  {/* Namespace */}
                  <Grid item xs={12}>
                    <Paper sx={{ padding: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 500 }}
                      >
                        Namespaces
                      </Typography>
                      <ul style={{ listStyleType: "none" }}>
                        {namespaceField.map((field, index) => (
                          <li>
                            <Grid container spacing={2}>
                              {/* checkbox */}
                              <Grid item xs={12} md={12}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Controller
                                        defaultValue={false}
                                        name="Required"
                                        control={control}
                                        {...register(
                                          `namespaces.[${index}].required`
                                        )}
                                        render={({ field: props }) => (
                                          <Checkbox
                                            {...props}
                                            checked={props.value}
                                            onClick={(e) => e.target.value}
                                          />
                                        )}
                                      />
                                    }
                                    label="Required?"
                                  />
                                </FormGroup>
                              </Grid>
                              {/* namespace */}
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Namespace
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <SelectDropDownNew
                                  control={control}
                                  name={`namespaces.${index}.namespace`}
                                  defaultValue="none"
                                  options={namespaceOptions}
                                  getValues={getValues}
                                  placeholder="Select"
                                  customOnChange={(e) =>
                                    setValue(
                                      `namespaces.[${index}].namespace`,
                                      e.target.value
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>

                            <MDBox sx={{ textAlign: "right" }}>
                              <MDButton
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  namespaceRemove(index);
                                }}
                              >
                                <Typography color="error" variant="body">
                                  Remove
                                </Typography>
                              </MDButton>
                            </MDBox>
                            {/* </MDBox>  */}
                          </li>
                        ))}
                        <MDButton
                          variant="contained"
                          color="info"
                          onClick={(e) => {
                          
                            e.preventDefault();
                            namespaceAppend({});
                          }}
                        >
                          + Add
                        </MDButton>
                      </ul>
                    </Paper>
                  </Grid>

                  {/* Icon Location */}

                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Icon Location</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <SelectDropDownNew
                      control={control}
                      name="icon.location"
                      defaultValue="none"
                      options={iconLocationOptions}
                      getValues={getValues}
                      placeholder="Select"
                      customOnChange={(e) => {
                        setValue("icon.location", e.target.value);
                      }}
                    />
                  </Grid>

                  {/* icon File Name */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon File Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.fileName")}
                    />
                  </Grid>

                  {/* Icon URI */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Uri
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.uri")}
                    />
                  </Grid>

                  {/* Icon Bucket */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Bucket
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.bucket")}
                    />
                  </Grid>

                  {/* ICON REGION */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Region
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.region")}
                    />
                  </Grid>

                  {/* iCON mIME tYPE */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Mime Type
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.mimeType")}
                    />
                  </Grid>

                  {/* Icon Secured Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Secure Url
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.secureUrl")}
                    />
                  </Grid>
                  {/* Icon Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Url
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.url")}
                    />
                  </Grid>

                  {/* Checkbox */}
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Controller
                            defaultValue={false}
                            name="icon.isSecured"
                            control={control}
                            render={({ field: props }) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label="Icon Is Secured"
                      />
                    </FormGroup>
                  </Grid>
                  {/* Icon Cdn Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Cdn Url
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.cdnUrl")}
                    />
                  </Grid>
                  {/* CreatedAt */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>

                  {/* Updated At */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>

                  {/* CreatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdBy.name.full")}
                    />
                  </Grid>

                  {/* updatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedBy.name.full")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Save
                  </Button>

                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default DocumentTypeForm;
