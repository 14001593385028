import axiosInstance from "../axios";

import { toast } from "react-toastify";

// export const getBillDetails = async (payload) => {
//   try {
//     // let payload = {
//     //   limit: 100,
//     //   offset: 1,
//     // };
//     let criteria = { 
//      limit: payload.pageSize,
//      offset: payload.currentPage,
//      };
//     const response = await axiosInstance.post(`/bill`, criteria);
//     //const totalCount = response.data.totalCount;
//     console.log("bill response", response);
//     return { response: response };
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

export const getBillDetails = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        keyWord: payload.searchKey,
        filterAll: payload.filterAll,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/bill`,criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//filtered bill
export const getBillFilteredService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        keyWord: payload.searchKey,
        filterAll: payload.filterAll,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/bill/filtered`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Get Admin User By Id
export const getBillDetailsById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/bill`, criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getInvoiceDetails = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        keyWord: payload.searchKey,
        // filterAll: payload.filterAll,
        branch: payload.branch,
        startDate: payload.startDate,
        endDate: payload.endDate, 
        startDueDate: payload.startDueDate,
        endDueDate: payload.endDueDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/invoice`,criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getInvoiceFilteredService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        keyWord: payload.searchKey,
        branch: payload.branch,
        startDate: payload.startDate,
        endDate: payload.endDate,
        startDueDate: payload.startDueDate,
        endDueDate: payload.endDueDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/invoice/filtered`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


//filter invoice by finance
export const getInvoiceServiceByFinance = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        keyWord: payload.searchKey,
        branch: payload.branch,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/invoice/downloadByFinance`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//filter invoice by finance
export const getInvoiceServiceByOperation = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        keyWord: payload.searchKey,
        branch: payload.branch,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/invoice/downloadByOperation`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getInvoiceDetailsById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/invoice`, criteria);
    // console.log("responseById: ", response);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Tax invoices 
export const getTaxInvoiceDetails = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        keyWord: payload.searchKey,
        // branch: payload.branch,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/invoiceTax`,criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//tax invoice by id 
export const getTaxInvoiceDetailsById = async (payload) => {
  try {
    // console.log("payload>>", payload);
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/invoiceTax`, criteria);
    // console.log("responseById: ", response);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//filter invoice tax
export const getInvoiceTaxFilteredService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        keyWord: payload.searchKey,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/invoiceTax/filteredTax`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


export const getBankDetails = async () => {
  try {
    const response = await axiosInstance.post(`/bank/list`);
    // console.log("responseBank: ", response);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getBankDetailsById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/bank/list`, criteria);
    //console.log("getReferralListById:", response)
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.msg };
  }
};


export const uploadInvoicePaidFile = async (payload) => {
  try {
    // console.log( "payload", payload);
    var image = payload;

    var formdata = new FormData();

    //if we want upload multiple images
    // payload.forEach((image, index) => {
    //   formdata.append(`file${index}`, image);
    // });

    formdata.append("image", image);

    const response = await axiosInstance.post(`/invoice/uploadInvoiceNumber`, formdata);
    // console.log("imageResponse", response);
    if(response.data.status === true) {
      toast.success("Status Changed Successfully",{
        position:"top-right"
      });
    }

    return response;
  } catch (e) {
    // console.log("error", e);
    if(e.response.data.msg === "First give approval for operation before finance.") {
      toast.error("Please check operation approval status",{
        position:"top-right"
      });
    }
    return { error: e.response.data.message };
  }
};

//update invoice
export const updateInvoiceService = async (payload) => {
  try {
    console.log("payload", payload)
    const response = await axiosInstance.patch(
      `/invoice/${payload.id}`,
      payload.payload
    );
    // console.log("fetched", response)
    if(response.data.status === true) {
      toast.success("Status Changed Successfully",{
        position:"top-right"
      });
    }
   
    return response;
  } catch (e) {
    console.log("error", e)
    if(e.response.data.msg === "First give approval for operation before finance.") {
      toast.error("Please check operation approval status",{
        position:"top-right"
      });
    }
    return { error: e.response.data };
  }
};


//bill upload file
export const uploadBillPaidFile = async (payload) => {
  try {
    // console.log( "payload", payload);
    var image = payload;

    var formdata = new FormData();

    //if we want upload multiple images
    // payload.forEach((image, index) => {
    //   formdata.append(`file${index}`, image);
    // });

    formdata.append("image", image);
    


    const response = await axiosInstance.post(`/bill/uploadBillNumber`, formdata);
    // console.log("imageResponse", response);
    if(response.data.status === true) {
      toast.success("Status Changed Successfully",{
        position:"top-right"
      });
    }

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//update Bill
export const updateBillService = async (payload) => {
  try {
    console.log("payload", payload)
    const response = await axiosInstance.patch(
      `/bill/${payload.id}`,
      payload.payload
    );
    // console.log("fetched", response)
    if(response.data.status === true) {
      toast.success("Status Changed Successfully",{
        position:"top-right"
      });
    }
   
    return response;
  } catch (e) {
    console.log("error", e)
    return { error: e.response.data };
  }
};

export const updateBillAmountService = async (payload) => {
  try {
    console.log("payload", payload)
    const response = await axiosInstance.patch(
      `/bill/${payload.id}`,
      payload.payload
    );
    console.log("fetched", response)
    if(response.data.status === true) {
      toast.success("Amount Receive Changed Successfully",{
        position:"top-right"
      });
    }
   
    return response;
  } catch (e) {
    console.log("error", e)
    return { error: e.response.data };
  }
};