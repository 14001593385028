import { yupResolver } from "@hookform/resolvers/yup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Moment from "moment";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDButton from "components/MDButton";
import SelectDropDown from "examples/DropDown";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  setCityList,
  setCompanyType,
  setDepartmentType,
  setExperience,
  setLanguageType,
  setProfessionType,
  setProfessionalRegistrationType,
  setQualificationType,
  setRoleType,
  setSpecialisationsType,
} from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserByIdService } from "services/userServices";
import userDetailsValidationSchema from "../../../util/validations/userDetailsValidation";
import { setProfilePhoto, updateUser } from "../Redux/actionCreator";

import SelectDropDownNew from "examples/DropDownNew";
import MultiSelectDropdown from "examples/Multi Select Dropdown";
import {
  GetAdditionalTrainingById,
  getSoftwaresList,
  setOrganizationsDropdown,
} from "layouts/common/Redux/actionCreator";
import { setDocumentType } from "layouts/events/Redux/actionCreator";
import { getDepartmentsList } from "layouts/hospital/Redux/actionCreator";
import moment from "moment";
import NestedArray1 from "./NestedArray1";
import NestedArray2 from "./NestedArray2";
import NestedArray3 from "./NestedArray3";
import "./styles.css";

function UserDetailByName() {
  const [dateFormat, setDateFormat] = useState();
  const [dob, setDob] = useState("");
  const ID = window.location.href.split("name")[1].split("/")[1];
  const [organisationID, setOrganisationID] = useState();
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [responseData, setResponseData] = useState([]);

  // console.log("responseData>>", responseData);

  const locationOptions = [
    { key: "AWS_S3", value: "AWS_S3" },
    { key: "LOCAL", value: "LOCAL" },
    { key: "SELF_HOSTED", value: "SELF_HOSTED" },
    { key: "CLOUDINARY", value: "CLOUDINARY" },
  ];
  const getData = async () => {
    let response = await getUserByIdService(ID);

    // console.log("getData", response[0]);

    setResponseData(response[0]);
    return response[0];
  };

  const getDataForProfile = async () => {
    const res = await getUserByIdService(ID);

    setDateFormat(res[0]?.profile?.dob);
    if (res[0]?.profile?.dob == undefined) setDob("");
    else getDobb(res[0]?.profile?.dob);
  };
  const getDobb = (dateFormatt) => {
    var a, b, c;

    var dayy = new Date(dateFormatt);

    a = dayy.getDate();
    b = dayy.getMonth();
    c = dayy.getFullYear();
    setDob(a + "/" + b + "/" + c);
  };

  useEffect(() => {
    getDataForProfile();
  }, []);

  useEffect(() => {
    if (file != null) {
      dispatch(setProfilePhoto(file));
    }
  }, [file]);

  const { OrganizationsData } = useSelector((state) => state.common);
  const organizationInfo = OrganizationsData?.organizationsInfo;
  const organizationOptions = [];

  const { SoftwaresData } = useSelector((state) => state.common);
  const workedonInfo = SoftwaresData?.softwaresInfo;

  const workedonOptions = [];

  const { Additional_Id_Data } = useSelector((state) => state.common);
  const additionaTrainingInfo = Additional_Id_Data?.additionalIdInfo;

  const additionalTrainingOptions = [];
  var languageKnownIDPayload = [];

  additionaTrainingInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    additionalTrainingOptions.push(obj);
  });

  workedonInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    workedonOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  const handleOrganizationChange = (e) => {
    let orgID;
    setValue("organization.name", e.target.value);
    //Searching for the name in the Users array to find the ID
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        orgID = obj.value;
      }
    });

    setOrganisationID(orgID);
  };
  //MULTI SELECT language known HANDLE SELECT
  const handleLanguageKnown = (values) => {
    //Searching for the name in the language array to find the ID

    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        languageOptions?.map((obj) => {
          if (obj.value == value) {
            languageKnownIDPayload.push(obj.key);
          }
        });
      });
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema),
    mode: "onChange",
    defaultValues: async () => await getData(),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const temp = getValues();
  // console.log("All data", temp)
  //Hanlde Download

  //Download Function
  function handleDownload(filename, url, extension) {
    const loadingTost = toast.loading("Downloading", { position: "top-right" });
    fetch(`https:${url}`)
      .then((value) => {
        return value.blob();
      })
      .then((value) => {
        toast.dismiss(loadingTost);
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(value);
        a.href = url;

        a.download = `${filename}.${extension}`;
        a.click();
        toast.success("Downloaded", { position: "top-right" });
      });
  }

  //Finding USER Object
  const { Users_Data, User_profile_image_Data } = useSelector(
    (state) => state.user
  );
  const profileImageInfo = User_profile_image_Data?.User_profile_image_info;
  const users = Users_Data.Users_DataInfo;

  var userObj;
  users.map((user) => {
    if (user._id == ID) {
      userObj = user;
    }
  });

  const onSubmit = (data) => {
    // console.log("data>>>", data);
    const organization = [];
    data?.organizations.map((ele) => {
      let obj = {
        role:
          typeof ele?.role == "object"
            ? ele?.role?._id
              ? ele?.role?._id
              : ""
            : ele?.role,
        organization:
          typeof ele?.organization == "object"
            ? ele?.organization?._id
              ? ele?.organization?._id
              : ""
            : ele?.organization,
        isOwner: ele?.isOwner,
      };
      organization.push(obj);
    });
    let departmentsArray = [];
    data.departments.map((obj) => {
      departmentsArray.push(obj._id);
    });

    let educationPayload = [];
    let jobHistoryPayload = [];

    //Job History Payload
    data?.profile?.jobHistory.map((obj) => {
      let tempObjJobHistory = {
        employmentLetter: {
          docType: "",
          files: "",
        },
        salarySlip: {
          docType: "",
          files: "",
        },
        isCurrent: "",
        hospital: "",
        department: "",
        specialisation: "",
        start: "",
        end: "",
        _id: "",
      };

      tempObjJobHistory.employmentLetter.docType =
        typeof obj?.employmentLetter.docType == "object"
          ? obj?.employmentLetter.docType?._id
            ? obj?.employmentLetter.docType?._id
            : ""
          : obj?.employmentLetter.docType;

      tempObjJobHistory.employmentLetter.files = obj?.employmentLetter?.files;
      //Salary Slip
      tempObjJobHistory.salarySlip.docType =
        typeof obj?.salarySlip.docType == "object"
          ? obj?.salarySlip.docType?._id
            ? obj?.salarySlip.docType?._id
            : ""
          : obj?.salarySlip.docType;

      tempObjJobHistory.salarySlip.files = obj?.salarySlip?.files;
      tempObjJobHistory.isCurrent = obj?.isCurrent;
      tempObjJobHistory.hospital = obj?.hospital;
      tempObjJobHistory.department =
        typeof obj?.department == "object"
          ? obj?.department?._id
            ? obj?.department?._id
            : ""
          : obj?.department;

      tempObjJobHistory.specialisation =
        typeof obj?.specialisation == "object"
          ? obj?.specialisation?._id
            ? obj?.specialisation?._id
            : ""
          : obj?.specialisation;

      tempObjJobHistory.start = obj?.start;
      tempObjJobHistory.end = obj?.end;
      tempObjJobHistory._id = obj?._id;

      jobHistoryPayload.push(tempObjJobHistory);
    });

    let tempObjJobHistory = {
      employmentLetter: {
        docType: "",
        files: "",
      },
      salarySlip: {
        docType: "",
        files: "",
      },
      isCurrent: "",
      hospital: "",
      department: "",
      specialisation: "",
      start: "",
      end: "",
      _id: "",
    };

    //Looping through Education
    data?.profile?.education.map((obj) => {
      let tempObj = {
        certificate: {
          docType: "",
          files: "",
          uploadedAt: "",
        },
        isForeign: "",
        specialisation: "",
        name: "",
        year: "",
        _id: "",
        course: "",
      };

      tempObj.certificate.docType =
        typeof obj?.certificate.docType == "object"
          ? obj?.certificate.docType?._id
            ? obj?.certificate.docType?._id
            : ""
          : obj?.certificate.docType;

      tempObj.certificate.files = obj?.certificate?.files;
      tempObj.certificate.uploadedAt = obj?.certificate?.uploadedAt;
      tempObj.isForeign = obj?.isForeign;
      tempObj.name = obj?.name;
      tempObj.specialisation =
        typeof obj?.specialisation == "object"
          ? obj?.specialisation?._id
            ? obj?.specialisation?._id
            : ""
          : obj?.specialisation;

      tempObj._id = obj?._id;
      tempObj.year = obj?.year;
      tempObj.course = obj?.course;

      educationPayload.push(tempObj);
    });

    var ProfileDob = [];
    var dobj;
    if (data?.profile?.dob?.length !== undefined) {
      if (data?.profile?.dob !== "") {
        ProfileDob = data?.profile?.dob.split("/");

        dobj = new Date(
          parseInt(ProfileDob[2]),
          parseInt(ProfileDob[1]) - 1,
          parseInt(ProfileDob[0]) + 1
        ).toISOString();
      }
    }

    let payload = {
      name: {
        salutation: data?.name?.salutation,
        first: data?.name?.first,
        middle: data?.name?.middle,
        last: data?.name?.last,
        full: data?.name?.full,
      },
      enabled: data?.enabled ? data?.enabled : false,
      _id: data?._id,
      emails: data?.emails,
      phoneNumbers: data?.phoneNumbers,
      password: data?.password,

      avatar: profileImageInfo[0] ? profileImageInfo[0] : data.avatar,

      correspondenceAddress: {
        address: data?.correspondenceAddress?.address,
        city: data?.correspondenceAddress?.city,
        state: data?.correspondenceAddress?.state,
        pin: data?.correspondenceAddress?.pin,
        country: data?.correspondenceAddress?.country,
        coords: data?.correspondenceAddress?.coords,
      },

      profile: {
        social: {
          facebook: data?.profile?.social?.facebook,
          linkedin: data?.profile?.social?.linkedin,
        },
        dob: dobj,
        currentlyEmployed: data?.profile?.currentlyEmployed,
        gender: data?.profile?.gender,
        pan: data?.profile?.pan,
        aadhar: data?.profile?.aadhar,
        covid: data?.profile?.covid,

        jobHistory: jobHistoryPayload,
        education: educationPayload,
        references: data?.profile?.references,
      },

      organizations: organization,
      __v: data?._v,

      progress: data?.progress,

      email: data?.email,
      phoneNumber: data?.phoneNumber,

      //profile photo url
      role: role,
      canAccessKeystone: data?.canAccessKeystone,
      // profilePhotoIsSecured:data?.profilePhotoIsSecured,
      status: data?.status,
      address: {
        address: data?.address?.address,
        city: data?.address?.city,
        state: data?.address?.state,
        pin: data?.address?.pin,
        country: data?.address?.country,
      },
      numExperience: exp,
      qualification: qual,

      professionalRegistration: {
        registrationType: proReg,
      },
      languageKnown: data?.languageKnown ? data?.languageKnown : "NA",
      languageKnownNew: Array.isArray(data?.languageKnownNew)
        ? data.languageKnownNew
        : typeof data?.languageKnownNew === "string"
        ? data.languageKnownNew.split(",").map((lang) => lang.trim())
        : ["NA"],
      // languageKnownNew: Array.isArray(data?.languageKnownNew) ? data?.languageKnownNew : ["NA"],

      // languageKnown:
      //   languageKnownIDPayload?.length > 0
      //     ? languageKnownIDPayload
      //     : data?.languageKnown.map((obj) => obj?._id),
      departments:
        deaprtmentsIDPayload?.length > 0
          ? deaprtmentsIDPayload
          : data?.departments.map((obj) => obj?._id),
      workCity: city,
      specialization: special,
      professionalType: proType,
      workedOn:
        workedOnIDPayload?.length > 0
          ? workedOnIDPayload
          : data?.workedOn.map((obj) => obj?._id),
      additionalTraining: data?.additionalTraining
        ? data?.additionalTraining
        : "NA",
      // additionalTrainingNew: data?.additionalTrainingNew ? data.additionalTrainingNew.split(',').map(lang => lang.trim()) : ["NA"],
      additionalTrainingNew: Array.isArray(data?.additionalTrainingNew)
        ? data.additionalTrainingNew
        : typeof data?.additionalTrainingNew === "string"
        ? data.additionalTrainingNew.split(",").map((lang) => lang.trim())
        : ["NA"],
      // additionalTraining:
      //   additionalTrainingIDPayload?.length > 0
      //     ? additionalTrainingIDPayload
      //     : data?.additionalTraining.map((obj) => obj?._id),
      bankDetails: {
        accountNumber: data?.bankDetails?.accountNumber,
        bankName: data?.bankDetails?.bankName,
        benifeciaryName: data?.bankDetails?.beneficiaryName,
        branchAddress: data?.bankDetails?.branchAddress,
        ifsc: data?.bankDetails?.ifsc,
      },
    };
    // console.log("payload>>", payload);
    dispatch(updateUser({ payload: payload, id: ID })).then(() => {
      navigate("/users");
    });
  };

  const {
    City_List_Data,
    Company_Type_Data,
    Experience_Data,
    Profession_Type_Data,
    Qualification_Type_Data,
    Specialization_Type_Data,
    Department_Type_Data,
    Role_Type_Data,
    Language_Type_Data,
    Professional_Registration_Type_Data,
    Document_Type_Data,
  } = useSelector((state) => state.events);

  const cityData = City_List_Data.cityListInfo;
  const employerData = Company_Type_Data.companyTypeInfo;
  const experienceData = Experience_Data.experienceInfo;
  const professionData = Profession_Type_Data.professionInfo;
  const qualificationsData = Qualification_Type_Data.qualificationInfo;
  const specializationsData = Specialization_Type_Data.specializationInfo;
  const departmentData = Department_Type_Data.departmentInfo;

  const roleData = Role_Type_Data.roleInfo;
  const languageData = Language_Type_Data.languageInfo;
  const professionalRegistrationTypeData =
    Professional_Registration_Type_Data.professionInfo;
  const documentTypeData = Document_Type_Data.documentTypeInfo;
  const { documentTypeDropDown } = useSelector((state) => state.hospital);
  const docTypeData = documentTypeDropDown?.documentTypeDropDownInfo;
  const docTypeOptions = [];

  const { hospital_department_Data, hospital_branch_Data } = useSelector(
    (state) => state.hospital
  );
  const department_Info = hospital_department_Data?.departmentInfo;

  documentTypeData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    docTypeOptions.push(obj);
  });

  //Drop Down Options
  const cityOptions = [];
  const employerTypeOptions = [];
  const experienceOptions = [];
  const professionOptions = [];
  const qualificationOptions = [];
  const specializationOptions = [];
  const departmentOptions = [];
  const roleOptions = [];
  const languageOptions = [];
  const professionalRegistrationTypeOptions = [];
  const documentTypeOptions = [];
  const departmentTypeOption = [];
  documentTypeData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    documentTypeOptions.push(obj);
  });

  departmentData.map((elem) => {
    let obj = {
      key: elem?._id,
      value: elem?.name,
    };
    departmentTypeOption.push(obj);
  });

  // departmentData.map((ele) => {
  //   let obj = {
  //     key: ele._id,
  //     value: ele.name,
  //   };

  //   departmentOptions.push(obj);
  // });

  department_Info.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentOptions.push(obj);
  });

  roleData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    roleOptions.push(obj);
  });

  languageData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    languageOptions.push(obj);
  });

  professionalRegistrationTypeData &&
    professionalRegistrationTypeData.map((ele) => {
      let obj = {
        key: ele._id,
        value: ele.name,
      };
      professionalRegistrationTypeOptions.push(obj);
    });

  cityData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    cityOptions.push(obj);
  });
  employerData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    employerTypeOptions.push(obj);
  });

  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  qualificationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    qualificationOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  //DropDown Options
  const selectOptions = [
    {
      key: "1",
      value: "1",
    },
    {
      key: "2",
      value: "2",
    },
  ];

  //Avatar Location
  const avatarLocationOptions = [
    {
      key: "AWS_S 3",
      value: "AWS_S 3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];

  const values = getValues();
  
  //Status DropDown Options
  const statusOptions = [
    { key: "ACTIVE", value: "ACTIVE" },
    { key: "INACTIVE", value: "INACTIVE" },
    { key: "SIGNUP", value: "SIGNUP" },
    { key: "BGVTRIGGERED", value: "BGVTRIGGERED" },
    { key: "BGVFAILED", value: "BGVFAILED" },
    { key: "BGVDELAYED", value: "BGVDELAYED" },
    { key: "", value: "" },
  ];

  const {
    fields: profileJobHistory,
    append: profileJobHistoryAppend,
    remove: profileJobHistoryRemove,
  } = useFieldArray({
    control,
    name: "profile.jobHistory",
  });

  const [salarySlip, setSalarySlip] = useState();
  const {
    fields: salaryField,
    remove: salaryRemove,
    append: salaryAppend,
  } = useFieldArray({
    control,
    name: `profile.jobHistory.[${salarySlip}].salarySlip.files`,
  });

  const [certificate, setCertificate] = useState();
  const {
    fields: CertificateField,
    remove: CertificateRemove,
    append: CertificateAppend,
  } = useFieldArray({
    control,
    name: `profile.education.[${certificate}].certificate.files`,
  });

  const [emp, setEmp] = useState();
  const {
    fields: employmentField,
    remove: employmentRemove,
    append: employmentAppend,
  } = useFieldArray({
    control,
    name: `profile.jobHistory.[${emp}].employmentLetter.files`,
  });

  const {
    fields: profileRefrence,
    append: profileRefrenceAppend,
    remove: profileRefrenceRemove,
  } = useFieldArray({
    control,
    name: "profile.references",
  });

  const {
    fields: Address,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: "Address",
  });

  const {
    fields: documents,
    append: documentsAppend,
    remove: documentsRemove,
  } = useFieldArray({
    control,
    name: "documents",
  });

  const {
    fields: organizations,
    append: companyAppend,
    remove: companyRemove,
  } = useFieldArray({
    control,
    name: "organizations",
  });

  const {
    fields: correspondenceAddress,
    append: correspondenceAppend,
    remove: correspondenceRemove,
  } = useFieldArray({
    control,
    name: "correspondenceAddress",
  });

  const {
    fields: profileEducation,
    append: profileEducationAppend,
    remove: profileEducationRemove,
  } = useFieldArray({
    control,
    name: "profile.education",
  });

  const [additionall, setAdditional] = useState("");

  var additionalTrainingIDPayload = [];

  const handleAdditionalTraining = (values) => {
    additionalTrainingIDPayload = [];
    let selectedValues = values;
    if (selectedValues) {
      selectedValues?.map((value) => {
        additionalTrainingOptions?.map((obj) => {
          if (obj.value == value) {
            additionalTrainingIDPayload.push(obj.key);
          }
        });
      });
    }
  };

  const [role, setRole] = useState("");
  const handleRole = (e) => {
    let userID;

    roleOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setRole(userID);
  };
  const [exp, setExp] = useState("");
  const handleExperience = (e) => {
    let userID;
    // setValue(`additionalTraining[0].name`,e.target.value)
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setExp(userID);
  };

  const [qual, setQual] = useState("");
  const handleQualification = (e) => {
    let userID;
    // setValue(`additionalTraining[0].name`,e.target.value)
    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setQual(userID);
  };

  const handleDocTypeChange = (e, name) => {
    let docTypeID;
    // setValue(`additionalTraining[0].name`,e.target.value)
    documentTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        docTypeID = obj.key;
      }
    });

    setValue(name, docTypeID);
  };

  const handleSpecializationDynamic = (e, name) => {
    let specializationID;

    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        specializationID = obj.key;
      }
    });
    setValue(name, specializationID);
  };

  const [proReg, setProReg] = useState("");
  const handleProfessionalReg = (e) => {
    let userID;
    // setValue(`professionalRegistration.registrationType.name`,e.target.value)
    professionalRegistrationTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setProReg(userID);
  };

  const [proType, setProType] = useState("");
  const handleProfessionalType = (e) => {
    let userID;
    setValue(`professionalType.name`, e.target.value);
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setProType(userID);
  };
  const [special, setSpecial] = useState("");

  const handleSpecialization = (e) => {
    let userID;
    setValue(`specialization.name`, e.target.value);
    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setSpecial(userID);
  };

  const [dprt, setDprt] = useState("");
  const handleDepartments = (e) => {
    setValue(`departments[0].name`, e.target.value);
    let userID;

    departmentTypeOption.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setDprt(userID);
  };

  const [city, setCity] = useState("");
  const handleWorkCity = (e) => {
    setValue(`workCity.name`, e.target.value);
    let userID;

    cityOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setCity(userID);
  };
  const [lang, setLang] = useState("");
  const handleLanguage = (e) => {
    setValue(`languageKnown[0].name`, e.target.value);
    setValue(`languageKnownNew[0].name`, e.target.value);
    let userID;

    languageOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setLang(userID);
  };

  const [worked, setWorked] = useState("");
  var workedOnIDPayload = [];

  const handleWorked = (values) => {
    workedOnIDPayload = [];
    let selectedValues = values;
    if (selectedValues) {
      selectedValues?.map((value) => {
        workedonOptions?.map((obj) => {
          if (obj.value == value) {
            workedOnIDPayload.push(obj.key);
          }
        });
      });
    }
  };

  var deaprtmentsIDPayload = [];

  const handleDepartmentMultiSelect = (values) => {
    deaprtmentsIDPayload = [];
    let selectedValues = values;
    if (selectedValues) {
      selectedValues?.map((value) => {
        departmentTypeOption?.map((obj) => {
          if (obj.value == value) {
            deaprtmentsIDPayload.push(obj.key);
          }
        });
      });
    }
  };

  const {
    fields: email,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    control,
    name: "emails",
  });
  const {
    fields: organization,
    append: organizationAppend,
    remove: organizationRemove,
  } = useFieldArray({
    control,
    name: "organizations",
  });

  const {
    fields: phone,
    append: phoneAppend,
    remove: phoneRemove,
  } = useFieldArray({
    control,
    name: "phoneNumbers",
  });
  const handleBack = () => {
    navigate(-1);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    dispatch(setOrganizationsDropdown());
    dispatch(setCityList());
    dispatch(setCompanyType());
    dispatch(setExperience());
    dispatch(setProfessionType());
    dispatch(setQualificationType());
    dispatch(setSpecialisationsType());
    dispatch(setDepartmentType());
    dispatch(setRoleType());
    dispatch(setLanguageType());
    dispatch(setProfessionalRegistrationType());
    dispatch(getSoftwaresList());
    dispatch(GetAdditionalTrainingById());
    dispatch(getDepartmentsList({}));
    dispatch(setDocumentType());
  }, []);

  const [updated1, setUpdated1] = useState(false);
  const [updated2, setUpdated2] = useState(false);
  const [updated3, setUpdated3] = useState(false);
  const [updated4, setUpdated4] = useState(false);
  const [updated5, setUpdated5] = useState(false);
  const [updated6, setUpdated6] = useState(false);
  const [arrData, setArrData] = useState([]);
  useEffect(() => {
    let createdDate = getValues("created_at");
    let updatedDate = getValues("updated_at");
    let profileDob = getValues("profile.dob");
    let regdate = getValues("professionalRegistration.registrationDate.ts");
    let expirydate = getValues("professionalRegistration.expiryDate.ts");

    // let profile=getValues("profile.jobHistory[0].employmentLetter.files[0].location");
    let profile1 = getValues("profile.jobHistory");
    if (createdDate == "Invalid date") {
      setValue("created_at", "");
    }
    if (updatedDate == "Invalid date") {
      setValue("updated_at", "");
    }
    setArrData(profile1);

    if (expirydate == "Invalid date") {
      setValue("professionalRegistration.expiryDate.ts", "");
    }

    if (expirydate !== undefined && expirydate !== "" && expirydate !== null) {
      if (!updated6) {
        const a = Moment(parseInt(expirydate)).format("DD/MM/YYYY");

        setValue("professionalRegistration.expiryDate.ts", a);
        setUpdated6(true);
      }
    }
    if (regdate == "Invalid date") {
      setValue("professionalRegistration.registrationDate.ts", "");
    }
    if (regdate !== undefined && regdate !== "" && regdate !== null) {
      if (!updated5) {
        const a = Moment(parseInt(regdate)).format("DD/MM/YYYY");

        setValue("professionalRegistration.registrationDate.ts", a);
        setUpdated5(true);
      }
    }
    if (createdDate != undefined && createdDate !== "") {
      if (!updated1) {
        const a = Moment(createdDate).format("DD/MM/YYYY");

        setValue("created_at", a);

        setUpdated1(true);
      }
    }

    if (updatedDate != undefined && updatedDate !== "") {
      if (!updated2) {
        const a = Moment(updatedDate).format("DD/MM/YYYY");
        setValue("updated_at", a);

        setUpdated2(true);
      }
    }

    if (profileDob != undefined && profileDob !== "" && profileDob !== null) {
      if (!updated3) {
        if (profileDob.includes("GM")) {
          setValue("profile.dob", moment(profileDob).format("DD/MM/YYYY"));
        } else {
          const profileDate = profileDob
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");

          setValue("profile.dob", profileDate);
        }

        setUpdated3(true);
      }
    }

    if (profileDob == "Invalid date") {
      setValue("profile.dob", "");
    }

    let avatarData = getValues("avatar");

    if (avatarData != undefined) {
      if (!updated4) {
        setImageUrl(avatarData?.url);
        setUpdated4(true);
      }
    }
  }, [
    getValues("updated_at"),
    getValues("profile.dob"),
    getValues("created_at"),
    getValues("professionalRegistration.registrationDate.ts"),
    getValues("profile"),
  ]);

  const formValues = getValues();

  const roleFilter = formValues?.role?.name;

  // const calculateRows = (content) => {
  //   // Adjust these values according to your requirement
  //   const rowHeight = 24; // Height of each row in pixels
  //   const maxHeight = 200; // Maximum height in pixels

  //   // Calculate the number of rows based on content length
  //   const numRows = Math.min(Math.ceil(content.length / 40), Math.floor(maxHeight / rowHeight));

  //   return numRows;
  // };

  // const additionalSkillContent = responseData?.additionalSkill?.map(skill => skill.name).join(', ');
  // console.log("organizations>>", organizations)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={4}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>

          {/* Hospital Role */}

          {roleFilter == "HOSPITAL" ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Full Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("name.full")}
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      {/* <Typography>ID: {ID}</Typography> */}
                      {/* <Grid container spacing={}> */}
                      {/* <Grid item xs={4}> */}
                      <Typography variant="subtitle1">ID: {ID}</Typography>
                      {/* </Grid> */}

                      {/* </Grid> */}
                    </Grid>
                    <Grid item xs={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              control={control}
                              {...register(`enabled`)}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={props.onChange}
                                />
                              )}
                            />
                          }
                          label="Enabled"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              control={control}
                              {...register(`canAccessKeystone`)}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={props.onChange}
                                />
                              )}
                            />
                          }
                          label="Can Access Keystone?"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {/* Company */}
                  <Grid item xs={12}>
                    <Paper sx={{ padding: "1rem" }}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 500 }}
                      >
                        Company
                      </Typography>
                      <hr style={{ marginBottom: "2rem" }} />
                      <ul style={{ listStyleType: "none" }}>
                        {organizations.map((field, index) => (
                          <li>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Company
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDownNew
                                options={organizationOptions}
                                getValues={getValues}
                                control={control}
                                name={`organizations.[${index}].organization.name`}
                                customOnChange={(e) => {
                                  organizationOptions.map((elem) => {
                                    if (elem.value == e.target.value) {
                                      setValue(
                                        `organizations.[${index}].organization`,
                                        elem.key
                                      );
                                    }
                                  });
                                }}
                              />
                            </Grid>

                            <Grid item xs={3}>
                              <Typography variant="subtitle1">Role</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDownNew
                                options={roleOptions}
                                getValues={getValues}
                                control={control}
                                name={`organizations.[${index}].role.name`}
                                customOnChange={(e) => {
                                  roleOptions.map((elem) => {
                                    if (elem.value == e.target.value) {
                                      setValue(
                                        `organizations.[${index}].role`,
                                        `${elem.key}`
                                      );
                                    }
                                  });
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Controller
                                      defaultValue={false}
                                      control={control}
                                      name={`organizations.[${index}].isOwner`}
                                      render={({ field: props }) => (
                                        <Checkbox
                                          {...props}
                                          checked={props.value}
                                          onClick={(e) =>
                                            props.onChange(e.target.value)
                                          }
                                        />
                                      )}
                                    />
                                  }
                                  label="IsOwner"
                                />
                              </FormGroup>
                            </Grid>

                            <MDBox sx={{ textAlign: "right" }}>
                              <MDButton
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  companyRemove(index);
                                }}
                              >
                                <Typography color="error" variant="body">
                                  Remove
                                </Typography>
                              </MDButton>
                            </MDBox>
                            {/* </MDBox>  */}
                          </li>
                        ))}
                        <MDButton
                          variant="contained"
                          color="info"
                          onClick={(e) => {
                            companyAppend({});
                          }}
                        >
                          + Add
                        </MDButton>
                      </ul>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {email.map((field, index) => (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">Email</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              disabled
                              {...register(`emails.[${index}].address`)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(`emails.[${index}].isPrimary`)}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Primary"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(`emails.[${index}].isVerified`)}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Verified"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {phone.map((field, index) => (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">
                              Phone Number
                            </Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              disabled
                              {...register(`phoneNumbers.[${index}].number`)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(
                                  `phoneNumbers.[${index}].isPrimary`
                                )}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Primary"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(
                                  `phoneNumbers.[${index}].isVerified`
                                )}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Verified"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Password</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    disabled
                    {...register("password")}
                  />
                </Grid>
                {/* Name Salutation */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Name</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled
                        fullWidth
                        {...register("name.salutation")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled
                        fullWidth
                        {...register("name.first")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled
                        fullWidth
                        {...register("name.middle")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        disabled
                        fullWidth
                        {...register("name.last")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Organization Name</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                    fullWidth
                    {...register("profile.organization.name")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Organization Role</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                    fullWidth
                    {...register("profile.organization.role")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">App Role</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                    fullWidth
                    {...register("role.name")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile URL</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    // value={dob}
                    fullWidth
                    disabled
                    {...register("avatar.url")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created at</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    disabled
                    {...register("created_at")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated at</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    disabled
                    {...register("updated_at")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created By</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled
                    fullWidth
                    {...register("createdBy.name.full")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated By</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    disabled
                    {...register("updatedBy.name.full")}
                  />
                </Grid>

                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 25, fontWeight: "bold" },
                    }}
                    {...register("name.full")}
                  />
                </Grid>
                <Grid item xs={12} sx={{ width: "100%", marginBottom: "20px" }}>
                  <Typography variant="subtitle1">ID: {ID}</Typography>
                </Grid>

                <Grid item xs={12}>
                  {email.map((field, index) => (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">Email</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              disabled
                              {...register(`emails.[${index}].address`)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(`emails.[${index}].isPrimary`)}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Primary"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(`emails.[${index}].isVerified`)}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Verified"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {phone.map((field, index) => (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={3}>
                            {index == 0 ? (
                              <Typography variant="subtitle1">
                                Phone Number
                              </Typography>
                            ) : (
                              <Typography variant="subtitle1">
                                Whatsapp Number
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              disabled
                              {...register(`phoneNumbers.[${index}].number`)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(
                                  `phoneNumbers.[${index}].isPrimary`
                                )}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Primary"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={3}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Controller
                                control={control}
                                {...register(
                                  `phoneNumbers.[${index}].isVerified`
                                )}
                                render={({ field: props }) => (
                                  <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={props.onChange}
                                  />
                                )}
                              />
                            }
                            label="Is Verified"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* Password */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Password</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("password")}
                  />
                </Grid>
                {/* Name */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Name</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        sx={{ width: "100%", marginTop: "0.4rem" }}
                        inputProps={{
                          style: { fontSize: 15, fontWeight: "bold" },
                        }}
                        fullWidth
                        {...register("name.salutation")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        sx={{ width: "100%", marginTop: "0.4rem" }}
                        inputProps={{
                          style: { fontSize: 15, fontWeight: "bold" },
                        }}
                        fullWidth
                        {...register("name.first")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        sx={{ width: "100%", marginTop: "0.4rem" }}
                        inputProps={{
                          style: { fontSize: 15, fontWeight: "bold" },
                        }}
                        fullWidth
                        {...register("name.middle")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        sx={{ width: "100%", marginTop: "0.4rem" }}
                        inputProps={{
                          style: { fontSize: 15, fontWeight: "bold" },
                        }}
                        fullWidth
                        {...register("name.last")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile DOB</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    // value={dob}
                    // defaultValue={dob}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.dob")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Currently Employed
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.currentlyEmployed")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile Gender</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.gender")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile Pan</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.pan")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile Aadhar</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.aadhar")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Profile Social Facebook
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.social.facebook")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Profile Social Linkedin
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidthsx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.social.linkedin")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile Covid</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("profile.covid")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ padding: "1rem" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                      Profile Job History
                    </Typography>
                    <hr style={{ marginBottom: "2rem" }} />
                    <ul style={{ listStyleType: "none" }}>
                      {profileJobHistory.map((field, index) => (
                        <li>
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Controller
                                    name={`profile.jobHistory.[${index}].isCurrent`}
                                    control={control}
                                    render={({ field: props }) => (
                                      <Checkbox
                                        {...props}
                                        checked={props.value}
                                        {...register(
                                          `profile.jobHistory.[${index}].isCurrent`
                                        )}
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                      />
                                    )}
                                  />
                                }
                                label="Is Current?"
                              />
                            </FormGroup>
                          </Grid>
                          {/* Hospital */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Hospital
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <MDInput
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                fullWidth
                                sx={{ width: "100%", marginTop: "0.4rem" }}
                                inputProps={{
                                  style: { fontSize: 15, fontWeight: "bold" },
                                }}
                                {...register(
                                  `profile.jobHistory.[${index}].hospital`
                                )}
                              />
                            </Grid>
                          </Grid>
                          {/* Department */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Department
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={9}>
                              <SelectDropDownNew
                                getValues={getValues}
                                control={control}
                                name={`profile.jobHistory.[${index}].department.name`}
                                defaultValue="none"
                                options={departmentTypeOption}
                                customOnChange={(e) => {
                                  departmentTypeOption.map((elem) => {
                                    if (elem?.value == e.target.value)
                                      setValue(
                                        `profile.jobHistory.[${index}].department`,
                                        elem?.key
                                      );
                                  });
                                }}
                              />
                            </Grid> */}
                            <Grid item xs={9}>
                              <MDInput
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                fullWidth
                                sx={{ width: "100%", marginTop: "0.4rem" }}
                                inputProps={{
                                  style: { fontSize: 15, fontWeight: "bold" },
                                }}
                                {...register(
                                  `profile.jobHistory.[${index}].department`
                                )}
                              />
                            </Grid>
                          </Grid>
                          {/* Specialisation */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Specialization
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={9}>
                              <SelectDropDownNew
                                getValues={getValues}
                                control={control}
                                name={`profile.jobHistory.[${index}].specialisation.name`}
                                defaultValue="none"
                                options={specializationOptions}
                                customOnChange={(e) => {
                                  specializationOptions.map((elem) => {
                                    if (elem?.value == e.target.value)
                                      setValue(
                                        `profile.jobHistory.[${index}].specialisation`,
                                        elem?.key
                                      );
                                  });
                                }}
                              />
                            </Grid> */}
                            <Grid item xs={9}>
                              <MDInput
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                fullWidth
                                sx={{ width: "100%", marginTop: "0.4rem" }}
                                inputProps={{
                                  style: { fontSize: 15, fontWeight: "bold" },
                                }}
                                {...register(
                                  `profile.jobHistory.[${index}].specialisation`
                                )}
                              />
                            </Grid>
                          </Grid>

                          {/* start date */}
                          <Grid container item xs={12}>
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "80%", marginTop: "0.8rem" }}
                              >
                                Start
                              </Typography>
                            </Grid>

                            <Grid
                              xs={7}
                              sx={{
                                marginTop: "2rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid item xs={7}>
                                <Controller
                                  name={`profile.jobHistory.[${index}].start`}
                                  control={control}
                                  render={({ field }) => (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        // minDate={new Date()}
                                        {...field}
                                        value={
                                          getValues(
                                            `profile.jobHistory.[${index}].start`
                                          )
                                            ? getValues(
                                                `profile.jobHistory.[${index}].start`
                                              )
                                            : null
                                        }
                                        inputProps={{
                                          style: {
                                            fontSize: 15,

                                            fontWeight: "bold",
                                          },
                                        }}
                                        {...register(
                                          `profile.jobHistory.[${index}].start`
                                        )}
                                        onChange={(newValue) => {
                                          setValue(
                                            `profile.jobHistory.[${index}].start`,

                                            newValue.$d
                                          );
                                          setValue(
                                            `profile.jobHistory.[${index}].end`,
                                            null
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            onKeyDown={onKeyDown}
                                            autoComplete="off"
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </Grid>
                              {/* <Grid item xs={3}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    {...register(
                                      `profileJobHistory.[${index}].starttime`
                                    )}
                                    onChange={(newValue) => {
                                      setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={3}>
                                <Button variant="contained">Now</Button>
                              </Grid> */}
                            </Grid>
                          </Grid>

                          {/* end date */}
                          <Grid container item xs={12}>
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "80%", marginTop: "0.8rem" }}
                              >
                                End
                              </Typography>
                            </Grid>

                            <Grid
                              xs={7}
                              sx={{
                                marginTop: "2rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid item xs={5}>
                                <Controller
                                  name={`profile.jobHistory.[${index}].end`}
                                  control={control}
                                  render={({ field }) => (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        minDate={getValues(
                                          `profile.jobHistory.[${index}].start`
                                        )}
                                        {...field}
                                        value={
                                          getValues(
                                            `profile.jobHistory.[${index}].end`
                                          )
                                            ? getValues(
                                                `profile.jobHistory.[${index}].end`
                                              )
                                            : null
                                        }
                                        inputProps={{
                                          style: {
                                            fontSize: 15,

                                            fontWeight: "bold",
                                          },
                                        }}
                                        {...register(
                                          `profile.jobHistory.[${index}].end`
                                        )}
                                        onChange={(newValue) => {
                                          setValue(
                                            `profile.jobHistory.[${index}].end`,

                                            newValue.$d
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            onKeyDown={onKeyDown}
                                            autoComplete="off"
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* employee letter doc type */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Employee Letter Doc Type
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDownNew
                                getValues={getValues}
                                control={control}
                                name={`profile.jobHistory.[${index}].employmentLetter.docType.name`}
                                options={documentTypeOptions}
                                defaultValue="none"
                                customOnChange={(e) => {
                                  handleDocTypeChange(
                                    e,
                                    `profile.jobHistory.[${index}].employmentLetter.docType`
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12}>
                            <Grid item xs={9}>
                              <Typography variant="h6">
                                Employment Letter Files
                              </Typography>
                              <hr
                                style={{
                                  marginBottom: "2rem",
                                  marginTop: "1rem",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={9}>
                            <NestedArray1
                              setValue={setValue}
                              getValues={getValues}
                              nestIndex={index}
                              {...{ control, register }}
                            />
                          </Grid>

                          {/* employee letter uploaded at */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Employee Letter Uploaded At
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <MDInput
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                fullWidth
                                {...register(
                                  `profile.jobHistory.[${index}].employmentLetter.uploadedAt`
                                )}
                              />
                            </Grid>
                          </Grid>

                          {/* salary slip doc type */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Salary Slip Doc Type
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDownNew
                                getValues={getValues}
                                control={control}
                                name={`profile.jobHistory.[${index}].salarySlip.docType.name`}
                                defaultValue="none"
                                options={docTypeOptions}
                                customOnChange={(e) => {
                                  docTypeOptions.map((elem) => {
                                    if (elem?.value == e.target.value)
                                      setValue(
                                        `profile.jobHistory.[${index}].salarySlip.docType`,
                                        `${elem.key}`
                                      );
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={9}>
                            <Typography variant="h6">
                              Salary Slip Files
                            </Typography>
                            <hr
                              style={{
                                marginBottom: "2rem",
                                marginTop: "1rem",
                              }}
                            />
                          </Grid>

                          <Grid item xs={9}>
                            <NestedArray2
                              setValue={setValue}
                              getValues={getValues}
                              nestIndex={index}
                              {...{ control, register }}
                            />
                          </Grid>

                          {/* Salary Slip Uploaded At */}
                          <Grid container item xs={12}>
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">
                                Salary Slip Uploaded At
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <MDInput
                                onKeyDown={(e) => {
                                  e.key === "Enter" && e.preventDefault();
                                }}
                                fullWidth
                                {...register(
                                  `profile.jobHistory.[${index}].salarySlip.uploadedAt`
                                )}
                              />
                            </Grid>
                          </Grid>

                          <MDBox sx={{ textAlign: "right" }}>
                            <MDButton
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                profileJobHistoryRemove(index);
                              }}
                            >
                              <Typography color="error" variant="body">
                                Remove
                              </Typography>
                            </MDButton>
                          </MDBox>
                          {/* </MDBox>  */}
                        </li>
                      ))}
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={(e) => {
                          profileJobHistoryAppend({});
                        }}
                      >
                        + Add
                      </MDButton>
                    </ul>
                  </Paper>
                </Grid>

                {/* profile education */}
                <Grid item xs={12}>
                  <Paper sx={{ padding: "1rem" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                      Profile Education
                    </Typography>
                    <hr style={{ marginBottom: "2rem" }} />
                    <ul style={{ listStyleType: "none" }}>
                      {profileEducation.map((field, index) => {
                        return (
                          <li>
                            <Grid item xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Controller
                                      name={`profile.education.[${index}].isForeign`}
                                      control={control}
                                      render={({ field: props }) => (
                                        <Checkbox
                                          {...props}
                                          checked={props.value}
                                          {...register(
                                            `profile.education.[${index}].isForeign`
                                          )}
                                          onChange={(e) =>
                                            props.onChange(e.target.checked)
                                          }
                                        />
                                      )}
                                    />
                                  }
                                  label="Is Foreign?"
                                />
                              </FormGroup>
                            </Grid>
                            {/* Course*/}
                            <Grid container item xs={12}>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Course
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <MDInput
                                  onKeyDown={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  fullWidth
                                  sx={{ width: "100%", marginTop: "0.4rem" }}
                                  inputProps={{
                                    style: { fontSize: 15, fontWeight: "bold" },
                                  }}
                                  {...register(
                                    `profile.education.[${index}].course`
                                  )}
                                />
                              </Grid>
                            </Grid>
                            {/* Specialisation*/}
                            <Grid container item xs={12}>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Specialization
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <SelectDropDownNew
                                  getValues={getValues}
                                  control={control}
                                  name={`profile.education.[${index}].specialisation.name`}
                                  defaultValue="none"
                                  options={specializationOptions}
                                  customOnChange={(e) => {
                                    handleSpecializationDynamic(
                                      e,
                                      `profile.education.[${index}].specialisation`
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {/* Specialisation */}
                            <Grid container item xs={12}>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Name
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <MDInput
                                  onKeyDown={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  fullWidth
                                  {...register(
                                    `profile.education.[${index}].name`
                                  )}
                                />
                              </Grid>
                            </Grid>

                            {/* Year */}
                            <Grid container item xs={12}>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Year
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <MDInput
                                  onKeyDown={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  fullWidth
                                  {...register(
                                    `profile.education.[${index}].year`
                                  )}
                                />
                              </Grid>
                            </Grid>

                            {/* Certificate doc type */}
                            <Grid container item xs={12}>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Certificate Doc Type
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <SelectDropDownNew
                                  getValues={getValues}
                                  control={control}
                                  name={`profile.education.[${index}].certificate.docType.name`}
                                  defaultValue="none"
                                  options={documentTypeOptions}
                                  customOnChange={(e) => {
                                    handleDocTypeChange(
                                      e,
                                      `profile.education.[${index}].certificate.docType`
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid item xs={9}>
                              <Typography variant="h6">
                                Certificate Files
                              </Typography>
                              <hr
                                style={{
                                  marginBottom: "2rem",
                                  marginTop: "1rem",
                                }}
                              />
                            </Grid>

                            <Grid item xs={9}>
                              <NestedArray3
                                setValue={setValue}
                                getValues={getValues}
                                nestIndex={index}
                                {...{ control, register }}
                              />
                            </Grid>

                            {/* Certificate Uploaded At */}
                            <Grid container item xs={12}>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                  Certificate Uploaded At
                                </Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <MDInput
                                  onKeyDown={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  fullWidth
                                  {...register(
                                    `profile.education.[${index}].certificate.uploadedAt`
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <MDBox sx={{ textAlign: "right" }}>
                              <MDButton
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  profileEducationRemove(index);
                                }}
                              >
                                <Typography color="error" variant="body">
                                  Remove
                                </Typography>
                              </MDButton>
                            </MDBox>
                            {/* </MDBox>  */}
                          </li>
                        );
                      })}
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={(e) => {
                          profileEducationAppend({});
                        }}
                      >
                        + Add
                      </MDButton>
                    </ul>
                  </Paper>
                </Grid>

                {/* profile refrence */}
                <Grid item xs={12}>
                  <Paper sx={{ padding: "1rem" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                      Profile References
                    </Typography>
                    <hr style={{ marginBottom: "2rem" }} />
                    <ul style={{ listStyleType: "none" }}>
                      {profileRefrence.map((field, index) => (
                        <li>
                          {/* name*/}
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">Name</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(
                                `profile.references.[${index}].name`
                              )}
                            />
                          </Grid>

                          {/* hospital*/}
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">
                              Hospital
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(
                                `profile.references.[${index}].hospital`
                              )}
                            />
                          </Grid>

                          {/* designation*/}
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">
                              Designation
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(
                                `profile.references.[${index}].designation`
                              )}
                            />
                          </Grid>

                          {/* phone*/}
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">Phone</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(
                                `profile.references.[${index}].phone`
                              )}
                            />
                          </Grid>

                          {/*email*/}
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">Email</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              fullWidth
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(
                                `profile.references.[${index}].email`
                              )}
                            />
                          </Grid>

                          <MDBox sx={{ textAlign: "right" }}>
                            <MDButton
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                profileRefrenceRemove(index);
                              }}
                            >
                              <Typography color="error" variant="body">
                                Remove
                              </Typography>
                            </MDButton>
                          </MDBox>
                          {/* </MDBox>  */}
                        </li>
                      ))}
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={(e) => {
                          profileRefrenceAppend({});
                        }}
                      >
                        + Add
                      </MDButton>
                    </ul>
                  </Paper>
                </Grid>

                {/* role */}

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Role</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={roleOptions}
                    control={control}
                    name={`role.name`}
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue(`role.name`, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Can Access Keystone?"
                      sx={{ marginTop: "1rem" }}
                    />
                  </FormGroup>
                </Grid>

                {/* profile photo upload */}

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    {imageUrl ? "Update Profile Photo" : "Upload Profile Photo"}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setImageUrl(URL.createObjectURL(e.target.files[0]));
                            setFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      {imageUrl && (
                        <div style={{ maxHeight: "40%", maxWidth: "50%" }}>
                          <CancelIcon
                            sx={{
                              position: "relative",
                              top: "1.8rem",
                              left: "90%",
                              color: "black",
                              cursor: "pointer",
                              marginBottom: "4px",
                              marginTop: "4px",
                            }}
                            onClick={() => {
                              setImageUrl(null);
                            }}
                          />{" "}
                          <img
                            src={imageUrl}
                            style={{
                              objectFit: "contain",
                              height: "70px",
                              width: "100px",
                              // marginLeft: "5px",
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {/* <div> */}
                  {/* <input type="file" accept="image/*"  onChange={setFile}/>
                    <button>Upload</button> */}

                  {/* </div> */}
                </Grid>

                {/* Avatr Url */}
                {/* <Grid item xs={3}>
                  <Typography variant="subtitle1">Profile Photo Url</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("avatar.url")}
                  />
                </Grid> */}
                {/* secured */}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          control={control}
                          {...register(`avatar.isSecured`)}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={props.onChange}
                            />
                          )}
                        />
                      }
                      label="Profile Photo Is Secured"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Status</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={statusOptions}
                    control={control}
                    name="status"
                    defaultValue={userObj?.status}
                    customOnChange={(e) => {
                      setValue("status", e.target.value);
                    }}
                  />
                </Grid>

                {/* Documents */}
                <Grid item xs={12}>
                  <Paper sx={{ padding: "1rem" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                      Documents
                    </Typography>
                    <hr style={{ marginBottom: "2rem" }} />
                    <ul style={{ listStyleType: "none" }}>
                      {documents.map((field, index) => (
                        <li>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">
                              Doc Type
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid container>
                              <Grid item xs={9}>
                                <MDInput
                                  onKeyDown={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  fullWidth
                                  sx={{ width: "100%", marginTop: "0.4rem" }}
                                  inputProps={{
                                    style: { fontSize: 15, fontWeight: "bold" },
                                  }}
                                  {...register(
                                    `documents.[${index}].docType.name`
                                  )}
                                />
                              </Grid>
                              <Grid
                                xs={3}
                                sx={{
                                  textAlign: "center",
                                  paddingTop: "0.7rem",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  aria-label="download"
                                  {...register(
                                    `documents.[${index}].files.url`
                                  )}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const values = getValues();
                                    const URL =
                                      values?.documents[index]?.files[0].uri;

                                    const professionalName = values?.name?.full;
                                    const userID = values?._id;
                                    const result = URL.split(".");
                                    const extension = result[result.length - 1];

                                    const DOCTYPE =
                                      values?.documents[index]?.docType.name;
                                    handleDownload(
                                      `${professionalName}/${DOCTYPE}/${userID}`,
                                      URL,
                                      extension
                                    );
                                  }}
                                >
                                  <FileDownloadIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* </MDBox>  */}
                        </li>
                      ))}
                    </ul>
                  </Paper>
                </Grid>

                {/* address address */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address Address</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.address")}
                  />
                </Grid>
                {/* address city */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address City</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.city")}
                  />
                </Grid>
                {/* address state */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address State</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.state")}
                  />
                </Grid>
                {/* address pin */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address Pin</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.pin")}
                  />
                </Grid>
                {/* address country */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address Country</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.country")}
                  />
                </Grid>

                {/* test paper attempts */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Test Paper Attempts
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDown
                    disabled
                    options={selectOptions}
                    control={control}
                    name={`testPaperAttempts`}
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue("testPaperAttempts", e.target.value);
                    }}
                  />
                </Grid>

                {/* Qualification */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Qualification</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={qualificationOptions}
                    control={control}
                    name={`qualification.name`}
                    defaultValue="none"
                    customOnChange={handleQualification}
                  />
                </Grid>

                {/* Num Experience */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Num Experience</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={experienceOptions}
                    control={control}
                    name={`numExperience.name`}
                    defaultValue="none"
                    customOnChange={handleExperience}
                  />
                </Grid>

                {/* Professionsla Registration Number */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Professional Registration Number
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("professionalRegistration.number")}
                  />
                </Grid>
                {/* Professionsla Registration registration date Year */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Professional Registration Registration Date
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.registrationDate.year"
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.registrationDate.month"
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.registrationDate.date"
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.registrationDate.ts"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Professionsla Registration expiry date Year */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Professional Registration Expiry Date
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={4}>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.expiryDate.year"
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.expiryDate.month"
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register(
                          "professionalRegistration.expiryDate.date"
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <MDInput
                        disabled
                        fullWidth
                        {...register("professionalRegistration.expiryDate.ts")}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* /Professional Registartion Registartion Type */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Professional Registration Registration Type
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={professionalRegistrationTypeOptions}
                    control={control}
                    name={`professionalRegistration.registrationType.name`}
                    defaultValue="none"
                    customOnChange={handleProfessionalReg}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Registration State
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    disabled={true}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("professionalRegistration.registrationState")}
                  />
                </Grid>
                {/* Professional Type */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Professional Type</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={professionOptions}
                    control={control}
                    name={`professionalType.name`}
                    defaultValue="none"
                    customOnChange={handleProfessionalType}
                  />
                </Grid>
                {/* Specialization */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Specialization</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={specializationOptions}
                    control={control}
                    name={`specialization.name`}
                    customOnChange={handleSpecialization}
                  />
                </Grid>
                {/* Departments  */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Departments</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MultiSelectDropdown
                    options={departmentTypeOption}
                    getValues={getValues}
                    name="departments"
                    setValue={setValue}
                    control={control}
                    customOnChange={handleDepartmentMultiSelect}
                  />
                  {/* <SelectDropDownNew
                    setValue={setValue}
                    getValues={getValues}
                    options={departmentTypeOption}
                    control={control}
                    name={"departments[0].name"}
                    defaultValue="none"
                    customOnChange={handleDepartments}
                  /> */}
                </Grid>

                {/* work city */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Work City</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    getValues={getValues}
                    options={cityOptions}
                    control={control}
                    name={`workCity.name`}
                    defaultValue="none"
                    customOnChange={handleWorkCity}
                  />
                </Grid>

                {/* Language Known */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Language Known</Typography>
                </Grid>
                <Grid item xs={9}>
                  {/* <SelectDropDownNew
                    getValues={getValues}
                    options={languageOptions}
                    control={control}
                    name={`languageKnown[0].name`}
                    defaultValue="none"
                    customOnChange={handleLanguage}
                  /> */}
                  {/* <MultiSelectDropdown
                    options={languageOptions}
                    getValues={getValues}
                    name={`languageKnown`}
                    setValue={setValue}
                    control={control}
                    customOnChange={handleLanguageKnown}
                  /> */}
                  <MDInput
                    multiline
                    rows={2}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register(
                      // `languageKnown`
                      `languageKnownNew`
                    )}
                  />
                </Grid>
                {/* Wokeen on */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Worked On</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MultiSelectDropdown
                    options={workedonOptions}
                    getValues={getValues}
                    name="workedOn"
                    setValue={setValue}
                    control={control}
                    customOnChange={handleWorked}
                  />
                </Grid>
                {/* Additional Training */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Additional Training
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {/* <MultiSelectDropdown
                    options={additionalTrainingOptions}
                    getValues={getValues}
                    name="additionalTraining"
                    setValue={setValue}
                    control={control}
                    customOnChange={handleAdditionalTraining}
                  /> */}

                  <MDInput
                    multiline
                    rows={3}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register(
                      // "additionalTraining"
                      `additionalTrainingNew`
                    )}
                  />
                </Grid>

                {/* Additional Skills */}
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Additional Skill</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    multiline
                    rows={3}
                    // multiline
                    // rows={calculateRows(additionalSkillContent)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      // rows: 6,
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    value={responseData?.additionalSkill
                      .map((skill) => skill.name)
                      .join(", ")}
                    // value={additionalSkillContent}
                    // {...register(
                    //   // "additionalTraining"
                    //   `additionalTrainingNew[0]`
                    // )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Bank Detail Benificiary Name
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("bankDetails.beneficiaryName")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Bank Detail Account Number
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("bankDetails.accountNumber")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Bank Detail IFSC</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("bankDetails.ifsc")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Bank Detail Bank Name
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("bankDetails.bankName")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Bank Detail Branch Address
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    fullWidth
                    {...register("bankDetails.branchAddress")}
                  />
                </Grid>

                {/* isPremium */}
                <Grid item xs={3} sx={{ marginTop: "0.4rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "50%", marginTop: "0.4rem"}}
                  >
                    IsPremium
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    placeholder="IsPremium"
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    // {...register("postedBy")}
                    value={responseData?.isPremium ? "True" : "False"}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created at</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    disabled
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("created_at")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated at</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    disabled
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updated_at")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created By</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    disabled
                    {...register("createdBy.name.full")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated By</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    disabled
                    {...register("updatedBy.name.full")}
                  />
                </Grid>
                {/* testing  */}
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </form>
          )}
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default UserDetailByName;
