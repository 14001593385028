import { createSlice } from "@reduxjs/toolkit";
import initialState from "./state";

import {
  createEvent,
  eventList,
  setAriType,
  setCityList,
  setCompanyType,
  setDepartmentType,
  setExperience,
  setLanguageType,
  setPhoto,
  setProfessionalRegistrationType,
  setProfessionIDPayload,
  setProfessionType,
  setQualificationType,
  setRoleType,
  setSpecialisationsType,
  eventDeletion,
  updateEvent,
  setCityIDPayload,
  setCompanyIDPayload,
  setDocumentType,
  setQualificationIDPayload,
  setSpecializationIDPayload,
  setExperienceIDPayload,
  interviewList,
  getInterviewListId,
  referralList,
  getReferralListId
} from "./actionCreator";
import { toast } from "react-toastify";

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    pageLimit(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    // City List
    builder.addCase(setCityList.pending, (state) => {});
    builder.addCase(setCityList.fulfilled, (state, action) => {
      state.City_List_Data.cityListInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setCityList.rejected, (state, action) => {});

    // Company Type
    builder.addCase(setCompanyType.pending, (state) => {});
    builder.addCase(setCompanyType.fulfilled, (state, action) => {
      state.Company_Type_Data.companyTypeInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setCompanyType.rejected, (state, action) => {});

    // Experience
    builder.addCase(setExperience.pending, (state) => {});
    builder.addCase(setExperience.fulfilled, (state, action) => {
      state.Experience_Data.experienceInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setExperience.rejected, (state, action) => {});

    //profession
    builder.addCase(setProfessionType.pending, (state) => {});
    builder.addCase(setProfessionType.fulfilled, (state, action) => {
      state.Profession_Type_Data.professionInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setProfessionType.rejected, (state, action) => {});

    //qualification
    builder.addCase(setQualificationType.pending, (state) => {});
    builder.addCase(setQualificationType.fulfilled, (state, action) => {
      state.Qualification_Type_Data.qualificationInfo =
        action?.payload?.data?.docs;
    });
    builder.addCase(setQualificationType.rejected, (state, action) => {});

    //specialization
    builder.addCase(setSpecialisationsType.pending, (state) => {});
    builder.addCase(setSpecialisationsType.fulfilled, (state, action) => {
      state.Specialization_Type_Data.specializationInfo =
        action?.payload?.data?.docs;
    });
    builder.addCase(setSpecialisationsType.rejected, (state, action) => {});

    //departments
    builder.addCase(setDepartmentType.pending, (state) => {});
    builder.addCase(setDepartmentType.fulfilled, (state, action) => {
      state.Department_Type_Data.departmentInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setDepartmentType.rejected, (state, action) => {});

    //roles
    builder.addCase(setRoleType.pending, (state) => {});
    builder.addCase(setRoleType.fulfilled, (state, action) => {
      state.Role_Type_Data.roleInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setRoleType.rejected, (state, action) => {});

    //DocumentType
    builder.addCase(setDocumentType.pending, (state) => {});
    builder.addCase(setDocumentType.fulfilled, (state, action) => {
      state.Document_Type_Data.documentTypeInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setDocumentType.rejected, (state, action) => {});

    //ari
    builder.addCase(setAriType.pending, (state) => {});
    builder.addCase(setAriType.fulfilled, (state, action) => {
      state.Ari_Data.ariInfo = action?.payload?.data?.docs;
    });

    builder.addCase(setAriType.rejected, (state, action) => {});

    //languages
    builder.addCase(setLanguageType.pending, (state) => {});
    builder.addCase(setLanguageType.fulfilled, (state, action) => {
      state.Language_Type_Data.languageInfo = action?.payload?.data?.docs;
    });
    builder.addCase(setLanguageType.rejected, (state, action) => {});

    //professionalRegistrationTypes
    builder.addCase(setProfessionalRegistrationType.pending, (state) => {});
    builder.addCase(
      setProfessionalRegistrationType.fulfilled,
      (state, action) => {
        state.Professional_Registration_Type_Data.professionInfo =
          action?.payload?.data?.docs;
      }
    );
    builder.addCase(
      setProfessionalRegistrationType.rejected,
      (state, action) => {}
    );

    //photo upload
    builder.addCase(setPhoto.pending, (state, action) => {
      const loadingToast = toast.loading("Image Uploading", {
        position: "top-right",
      });
      //Dismissing toast after 3 seconds
      setTimeout(() => {
        toast.dismiss(loadingToast);
      }, 3000);
    });
    builder.addCase(setPhoto.rejected, (state, action) => {
      toast.error("Error Upoading Image", {
        position: "top-right",
      });
    });
    builder.addCase(setPhoto.fulfilled, (state, action) => {
      state.Event_Details_Data.imageDetails = action?.payload?.data?.data;
      if (action?.payload?.status == 200) {
        toast.success("Image Uploaded", {
          position: "top-right",
        });
      }
    });

    // Saving Event Details
    builder.addCase(createEvent.pending, (state) => {});
    builder.addCase(createEvent.fulfilled, (state, payload) => {
      state.Event_Details_Data.eventDetailsLoading = false;

      state.Event_Details_Data.eventDetailsInfo = "";
      state.Event_Details_Data.imageDetails = [];

      toast.success("Event Created", {
        position: "top-right",
      });
    });
    builder.addCase(createEvent.rejected, (state, action) => {
      toast.error(action?.payload, {
        position: "top-right",
      });
    });

    // get event
    builder.addCase(eventList.pending, (state) => {
      state.Event_List_Data.eventListLoading = true;
    });
    builder.addCase(eventList.fulfilled, (state, action) => {
      state.Event_List_Data.eventListLoading = false;
      state.Event_List_Data.eventListInfo = action?.payload?.data?.docs;
    });
    builder.addCase(eventList.rejected, (state, action) => {
      state.Event_List_Data.eventListLoading = false;
    });

    //
    builder.addCase(updateEvent.pending, (state) => {});
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      toast.success("Event Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateEvent.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    // detele event
    builder.addCase(eventDeletion.pending, (state) => {});
    builder.addCase(eventDeletion.fulfilled, (state, action) => {
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(eventDeletion.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //Setting Payload
    builder.addCase(setProfessionIDPayload.pending, (state) => {});
    builder.addCase(setProfessionIDPayload.fulfilled, (state, action) => {
      state.Profession_Type_Data.professionPayloadIDOptions = action?.payload;
    });

    builder.addCase(setProfessionIDPayload.rejected, (state, action) => {});

    //Setting Payload
    builder.addCase(setQualificationIDPayload.pending, (state) => {});
    builder.addCase(setQualificationIDPayload.fulfilled, (state, action) => {
      state.Qualification_Type_Data.qualificationPayloadIDOptions =
        action?.payload;
    });

    builder.addCase(setQualificationIDPayload.rejected, (state, action) => {});

    //Setting Payload
    builder.addCase(setSpecializationIDPayload.pending, (state) => {});
    builder.addCase(setSpecializationIDPayload.fulfilled, (state, action) => {
      state.Specialization_Type_Data.specializationPayloadIDOptions =
        action?.payload;
    });

    builder.addCase(setSpecializationIDPayload.rejected, (state, action) => {});

    //Setting Payload
    builder.addCase(setExperienceIDPayload.pending, (state) => {});
    builder.addCase(setExperienceIDPayload.fulfilled, (state, action) => {
      
      state.Experience_Data.experiencePayloadIDOptions = action?.payload;
    });

    builder.addCase(setExperienceIDPayload.rejected, (state, action) => {});

    //Setting Payload City
    builder.addCase(setCityIDPayload.pending, (state) => {});
    builder.addCase(setCityIDPayload.fulfilled, (state, action) => {
      state.City_List_Data.cityPayloadIDOptions = action?.payload;
    });

    builder.addCase(setCityIDPayload.rejected, (state, action) => {});

    //Setting Payload Company
    builder.addCase(setCompanyIDPayload.pending, (state) => {});
    builder.addCase(setCompanyIDPayload.fulfilled, (state, action) => {
      state.Company_Type_Data.companyTypePayloadIDOptions = action?.payload;
    });

    builder.addCase(setCompanyIDPayload.rejected, (state, action) => {});


    // get all interview slot details
     builder.addCase(interviewList.pending, (state) => {
      state.Interview_Details_Data.interviewDetailsLoading = true;
    });
    builder.addCase(interviewList.fulfilled, (state, action) => {
      state.Interview_Details_Data.interviewDetailsLoading = false;
      state.Interview_Details_Data.interviewDetailsInfo = action?.payload?.response?.data?.docs;
    });
    builder.addCase(interviewList.rejected, (state, action) => {
      state.Interview_Details_Data.interviewDetailsLoading = false;
      state.Interview_Details_Data.interviewDetailsError = "Interview Details Error";
    });
    

    // get all interview slot details by id
    // builder.addCase(interviewListById.pending, (state) => {
    //   state.Interview_Details_Data_ID.interviewDetailsLoadingID = true;
    // });
    // builder.addCase(interviewListById.fulfilled, (state, action) => {
    //   state.Interview_Details_Data_ID.interviewDetailsLoadingID = false;
    //   state.Interview_Details_Data_ID.interviewDetailsInfoID = action?.payload?.response?.data?.docs;
    // });
    // builder.addCase(interviewListById.rejected, (state, action) => {
    //   state.Interview_Details_Data_ID.interviewDetailsLoadingID = false;
    //   state.Interview_Details_Data_ID.interviewDetailsErrorID = "Interview Details Error";
    // });


    //GET Job Jobs
    builder.addCase(getInterviewListId.pending, (state) => {});
    builder.addCase(getInterviewListId.fulfilled, (state, action) => {
      state.interviewList_Data.interviewList_Data_loading = false;
      state.interviewList_Data.interviewList_Data_info = action?.payload?.response?.data?.docs;
     
    });
    builder.addCase(getInterviewListId.rejected, (state, action) => {
     
    });

    //referral list
    builder.addCase(referralList.pending, (state) => {
      state.Referral_Details_Data.referralDetailsLoading = true;
    });
    builder.addCase(referralList.fulfilled, (state, action) => {
      state.Referral_Details_Data.referralDetailsLoading = false;
      state.Referral_Details_Data.referralDetailsInfo = action?.payload?.response?.data?.docs;
      state.Referral_Details_Data.total = action?.payload?.response?.data?.total;
    });
    builder.addCase(referralList.rejected, (state, action) => {
      state.Referral_Details_Data.referralDetailsLoading = false;
      state.Referral_Details_Data.referralDetailsError = "Referral Details Error";
    });


    //referral list by id
  builder.addCase(getReferralListId.pending, (state) => {})
  builder.addCase(getReferralListId.fulfilled, (state, action) => {
    state.referralList_Data.referralList_Data_loading = false;
    state.referralList_Data.referralList_Data_info = action?.payload?.response?.data?.docs;  
  })
  builder.addCase(getReferralListId.rejected, (state, action) => {  
  })


  
  },
});

export const {
  pageChanged,
  pageLimit,
} = eventsSlice.actions;

export default eventsSlice.reducer;
