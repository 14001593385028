import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
  Link,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import SelectDropDown from "examples/DropDown";
import DynamicCoords from "examples/DynamicCoords";
import DynamicDocuments from "examples/DynamicDocuments";
import DynamicPermissions from "examples/DynamicPermissions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import { setCompanyType } from "layouts/events/Redux/actionCreator";
import { setRoleType } from "layouts/events/Redux/actionCreator";
import { setAriType } from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCompanyByIdService } from "services/hospitalServices";
import {
  setDocumentType,
  updateOrganization,
  updatehospitalCompany,
} from "../Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";

const OrganizationDetailsForm = () => {
  const ID = window.location.href.split("organization")[1].split("/")[1];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { Ari_Data, Role_Type_Data, Company_Type_Data } = useSelector(
    (state) => state.events
  );
  const ariData = Ari_Data?.ariInfo;
  const roleData = Role_Type_Data.roleInfo;
  const companyTypeData = Company_Type_Data?.companyTypeInfo;

  const { documentTypeDropDown } = useSelector((state) => state.hospital);
  const docTypeData = documentTypeDropDown?.documentTypeDropDownInfo;

  //USer Options
  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;

  const roleOptions = [];
  const docTypeOptions = [];
  const ariOptions = [];
  const companyTypeOptions = [];
  const userOptions = [];

  roleData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    roleOptions.push(obj);
  });

  docTypeData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    docTypeOptions.push(obj);
  });

  ariData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.ari,
    };

    ariOptions.push(obj);
  });

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  companyTypeData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyTypeOptions.push(obj);
  });

  const logoOptions = [
    {
      key: "AWS_S3",
      value: "AWS_S3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];

  const getData = async () => {
  
    let response = await getCompanyByIdService(ID);
   

    
    return response[0];
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "permissionOverrides", // unique name for your Field Array
  });

  const selectOptionsLevel = [
    {
      key: "WRITER",
      value: "WRITER",
    },
    {
      key: "READER",
      value: "READER",
    },
    {
      key: "NONE",
      value: "NONE",
    },
  ];

  const [companyTypePayloadID, setCompanyTypeID] = useState("");

  //Handlers
  const handleCompanyTypeChange = (e) => {
    setValue("companyType.name", e.target.value);
    let companyTypeID;
    companyTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        companyTypeID = obj.key;
      }
    });
    setCompanyTypeID(companyTypeID);
  };
  const selectOptions = [
    {
      key: "1",
      value: "1",
    },
    {
      key: "2",
      value: "2",
    },
  ];
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setAriType());
    dispatch(setRoleType());
    dispatch(setDocumentType());
    dispatch(setCompanyType());
    dispatch(setUsersDropdown());
  }, []);

  const onSubmit = (data) => {
   

    let payload = {
      logo: {
        location: data?.logo?.location,
        isSecured: data?.logo?.isSecured,
        bucket: data?.logo?.bucket,
        cdnUrl: data?.logo?.cdnUrl,
        fileName: data?.logo?.fileName,
        mimeType: data?.logo?.mimeType,
        region: data?.logo?.region,
        secureUrl: data?.logo?.secureUrl,
        uri: data?.logo?.uri,
        url: data?.logo?.url,
      },
      billingAddress: {
        address: {
          coords: data?.billingAddress?.address?.coords,
          address: data?.billingAddress?.address?.address,
          city: data?.billingAddress?.address?.city,
          country: data?.billingAddress?.address?.country,
          pin: data?.billingAddress?.address?.pin,
          state: data?.billingAddress?.address?.state,
        },
        isGstRegistered: data?.isGstRegistered,
        gstNumber: data?.gstNumber,
      },

      companyType: companyTypePayloadID
        ? companyTypePayloadID
        : data?.companyType?._id,
      name: data?.name,
      isEnabled: data?.isEnabled,
      documents: data?.documents,
      isDeleted: data?.isDeleted,
      cin: data?.cin,
      code: data?.code,
    };

    

    dispatch(updateOrganization({ payload: payload, id: ID })).then((res) => {
      
      if (!res?.error) {
        navigate("/hospital/organization");
      }
    });
  };

  const formValues = getValues();
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
              
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDInput
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 25, fontWeight: "bold" },
                    }}
                    {...register("name")}
                  />
                </Grid>
                <Grid item xs={12} sx={{ width: "100%", marginBottom: "20px" }}>
                  <Typography variant="subtitle1">ID: {ID}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="isDeleted"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("isDeleted")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="isDeleted"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Location</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={logoOptions}
                    control={control}
                    getValues={getValues}
                    placeholder="Select"
                    name="logo.location"
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue("logo.location", e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo File Name</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.fileName")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Uri</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.uri")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Bucket</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.bucket")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Region</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.region")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Mime Type</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.mimeType")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Secure Url</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.secureUrl")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Url</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.url")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="logo.isSecured"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("logo.isSecured")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Logo is Secured"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Logo Cdn Url</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("logo.cdnUrl")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">TId</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("TId")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">SId</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("SId")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Ssl Private Key</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("sslPrivateKey")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Ssl Certificate</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("sslCertificate")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Description</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("description")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Company Type</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={companyTypeOptions}
                    control={control}
                    getValues={getValues}
                    placeholder="Select"
                    name="companyType.name"
                    defaultValue="none"
                    customOnChange={handleCompanyTypeChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Website</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("website")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="isEnabled"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("isEnabled")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="is Enabled"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address Address</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.address")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address City</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.city")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address State</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.state")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address Pin</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("billingAddress.address.pin")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Address Country</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("billingAddress.address.country")}
                  />
                </Grid>

                {/* Coords */}
                <Grid item xs={12}>
                  <DynamicCoords
                    control={control}
                    name="billingAddress.address.coords"
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Grid>
                {/* 
              Documents */}
                <Grid item xs={12}>
                  <DynamicDocuments
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                    selectOptions={docTypeOptions}
                  />
                </Grid>
                {/* 
Permission Overrides */}
                <Grid item xs={12}>
                  <Paper sx={{ padding: "1rem" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                      Permission Overrides
                    </Typography>
                    <hr style={{ marginBottom: "2rem" }} />
                    <ul style={{ listStyleType: "none" }}>
                      {fields.map((field, index) => (
                        <li>
                          <Grid container spacing={2}>
                            {/* Role */}
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">Role</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDown
                                options={roleOptions}
                                name={`permissionOverrides.${index}.role`}
                                control={control}
                                defaultValue="none"
                                placeholder="Select"
                                customOnChange={(e) =>
                                  setValue(
                                    `permissionOverrides.${index}.role`,
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            {/* Permissions */}
                            <Grid item xs={12}>
                              <DynamicPermissions
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                selectOptionslevel={selectOptionsLevel}
                                selectOptionsAri={ariOptions}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <hr />
                            </Grid>
                          </Grid>

                          <MDBox sx={{ textAlign: "right" }}>
                            <MDButton
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                            >
                              <Typography color="error" variant="body">
                                Remove
                              </Typography>
                            </MDButton>
                          </MDBox>
                          {/* </MDBox>  */}
                        </li>
                      ))}
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={(e) => {
                        ;
                          append({});
                        }}
                      >
                        + Add
                      </MDButton>
                    </ul>
                  </Paper>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created At</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("createdAt")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated At</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    fullWidth
                    {...register("updatedAt")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created By</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={userOptions}
                    control={control}
                    getValues={getValues}
                    placeholder="Select"
                    name="createdBy.name.full"
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue("createdBy.name.full", e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated By</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={userOptions}
                    control={control}
                    placeholder="Select"
                    getValues={getValues}
                    name="updatedBy.name.full"
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue("updatedBy.name.full", e.target.value);
                    }}
                  />
                </Grid>
                <Grid xs={12} sx={{ width: "100%", margin: "10px" }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="contained" color="info" type="submit">
                    Save
                  </MDButton>
                  <MDButton variant="text" color="info">
                    Reset
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default OrganizationDetailsForm;
