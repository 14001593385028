import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";

const IsDeletedComponent = ({ handleDeleteApi }) => {
  const handleChange = (e) => {
    //Calling main Parent function
    handleDeleteApi(e.target.checked);
  };
  return (
    <Grid container>
      <Grid xs={2} margin={0}>
        <FormControlLabel
          control={<Checkbox onChange={handleChange} />}
          label="isDeleted"
        />
      </Grid>
    </Grid>
  );
};

export default IsDeletedComponent;
