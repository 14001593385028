import axiosInstance from "../axios";
import { toast } from "react-toastify";

export const getCitiesById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/city/listCity`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// City List
export const addNewCity = async (payload) => {
  try {
    const response = await axiosInstance.post(`/city`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update City
export const updateCity = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/city/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete City
export const deleteCity = async (id) => {
  try {
    const response = await axiosInstance.delete(`/city/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Language Services

// language list

export const getLanguages = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/language/list`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// language by id

export const getLanguagesById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/language/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add language
export const addNewLanguage = async (payload) => {
  try {
    const response = await axiosInstance.post(`/language`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update language
export const updateLanguages = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/language/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete language
export const deleteLanguages = async (id) => {
  try {
    const response = await axiosInstance.delete(`/language/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Add new Company
export const addNewCompany = async (payload) => {
  try {
    const response = await axiosInstance.post(`/companyTypes`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete Company
export const deleteCompany = async (id) => {
  try {
    const response = await axiosInstance.delete(`/companyTypes/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getExperiences = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `/experience/listNumExperience`,
      criteria
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Experience by id

export const getExperiencesById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(
      `/experience/listNumExperience`,
      criteria
    );

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add Experience
export const addNewExperiences = async (payload) => {
  try {
    const response = await axiosInstance.post(`/experience`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update Experience
export const updateExperiences = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/experience/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete Experience
export const deleteExperiences = async (id) => {
  try {
    const response = await axiosInstance.delete(`/experience/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Specialization Services

// Specialization list

export const getSpecializationsService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    // let criteria = { criteria: { isDeleted: payload } };
    let criteria = {
      criteria: {
        isDeleted: false,
        profession: payload.profession,
      },
    };
    const response = await axiosInstance.post(
      `/specialization/listSpecialisations`,
      criteria
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Specialization by id

export const getSpecializationsByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(
      `/specialization/listSpecialisations`,
      criteria
    );

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add Specialization
export const addNewSpecializationsService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/specialization`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update Specialization
export const updateSpecializationsService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/specialization/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete Specialization
export const deleteSpecializationsService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/specialization/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// DocumentType Services

// DocumentType list

export const getDocumentTypeService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/documenttype/list`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// DocumentType by id

export const getDocumentTypeByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/documenttype/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add DocumentType
export const addNewDocumentTypeService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/documenttype`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update DocumentType
export const updateDocumentTypeService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/documenttype/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete DocumentType
export const deleteDocumentTypeService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/documenttype/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// DepartmentType Services

// DepartmentType list

export const getDepartmentTypeService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: { isDeleted: false, profession: payload.profession },
    };
    const response = await axiosInstance.post(`/departmenttype/list`, criteria);
    // console.log("response", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// DepartmentType by id

export const getDepartmentTypeByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/departmenttype/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add DepartmentType
export const addNewDepartmentTypeService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/departmenttype`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update DepartmentType
export const updateDepartmentTypeService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/departmenttype/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

export const deleteDepartmentTypeService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/departmenttype/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//get additional training
export const additionalTraining = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `/additionaltraining/list`,
      criteria
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//add additional training
export const addAdditionalTraining = async (payload) => {
  try {
    const response = await axiosInstance.post(`/additionaltraining/`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//delete Additional Training
export const deleteAdditonal = async (id) => {
  try {
    const response = await axiosInstance.delete(`/additionaltraining/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//edit Additional training
export const getAdditionalTrainingById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(
      `/additionaltraining/list`,
      criteria
    );

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Update additional
export const updateAdditional = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/additionaltraining/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// skills
// add skills
export const addNewSkill = async (payload) => {
  try {
    const response = await axiosInstance.post(`/skillsRoute`, payload);
    // console.log("created", response);
    if (response.data.status === true) {
      toast.success("Created Successfully", {
        position: "top-right",
      });
    }
    return response;
  } catch (e) {
    // console.log("error",e)
    if (e.response.data.msg === "Same data is already exists.") {
      toast.error("Skill already exist for same deparment & profession", {
        position: "top-right",
      });
    }
    return { error: e.response.data };
  }
};

//get skills
export const getSkills = async (payload) => {
  try {
    let criteria = { criteria: { isDeleted: false, _id: payload } };
    const response = await axiosInstance.post(`/skillsRoute/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getSkillsById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/skillsRoute/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Update Skills
export const updateSkills = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/skillsRoute/${payload.id}`,
      payload.payload
    );
    // console.log("fetched", response);
    if (response.data.status === true) {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    }
    return response;
  } catch (e) {
    // console.log("error",e)
    if (e.response.data.msg === "Same data is already exists.") {
      toast.error("Skill already exist for same deparment & profession", {
        position: "top-right",
      });
    }
    if (e.response.data.msg === "ID is deleted") {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    }
    return { error: e.response.data };
  }
};

// Delete Skills
export const deleteSkill = async (id) => {
  try {
    const response = await axiosInstance.delete(`/skillsRoute/${id}`);
    // console.log("deleteResponse: ", response);
    if (response.data.acknowledged === true) {
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getSkillsList = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/skillsRoute/list`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//get Doc verification list
export const getDocVerification = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    //   let criteria = {
    //     criteria:
    //     {isDeleted: false, _id: payload },
    //   limit: payload.pageSize,
    //   offset: payload.currentPage,
    // };
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        startDate: payload.startDate,
        endDate: payload.endDate,
        requestState: payload.filterState,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(
      `/docVerification/list`,
      criteria
    );
    // console.log("res>>>", response);
    return response?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getDocsFilteredService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        requestState: payload.filterState,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(
      `/docVerification/filtered`,
      criteria
    );
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//update doc verification
export const updateDocVerification = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/docVerification/${payload.id}`,
      payload.payload
    );
    // console.log("fetched", response);
    if (response.data.status === true) {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    }
    return response;
  } catch (e) {
    console.log("error", e);
    if (e.response.data.msg === "ID is deleted") {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    }
    return { error: e.response.data };
  }
};

export const getDocVerificationByID = async (payload) => {
  try {
    let criteria = { criteria: { isDeleted: false, _id: payload } };
    const response = await axiosInstance.post(
      `/docVerification/list`,
      criteria
    );
    // console.log("res", response);

    return response?.data?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


//get all gcc department data
export const getDepartmentGCCService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      // criteria: { isDeleted: false, profession: payload.profession },
      criteria: { isDeleted: payload ? true : false, profession: payload.profession },
    };
    const response = await axiosInstance.post(`/gccDepartment/list`, criteria);
    // console.log("response", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// GCC Department by id

export const getGCCDepartmentByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/gccDepartment/list`, criteria);
   
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add DepartmentType
export const addNewGCCDepartmentService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/gccDepartment`, payload);
    console.log("response", response)
    if(response.status === 200) {
      toast.success("Created Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update DepartmentType
export const updateGCCDepartmentService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/gccDepartment/${payload.id}`,
      payload.payload
    );
    if(response.status === 200) {
      toast.success("Updated Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

export const deleteGCCDepartmentService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/gccDepartment/${id}`);
    if(response.status === 200) {
      toast.success("Deleted Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};



//get all gcc Experience data
export const getExperienceGCCService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      // criteria: { isDeleted: false},
      criteria: { isDeleted: payload ? true : false },
    };
   
    const response = await axiosInstance.post(`/gccExperience/list`, criteria);
    console.log("response", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// GCC Experience by id

export const getGCCExperienceByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/gccExperience/list`, criteria);
  //  console.log("GCC Experience", response)
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add gcc Experience
export const addNewGCCExperienceService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/gccExperience`, payload);
    // console.log("response", response)
    if(response.status === 200) {
      toast.success("Created Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update gcc Experience
export const updateGCCExperienceService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/gccExperience/${payload.id}`,
      payload.payload
    );
    if(response.status === 200) {
      toast.success("Updated Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};


//delete gcc Experience
export const deleteGCCExperienceService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/gccExperience/${id}`);
    if(response.status === 200) {
      toast.success("Deleted Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
