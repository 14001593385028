import { Delete } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { DeleteJobs } from "layouts/common/Redux/actionCreator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getDepartments } from "services/hospitalServices";
import { formatDate } from "util/Helper";
import CreateDepartmentDialog from "./components/CreateDepartmentDialog";
import {
  deleteDepartment,
  getDepartmentsList,
} from "../hospital/Redux/actionCreator";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
import UploadDialog from "./UploadDialog";
import moment from "moment";

const HospitalDepartments = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleDelete = (ID) => {
    dispatch(deleteDepartment(ID));
  };

  //Value Getters
  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getDepartmentName(params) {
    return `${params?.row?.departmentType?.name || "NA"}`;
  }

  function getOrganizationName(params) {
    return `${params?.row?.organization?.name || "NA"}`;
  }
  function getBranchName(params) {
    return `${params?.row?.branch?.name || "NA"}`;
  }
  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }

  // function getCreatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dayyy = new Date(rowName);
  //   var a, b, c;
  //   a = dayyy.getDate();
  //   b = dayyy.getMonth() + 1;
  //   c = dayyy.getFullYear();
  //   var dateNow;

  //   dateNow = a + "/" + b + "/" + c;
  //   return dateNow;
  // }

  // function getUpdatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dayyy = new Date(rowName);
  //   var a, b, c;
  //   a = dayyy.getDate();
  //   b = dayyy.getMonth() + 1;
  //   c = dayyy.getFullYear();
  //   var dateNow;

  //   dateNow = a + "/" + b + "/" + c;
  //   return dateNow;
  // }

  function getCreatedAT(params) {
    var a
    const rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;
    // return formattedDate;
  }

  function getUpdatedAT(params) {
    var a
    const rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;
    // return formattedDate;
  }

  function getBillingAddressAddress(params) {
    return `${params?.row?.billingAddress?.address?.address || "NA"}`;
  }

  function getBillingAddressCoords(params) {
    return `${params?.row?.billingAddress?.address?.coords || "NA"}`;
  }
  function getBillingAddressCity(params) {
    return `${params?.row?.billingAddress?.address?.city || "NA"}`;
  }
  function getBillingAddressState(params) {
    return `${params?.row?.billingAddress?.address?.state || "NA"}`;
  }
  function getBillingAddressPin(params) {
    return `${params?.row?.billingAddress?.address?.pin || "NA"}`;
  }
  function getBillingAddressCountry(params) {
    return `${params?.row?.billingAddress?.address?.country || "NA"}`;
  }

  //Department Columns
  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowID = cellValues?.row?._id;
        return rowID;
      },
      valueGetter: getID,
    },
    {
      field: "departmentType",
      headerName: `Department Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.departmentType?.name;
        const rowID = cellValues?.row?._id;
        return <Link to={`${rowID}`}>{rowName}</Link>;
      },
      valueGetter: getDepartmentName,
      flex: 1,
    },
    {
      field: "organization",
      headerName: `Company`,
      renderCell: (cellValues) => {
        const rowID = cellValues?.row?.organization;
        return (
          <Link to={`/hospital/company/${rowID?._id}`}>
            {rowID?.name ? rowID?.name : "N.A."}
          </Link>
        );
      },
      valueGetter: getOrganizationName,
      flex: 1,
    },
    {
      field: "branch",
      headerName: `Branch`,
      renderCell: (cellValues) => {
        const rowID = cellValues?.row?.branch;
        return (
          <Link to={`/hospital/branch/${rowID?._id}`}>
            {rowID?.name ? rowID?.name : "N.A."}
          </Link>
        );
      },

      valueGetter: getBranchName,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a
    const rowName = cellValues?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    //     var rowName = formatDate(cellValues?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;

    // return formattedDate;
      },
      type: "date",
      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    //     var rowName = formatDate(cellValues?.row?.updatedAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;

    // return formattedDate;
        
      },
      type: "date",
      valueGetter: getUpdatedAT,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,

      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
    },
    {
      field: "billingAddressAddress",
      headerName: `Billing Address Address`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.address;
      },
      valueGetter: getBillingAddressAddress,
    },
    {
      field: "billingAddressCity",
      headerName: `Billing Address City`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.city;
      },
      valueGetter: getBillingAddressCity,
    },
    {
      field: "billingAddressState",
      headerName: `Billing Address State`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.state;
      },
      valueGetter: getBillingAddressState,
    },
    {
      field: "billingAddressCountry",
      headerName: `Billing Address Country`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.country;
      },
      valueGetter: getBillingAddressCountry,
    },
    {
      field: "billingAddressPin",
      headerName: `Billing Address Pin`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.pin;
      },
      valueGetter: getBillingAddressPin,
    },
    {
      field: "billingAddressCoords",
      headerName: `Billing Address Coords`,

      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address?.coords;
      },
      valueGetter: getBillingAddressCoords,
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.departmentName}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
    },
  ];

  // const handleCellClick = (event) => {
  //   event.originalEvent.stopPropagation();
  // };
  // const handleRowClick = (event) => {
  //   event.originalEvent.stopPropagation();
  // };

  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      // API CALL for Delted Records
      const response = await getDepartments({
        criteria: {
          isDeletedd: checked,
        },
      });

      // const response = await getDepartments("deleted");

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getDepartments({
        criteria: {
          isDeleted: checked,
        },
      });
    }
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

  const { hospital_department_Data } = useSelector((state) => state.hospital);
  const departments = hospital_department_Data?.departmentInfo;

  useEffect(() => {
    dispatch(getDepartmentsList([]));
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      {" "}
      <DashboardNavbar />{" "}
      <FilterBar
        title="Create Department"
        title1="Department"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        dialogContent={
          <CreateDepartmentDialog handleDialogClose={handleDialogClose} />
        }
        //upload props
        handleUploadDialogClose={uploadDialogClose}
        handleUploadDialogOpen={uploadDialogOpen}
        isOpenUpload={isOpenUpload}
        showUpload={true}
        uploadDialogContent={
          <UploadDialog
            handleUploadDialogClose={uploadDialogClose}
            title="Department"
          />
        }
      />{" "}
      <MDBox sx={{ marginTop: "2rem" }}>
        {" "}
        <DataGrid
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                fileName: `Department-${formatDate()}`,
              },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                billingAddressCoords: false,
                billingAddressCountry: false,
                billingAddressState: false,
                billingAddressCity: false,
                billingAddressPin: false,
                billingAddressAddress: false,
                createdAt: false,
                updatedAt: false,
                createdBy: false,
                updatedBy: false,
                Delete: false,
                _id: false,
              },
            },
          }}
          loading={departments.length > 0 ? false : true}
          columns={columns}
          rows={showDeltedRecords ? deletedRecords : [...departments].reverse()}
          density="compact"
          //isRowSelectable
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />{" "}
      </MDBox>{" "}
    </DashboardLayout>
  );
};
export default HospitalDepartments;
