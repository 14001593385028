import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCitiesById } from "services/commonServices";
import { getLanguages } from "services/commonServices";
import { updateLanguages } from "services/commonServices";
import { getLanguagesById } from "services/commonServices";
import { addNewLanguage } from "services/commonServices";
import { deleteLanguages } from "services/commonServices";
import { updateCity } from "services/commonServices";
import { addNewCity, deleteCity } from "services/commonServices";
import { addNewProfession } from "shared/services";
import { updateProfessionService } from "shared/services";
import { addNewQualification } from "shared/services";
import { getProfessionByIdService } from "shared/services";
import { getProfessions } from "shared/services";
import { getQualifications } from "shared/services";
import { deleteProfessionService } from "shared/services";
import { deleteQualificationService } from "shared/services";
import { getCities } from "shared/services";


import {
  addSingleJobDetail,
  DeleteJobDetail,
  editJobDetail,
  fetchJobDetails,
  uploadJobDescriptionFile,
} from "./../../../services/jobDetailsService";
import { updateSoftwareService } from "shared/services";
import { addNewSoftware } from "shared/services";
import { deleteSoftwareService } from "shared/services";
import { fetchUsersDropdownService } from "shared/services";
import { getSoftwares } from "shared/services";
import { getSoftwareByIdService } from "shared/services";
import { deleteCompany } from "services/commonServices";
import { addNewCompany } from "services/commonServices";
import { getCompanies } from "shared/services";
import { getExperiences } from "services/commonServices";
import { getExperiencesById } from "services/commonServices";
import { addNewExperiences } from "services/commonServices";
import { updateExperiences } from "services/commonServices";
import { deleteExperiences } from "services/commonServices";
import { getProfessionRegistrationTypes } from "shared/services";
import { addNewProfessionRegistrationType } from "shared/services";
import { updateProfessionRegistrationTypeService } from "shared/services";
import { getProfessionRegistrationTypeByIDService } from "shared/services";
import { deleteProfessionRegistrationTypeService } from "shared/services";
import { getSpecializationsService } from "services/commonServices";
import { deleteSpecializationsService } from "services/commonServices";
import { addNewSpecializationsService } from "services/commonServices";
import { getSpecializationsByIdService } from "services/commonServices";
import { updateSpecializationsService } from "services/commonServices";
import { getDocumentTypeService } from "services/commonServices";
import { deleteDocumentTypeService } from "services/commonServices";
import { addNewDocumentTypeService } from "services/commonServices";
import { getDocumentTypeByIdService } from "services/commonServices";
import { updateDocumentTypeService } from "services/commonServices";
import { updateDepartmentTypeService } from "services/commonServices";
import { getDepartmentTypeByIdService } from "services/commonServices";
import { addNewDepartmentTypeService } from "services/commonServices";
import { deleteDepartmentTypeService } from "services/commonServices";
import { getDepartmentTypeService } from "services/commonServices";
import { deleteAdditonal } from "services/commonServices";
import { updateAdditional } from "services/commonServices";
import { getAdditionalTrainingById } from "services/commonServices";
import { addAdditionalTraining } from "services/commonServices";
import { additionalTraining } from "services/commonServices";
import { fetchOrganizationsDropdownService } from "shared/services";
import { updateQualificationService } from "shared/services";

import {getSkills} from "services/commonServices";
import {deleteSkill} from "services/commonServices";
import {addNewSkill} from "services/commonServices";
import {updateSkills} from "services/commonServices";
import {getSkillsById} from "services/commonServices";

import {getDocVerification} from "services/commonServices";
import {updateDocVerification} from "services/commonServices";
import {getDocsFilteredService} from "services/commonServices";
import {getDepartmentGCCService} from "services/commonServices";
import {getGCCDepartmentByIdService} from "services/commonServices";
import {addNewGCCDepartmentService} from "services/commonServices";
import {updateGCCDepartmentService} from "services/commonServices";
import {deleteGCCDepartmentService} from "services/commonServices";
import {getExperienceGCCService} from "services/commonServices";
import {getGCCExperienceByIdService} from "services/commonServices";
import {addNewGCCExperienceService} from "services/commonServices";
import {updateGCCExperienceService} from "services/commonServices";
import {deleteGCCExperienceService} from "services/commonServices";

export const fetchJobDetailsData = createAsyncThunk(
  "common/jobDetails",
  async (thunkAPI) => {
    const { response, error } = await fetchJobDetails();
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const uploadFile = createAsyncThunk(
  "jdRule/uploadJdRules",
  async (payload, thunkAPI) => {
    const { response, error } = await uploadJobDescriptionFile(payload);
    thunkAPI.dispatch(fetchJobDetailsData());
    if (error) {

      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const DeleteJobs = createAsyncThunk(
  "common/deletejobDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await DeleteJobDetail(payload);
    thunkAPI.dispatch(fetchJobDetailsData());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const EditJobs = createAsyncThunk(
  "common/editjobDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await editJobDetail(payload);
    thunkAPI.dispatch(fetchJobDetailsData());
    return response;
  }
);

export const addSingleJobDetails = createAsyncThunk(
  "jdRule/uploadSingleJD",
  async (payload, thunkAPI) => {
    const { response, error } = await addSingleJobDetail(payload);

    thunkAPI.dispatch(fetchJobDetailsData());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const getCityList = createAsyncThunk(
  "/city/listCity",
  async (payload, thunkAPI) => {
    const response = await getCities(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

export const getCitybyId = createAsyncThunk(
  "/city/listCity/id",
  async (payload, thunkAPI) => {
    const response = await getCitiesById(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete City
export const deleteCities = createAsyncThunk(
  `/city/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteCity(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(getCityList());
    }
    return response;
  }

  // if (error) {
  //   return thunkAPI.rejectWithValue(error);
  // }
);
export const addCity = createAsyncThunk("/city", async (payload, thunkAPI) => {
  const { response, error } = await addNewCity(payload);
  thunkAPI.dispatch(getCityList());
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return response;
});

export const updateCityy = createAsyncThunk(
  "/city/update",
  async (payload, thunkAPI) => {
   
    const { response, error } = await updateCity(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//*********************Qualifications********************//

// List Qualification
export const getQualificationsList = createAsyncThunk(
  "/qualifications/listQualifications",
  async (payload, thunkAPI) => {
    // console.log("payloadAct>>", payload);
    const response = await getQualifications(payload);

    // console.log("response>>", response);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete Qualification
export const deleteQualifications = createAsyncThunk(
  `/qualifications/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteQualificationService(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(getQualificationsList());
    }
    return response;
  }
);

//Add Qualification

export const addQualification = createAsyncThunk(
  "/qualificationTypes",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewQualification(payload);
    thunkAPI.dispatch(getQualificationsList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//Update Qualification
export const updateQualification = createAsyncThunk(
  "/qualifications/update",
  async (payload, thunkAPI) => {
    const { response, error } = await updateQualificationService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//******************Profession************************//

// List Professions
export const getProfessionsList = createAsyncThunk(
  "/professions/listProfessions",
  async (payload, thunkAPI) => {
    const response = await getProfessions(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete Professions
export const deleteProfession = createAsyncThunk(
  `/professions/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteProfessionService(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(getProfessionsList());
    }
    return response;
  }
);

//Add Profession
export const addProfession = createAsyncThunk(
  "/profession",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewProfession(payload);
    thunkAPI.dispatch(getProfessionsList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const getProfessionById = createAsyncThunk(
  "/professions/listProfessions/id",
  async (payload, thunkAPI) => {
    const response = await getProfessionByIdService(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

export const updateProfession = createAsyncThunk(
  "/profession/update",
  async (payload, thunkAPI) => {

    const { response, error } = await updateProfessionService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//******************Profession Registration Type************************//

//******************Languages************************//

// List Languages
export const getLanguagesList = createAsyncThunk(
  "/language/listLanguages",
  async (payload, thunkAPI) => {
    const response = await getLanguages(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete Languages
export const deleteLanguage = createAsyncThunk(
  `/language/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteLanguages(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(getLanguagesList());
    }
    return response;
  }
);

//Add Languages
export const addLanguages = createAsyncThunk(
  "/languages",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewLanguage(payload);
    thunkAPI.dispatch(getLanguagesList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const getLanguagesId = createAsyncThunk(
  "/language/listLanguages/id",
  async (payload, thunkAPI) => {
    const response = await getLanguagesById(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

export const updateLanguage = createAsyncThunk(
  "/language/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updateLanguages(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//******************Softwares************************////

// List Softwares

export const getSoftwaresList = createAsyncThunk(
  "/softwares/listSoftwares",
  async (payload, thunkAPI) => {
    const response = await getSoftwares(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
// Delete Software
export const deleteSoftware = createAsyncThunk(
  `/softwares/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteSoftwareService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getSoftwaresList());
    }
    return response;
  }
);
//Add Software
export const addSoftware = createAsyncThunk(
  "/softwares",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewSoftware(payload);
    thunkAPI.dispatch(getSoftwaresList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getSoftwareByID = createAsyncThunk(
  "/softwares/listSoftwares/id",
  async (payload, thunkAPI) => {
    const response = await getSoftwareByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }    return response;
  }
);
export const updateSoftware = createAsyncThunk(
  "/softwares/update",
  async (payload, thunkAPI) => {

    const { response, error } = await updateSoftwareService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
//**USER DROP DOWN ACTIONS */
export const setUsersDropdown = createAsyncThunk(
  "users/getDropDownUsers",
  async (payload) => {
    const { response, error } = await fetchUsersDropdownService(payload);
    if (error) {
      return error;
    }
    return response;
  }
);

//**ORGANIZATION DROP DOWN ACTIONS */
export const setOrganizationsDropdown = createAsyncThunk(
  "organizations/getDropDownOrganizations",
  async (payload) => {
    const { response, error } = await fetchOrganizationsDropdownService(
      payload
    );
    if (error) {
      return error;
    }
    return response;
  }
);

//get Company
export const getCompanyList = createAsyncThunk(
  "/companyTypes/listCompanyType",
  async (payload, thunkAPI) => {
    const response = await getCompanies(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//add new company
export const addCompany = createAsyncThunk(
  "/companyTypes",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewCompany(payload);
    thunkAPI.dispatch(getCompanyList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//delete company
export const delCompany = createAsyncThunk(
  `/companyTypes/id`,
  async (payload, thunkAPI) => {
  
    const response = await deleteCompany(payload);


  
    if (response?.status === 200) {
     
      thunkAPI.dispatch(getCompanyList());
    }
    return response;
  }
);

//******************Experiences************************////

// List Experiences

export const getExperienceList = createAsyncThunk(
  "/experience/listExperiences",
  async (payload, thunkAPI) => {
    const response = await getExperiences(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
// Delete Experiences
export const deleteExperience = createAsyncThunk(
  `/experience/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteExperiences(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getExperienceList());
    }
    return response;
  }
);
//Add Experiences
export const addExperience = createAsyncThunk(
  "/experience",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewExperiences(payload);
    thunkAPI.dispatch(getExperienceList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getExperienceByID = createAsyncThunk(
  "/experience/listExperiences/id",
  async (payload, thunkAPI) => {
    const response = await getExperiencesById(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
export const updateExperience = createAsyncThunk(
  "/experience/update",
  async (payload, thunkAPI) => {

    const { response, error } = await updateExperiences(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//******************Profession Registration Types************************//
// List Profession Registration Types
export const getProfessionRegistrationTypeList = createAsyncThunk(
  "/professionRegistrationTypes/listProfessionRegistrationTypes",
  async (payload, thunkAPI) => {
    const response = await getProfessionRegistrationTypes(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete Profession Registration Types
export const deleteProfessionRegistrationType = createAsyncThunk(
  `/professionRegistrationTypes/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteProfessionRegistrationTypeService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getProfessionRegistrationTypeList());
    }
    return response;
  }
);
//Add Profession Registration Types
export const addProfessionRegistrationType = createAsyncThunk(
  "/professionalregistrationtypes",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewProfessionRegistrationType(payload);
    thunkAPI.dispatch(getProfessionRegistrationTypeList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getProfessionRegistrationTypeById = createAsyncThunk(
  "/professionRegistrationTypes/listProfessionRegistrationTypes/id",
  async (payload, thunkAPI) => {
    const response = await getProfessionRegistrationTypeByIDService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
export const updateProfessionRegistrationType = createAsyncThunk(
  "/professionRegistrationTypes/update",
  async (payload, thunkAPI) => {
  
    const { response, error } = await updateProfessionRegistrationTypeService(
      payload
    );
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//****************** Specializations **********************//
// List Specializations
export const getSpecializationsList = createAsyncThunk(
  "/specializations/listSpecializations",
  async (payload, thunkAPI) => {
    const response = await getSpecializationsService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete Specializations
export const deleteSpecializations = createAsyncThunk(
  `/specializations/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteSpecializationsService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getSpecializationsList());
    }
    return response;
  }
);
//Add Specializations
export const addSpecializations = createAsyncThunk(
  "/specializations",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewSpecializationsService(payload);
    thunkAPI.dispatch(getSpecializationsList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getSpecializationsById = createAsyncThunk(
  "/specializations/listSpecializations/id",
  async (payload, thunkAPI) => {
    const response = await getSpecializationsByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
export const updateSpecializations = createAsyncThunk(
  "/specializations/update",
  async (payload, thunkAPI) => {
  
    const { response, error } = await updateSpecializationsService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//****************** Document Type **********************//
// List DocumentType
export const getDocumentType = createAsyncThunk(
  "/documentType/listDocumentType",
  async (payload, thunkAPI) => {
    const response = await getDocumentTypeService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete DocumentType
export const deleteDocumentType = createAsyncThunk(
  `/documentType/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteDocumentTypeService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getDocumentType());
    }
    return response;
  }
);
//Add DocumentType
export const addDocumentType = createAsyncThunk(
  "/documentType",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewDocumentTypeService(payload);
    thunkAPI.dispatch(getDocumentType());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getDocumentTypeById = createAsyncThunk(
  "/documentType/listDocumentType/id",
  async (payload, thunkAPI) => {
    const response = await getDocumentTypeByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
export const updateDocumentType = createAsyncThunk(
  "/documentType/update",
  async (payload, thunkAPI) => {
   
    const { response, error } = await updateDocumentTypeService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//****************** Department Type **********************//
// List DepartmentType
export const getDepartmentType = createAsyncThunk(
  "/departmentType/listDepartmentType",
  async (payload, thunkAPI) => {
    const response = await getDepartmentTypeService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete DepartmentType
export const deleteDepartmentType = createAsyncThunk(
  `/departmentType/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteDepartmentTypeService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getDepartmentType());
    }
    return response;
  }
);
//Add DepartmentType
export const addDepartmentType = createAsyncThunk(
  "/departmentType",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewDepartmentTypeService(payload);
    thunkAPI.dispatch(getDepartmentType());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getDepartmentTypeById = createAsyncThunk(
  "/departmentType/listDepartmentType/id",
  async (payload, thunkAPI) => {
    const response = await getDepartmentTypeByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
export const updateDepartmentType = createAsyncThunk(
  "/departmentType/update",
  async (payload, thunkAPI) => {

    const { response, error } = await updateDepartmentTypeService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
//get additional training

export const getAdditionalTrainingList = createAsyncThunk(
  "/additionaltraining/",
  async (payload, thunkAPI) => {
    const response = await additionalTraining(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//add additional training
export const addAdditionalTrainings = createAsyncThunk(
  "/additionaltraining/hg",
  async (payload, thunkAPI) => {
 
    const { response, error } = await addAdditionalTraining(payload);
  
    thunkAPI.dispatch(getAdditionalTrainingList());
    if (error) {
     
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//delete Additonal Data
export const delAdditional = createAsyncThunk(
  `/additionaltraining/id`,
  async (payload, thunkAPI) => {
   
    const response = await deleteAdditonal(payload);
   
    if (response?.status === 200) {
    
      thunkAPI.dispatch(getAdditionalTrainingList());
    }
    return response;
  }
);

// get additional data by id
export const GetAdditionalTrainingById = createAsyncThunk(
  `/additionaltraining/list`,
  async (payload, thunkAPI) => {
  
    const response = await getAdditionalTrainingById(payload);
   
    if (response?.status === 200) {
    
      thunkAPI.dispatch(getAdditionalTrainingList());
    }
    return response;
  }
);

//put additional training
export const UpdateAdditionall = createAsyncThunk(
  "/additionaltraining/list/vg",
  async (payload, thunkAPI) => {

    const { response, error } = await updateAdditional(payload);
    thunkAPI.dispatch(getAdditionalTrainingList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);




//skills
//add skills
export const addSkill = createAsyncThunk("/skill", async (payload, thunkAPI) => {
  const { response, error } = await addNewSkill(payload);
  thunkAPI.dispatch(getCityList());
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return response;
});

// update skills
export const updateSkillList = createAsyncThunk(
  "/skill/update",
  async (payload, thunkAPI) => {
   
    const { response, error } = await updateSkills(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//skills  list
export const getSkillList = createAsyncThunk(
  "/skill/listSkill",
  async (payload, thunkAPI) => {
    const response = await getSkills(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//skill by id
export const getSkillbyId = createAsyncThunk(
  "/skill/listSkill/id",
  async (payload, thunkAPI) => {
    const response = await getSkillsById(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete Skill
export const deleteSkillList = createAsyncThunk(
  `/skill/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteSkill(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(getSkillList());
    }
    return response;
  }

  // if (error) {
  //   return thunkAPI.rejectWithValue(error);
  // }
);


//Doc verifaction list
export const getDocVerfList = createAsyncThunk(
  "/docverifcation/list",
  async (payload, thunkAPI) => {
    const response = await getDocVerification(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// update doc verification
export const updateDocVerificationList = createAsyncThunk(
  "/docVerification/update",
  async (payload, thunkAPI) => {
   
    const { response, error } = await updateDocVerification(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//filter Invoice service
export const getFilteredDocsDetails = createAsyncThunk(
  "docVerification/filteredDocs",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getDocsFilteredService(payload);
    // console.log("17>>>>", response)
    return response;
  }
);


//****************** Department GCC **********************//
// List DepartmentGCC
export const getGCCDepartment = createAsyncThunk(
  "/GCCdepartment/listGCCDepartment",
  async (payload, thunkAPI) => {
    const response = await getDepartmentGCCService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete DepartmentType
export const deleteGCCDepartment = createAsyncThunk(
  `/departmentGCC/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteGCCDepartmentService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getDepartmentType());
    }
    return response;
  }
);
//Add DepartmentType
export const addGCCDepartment = createAsyncThunk(
  "/departmentGCC",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewGCCDepartmentService(payload);
    thunkAPI.dispatch(getDepartmentType());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getGCCDepartmentById = createAsyncThunk(
  "/departmentGCC/listDepartmentGCC/id",
  async (payload, thunkAPI) => {
    const response = await getGCCDepartmentByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
export const updateGCCDepartment = createAsyncThunk(
  "/departmentGCC/update",
  async (payload, thunkAPI) => {

    const { response, error } = await updateGCCDepartmentService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);


//****************** Experience GCC **********************//
// List ExperienceGCC
export const getGCCExperience = createAsyncThunk(
  "/GCCExperience/listGCCExperience",
  async (payload, thunkAPI) => {
    const response = await getExperienceGCCService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

// Delete DepartmentType
export const deleteGCCExperience = createAsyncThunk(
  `/ExperienceGCC/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteGCCExperienceService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getDepartmentType());
    }
    return response;
  }
);
//Add DepartmentType
export const addGCCExperience = createAsyncThunk(
  "/experienceGCC",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewGCCExperienceService(payload);
    // thunkAPI.dispatch(getDepartmentType());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getGCCExperienceById = createAsyncThunk(
  "/experienceGCC/listExperienceGCC/id",
  async (payload, thunkAPI) => {
    const response = await getGCCExperienceByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
export const updateGCCExperience = createAsyncThunk(
  "/experienceGCC/update",
  async (payload, thunkAPI) => {

    const { response, error } = await updateGCCExperienceService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);