import { Icon, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// import { useDrag } from "react-dnd";

// import Logo from "../../Assets/logos/mastercard.svg";
import "./styles.css";
import AdminUsers from "layouts/dashboards/adminUsers";
import { Link } from "react-router-dom";

const SmallCard = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(props.route, { state: { data: true } });
  };

  return (
    <Link to={props.route}>
      <div className="container" onClick={handleRedirect}>
        <Paper
          elevated={24}
          sx={{
            backgroundColor: "#FFF",
            color: "#111",
            borderRadius: "10px",
            padding: "2px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <img src={props.logo} />
            <div className="content">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textAlign: "start",
                  fontFamily: "Roboto sans-serif",
                  fontSize: "1rem",
                  fontWeight: "600",
                  color: "grey",
                }}
              >
                {/* <Typography variant="text">{props.title}</Typography> */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: "Roboto",
                  }}
                >
                  <Link to={props.route}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        color: "grey",
                        display: "inline-block !important",
                        width: "180px !important",
                        whiteSpace: "nowrap !important",
                        overflow: "hidden !important",
                        textOverflow: "ellipsis !important",
                      }}
                      variant="text"
                    >
                      {props.title}
                    </Typography>
                  </Link>
                  <Typography
                    style={{
                      fontWeight: "100",
                      color: "#9a9a9a",
                    }}
                    variant="text"
                  >
                    {props.subTitle}
                  </Typography>
                </Box>
                <Link to={props.route}>
                <Box>
                  <IconButton aria-label="add" className="smallCard-icon">
                    <AddIcon
                      onClick={() => {
                        handleRedirect();
                      }}
                    />
                  </IconButton>
                </Box>
                </Link>
              </Box>
            </div>
          </Box>
        </Paper>
      </div>
    </Link>
  );
});

SmallCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};
export default SmallCard;
