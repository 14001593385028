import * as yup from "yup";

export const CitiesDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

export const AdditionalDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

export const ProfessionDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

export const PRTDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

export const QualificationDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
    profession:yup.string().required("Profession is required")
});

export const SpecialisationDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
    profession:yup.string().required("Profession is Required")
});

export const SoftwareDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

// Experience Type
export const ExperienceDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

// LanguageDialog
export const LanguageDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

// Department Type
export const DepartmentTypeDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
    profession:yup.string().required()
    
});
// Document Type
export const DocumentTypeDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
  iconLocation:yup.string().required("Icon Location is required")
});

// Company Type
export const CompanyTypeDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});

export const SkillsDialogValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
  departmentType: yup.string().required("Department Type is required")
  // .matches(/^[aA-zZ\s]+$/, "Invalid Name"),
});