import { IconButton, InputLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import MDButton from "./../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import SelectDropDown from "./../../../examples/DropDown/index";
import CloseIcon from "@mui/icons-material/Close";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { addJobJobs } from "../Redux/actionCreator";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { setSpecialisationsType } from "layouts/events/Redux/actionCreator";
import { setQualificationType } from "layouts/events/Redux/actionCreator";
import { setExperience } from "layouts/events/Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import { getCompanyList } from "layouts/common/Redux/actionCreator";
import { getDepartmentsList } from "layouts/hospital/Redux/actionCreator";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";

const AddJobDialog = ({ handleDialogClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    //   resolver:yupResolver()
  });
  const selectOptions = [
    {
      key: "PROPOSED",
      value: "PROPOSED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "APPROVED",
      value: "APPROVED",
    },
    {
      key: "ONGOING",
      value: "ONGOING",
    },
    {
      key: "VALIDATION",
      value: "VALIDATION",
    },
    {
      key: "COMPLETED",
      value: "COMPLETED",
    },
    {
      key: "CANCELLED",
      value: "CANCELLED",
    },
  ];

  const shiftOptions = [
    {
      key: "EARLY_MORNING",
      value: "EARLY_MORNING",
    },
    {
      key: "MORNING",
      value: "MORNING",
    },
    {
      key: "AFTERNOON",
      value: "AFTERNOON",
    },
    {
      key: "EVENING",
      value: "EVENING",
    },
    {
      key: "NIGHT",
      value: "NIGHT",
    },
    {
      key: "DAY_NIGHT",
      value: "DAY_NIGHT",
    },
    {
      key: "ALL_DAY",
      value: "ALL_DAY",
    },
  ];

  const timingOptions = [
    {
      key: "CONSECUTIVE",
      value: "CONSECUTIVE",
    },
    {
      key: "NON_CONSECUTIVE",
      value: "NON_CONSECUTIVE",
    },
    {
      key: "RANDOM",
      value: "RANDOM",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { OrganizationsData, Company_List_Data } = useSelector(
    (state) => state.common
  );
  const {
    Experience_Data,
    Qualification_Type_Data,
    Specialization_Type_Data,
    Profession_Type_Data,
  } = useSelector((state) => state.events);
  const { hospital_department_Data, hospital_branch_Data } = useSelector(
    (state) => state.hospital
  );

  const department_Info = hospital_department_Data?.departmentInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;
  
  const organizationOptions = [];
  const experienceData = Experience_Data?.experienceInfo;
  const qualificationsData = Qualification_Type_Data?.qualificationInfo;
  const professionData = Profession_Type_Data?.professionInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;
  const companyData = Company_List_Data?.companyListInfo;

  const branchData = hospital_branch_Data?.branchInfo;

  const departmentOptions = [];
  const experienceOptions = [];
  const qualificationOptions = [];
  const specializationOptions = [];
  const professionOptions = [];
  const companyOptions = [];
  const branchOptions = [];

  department_Info.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentOptions.push(obj);
  });


  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  companyData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  qualificationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    qualificationOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  const handleOrganizationChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    
    setValue("organization", userID);
  };

  const handleProfessionChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

  
    setValue("profession", userID);
  };

  const handleExperienceChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    
    setValue("numExperience", userID);
  };

  const handleSpecializationChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    
    setValue("specialization", userID);
  };

  const handleQualificationChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });


    setValue("qualification", userID);
  };

  const handleCompanyChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    companyOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

   
    setValue("company", userID);
  };

  const handleDepartmentChange = (e) => {
    let userID;
    //Searching for the name in the Users array to find the ID
    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

   
    setValue("department", userID);
  };



  const handleBranchChange = (e) => {
    let userID;
    setValue("branch", e.target.value);
    //Searching for the name in the Users array to find the ID
    branchOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

   
  };

  useEffect(() => {
    dispatch(setOrganizationsDropdown());
    dispatch(setSpecialisationsType());
    dispatch(setProfessionType());
    dispatch(setQualificationType());
    dispatch(setExperience());
    dispatch(getCompanyList());
    dispatch(getDepartmentsList());
    dispatch(
      HospitalBranchList({
        criteria: {
          listName: true,
        },
      })
    );
  }, []);

  const onSubmit = (data) => {

    dispatch(addJobJobs(data));
    handleDialogClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel fullWidth>Job Code</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("code")}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>State</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={selectOptions}
              placeholder="State"
              control={control}
              name="state"
              defaultValue="none"
              getValues={getValues}
              customOnChange={(e) => setValue("state", e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Organization</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={organizationOptions}
              placeholder="Organization"
              control={control}
              name="organization"
              defaultValue="none"
              getValues={getValues}
              // {...register("organization")}
              customOnChange={handleOrganizationChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Company</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={companyOptions}
              placeholder="Company"
              control={control}
              // {...register("company")}
              name="company"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleCompanyChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Department</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={departmentOptions}
              placeholder="Department"
              control={control}
              name="department"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleDepartmentChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Branch</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={branchOptions}
              placeholder="Branch"
              control={control}
              // {...register("branch")}
              name="branch"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleBranchChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Qualification</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={qualificationOptions}
              placeholder="Qualifications"
              control={control}
              name="qualification"
              defaultValue="none"
              getValues={getValues}
              // {...register("qualification")}
              customOnChange={handleQualificationChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Num Experience</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={experienceOptions}
              placeholder="Experience"
              control={control}
              name="experience"
              defaultValue="none"
              getValues={getValues}
              // {...register("numExperience")}
              customOnChange={handleExperienceChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Specialization</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={specializationOptions}
              placeholder="Specialization"
              control={control}
              name="specialization"
              defaultValue="none"
              getValues={getValues}
              // {...register("specialization")}
              customOnChange={handleSpecializationChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Candidate Type</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={professionOptions}
              placeholder="Profession"
              control={control}
              name="profession"
              defaultValue="none"
              getValues={getValues}
              // {...register("profession")}
              customOnChange={handleProfessionChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Timings Type</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={timingOptions}
              placeholder="Timings"
              name="timingsType"
              control={control}
              defaultValue="none"
              getValues={getValues}
              customOnChange={(e) => setValue("timingsType", e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Shift</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDown
              options={shiftOptions}
              placeholder="Shift"
              name="shift"
              control={control}
              defaultValue="none"
              getValues={getValues}
              customOnChange={(e) => setValue("shift", e.target.value)}
            />
          </Grid>
          <MDButton
            variant="contained"
            color="info"
            sx={{ marginTop: "2rem", marginLeft: "1rem" }}
            type="submit"
            onClick={handleDialogClose}
          >
            create
          </MDButton>
        </Grid>
      </form>

      {/* <Grid container> */}
      {/* <Grid
            item
            display={"flex"}
            // justifyContent={"flex-start"}
            // alignItems={"center"}
            flexDirection={"column"}
            gap={"10px"}
            width={"90%"}
            // margin={"20px 10px 20px 10px"}
          >
            <InputLabel fullWidth>State</InputLabel>
            <SelectDropDown
              options={selectOptions}
              placeholder="State"
            />
          </Grid> */}
      {/* </Grid> */}
    </div>
  );
};

export default AddJobDialog;
