import { Delete } from "@mui/icons-material";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCompany } from "services/hospitalServices";
import { getCompanies } from "shared/services";
import { formatDate } from "util/Helper";
import DeleteDialog from "../../../components/DeleteDialogBox/DeleteDialog";
import MDBox from "../../../components/MDBox/index";
import { delCompany, getCompanyList } from "../../common/Redux/actionCreator";
import CompanyTypeDialog from "./CompanyTypeDialog";
import moment from "moment";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

function getUpdatedByName(params) {
  return `${params?.row?.updatedBy?.name?.full || "NA"}`;
}
function getCreatedByName(params) {
  return `${params?.row?.createdBy?.name?.full || "NA"}`;
}

function getCreatedAT(params) {
  var a
  const rowName = params?.row?.createdAt;
  if (rowName) {
    a = moment(rowName).format("YYYY/MM/DD");
    return `${a}`;
  } else {
    a = "NA";
    return `${a}`;
  }
  // var rowName = formatDate(params?.row?.createdAt);
  // // var dateNOW = new Date(rowName);
  // var dateNOW = moment(rowName).format("YYYY/MM/DD")
  // return dateNOW;
}

function getUpdatedAT(params) {
  var a
  const rowName = params?.row?.updatedAt;
  if (rowName) {
    a = moment(rowName).format("YYYY/MM/DD");
    return `${a}`;
  } else {
    a = "NA";
    return `${a}`;
  }
  // var rowName = formatDate(params?.row?.updatedAt);
  // // var dateNOW = new Date(rowName);
  // var dateNOW = moment(rowName).format("YYYY/MM/DD")
  // return dateNOW;
}

const CompanyType = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const handleDelete = (ID) => {
    dispatch(delCompany(ID));
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getCompanies(checked);

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getCompanies(checked);
    }
  };

  const { Company_List_Data } = useSelector((state) => state.common);
  const companies = Company_List_Data?.companyListInfo;

  const columns = [
    {
      field: "name",
      headerName: `Name`,

      flex: 1.5,
    },

    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: "description",
      headerName: `Description`,
      flex: 1.5,
    },
    {
      field: "isDeleted",
      headerName: `Is Deleted`,
      renderCell: (cellValues) => {
        // const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isDeleted}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a
  const rowName = cellValues?.row?.createdAt;
  if (rowName) {
    a = moment(rowName).format("YYYY/MM/DD");
    return `${a}`;
  } else {
    a = "NA";
    return `${a}`;
  }
        // const updated = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return updated;
      },
      type: "date",
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

        // return updated;
      },
      type: "date",
      valueGetter: getUpdatedAT,
      // flex: 1.5,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
      flex: 1,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
      flex: 1,
    },
  ];
  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };
  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <>
      <DashboardLayout className="dashboardLayout">
        <DashboardNavbar />
        <FilterBar
          title="Create Company Type"
          title1="Company Type"
          isOpen={isOpen}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          handleDeleteApi={handleDeleteApi}
          dialogContent={
            <CompanyTypeDialog handleDialogClose={handleDialogClose} />
          }
        />

        <MDBox sx={{ marginTop: "2rem" }}>
          <DataGrid
            getRowId={(row) => row._id}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: {
                  fileName: `CompanyTypes-${formatDate()}`,
                },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  isDeleted: false,
                  description: false,
                  createdAt: false,
                  updatedAt: false,
                  createdBy: false,
                  updatedBy: false,
                  Delete: false,
                },
              },
            }}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            loading={companies.length > 0 ? false : true}
            columns={columns}
            rows={showDeltedRecords ? deletedRecords : [...companies].reverse()}
            pagination
            pageSize={100}
            rowsPerPageOptions={[100]}
            // onCellClick={handleCellClick}
            // onRowClick={handleRowClick}
            //isRowSelectable
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
          />
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default CompanyType;
