import Cookie from "universal-cookie";
const cookies = new Cookie();

let state = {
  t_id: cookies.get("t_id") || "",
  loading: false,
  error: "",
  success: false,
  email: "testtest@gmail.com",
  password: "testtesttest",
};

export default state;
