import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// export const get_Token = () => {
//   return localStorage.getItem("t_id");
// };

export const get_Token = () => {
  return cookies.get("t_id");
};

export const formatDate = (date) => {
  let realDate = moment(date).format();
  return realDate.split("T")[0];
};


