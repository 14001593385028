import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import { FormHelperText } from "@material-ui/core";
import React, { useEffect } from "react";
import MDButton from "../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectDropDown from "./../../../examples/DropDown/index";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import MDInput from "../../../components/MDInput";
import { getValue } from "@mui/system";
import { QualificationDialogValidation } from "util/validations/commonValidations";
import { useDispatch, useSelector } from "react-redux";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import { addQualification } from "../Redux/actionCreator";
import { toast } from "react-toastify";
import SelectDropDownNew from "examples/DropDownNew";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const QualificationDialog = ({ handleDialogClose }) => {
 
  const[profession,setProfession]=useState();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(QualificationDialogValidation),
  });
  const selectOptions = [
    {
      key: "Organization",
      value: "Organization",
    },
    {
      key: "SuperAdmin",
      value: "Super_Admin",
    },
    {
      key: "Applicant",
      value: "Applicant",
    },
  ];

  const dispatch = useDispatch();

  const professionOptions = [];
  const { Profession_Type_Data } = useSelector((state) => state.events);
  const professionData = Profession_Type_Data?.professionInfo;
  const[professionn,setProfessionn]=useState();
  //Iterating and pushing into options
  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  const [idSelected, setIdSelected] = useState("");
  //Profession Change
  const handleProfessionChange = (e) => {
    if(errors?.profession?.message)
    errors.profession.message=""
    setValue("profession", e.target.value);
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
      
        setIdSelected(obj.key);
      }
    });
    setProfessionn(e.target.value);
  };
  const onSubmit = (data) => {
    let payload = {
      name: data.name,
      profession: idSelected,
    };

   
    
      dispatch(addQualification(payload));
      handleDialogClose();
    
   
    
  };

  useEffect(() => {
    dispatch(setProfessionType());
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("name")}
              error={errors?.name?.message}
              helperText={errors?.name?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Profession
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={professionOptions}
              placeholder="Select"
              name="profession"
              control={control}
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleProfessionChange}
             
            />
            <FormHelperText>{errors?.profession?.message}</FormHelperText>
          </Grid>
        </Grid>

        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
        >
          create
        </MDButton>
      
      </form>
    </div>
  );
};

export default QualificationDialog;