//*************Users *********************** */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadPhoto } from "services/eventServices";


import { getAvalibilityList } from "services/permanentServices";
import {addNewJob} from "services/permanentServices";
import {getPermanentJob} from "services/permanentServices";
import {updatePermanentJobService} from "services/permanentServices";
import {getPermanentJobsByIdService} from "services/permanentServices";
import {getPermanentApplication} from "services/permanentServices";
import {updatePermanentApplicationService} from "services/permanentServices";
import {addGCCNewJob} from "services/gccServices";
import {getGCCJob} from "services/gccServices";
import {updateGCCJobService} from "services/gccServices";

 
//create gcc job  
export const addNewGCCJob = createAsyncThunk(
  "/createGCCJob",  
  async (payload, thunkAPI) => {
    const { response, error } = await addGCCNewJob(payload);
    // thunkAPI.dispatch(getAdminUser());
    if (error) {
      
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//get permanent job
export const getGCCJobList = createAsyncThunk(
  "/gccjob/list",
  async (payload, thunkAPI) => {
    const response = await getGCCJob();
    // console.log("actionCreator>>", response);
    return response;
  }
);

//update permannet job
export const updateGCCJobs = createAsyncThunk(
  "/gccJobs/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updateGCCJobService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//get permanent job
export const getPermanentApplicationList = createAsyncThunk(
  "/gcc/application/list",
  async (payload, thunkAPI) => {
    const response = await getPermanentApplication();
    // console.log("actionCreator>>", response);
    return response;
  }
);

//update permannet application
export const updatePermanentApplication = createAsyncThunk(
  "/gccapplication/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updatePermanentApplicationService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

