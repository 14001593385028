const state = {
  loading: false,
  success: false,
  error: "",
  City_List_Data: {
    cityListLoading: true,
    cityListError: "",
    cityListInfo: [],
    cityPayloadIDOptions: [],
  },
  Company_Type_Data: {
    companyTypeLoading: true,
    companyTypeError: "",
    companyTypeInfo: [],
    companyTypePayloadIDOptions: [],
  },
  Experience_Data: {
    experienceLoading: true,
    experienceError: "",
    experienceInfo: [],
    experiencePayloadIDOptions: [],
  },
  Profession_Type_Data: {
    professionLoading: true,
    professionError: "",
    professionInfo: [],
    professionPayloadIDOptions: [],
  },
  Qualification_Type_Data: {
    qualificationLoading: true,
    qualificationError: "",
    qualificationInfo: [],
    qualificationPayloadIDOptions: [],
  },
  Document_Type_Data: {
    documentTypeLoading: true,
    documentTypeError: "",
    documentTypeInfo: [],
  },
  Specialization_Type_Data: {
    specializationLoading: true,
    specializationError: "",
    specializationInfo: [],
    specializationPayloadIDOptions: [],
  },
  Department_Type_Data: {
    departmentLoading: true,
    departmentError: "",
    departmentInfo: [],
  },
  Role_Type_Data: {
    roleLoading: true,
    roleError: "",
    roleInfo: [],
  },
  Ari_Data: {
    ariLoading: true,
    ariError: "",
    ariInfo: [],
  },
  Language_Type_Data: {
    languageLoading: true,
    languageError: "",
    languageInfo: [],
  },
  Professional_Registration_Type_Data: {
    professionalRegistrationLoading: true,
    professionalRegistrationError: "",
    professionalRegistrationInfo: [],
  },
  Upload_Photo_Data: {
    uploadPhotoLoading: true,
    uploadPhotoError: "",
    uploadPhotoInfo: [],
  },
  Event_Details_Data: {
    eventDetailsLoading: true,
    eventDetailsError: "",
    eventDetailsInfo: [],
    imageDetails: {},
  },
  Event_List_Data: {
    eventListLoading: true,
    eventListError: "",
    eventListInfo: [],
  },
  Interview_Details_Data: {
    interviewDetailsLoading: true,
    interviewDetailsError: "",
    interviewDetailsInfo: [],
  },

  interviewList_Data: {
    interviewList_Data_loading: true,
    interviewList_Data_error: "",
    interviewList_Data_info: [],
  },

  Referral_Details_Data: {
    referralDetailsLoading: true,
    referralDetailsError: "",
    referralDetailsInfo: [],
    total: 0,
  },

  referralList_Data: {
    referralList_Data_loading: true,
    referralList_Data_error: "",
    referralList_Data_info: [],
  },
  currentPage: 0,
  pageSize: 10,
};

export default state;
