import {
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDepartmentTypeByIdService } from "services/commonServices";
import MDBox from "../../../components/MDBox/index";
import { setUsersDropdown, updateDepartmentType } from "../Redux/actionCreator";
import SelectDropDown from "./../../../examples/DropDown/index";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Moment from "moment";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const DepartmentTypeForm = () => {
  //Fetching User Dropdown Details
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;



  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });



  const { Profession_Type_Data } = useSelector((state) => state.events);
  const professionData = Profession_Type_Data?.professionInfo;
  const professionOptions = [];
  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });
  const ID = window.location.href.split("departmentTypes")[1].split("/")[1];
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
    
    let response = await getDepartmentTypeByIdService(ID);
   
    setResponseData(response);
    return response[0];
  };

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: () => getData() });

  const[professionId,setPorfessionId]=useState();
  const handleProfessionChange = (e) => {
    
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
  
    setValue("profession", e.target.value);
    setPorfessionId(userID);
  
  };


  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      description:data?.description,
      profession:professionId,
      isDeleted: data.isDeleted,
      
    };
    dispatch(updateDepartmentType({ payload: payload, id: ID })).then(() => {
      navigate("/common/departmentTypes");
    });
  };
  const handleReset = () => {
    reset();
  };

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

  };


 
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setUsersDropdown());
    dispatch(setProfessionType());
  }, []);

  const [updateCreated, setUpdateCreated] = useState(false);
  const [updateUpdated, setUpdateUpdated] = useState(false);
  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");
    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }
    if (created !== undefined  && created!=="") {
      if (!updateCreated) {
        // create=Moment(created).format("DD/MM/YYYY")
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
        setUpdateCreated(true);
      }
    }
    if (updated !== undefined && updated !=="") {
      if (!updateUpdated) {
        setValue("updatedAt", Moment(created).format("DD/MM/YYYY"));
        setUpdateUpdated(true);
      }
    }
  }, [getValues("createdAt"), getValues("updatedAt")]);
  const formValues = getValues();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Id */}
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name")}
                    />
                  </Grid>
                  {/* ID */}
                  <Grid item xs={12}>
                    <Typography variant="body">ID:{ID}</Typography>
                  </Grid>
                  {/* Checkbox */}
                  {/* is Deleted */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isDeleted"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Is Deleted"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/* Icon Cdn Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("description")}
                    />
                  </Grid>

                  <Grid item xs={4}>
                              <Typography variant="subtitle1">Profession</Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <SelectDropDownNew
                                options={professionOptions}
                                name={`profession.name`}
                                control={control}
                                getValues={getValues}
                                defaultValue="none"
                                placeholder="Select"
                                customOnChange={handleProfessionChange}
                              />
</Grid>
                  {/* CreatedAt */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>

                  {/* Updated At */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>

                  {/* CreatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdBy.name.full")}
                    />
                  </Grid>

                  {/* updatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedBy.name.full")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Save
                  </Button>

                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default DepartmentTypeForm;
