import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CancelIcon from "@mui/icons-material/Cancel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";

import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "../../../components/MDBox/index";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Paper } from "@mui/material";
import SelectDropDown from "./../../../examples/DropDown/index";
import Moment from "moment";
import {
  DialogContent,
  Typography,
  FormControlLabel,
  FormGroup,
  Link,
} from "@mui/material";
import { ArrowBackIos, Diversity1 } from "@mui/icons-material";
import SelectDropDownNew from "examples/DropDownNew";
import { getHospitalCompanyByIdService } from "services/hospitalServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import { getCompanyList } from "layouts/common/Redux/actionCreator";
import DynamicCoords from "examples/DynamicCoords";
import DynamicDocuments from "examples/DynamicDocuments";
import DynamicPermissions from "examples/DynamicPermissions";
import { setAriType } from "layouts/events/Redux/actionCreator";
import { setRoleType } from "layouts/events/Redux/actionCreator";
import MultiSelectDropdown from "examples/Multi Select Dropdown";
import {
  setCompanies,
  setCompanyLogo,
  updateHospitalCompany,
} from "../Redux/actionCreator";
import { getDocumentType } from "layouts/common/Redux/actionCreator";

const label = { inputProps: { "aria-label": "Checkbox demo" } };


const CompanyDetailsForm = () => {
  const { DocumentType_Data } = useSelector((state) => state.common);
  const documents = DocumentType_Data?.documentTypeInfo;
  // console.log("1--->", documents)
  
  const docTypeOptions=[];
  documents.map((elem)=>{
     let obj={
      key:elem?._id,
      value:elem?.name
     }
     docTypeOptions.push(obj);
  })
  //Finding Hospital Company ID
  const ID = window.location.href.split("company")[1].split("/")[1];

  //Getting Hospital Company By ID
  const getData = async () => {
    let response = await getHospitalCompanyByIdService(ID);

    return response[0];
  };

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { Company_List_Data, UsersData } = useSelector((state) => state.common);
  const { Ari_Data, Role_Type_Data } = useSelector((state) => state.events);
  const { companies_DropDown_Data, Company_Logo_Data } = useSelector(
    (state) => state.hospital
  );


  const companyLogoInfo = Company_Logo_Data?.company_logo_info;
  // console.log("companyLogoInfo", companyLogoInfo)

 

  const companiesData = companies_DropDown_Data?.companiesDropDownInfo;
  const companyTypeData = Company_List_Data?.companyListInfo;
  const userInfo = UsersData?.usersInfo;
  const ariData = Ari_Data?.ariInfo;
  const roleData = Role_Type_Data.roleInfo;

  const companyTypeOptions = [];
  const userOptions = [];
  const ariOptions = [];
  const roleOptions = [];

  userInfo?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  companyTypeData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyTypeOptions.push(obj);
  });
  ariData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.ari,
    };

    ariOptions.push(obj);
  });

  roleData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    roleOptions.push(obj);
  });

  const logoOptions = [
    {
      key: "AWS_S3",
      value: "AWS_S3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];

  const [companyTypePayloadID, setCompanyTypeID] = useState("");
  const [RoleTypeArray, setRoleTypeArray] = useState([]);


  const companiesOptions = [];

  companiesData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companiesOptions.push(obj);
  });
  //handler Functions
  const handleCompanyTypeChange = (e) => {
    setValue("companyType.name", e.target.value);
    let companyTypeID;
    //Searching for the name in the Users array to find the ID
    companyTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        companyTypeID = obj.key;
      }
    });

    setCompanyTypeID(companyTypeID);
  };

  const handleRoleChange = (e, index) => {
    let roleID;
    //Searching for the name in the Users array to find the ID
    roleOptions.map((obj) => {
      if (obj.value == e.target.value) {
        roleID = obj.key;
      }
    });
    setValue(`permissionOverrides.${index}.role`, roleID);
    setRoleTypeArray((prev) => [...prev, roleID]);

  
  };
const handleComapnyChange=()=>{

}
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectOptionsLevel = [
    {
      key: "WRITER",
      value: "WRITER",
    },
    {
      key: "READER",
      value: "READER",
    },
    {
      key: "NONE",
      value: "NONE",
    },
  ];

  // const docTypeOptions = [
  //   {
  //     key: "AADHAR_FRONT",
  //     value: "AADHAR_FRONT",
  //   },
  //   {
  //     key: "AADHAR_BACK",
  //     value: "AADHAR_BACK",
  //   },
  //   {
  //     key: "COVID",
  //     value: "COVID",
  //   },
  //   {
  //     key: "CV",
  //     value: "CV",
  //   },
  //   {
  //     key: "EDUCATION_CERTIFICATE",
  //     value: "EDUCATION_CERTIFICATE",
  //   },
  //   {
  //     key: "EMPLOYMENT_LETTER",
  //     value: "EMPLOYMENT_LETTER",
  //   },
  //   {
  //     key: "PAN",
  //     value: "PAN",
  //   },
  //   {
  //     key: "REGISTRATION",
  //     value: "REGISTRATION",
  //   },
  //   {
  //     key: "SALARY_SLIP",
  //     value: "SALARY_SLIP",
  //   },
  // ];
  const handleBack = () => {
    navigate(-1);
  };
  const [companiesIDPayload, setCompaniesIDPayload] = useState([]);
  const handleCompaniesChange = (values) => {
    //Searching for the name in the qualification array to find the ID
    let companiesID = [];
    let selectedValues = values;


    if (selectedValues) {
      selectedValues?.map((value) => {
        companiesOptions?.map((obj) => {
          if (obj.value == value) {
            companiesID.push(obj.key);
           
          }
        });
      });
    }

    setCompaniesIDPayload(companiesID);
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "permissionOverrides", // unique name for your Field Array
  });

  const onSubmit = (data) => {
    let coordsData=[];
     data?.address?.coords.map((obj)=>{
        if(typeof obj=="object")
        coordsData.push("");
        else
        coordsData.push(obj);
     })
     let documentsData=[];
     data?.documents.map((obj)=>{
        let DocObj={
          
          docType:typeof obj?.docType=="object"? obj?.docType?.name=="undefined"?"":obj?.docType?._id:obj?.docType,
          files:obj?.files,
          uploadedAt:obj?.uploadedAt
        }
        documentsData.push(DocObj);
     })


     let permissionOverridesData=[];
     data?.permissionOverrides.map((obj)=>{
      let Permission=[];
      obj?.permissions && obj?.permissions.map((ele)=>{
         const obj={
          ari:typeof ele?.ari=="object"?"":ele?.ari,
          level:ele?.level
         }
         Permission.push(obj);
      })
      let PermissionObj={
         
        role:typeof obj?.role=="object"? obj?.role?.name=="undefined"?"":obj?.role?._id:obj?.role,
        permissions:Permission,
        
      }
      permissionOverridesData.push(PermissionObj);
     })
    let payload = {
      logo: companyLogoInfo[0] ? companyLogoInfo[0] : data?.logo,

      address: {
        coords: coordsData,
        address: data?.address?.address,
        city: data?.address?.city,
        country: data?.address?.country,
        pin: data?.address?.pin,
        state: data?.address?.state,
      },

      companyType: companyTypePayloadID
        ? companyTypePayloadID
        : data?.companyType?._id,
      name: data.name,
      permissionOverrides: permissionOverridesData,
      companies:
        companiesIDPayload?.length > 0
          ? companiesIDPayload
          : data.companies[0]?._id,
      documents: documentsData,
      enabled: data?.enabled,
      isDeleted: data?.isDeleted,
      ssl:{
        privateKey:data?.ssl?.privateKey,
        certificate:data?.ssl?.certificate
      },
      description: data?.description,
      sld: data?.sld,
      tld: data?.tld,
      website: data?.website,
      
    };

   
    dispatch(updateHospitalCompany({ payload: payload, id: ID })).then(
      (res) => {
        
        if (!res?.error) {
          navigate("/hospital/company");
        }
      }
    );
  };
  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    dispatch(setUsersDropdown());
    dispatch(getCompanyList());
    dispatch(setUsersDropdown());
    dispatch(setAriType());
    dispatch(setRoleType());
    dispatch(
      setCompanies({
        criteria: {
          listName: true,
        },
      })
    );
  }, []);
  useEffect(() => {
    dispatch(getDocumentType());
  }, []);

  useEffect(() => {
    if (file != null) {
    
      dispatch(setCompanyLogo(file));
    }
  }, [file]);

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    let logo = getValues("logo");

    if (logo.url != "") {
      if (!updated) {
        setImageUrl(logo.url);
        setUpdated(true);
      }
    }
  }, [getValues("name")]);

  const [created1, setCreated1] = useState(false);
  const [updated1, setUpdated1] = useState(false);

  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");

    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }

  
    if (created != undefined && created != "") {
      if (!created1) {
       
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
      }
      setCreated1(true);
    }

    if (updated !== undefined && updated != "") {
      if (!updated1) {
        setValue("updatedAt", Moment(updated).format("DD/MM/YYYY"));
      }
      setUpdated1(true);
    }
  }, [getValues("createdAt"), getValues("updatedAt")]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={4}>
          <Grid item xs={12}>
            
              <div style={{ display: "flex",width:"70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
                </Link>
              </div>
           
          </Grid>
          {/* <Divider /> */}
          <DialogContent style={{ marginTop: "20px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                {/* Name Title */}
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body">ID:{ID}</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="isDeleted"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("isDeleted")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="isDeleted"
                    />
                  </FormGroup>
                </Grid>
                {/* code */}
                {/* <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Code
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("code")}
                  />
                </Grid> */}
               
                {/*Cin */}
                {/* <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Cin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("Cin")}
                  />
                </Grid> */}
                {/* Logo Location */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Location
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDownNew
                    options={logoOptions}
                    control={control}
                    getValues={getValues}
                    name="logo.location"
                    placeholder="Select"
                    customOnChange={(e) => {
                      setValue("logo.location", e.target.value);
                    }}
                  />
                </Grid>
                {/* Logo File Name */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo File Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.fileName")}
                  />
                </Grid>
                {/* Logo URI */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Uri
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.uri")}
                  />
                </Grid>
                {/* Logo Bucket */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Bucket
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.bucket")}
                  />
                </Grid>
                {/* Logo Region */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Region
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.region")}
                  />
                </Grid>
                {/* Logo Mime Type */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Mime Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.mimeType")}
                  />
                </Grid>
                {/* Logo Secure Url */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Secure Url
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.secureUrl")}
                  />
                </Grid>
                {/* Logo URL */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Url
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.url")}
                  />
                </Grid>


                {/* logo is secured */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="logo.isSecured"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("logo.isSecured")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Logo Is Secured"
                    />
                  </FormGroup>
                </Grid>

               

                {/* logo cdn url */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Cdn Url
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.cdnUrl")}
                  />
                </Grid>

                {/* tid */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    TId
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("tld")}
                  />
                </Grid>
               {/* SID */}
               <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    SId
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("sld")}
                  />
                </Grid>
               {/* ssl private key */}
               <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                  SSl Private Key
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("ssl.privateKey")}
                  />
                </Grid>
               {/* ssl certificate */}
               <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    SSl Certificate
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("ssl.certificate")}
                  />
                </Grid>

                {/* COMPANY LOGO Upload */}

                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    {imageUrl ? "Update Company Logo" : "Upload Company Logo"}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                           
                            {console.log("file>>>", setFile)}
                            setImageUrl(URL.createObjectURL(e.target.files[0]));
                            setFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      {imageUrl && (
                        <div style={{ maxHeight: "40%", maxWidth: "50%" }}>
                          <CancelIcon
                            sx={{
                              position: "relative",
                              top: "1.8rem",
                              left: "90%",
                              color: "black",
                              cursor: "pointer",
                              marginBottom: "4px",
                              marginTop: "4px",
                            }}
                            onClick={() => {
                              
                              setImageUrl(null);
                            }}
                          />{" "}
                          <img
                            src={imageUrl}
                            style={{
                              objectFit: "contain",
                              height: "70px",
                              width: "100px",
                              // marginLeft: "5px",
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {/* <div> */}
                  {/* <input type="file" accept="image/*"  onChange={setFile}/>
                    <button>Upload</button> */}

                  {/* </div> */}
                </Grid>


                {/* descitption */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("description")}
                  />
                </Grid>

                 {/* Corporate Type */}
                 <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Company Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDownNew
                    options={companyTypeOptions}
                    control={control}
                    getValues={getValues}
                    name="companyType.name"
                    placeholder="Select"
                    customOnChange={handleCompanyTypeChange}
                  />
                </Grid>


                {/* company type */}
                {/* <Grid item xs={3}>
                              <Typography variant="subtitle1">Comapny Type</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDownNew
                                options={roleOptions}
                                name={`permissionOverrides.${index}.role.name`}
                                control={control}
                                getValues={getValues}
                                defaultValue="none"
                                placeholder="Select"
                                customOnChange={handleComapnyChange}
                              />
                            </Grid> */}

                {/* logo is secured */}
                {/* <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="logo.isSecured"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("logo.isSecured")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="is logo Secured ?"
                    />
                  </FormGroup>
                </Grid> */}
                {/* Logo cdn URL */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Website
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("website")}
                  />
                </Grid>
                {/* checkbox */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="enabled"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("enabled")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Enabled"
                    />
                  </FormGroup>
                </Grid>
                {/* Billing Address Address Address */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Address Address
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.address")}
                  />
                </Grid>
                {/* Billing Address Address City */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Address City
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.city")}
                  />
                </Grid>
                {/* Billing Address Address state */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Address State
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.state")}
                  />
                </Grid>
                {/* Billing Address Address pin */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Address Pin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.pin")}
                  />
                </Grid>
                {/* Billing Address Address Country */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Address Country
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("address.country")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DynamicCoords
                    control={control}
                    name="address.coords"
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Grid>
                {/* Billing Address gst number */}
                {/* <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Address GST Number
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("gstNumber")}
                  />
                </Grid> */}
                {/* 
              Documents */}
                <Grid item xs={12}>
                  <DynamicDocuments
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                    selectOptions={docTypeOptions}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Companies
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MultiSelectDropdown
                    options={companiesOptions}
                    getValues={getValues}
                    name="companies"
                    setValue={setValue}
                    control={control}
                    customOnChange={handleCompaniesChange}
                  />
                </Grid>
                {/* Permission Overrides */}
                <Grid item xs={12}>
                  <Paper sx={{ padding: "1rem" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                      Permission Overrides
                    </Typography>
                    <hr style={{ marginBottom: "2rem" }} />
                    <ul style={{ listStyleType: "none" }}>
                      {fields.map((field, index) => (
                        <li>
                          <Grid container spacing={2}>
                            {/* Role */}
                            <Grid item xs={3}>
                              <Typography variant="subtitle1">Role</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <SelectDropDownNew
                                options={roleOptions}
                                name={`permissionOverrides.${index}.role.name`}
                                control={control}
                                getValues={getValues}
                                defaultValue="none"
                                placeholder="Select"
                                customOnChange={(e) =>
                                  handleRoleChange(e, index)
                                }
                              />
                            </Grid>
                            {/* Permissions */}
                            <Grid item xs={12}>
                              <DynamicPermissions
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                name={`permissionOverrides.${index}`}
                                selectOptionslevel={selectOptionsLevel}
                                selectOptionsAri={ariOptions}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <hr />
                            </Grid>
                          </Grid>

                          <MDBox sx={{ textAlign: "right" }}>
                            <MDButton
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                            >
                              <Typography color="error" variant="body">
                                Remove
                              </Typography>
                            </MDButton>
                          </MDBox>
                          {/* </MDBox>  */}
                        </li>
                      ))}
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={(e) => {
                         
                          append({});
                        }}
                      >
                        + Add
                      </MDButton>
                    </ul>
                  </Paper>
                </Grid>
                {/* Billing Address is GST registered */}
                {/* <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="address.isGstRegistered"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("address.isGstRegistered")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Address is Gst Registered"
                    />
                  </FormGroup>
                </Grid> */}
                {/* CreatedAt */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Created At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("createdAt")}
                  />
                </Grid>

                {/* Updated At */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Updated At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updatedAt")}
                  />
                </Grid>

                {/* CreatedBy */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Created By
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("createdBy.name.full")}
                  />
                </Grid>

                {/* updatedBy */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Updated By
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updatedBy.name.full")}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: "2rem" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  Save
                </Button>

                {/* <Button
                  variant="outlined"
                  sx={{ color: "#111", marginLeft: "1rem" }}
                  onClick={handleReset}
                >
                  Reset
                </Button> */}
              </div>
            </form>
          </DialogContent>
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default CompanyDetailsForm;
