import { createSlice } from "@reduxjs/toolkit";
import {
  addBiller,
  addOrder,
  addTransaction,
  deleteBiller,
  deleteOrder,
  deleteTransaction,
  getBillersList,
  getOrdersList,
  getTransactionsList,
  updateBiller,
  updateOrder,
  updateTransaction,
} from "./actionCreator";
import initialState from "./state";

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  extraReducers: (builder) => {
    //Orders Reducers
    //GET Order
    builder.addCase(getOrdersList.pending, (state) => {});
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      state.Transaction_Orders_Data.transactionOrderLoading = false;
      state.Transaction_Orders_Data.transactionOrderInfo =
        action?.payload?.data?.docs;
    
    });
    builder.addCase(getOrdersList.rejected, (state, action) => {
     
    });
    //Delete Order
    builder.addCase(deleteOrder.pending, (state) => {});
    builder.addCase(deleteOrder.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteOrder.rejected, (state, action) => {
  
    });
    //Add Order
    builder.addCase(addOrder.pending, (state) => {});
    builder.addCase(addOrder.fulfilled, (state, payload) => {
     
    });
    builder.addCase(addOrder.rejected, (state, action) => {
      
    });
    //Update Order
    builder.addCase(updateOrder.pending, (state) => {});
    builder.addCase(updateOrder.fulfilled, (state, payload) => {
      
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      
    });

    //Billers Reducers
    //GET Biller
    builder.addCase(getBillersList.pending, (state) => {});
    builder.addCase(getBillersList.fulfilled, (state, action) => {
      state.Transaction_Billers_Data.transactionBillerLoading = false;
      state.Transaction_Billers_Data.transactionBillerInfo =
        action?.payload?.data?.docs;
     
    });
    builder.addCase(getBillersList.rejected, (state, action) => {
    
    });
    //Delete Biller
    builder.addCase(deleteBiller.pending, (state) => {});
    builder.addCase(deleteBiller.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteBiller.rejected, (state, action) => {
      
    });
    //Add Biller
    builder.addCase(addBiller.pending, (state) => {});
    builder.addCase(addBiller.fulfilled, (state, payload) => {
    
    });
    builder.addCase(addBiller.rejected, (state, action) => {
    
    });
    //Update Biller
    builder.addCase(updateBiller.pending, (state) => {});
    builder.addCase(updateBiller.fulfilled, (state, payload) => {
     
    });
    builder.addCase(updateBiller.rejected, (state, action) => {
     
    });

    //Transactions Reducers
    //GET Transactions
    builder.addCase(getTransactionsList.pending, (state) => {});
    builder.addCase(getTransactionsList.fulfilled, (state, action) => {
      state.Transaction_Data.transactionsLoading = false;
      state.Transaction_Data.transactionsInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getTransactionsList.rejected, (state, action) => {
     
    });
    //Delete Transaction
    builder.addCase(deleteTransaction.pending, (state) => {});
    builder.addCase(deleteTransaction.fulfilled, (state, action) => {
    
    });
    builder.addCase(deleteTransaction.rejected, (state, action) => {
     
    });
    //Add Transaction
    builder.addCase(addTransaction.pending, (state) => {});
    builder.addCase(addTransaction.fulfilled, (state, payload) => {
     
    });
    builder.addCase(addTransaction.rejected, (state, action) => {
     
    });
    //Update Transaction
    builder.addCase(updateTransaction.pending, (state) => {});
    builder.addCase(updateTransaction.fulfilled, (state, payload) => {
   
    });
    builder.addCase(updateTransaction.rejected, (state, action) => {

    });
  },
});

export default transactionSlice.reducer;
