import { Delete } from "@mui/icons-material";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { additionalTraining } from "services/commonServices";
import { formatDate } from "util/Helper";
import moment from "moment";
import {
  delAdditional,
  getAdditionalTrainingList,
} from "../common/Redux/actionCreator";
import AdditionalTrainingDialogContent from "./AdditionalTrainingDialogContent";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

function AdditionalTrainings() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  // const { Additional_Training } = useSelector((state) => state.common);

  // const additonalTrainingData = Additional_Training?.additionalTrainingInfo;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = (ID) => {
    dispatch(delAdditional(ID));
  };

  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }

  function getCreatedAT(params) {
    var a
        const rowName = params?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    // var rowName = formatDate(params?.row?.createdAt);
    // // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    
    // return dateNOW;
  }

  function getUpdatedAT(params) {
    var a
        const rowName = params?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    // var rowName = formatDate(params?.row?.updatedAt);
    // // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    // return dateNOW;
  }

  const columns = [
    {
      field: "isDeleted",
      headerName: `Is Deleted`,
      renderCell: (cellValues) => {
        // const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isDeleted}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name;

        return <Link to={`${cellValues?.row?._id}`}>{rowName}</Link>;
      },
      valueGetter: (cellValues) => cellValues?.row?.name,
      flex: 1.5,
    },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",
      flex: 1.5,

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

        // return updated;
      },
      type: "date",
      valueGetter: getUpdatedAT,
      flex: 1.5,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
      flex: 1,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
      flex: 1,
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name}?`}
            handleConfirmation={() => {
              handleDelete(row?._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
      flex: 1,
    },
  ];

  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [notDeletedRecords, setNotDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await additionalTraining(checked);
      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await additionalTraining(checked);
      setNotDeletedRecords(response?.data?.docs);
    }
  };

  const { Additional_Training } = useSelector((state) => state.common);
  const additonalTrainingData = Additional_Training?.additionalTrainingInfo;

  useEffect(() => {
    dispatch(getAdditionalTrainingList());
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Add Additional Training"
        title1="Additional Training"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        dialogContent={
          <AdditionalTrainingDialogContent
            handleDialogClose={handleDialogClose}
          />
        }
      />
      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `AdditionalTrainings-${formatDate()}`,
            },
          },
        }}
        slots={{
          loadingOverlay: <CircularProgress disableShrink />,
        }}
        loading={additonalTrainingData.length > 0 ? false : true}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        rows={showDeltedRecords ? deletedRecords : additonalTrainingData}
        //isRowSelectable
        pagination
        initialState={{
          columns: {
            columnVisibilityModel: {
              Delete: false,
              isDeleted: false,
              createdAt: false,
              updatedAt: false,
              createdBy: false,
              updatedBy: false,
            },
          },
        }}
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
}
export default AdditionalTrainings;
