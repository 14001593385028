import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import Moment from 'moment';
import SelectDropDown from "./../../../examples/DropDown/index";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import adminPanelValidation, {
  AriFormValidation,
} from "../../../util/validations/adminPanelValidation";
import {
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Link
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import { getProfessionByIdService } from "shared/services";
import { setUsersDropdown, updateProfession } from "../Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import SelectDropDownNew from "examples/DropDownNew";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ProfessionForm = () => {
  const ID = window.location.href.split("profession")[1].split("/")[1];
  console.log("ID: ", ID);
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
    let response = await getProfessionByIdService(ID);
    
    setResponseData(response);
    return response[0];
  };

  console.log("getData", getData);


  let professionId = useParams();
 

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];

  //Icon Location
  const iconLocationOptions = [
    {
      key: "AWS_S3",
      value: "AWS_S3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];

  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: () => getData() });

  console.log("getValues", getValues);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const payload = {
      icon: {
        isSecured: data?.icon?.isSecured,
        location: data?.icon?.location,
        bucket: data?.icon?.bucket,
        cdnUrl: data?.icon?.cdnUrl,
        fileName: data?.icon?.fileName,
        mimeType: data?.icon?.mimeType,
        region: data?.icon?.region,
        secureUrl: data?.icon?.secureUrl,
        uri: data?.icon?.uri,
        url: data?.icon?.url,
      },
      profession: data.profession,
      name: data.name,
      isDeleted: data.isDeleted,
      description: data.description,
      // createdAt: data.createdAt,
      // updatedAt: data.updatedAt,
      // createdBy: data.createdBy,
      // updatedBy: data.updatedBy,
    };
    dispatch(updateProfession({ payload: payload, id: ID })).then(() => {
      navigate("/common/profession");
    });
  };
  const handleReset = () => {
    reset();
  };

  const formValues = getValues();
  console.log("formValues", formValues)

  const { UsersData } = useSelector((state) => state.common);
  console.log("userDaata:", UsersData)

  const userInfo = UsersData?.usersInfo;

  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });


  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setUsersDropdown());
  }, []);



  const[updateCreated,setUpdateCreated]=useState(false);
const[updateUpdated,setUpdateUpdated]=useState(false);
  useEffect(()=>{
    const created=getValues("createdAt");
    const updated=getValues("updatedAt");
   
    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }


    if(created!==undefined && created!=="")
    {
     if(!updateCreated)
     {
       // create=Moment(created).format("DD/MM/YYYY")
        setValue("createdAt",Moment(created).format("DD/MM/YYYY"));
       setUpdateCreated(true);
     }
    }
  
    if(updated!==undefined && updated!=="")
    {
     if(!updateUpdated)
     {
       setValue("updatedAt",Moment(created).format("DD/MM/YYYY"));
       setUpdateUpdated(true);
     }
    }
  },[getValues("createdAt"),getValues("updatedAt")])

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
          <Grid item xs={12}>
         
            <div style={{ display: "flex",width:"70px" }}>
            <Link onClick={handleBack}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
               <ArrowBackIos fontSize="small" />
              
                 
                  back
               
              </div>
              </Link>
            </div>
           
          </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Id */}
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name")}
                      // value="HOSPITAL"
                    />
                  </Grid>
                  {/* ID */}
                  <Grid item xs={12}>
                    <Typography variant="body">ID:{ID}</Typography>
                  </Grid>

                  {/* Description */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("description")}
                    />
                  </Grid>

                  {/* Icon Location */}

                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Icon Location</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <SelectDropDownNew
                      options={iconLocationOptions}
                      control={control}
                      name="icon.location"
                      getValues={getValues}
                      defaultValue="none"
                      placeholder="Select"
                      customOnChange={(e) => {
                        setValue("icon.location", e.target.value);
                      }}
                    />
                  </Grid>

                  {/* icon File Name */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon File Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.fileName")}
                    />
                  </Grid>

                  {/* Icon URI */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Uri
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.uri")}
                    />
                  </Grid>

                  {/* Icon Bucket */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Bucket
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.bucket")}
                    />
                  </Grid>

                  {/* ICON REGION */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Region
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.region")}
                    />
                  </Grid>

                  {/* iCON mIME tYPE */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Mime Type
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.mimeType")}
                    />
                  </Grid>

                  {/* Icon Secured Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Secure Url
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.secureUrl")}
                    />
                  </Grid>
                  {/* Icon Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Url
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.url")}
                    />
                  </Grid>

                  {/* Checkbox */}
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Controller
                            defaultValue={false}
                            name="icon.isSecured"
                            control={control}
                            render={({ field: props }) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                {...register("icon.isSecured")}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label="Icon Is Secured"
                      />
                    </FormGroup>
                  </Grid>
                  {/* Icon Cdn Url */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Icon Cdn Url
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("icon.cdnUrl")}
                    />
                  </Grid>
                  {/* CreatedAt */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                    disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>

                  {/* Updated At */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput 
                    disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>

                  {/* CreatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                  <MDInput
                  disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdBy.name.full")}
                    />
                  </Grid>

                  {/* updatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                  <MDInput
                  disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedBy.name.full")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Save
                  </Button>

                  {/* <Button
                    variant="outlined"P
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default ProfessionForm;
