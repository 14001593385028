import { IconButton, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { get, useForm } from "react-hook-form";
import MDButton from "../../../components/MDButton/index";
import SelectDropDown from "../../../examples/DropDown/index";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { createRate, HospitalBranchList } from "../Redux/actionCreator";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import { setSpecialisationsType } from "layouts/events/Redux/actionCreator";
import { setQualificationType } from "layouts/events/Redux/actionCreator";
import { setExperience } from "layouts/events/Redux/actionCreator";
import { setDepartmentType } from "layouts/events/Redux/actionCreator";
import { toast } from "react-toastify";
import { getDepartmentsList } from "../Redux/actionCreator";
import { ratesValidation } from "util/validations/hospitalPanelValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import SelectDropDownNew from "examples/DropDownNew";
import { FormHelperText } from "@material-ui/core";

const CreateRateDialog = ({ handleDialogClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ratesValidation),
  });

  //Fetching Drop Downs Data from Events Redux Rest will be created in Hospital Redux
  const {
    Experience_Data,
    Profession_Type_Data,
    Qualification_Type_Data,
    Specialization_Type_Data,
    Department_Type_Data,
  } = useSelector((state) => state.events);

  //Branch Dropown Data
  const { hospital_department_Data } = useSelector((state) => state.hospital);
  const departmentData = hospital_department_Data?.departmentInfo;

  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  const experienceData = Experience_Data?.experienceInfo;
  const professionData = Profession_Type_Data?.professionInfo;
  const qualificationsData = Qualification_Type_Data?.qualificationInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;

  const branchData = hospital_branch_Data?.branchInfo;

  const experienceOptions = [];
  const professionOptions = [];
  const qualificationOptions = [];
  const specializationOptions = [];
  const departmentOptions = [];
  const branchOptions = [];

  departmentData.map((ele) => {
    let obj = {
      key: ele?._id,
      value: ele?.name,
    };

    departmentOptions.push(obj);
  });

  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  qualificationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    qualificationOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  const selectOptions = [
    {
      key: "1",
      value: "1",
    },
  ];


  const dispatch = useDispatch();

  const onSubmit = (data) => {
   
      let payload = {
        minRate: data?.minRate,
        marginHospital: data?.marginHospital,
        marginCandidate: data?.marginCandidate,
        profession: professionpayloadID,
        qualification: qualificationPayloadID,
        branch: branchPayloadID,
        department: departmentPayloadID,
        specialization: specializationPayloadID,
        experience: experiencePayloadID,
      };
      if(departmentPayloadID)
      {
        dispatch(createRate(payload));
        handleDialogClose();
      }
     
 
  };

  //IDS
  const[showDprt,setShowDprt]=useState(false);
  const [professionpayloadID, setProfessionID] = useState("");
  const [qualificationPayloadID, setQualificationID] = useState("");
  const [branchPayloadID, setBranchID] = useState("");
  const [specializationPayloadID, setSpecializationID] = useState("");
  const [departmentPayloadID, setDepartmentID] = useState("");
  const [experiencePayloadID, setExperienceID] = useState("");
  const [count, setCount] = useState(0);

  //Handler Functions
  const handleProfessionChange = (e) => {
    if(errors?.profession?.message)
    errors.profession.message="";
    setValue("profession", e.target.value);
    let professionID;

    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        professionID = obj.key;
      }
    });

    setProfessionID(professionID);
  };

  const handleQualificationChange = (e) => {
    if(errors?.qualification?.message)
    errors.qualification.message="";
    setValue("qualification", e.target.value);
    let qualificationID;

    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        qualificationID = obj.key;
      }
    });

    setQualificationID(qualificationID);
  };

  const handleBranchChange = (e) => {
    if(errors?.branch?.message)
    errors.branch.message="";
    setValue("branch", e.target.value);
    setValue("department", "none");
    setShowDprt(true);
    let branchID;

    branchOptions.map((obj) => {
      if (obj.value == e.target.value) {
        branchID = obj.key;
      }
    });

    setCount(1);

    setBranchID(branchID);
  };

  const handleDepartmentChange = (e) => {
    if(errors?.department?.message)
    errors.department.message="";
    setValue("department", e.target.value);
    let departmentID;

    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        departmentID = obj.key;
      }
    });

    setDepartmentID(departmentID);
  };

  useEffect(() => {
    if (count == 1) {
      dispatch(getDepartmentsList([branchPayloadID]));
    }
  }, [branchPayloadID]);

  const handleSpecializationChange = (e) => {
    if(errors?.specialization?.message)
    errors.specialization.message="";
    setValue("specialization", e.target.value);
    let specializationID;

    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        specializationID = obj.key;
      }
    });

    setSpecializationID(specializationID);
  };

  const handleExperienceChange = (e) => {
    if(errors?.experience?.message)
    errors.experience.message="";
    setValue("experience", e.target.value);
    let experienceID;
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        experienceID = obj.key;
      }
    });
    setExperienceID(experienceID);
  };

  //Dispatching DropDown APIS
  useEffect(() => {
    dispatch(HospitalBranchList([true]));
    dispatch(setProfessionType());
    dispatch(setSpecialisationsType());
    dispatch(setQualificationType());
    dispatch(setExperience());
  }, [dispatch]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel fullWidth>Profession</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={professionOptions}
              control={control}
              name="profession"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleProfessionChange}
            />
            <FormHelperText>{errors?.profession?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Qualification</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              control={control}
              name="qualification"
              defaultValue="none"
              getValues={getValues}
              options={qualificationOptions}
              customOnChange={handleQualificationChange}
            />
             <FormHelperText>{errors?.qualification?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Branch</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              control={control}
              name="branch"
              defaultValue="none"
              getValues={getValues}
              options={branchOptions}
              customOnChange={handleBranchChange}
            />
             <FormHelperText>{errors?.branch?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Department</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
            disabled={showDprt?false:true}
              control={control}
              name="department"
              defaultValue="none"
              getValues={getValues}
              options={departmentOptions ? departmentOptions : []}
              customOnChange={handleDepartmentChange}
            />
             <FormHelperText>{errors?.department?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Specialization</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={specializationOptions}
              control={control}
              name="specialization"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleSpecializationChange}
            />
             <FormHelperText>{errors?.specialization?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Experience</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={experienceOptions}
              control={control}
              name="experience"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleExperienceChange}
            />
             <FormHelperText>{errors?.experience?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Min Rate</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              fullWidth
              {...register("minRate")}
              error={errors?.minRate?.message}
              helperText={errors?.minRate?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Margin Hospital</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              fullWidth
              {...register("marginHospital")}
              error={errors?.marginHospital?.message}
              helperText={errors?.marginHospital?.message}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Margin Candidate</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              fullWidth
              {...register("marginCandidate")}
              error={errors?.marginCandidate?.message}
              helperText={errors?.marginCandidate?.message}
            />
          </Grid>
          <MDButton
            variant="contained"
            color="info"
            type="submit"
            sx={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            Create
          </MDButton>
        </Grid>
      </form>
    </div>
  );
};

export default CreateRateDialog;