import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useFieldArray, useForm } from "react-hook-form";

// import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import { Edit } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Link, Box } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  getDepartmentType,
  getQualificationsList,
  getExperienceList,
  getSpecializationsList,
  getProfessionsList,
} from "../../../layouts/common/Redux/actionCreator";
import { setPhoto } from "../../../layouts/events/Redux/actionCreator";
// import MDButton from "./../../components/MDButton/index";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigationType } from "react-router-dom";
// import { getPermanentJobsByIdService } from "../../../services/permanentServices";
import { getPermanentApplicationByIdService } from "../../../services/permanentServices";
import { getDocVerificationByID } from "../../../services/commonServices";
import { getMonetizationByIdService } from "../../../services/userServices";
// import { updatePermanentJobs } from "../Redux/actionCreator";
import { updateDocVerificationList } from "../Redux/actionCreator";
import { UpdateMonetizationRule } from "../Redux/actionCreator";

import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import AttachFileIcon from "@mui/icons-material/AttachFile";

function UpdateMonetizationById() {
  const navigation = useNavigationType();
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const [responseData, setResponseData] = useState([]);

  // const selectValueOptions = [
  //   {
  //     key: "Percentage",
  //     value: "Percentage",
  //   },
  //   {
  //     key: "Value",
  //     value: "Value",
  //   },
  // ];  
  const selectValueOptions = [
    {
      key: "AbsoluteByPercentage",
      value: "AbsoluteByPercentage",
    },
    {
      key: "AbsoluteByValue",
      value: "AbsoluteByValue",
    },
  ];  

  // const code = window.location.href.split("code")[1].split("/")[1];
  // const ID = window.location.href.split("code")[1].split("/")[2];
  const ID = window.location.href
    .split("users/monetization")[1]
    .split("/")[1];
    // console.log("ID", ID);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getData = async () => {
    // let response = await getJobJobsByIdService(ID);
    let response = await getMonetizationByIdService(ID);
    // console.log("426--->", response);

    setResponseData(response[0]);

    return response[0];
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: async () => await getData(),
  });

  const [updated, setUpdated] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

//   console.log("responseData", responseData);
  // const [value, setValue] = useState(null);
  const onSubmit = (data) => {
    // console.log("onSubmit", data);

    try {
      const payload = {
        valueType: data.valueType,
        value: data.value
      };
        // console.log("payload", payload);

      dispatch(UpdateMonetizationRule({ payload: payload, id: ID })).then(
        () => {
          navigate("/users/monetizationmlp");
        }
      );
    } catch (error) {
      console.error("Error during form submission:", error);
      // Handle the error appropriately
    }
  };
  

  const handleBack = () => {
    navigate(-1);
  };

  const formValues = getValues();
  //   console.log("1074-->", formValues);
  // console.log("1-->",getValues("createdAt"))

  const formattedCreatedAt = getValues("createdAt")
    ? dayjs(getValues("createdAt"))?.format("YYYY/MM/DD")
    : null;

  const formattedUpdatedAt = getValues("updatedAt")
    ? dayjs(getValues("updatedAt"))?.format("YYYY/MM/DD")
    : null;

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // console.log("responseData", responseData)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>

                      {/* Verification status */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Country
                        </Typography> 
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "1rem" }}>
                      <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Name"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.country}
                        />
                      </Grid>

                      {/* City */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          City
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="City"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.city?.name}
                        />
                      </Grid>

                      {/* role */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Role
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="role"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.role?.name}
                        />
                      </Grid>


                      {/* Department */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Department
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="department"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.department?.name}
                        />
                      </Grid>

                      {/* Specialization */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Specialization
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="specialization"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.specialization?.name}
                        />
                      </Grid>

                      {/* numExperience */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Experience
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="numExperience"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.numExperience?.name}
                        />
                      </Grid>


                      {/* valueType */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Value Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ marginTop: "2rem" }}>
                      <SelectDropDownNew
                          options={selectValueOptions}
                          placeholder="Value Type"
                          control={control}
                          name="valueType"
                          //   defaultValue="APPLIED"
                          getValues={getValues}
                          customOnChange={(e) =>
                            setValue("valueType", e.target.value)
                          }
                          />
                        {/* <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="valueType"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("valueType")}
                          // value={responseData?.valueType}
                        /> */}
                      </Grid>

                       {/* Value */}
                       <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Value
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="value"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("value")}
                          // value={responseData?.value}
                        />
                      </Grid>

                     

                      {/* created and updated timestamps */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Created At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedCreatedAt}
                          {...register("createdAt")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Updated At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedUpdatedAt}
                          {...register("updatedAt")}
                        />
                      </Grid>

                      <Grid xs={12} md={12}>
                        <Divider sx={{ width: "100%" }} />
                      </Grid>

                      <Grid item xs={12}>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default UpdateMonetizationById;
