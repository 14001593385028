import { Delete, PhotoCamera, Upload } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getRates } from "services/hospitalServices";
import CreateRateDialog from "./components/CreateRateDialog";
import { deleteRate, getRatesList } from "./Redux/actionCreator";
import moment from "moment";
import { formatDate } from "util/Helper";
import MDButton from "components/MDButton";
import { uploadHospitalRateFile } from "services/hospitalServices";
import UploadDialog from "./UploadDialog";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
const HospitalRates = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  //Handler Functions
  const handleDelete = (ID) => {
    dispatch(deleteRate(ID));
  };

  const handleCellClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleRowClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  //Value Getters

  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getBranchName(params) {
    return `${params?.row?.branch?.name || "NA"}`;
  }

  function getDepartmentName(params) {
    return `${params?.row?.department?.name || "NA"}`;
  }

  function getSpecializationName(params) {
    return `${params?.row?.specialization?.name || "NA"}`;
  }

  function getProfessionName(params) {
    return `${params?.row?.profession?.name || "NA"}`;
  }

  function getQualificationName(params) {
    return `${params?.row?.qualification?.name || "NA"}`;
  }

  function getExperienceName(params) {
    return `${params?.row?.experience?.name || "NA"}`;
  }

  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }
  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }

  // function getCreatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dateNOW = new Date(rowName);
  //   return dateNOW;
  // }

  // function getUpdatedAT(params) {
  //   var rowName = formatDate(params?.row?.createdAt);
  //   var dateNOW = new Date(rowName);
  //   return dateNOW;
  // }

  function getCreatedAT(params) {
    var a
        const rowName = params?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;

    // return formattedDate;
  }

  function getUpdatedAT(params) {
    var a
        const rowName = params?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dateNOW = new Date(rowName);
    // var day = dateNOW.getDate();
    // var month = dateNOW.getMonth() + 1;
    // var year = dateNOW.getFullYear();
  
    // // var formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}`;
    // return formattedDate;
  }

  //Rates Columns

  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowID = cellValues?.row?._id;
        return <Link to={`${rowID}`}>{rowID}</Link>;
      },
      valueGetter: getID,
    },

    {
      field: "branch",
      headerName: `Branch`,
      renderCell: (cellValues) => {
        const branchName = cellValues?.row?.branch?.name;
        return branchName;
      },
      valueGetter: getBranchName,
    },
    {
      field: "department",
      headerName: `Department`,
      renderCell: (cellValues) => {
        const departmentName = cellValues?.row?.department?.name;
        return departmentName;
      },
      valueGetter: getDepartmentName,
      flex: 1,
    },
    {
      field: "specialization",
      headerName: `Specialization`,
      renderCell: (cellValues) => {
        const specializationName = cellValues?.row?.specialization?.name;
        return specializationName;
      },
      valueGetter: getSpecializationName,
      flex: 1,
    },
    {
      field: "profession",
      headerName: `Profession`,
      renderCell: (cellValues) => {
        const professionName = cellValues?.row?.profession?.name;
        return professionName;
      },

      valueGetter: getProfessionName,
      flex: 1,
    },
    {
      field: "qualification",
      headerName: `Qualification`,
      renderCell: (cellValues) => {
        const qualificationName = cellValues?.row?.qualification?.name;
        return qualificationName;
      },
      valueGetter: getQualificationName,
    },
    {
      field: "experience",
      headerName: `Experience`,
      renderCell: (cellValues) => {
        const experienceNAme = cellValues?.row?.experience?.name;
        return experienceNAme;
      },
      valueGetter: getExperienceName,
    },
    {
      field: "minRate",
      headerName: `Min Rate`,
    },

    {
      field: "marginCandidate",
      headerName: `Margin Candidate`,
    },
    {
      field: "marginHospital",
      headerName: `Margin Hospital`,
    },
    // {
    //   field: "createdAt",
    //   headerName: `Created At`,
    //   type: "date",

    //   valueGetter: getCreatedAT,
    // },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        // console.log("cellValues", cellValues)
        var a
        const rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.createdAt);
        // var dateNOW = new Date(rowName);
        // var day = dateNOW.getDate();
        // var month = dateNOW.getMonth() + 1;
        // var year = dateNOW.getFullYear();
  
        // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day} `;

        // return formattedDate;
      },
      type: "date",

      valueGetter: getCreatedAT,
    },
    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    // },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.updatedAt);
        // var dateNOW = new Date(rowName);
        // var day = dateNOW.getDate();
        // var month = dateNOW.getMonth() + 1;
        // var year = dateNOW.getFullYear();
  
        // var formattedDate = `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day} `;

        // return formattedDate;
      },
      type: "date",

      valueGetter: getUpdatedAT,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,

      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?._id}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
    },
  ];

  const [isOpen, setisOpen] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getRatesList());
  }, []);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const { hospital_rates_Data } = useSelector((state) => state?.hospital);
  var rates = hospital_rates_Data?.ratesInfo;

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getRates({
        criteria: {
          isDeleted: checked,
        },
      });

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getRates({
        criteria: {
          isDeleted: checked,
        },
      });
      rates = response?.data?.docs;
    }
  };

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
} 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Create Rate"
        title1="Rate"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        //Upload Props
        handleUploadDialogClose={uploadDialogClose}
        handleUploadDialogOpen={uploadDialogOpen}
        isOpenUpload={isOpenUpload}
        showUpload={true}
        dialogContent={
          <CreateRateDialog handleDialogClose={handleDialogClose} />
        }
        uploadDialogContent={
          <UploadDialog
            handleUploadDialogClose={uploadDialogClose}
            title="Rate"
          />
        }
      />

      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          density="compact"
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                fileName: `Rates-${formatDate()}`,
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                marginCandidate: false,
                marginHospital: false,
                createdBy: false,
                updatedBy: false,
                Delete: false,
              },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          loading={rates.length > 0 ? false : true}
          columns={columns}
          rows={showDeltedRecords ? deletedRecords : [...rates].reverse()}
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default HospitalRates;
