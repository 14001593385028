import * as yup from "yup";

const userDetailsValidationSchema = yup.object().shape({
  HospitalName: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Name"),
  Profession: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Profession"),
  Qualification: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Qualification"),
  Department: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Department"),
  Specialization: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Specialization"),
  Experience: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Experience"),

  //   Job Details
  shiftDays: yup.string().matches(/^[0-9\s]+$/, "Invalid Days"),
  ProfessionalsNumber: yup.string().matches(/^[0-9\s]+$/, "Invalid Proffesionals"),
  RatedOffer: yup.string().matches(/^[0-9\s]+$/, "Invalid Offer"),

  //   Personal Details
  Name: yup.string().matches(/^[aA-zZ\s]+$/, "Invalid Name"),
  email: yup.string(),
  mobile: yup.string().matches(/^[0-9\s]+$/, "Invalid Phone"),
  totalCost: yup.string().matches(/^[0-9\s]+$/, "Invalid Amount"),
  convinienceFees: yup.string().matches(/^[0-9\s]+$/, "Invalid Amount"),
  taxes: yup.string().matches(/^[0-9\s]+$/, "Invalid Amount"),
  totalAmount: yup.string().matches(/^[0-9\s]+$/, "Invalid Amount"),
});

export default userDetailsValidationSchema;
