import React, { useEffect } from "react";
import MDButton from "../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import SelectDropDown from "../../../examples/DropDown/index";
import Checkbox from "@mui/material/Checkbox";
import { useForm, Controller } from "react-hook-form";
import MDInput from "components/MDInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { getCompanyList } from "layouts/hospital/Redux/actionCreator";
import * as yup from "yup";
import hospitalPanelValidation, {
  hospitaBranchDialogValidation,
} from "../../../util/validations/hospitalPanelValidation";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { createhospitalBranch } from "layouts/hospital/Redux/actionCreator";
import { toast } from "react-toastify";
import SelectDropDownNew from "examples/DropDownNew";
import { FormHelperText } from "@material-ui/core";
const label = { inputProps: { "aria-label": "Checkbox demo" } };





const AddHospitalBranchDialogContent = ({ handleDialogClose }) => {
  const [companyID, setCompanyID] = useState("");
  const[company,setCompany]=useState();
  const[corporate,setCorprate]=useState();
  const[reverseCharges,setReverseCharges]=useState();
  const { hospital_company_Data } = useSelector((state) => state.hospital);
  const companyOptions = [];

const companyData = hospital_company_Data?.companyInfo;
companyData.map((ele) => {
  let obj = {
    key: ele._id,
    value: ele.name,
  };

  companyOptions.push(obj);
});

const handleCompanyChange = (e) => {
  if(errors?.corporate?.message)
  errors.corporate.message="";
  setValue("corporate", e.target.value);
  let userID;
  //Searching for the name in the Users array to find the ID
  companyOptions.map((obj) => {
    if (obj.value == e.target.value) {
      userID = obj.key;
      
      setValue("corporate", userID);
    }
  });

  setCompanyID(userID);
  setCorprate(userID);
};
  useEffect(()=>{
    dispatch(getCompanyList());
    dispatch(setOrganizationsDropdown());
  
  },[]);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(hospitaBranchDialogValidation),
  });

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];

  const ReverseOptions = [
    {
      key: "YES",
      value: "YES",
    },
    {
      key: "NO",
      value: "NO",
    },
  ];
  const [organizationID, setOrganizationID] = useState("");
  const { OrganizationsData } = useSelector((state) => state.common);

  const organizationInfo = OrganizationsData?.organizationsInfo;

  const organizationOptions = [];
  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });
  const handleOrganizationChange = (e) => {
    if(errors?.company?.message)
    errors.company.message="";
    setValue("company", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setOrganizationID(userID);
    setCompany(userID);
  };
  const dispatch = useDispatch();
  const onSubmit = (data) => {
 
    const payload = {
      jobLocation: {
        branchName: data?.jobLocationBranchName,
        hospitalName: data?.jobLocationHospitalName,
      },
       organization: organizationID,
      company:companyID ,
      // corporate: data?.company,
        reverseChargesApplicable:data?.reverseCharges,
    };
  
      dispatch(createhospitalBranch(payload));
      handleDialogClose();

   
   
   
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {/* company */}

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Company
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
          <SelectDropDownNew
                              options={organizationOptions}
                              getValues={getValues}
                              placeholder="Organization"
                              control={control}
                              name="company"
                              defaultValue="none"
                              customOnChange={handleOrganizationChange}
                            />
                            <FormHelperText>{errors?.company?.message}</FormHelperText>
          </Grid>

          {/* corporate */}

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Corporate
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
          <SelectDropDownNew
                          options={companyOptions}
                          getValues={getValues}
                          placeholder="Corporate"
                          control={control}
                          name="corporate"
                          defaultValue="none"
                           customOnChange={handleCompanyChange}
                        />
                         <FormHelperText>{errors?.corporate?.message}</FormHelperText>
          </Grid>

          {/* job location hospital name */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Job Location Hospital Name
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("jobLocationHospitalName")}
              error={errors?.jobLocationHospitalName?.message}
              helperText={errors?.jobLocationHospitalName?.message}
            />
          </Grid>

          {/* job location branch name */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Job Location Branch Name
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("jobLocationBranchName")}
              error={errors?.jobLocationBranchName?.message}
              helperText={errors?.jobLocationBranchName?.message}
            />
          </Grid>

          {/* reverse charges application */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Reverse Charges Application
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
        
            <SelectDropDownNew
            control={control}
            name='reverseCharges'
          
          getValues={getValues}
              options={ReverseOptions}
              placeholder="Select"
              customOnChange={(e) =>{
                if(errors?.reverseCharges?.message)
                errors.reverseCharges.message="";
                setReverseCharges(e.target.value);
                setValue("reverseCharges", e.target.value)
              }
               
              }
            />
             <FormHelperText>{errors?.reverseCharges?.message}</FormHelperText>
         
           
          </Grid>
        </Grid>
        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "2rem" }}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default AddHospitalBranchDialogContent;