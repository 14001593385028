import MDInput from "components/MDInput";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useForm } from 'react-hook-form';
import MDButton from "components/MDButton";
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { ka } from "date-fns/locale";
import SelectDropDownNew from "examples/DropDownNew";

const locationOptions = [
    { key: "AWS_S3", value: "AWS_S3" },
    { key: "LOCAL", value: "LOCAL" },
    { key: "SELF_HOSTED", value: "SELF_HOSTED" },
    { key: "CLOUDINARY", value: "CLOUDINARY" }
]



export default ({ nestIndex, control, register, setValue,getValues }) => {
    
    const { fields: employmentField, remove: employmentRemove, append: employmentAppend } = useFieldArray({
        control,
        name: `profile.jobHistory.[${nestIndex}].employmentLetter.files`,
    });



    return (
        <ul style={{ listStyleType: "none" }}>
            {employmentField.map((item, k) => {
                return (
                    <li>
                        <Grid container item={12}>

                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Location
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <SelectDropDownNew
                                 setValue={setValue}
                                    
                                    options={locationOptions}
                                    getValues={getValues}
                                    control={control}
                                   
                                    name={`profile.jobHistory.[${nestIndex}].employmentLetter.files.[${k}].location`}
                                    customOnChange={(e) => {
                                        locationOptions.map((elem) => {
                                            if (elem.value == e.target.value) {

                                                setValue(
                                                    `profile.jobHistory.[${nestIndex}].employmentLetter.files.[${k}].location`,
                                                    elem.key
                                                );
                                            }
                                        });
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    File Name
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].fileName`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Uri
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].uri`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Bucket
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].bucket`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>


                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Region
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].region`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>


                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Mime Type
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].mimeType`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>


                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Secure Url
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].secureUrl`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>


                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Url
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].url`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name={`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].isSecured`}
                                                control={control}
                                                render={({ field: props }) => (
                                                    <Checkbox
                                                        {...props}
                                                        checked={props.value}
                                                        {...register(
                                                            `profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].isSecured`
                                                        )}
                                                        onChange={(e) =>
                                                            props.onChange(e.target.checked)
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Is Secured"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Cdn Url
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`profile.jobHistory.[${nestIndex}].employmentLetter.files[${k}].cdnUrl`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>


                            <MDBox
                                sx={{
                                    textAlign: "right",
                                    background: "transparent",
                                    width: "100%",
                                }}
                            >
                                <MDButton
                                    variant="outlined"
                                    sx={{ border: "none", height: "10px", width: "30px" }}
                                    onMouseHover={{ color: "black" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        employmentRemove(k);
                                    }}
                                >
                                    <Typography
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                        onHover={{ color: "black" }}
                                    >
                                        Remove
                                    </Typography>
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </li>
                );
            })}

            <MDButton
                variant="contained"
                color="info"
                type="button"
                onClick={() =>
                    employmentAppend({})
                }
            >
                +Add
            </MDButton>
        </ul>
    );
};
