import { useForm, Controller } from "react-hook-form";
import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import { adminUserFormValidation } from "../../../util/validations/adminPanelValidation";
import SelectDropDown from "./../../../examples/DropDown/index";
import {
  DialogContent,
  Typography,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Link,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateAdminUSer } from "../Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getAdminUserByIdService } from "services/adminServices";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ArrowBackIos } from "@mui/icons-material";
import Moment from 'moment';
import { setRoleType } from "layouts/events/Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AdminUserForm = () => {
  const params = useParams();
  const [passwordButton, setPasswordButton] = useState(true);

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];
 const ID=params.userName;
  //const ID = window.location.href.split("admin")[1].split("/")[1];
 // const ID = window.location.href.split("admin")[2].split("/")[1];

const roleOptions=[
  {
    key:"Company",
    value:"Company"
  }
 ]

  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
   
    let response = await getAdminUserByIdService(ID);
   
    setResponseData(response);
 
    return response[0];
  };

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: () => getData(),
    resolver: yupResolver(adminUserFormValidation),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {

    let payload = {
      name: {
        first: data?.name?.first,
        last: data?.name?.last,
      },

      isDeleted: data?.isDeleted,
      role: data?.role,
      password: data?.password,
      email: data?.email,
      isAdmin: data?.isAdmin,
      // createdAt: data?.createdAt,
      // updatedAt: data?.updatedAt,
    };

    
    dispatch(updateAdminUSer({ payload: payload, id: ID })).then(() => {
      navigate("/dashboards/admin");
    });
  };
  const handleReset = () => {
    reset();
  };

  const formValues = getValues();


  const handleBack = () => {

    navigate(-1);
  };
  const [created1, setCreated1] = useState(false);
  const [updated1, setUpdated1] = useState(false);
  const[passwordField,setpasswordField]=useState(false);
  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");
    const password=getValues("password");
    
    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }


    if (created != undefined && created != "") {
      if (!created1) {
        
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
      }
      setCreated1(true);
    }

    if (updated !== undefined && updated != "") {
      if (!updated1) {
        setValue("updatedAt", Moment(updated).format("DD/MM/YYYY"));
      }
      setUpdated1(true);
    }
    if(password!=undefined)
    {
      if(!passwordField)
      {
        setValue("password","");
      }
      setpasswordField(true)
    }
  }, [getValues("createdAt"), getValues("updatedAt"),getValues("password")]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox m={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex" ,width:"70px"}}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
              
            </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Name Title */}
                  <Grid item xs={12}>
                   

                      <Typography variant="h4">{params.userName}</Typography>
                   
                  </Grid>

                  {/* Name */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Name
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <MDInput
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder="First Name"
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name.first")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder="Last Name"
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name.last")}
                    />
                  </Grid>

                  {/* Email */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder="Email"
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("email")}
                      error={errors?.email?.message}
                      helperText={errors?.email?.message}
                    />
                  </Grid>

                  {/* Password */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Password
                    </Typography>
                  </Grid>
                  {passwordButton ? (
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        sx={{ color: "#fff" }}
                        onClick={() => setPasswordButton(false)}
                      >
                        {" "}
                        Change Password
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={8}>
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <MDInput
                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            placeholder="New Password"
                            sx={{ width: "100%", marginTop: "0.4rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            {...register("password")}
                            error={errors?.password?.message}
                            helperText={errors?.password?.message}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <MDInput
                          onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            placeholder="Confirm New Password"
                            sx={{ width: "100%", marginTop: "0.4rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            {...register("confirmPassword")}
                            error={errors?.confirmPassword?.message}
                            helperText={errors?.confirmPassword?.message}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="contained"
                            sx={{ color: "#fff", marginTop: "0.4rem" }}
                            onClick={() => setPasswordButton(true)}
                          >
                            {" "}
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {/* Permission title */}
                  <Grid item xs={12}>
                    <Typography variant="h4">Permissions</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Role</Typography>
                  </Grid>
                  <Grid item xs={8}>
                  <SelectDropDownNew
                control={control}
              name="role"
             
              defaultValue="none"
              options={roleOptions}
              getValues={getValues}
              placeholder="Select"
              customOnChange={(e)=>{
                setValue("role",e.target.value)
              }}

            />         
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Controller
                            defaultValue={false}
                            name="isAdmin"
                            control={control}
                            render={({ field: props }) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                {...register("isAdmin")}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label="Can Login to Admin?"
                      />
                    </FormGroup>
                  </Grid>
                  {/* Meta */}
                  <Grid item xs={12}>
                    <Typography variant="h4">Meta</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    disabled
                      placeholder="Created At"
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    disabled
                      placeholder="Updated At"
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Save
                  </Button>

                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default AdminUserForm;