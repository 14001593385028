const state = {
  loading: false,
  error: "",
  Users_Data: {
    Users_DataLoading: true,
    Users_DataError: "",
    Users_DataInfo: [],
    total: 0,
  },
  Users_Filtered_Data: {
    Users_Filtered_DataLoading: true,
    Users_Filtered_DataError: "",
    Users_Filtered_DataInfo: [],
  },

  Users_Searched_Data: {
    Users_Searched_DataLoading: true,
    Users_Searched_DataError: "",
    Users_Searched_DataInfo: [],
  },

  User_profile_image_Data: {
    User_profile_image_loading: true,
    User_profile_image_info: [],
    User_profile_image_error: "",
  },

  Premium_Users_Data: {
    Premium_Users_DataLoading: true,
    Premium_Users_DataError: "",
    Premium_Users_DataInfo: [],
    total: 0,
  },

  Premium_Filtered_Users_Data: {
    Premium_Filtered_Users_DataLoading: true,
    Premium_Filtered_Users_DataError: "",
    Premium_Filtered_Users_DataInfo: [],
  },

  Monetization_MLP: {
    Monetization_MLP_DataLoading: true,
    Monetization_MLP_DataError: "",
    Monetization_MLP_DataInfo: [],
    total: 0,
  },
  Monetization_Discount: {
    Monetization_Discount_DataLoading: true,
    Monetization_Discount_DataError: "",
    Monetization_Discount_DataInfo: [],
    total: 0,
  },

  currentPage: 0,
  pageSize: 10,
  searchKey: "",
  role: "",
  workCity: "",
  specialization: "",
  organization: "",
  startDate: "",
  endDate: "",
  filterProfessionalType: "",
  filterPastPremium: false,
  filterPresentPremium: false,
  filterByStatus: null
};

export default state;
