import { Button, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import UploadFile from "react-material-file-upload";
import { useDispatch } from "react-redux";
import { uploadRateCard } from "../Redux/actionCreator"; 
import { uploadMonetizationCard } from "../Redux/actionCreator"; 

import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
import SampleFile from "../../../assets/Sample Files/Sample Rate Card.xlsx";
import MonetizationSampleFile from "../../../assets/Sample Files/Monetization File.xlsx";
const UploadMonetizationFile = ({ handleUploadDialogClose,title }) => {
  const [file, setFile] = useState(null);
  const dispatch=useDispatch();
  const[count,setCount]=useState(0);

  const handleSubmit = () => {
    if(title=="Monetization File")
    {
      if (file != null) {
        dispatch(uploadMonetizationCard(file[0]));
        handleUploadDialogClose();
      }
      else
      {
        if(count==0)
        {
           dispatch(uploadMonetizationCard());
           setCount(1);
        }
      
  
      }
    }
  
    
  };

  return (
    <MDBox p={2}>
      <Typography>
        Upload
        <span style={{ color: "red" }}> *</span>
      </Typography>
      <Grid container spacing={1}>
        {/* Rate Card UPLOADER */}
        <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
          <a href={title=="Monetization File" ? MonetizationSampleFile:null} download={`Sample ${title}`}>
            <Button>
              Download Sample File &nbsp;
              <Icon>download</Icon>
            </Button>
          </a>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Uploader */}
          <UploadFile
            value={file}
            accept=".xlsx,.csv"
            onChange={setFile}
            sx={{ color: "#111", margin: "2rem" }}
            title={`Upload ${title} or Drag a ${title}`}
            buttonText="Select File"
            buttonProps={{
              styles: {
                fontSize: "10rem",
              },
            }}
            typographyProps={{ fontSize: "1.5rem" }}
          />

          <MDButton variant="contained" color="info" onClick={handleSubmit}>
            Submit
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default UploadMonetizationFile;