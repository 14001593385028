import React, { useEffect } from "react";
import {  InputLabel, Typography } from "@mui/material";
import { FormHelperText } from "@material-ui/core";
import MDButton from "./../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import SelectDropDown from "./../../../examples/DropDown/index";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";
import MDInput from "components/MDInput";
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import adminPanelValidation, {
  adminUserDialogValidation,
} from "../../../util/validations/adminPanelValidation";
import { getValue } from "@mui/system";
import { addAdminUser } from "../Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { setRoleType } from "layouts/events/Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddAdminUserDialogContent = ({ handleDialogClose }) => {
  const[role,setRole]=useState();
  

 const roleOptions=[
  {
    key:"Company",
    value:"Company"
  }
 ]

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminUserDialogValidation),
    // mode: "onTouched",
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    let payload = {
      name: {
        first: data.name.first,
        last: data.name.last,
      },
      email: data.email,
      password: data.password,

      role: data.role,
    };
    dispatch(addAdminUser(payload));
    handleDialogClose();
  };

  useEffect(() => {
    dispatch(setRoleType());
  }, []);
  const handleRoleChange=(e)=>{
   
    if(errors?.role?.message)
    errors.role.message="";
   
    setValue("role", e.target.value);
    setRole(e.target.value);
  }
  
  
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name */}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>
          <Grid item xs={4}>
            <form noValidate>
              <MDInput
                placeholder="First name"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                error={errors?.adminUserName?.message}
                helperText={errors?.adminUserName?.message}
                {...register("name.first")}
              />
            </form>
          </Grid>
          <Grid item xs={4}>
            <form noValidate>
              <MDInput
                placeholder="Last name"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("name.last")}
              />
            </form>
          </Grid>
          {/* Email */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Email
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <form noValidate>
              <MDInput
                placeholder="Email"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("email")}
                error={errors?.email?.message}
                helperText={errors?.email?.message}
              />
            </form>
          </Grid>
          {/* Password */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Password
            </InputLabel>
          </Grid>
          <Grid item xs={4}>
            <form noValidate>
              <MDInput
                placeholder="Password"
                type="password"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("password")}
                error={errors?.password?.message}
                helperText={errors?.password?.message}
              />
            </form>
          </Grid>
          <Grid item xs={4}>
            <form noValidate>
              <MDInput
                placeholder="Confirm Password"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("confirmPassword")}
                type="password"
                error={errors?.confirmPassword?.message}
                helperText={errors?.confirmPassword?.message}
              />
            </form>
          </Grid>
          {/* Role  */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Role
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
           
          <SelectDropDownNew
              control={control}
              name="role"
             
              defaultValue="none"
              options={roleOptions}
              getValues={getValues}
              placeholder="Select"
              customOnChange={handleRoleChange}
             
            />
           {role?<></>:<FormHelperText>{errors?.role?.message}</FormHelperText>} 
              
              
              
           

         
          </Grid>

        
        </Grid>

        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default AddAdminUserDialogContent;