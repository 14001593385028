import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
} from "@mui/material";

const ReusableDialog = ({ title, subtitle, children, isOpen, handleClose }) => {
  return (
    <>
      <Dialog maxWidth="md" open={isOpen} onClose={handleClose} >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent  style={{width:"768px"}}>
          <DialogContentText>{subtitle}</DialogContentText>
          {/* <Divider /> */}
        
             {children}
          
          <DialogActions sx={{height:"0px"}}>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{ position: "relative", bottom: "1.8rem" }}
          >
            Cancel
          </Button>
        </DialogActions>
         
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReusableDialog;
