import axiosInstance from "../axios";

//***************User Services Services*******************//

// List User
export const getAvalibilityList = async (payload) => {
  // console.log(payload, "payload");
  try {
    //  let page = 1;
    //  let pageSize = 100;
    //  let skip = (page - 1) * pageSize;
    if (payload === undefined) payload = false;
    let criteria;

    if (payload) {
      criteria = {
        criteria: { isDeleted: false, jobId: payload.jobId, _id: payload._id },
        limit: payload[0],
        offset: payload[1],
        // limit: payload.pageSize,
        // offset: payload.currentPage,
      };
    } else {
      criteria = {
        criteria: { isDeleted: false },
        limit: payload[0],
        offset: payload[1],
      //   limit: payload.pageSize,
      // offset: payload.currentPage,
      };
    }
    const response = await axiosInstance.post(`/avalibility/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

export const getAvalibilityListId = async (id) => {
  
  try {
    
    let criteria;

    
      criteria = {
        criteria: { isDeleted: false, _id: id },
        limit: 0,
        offset: 1,
      };
    
    const response = await axiosInstance.post(`/avalibility/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// //Delete a User
// export const deleteUserService = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`/user/${id}`);
//     return response;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

// // Add User
// export const addNewUser = async (payload) => {
//   try {
//     const response = await axiosInstance.post(`/user`, payload);
//     return response;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

//Get User By Id

// export const getUserByIdService = async (payload) => {
//   try {
//     let criteria = { criteria: { _id: payload } };
//     const response = await axiosInstance.post(`/user/all`, criteria);
//     return response?.data?.data;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

//Update User
// export const updateUserService = async (payload) => {
//   try {
//     const response = await axiosInstance.patch(
//       `/user/${payload.id}`,
//       payload.payload
//     );

//     return response;
//   } catch (e) {
//     return { error: e.response.data };
//   }
// };
