import MDInput from "components/MDInput";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useForm } from 'react-hook-form';
import MDButton from "components/MDButton";
import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";

import SelectDropDownNew from "examples/DropDownNew";
import Options from "./Options";





export default ({ nestIndex, control, register, setValue,getValues }) => {
    
    const { fields: field, remove: remove, append: append } = useFieldArray({
        control,
        name: `testPapers[${nestIndex}].questions`,
    });



    return (
        <ul style={{ listStyleType: "none" }}>
            {field.map((item, k) => {
                return (
                    <li>
                        <Grid container item={12}>




                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Question
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`testPapers[${nestIndex}].questions[${k}].question`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>

                          
                            <Grid item xs={12}>
                            <Paper sx={{ padding: "1rem" }}>
                                        <Typography variant="h6">
                                          Options
                                        </Typography>
                                        <hr style={{ marginBottom: "2rem",marginTop:"1rem" }} />
                                    
                                      <Options
                                       setValue={setValue}
                                       getValues={getValues}
                                                       nestIndex={nestIndex}
                                                       nestedIndex={k}
                                                       {...{ control, register }}
                                      />
                                      </Paper>
                                      </Grid>


                            <MDBox
                                sx={{
                                    textAlign: "right",
                                    background: "transparent",
                                    width: "100%",
                                }}
                            >
                                <MDButton
                                    variant="outlined"
                                    sx={{ border: "none", height: "10px", width: "30px" }}
                                    onMouseHover={{ color: "black" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        remove(k);
                                    }}
                                >
                                    <Typography
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                        onHover={{ color: "black" }}
                                    >
                                        Remove
                                    </Typography>
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </li>
                );
            })}

            <MDButton
                variant="contained"
                color="info"
                type="button"
                onClick={() =>
                    append({})
                }
            >
                +Add
            </MDButton>
        </ul>
    );
};
