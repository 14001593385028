import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import React from "react";
import MDButton from "../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import MDInput from "../../../components/MDInput";
import { PRTDialogValidation } from "util/validations/commonValidations";
import { useDispatch } from "react-redux";
import {
  addProfession,
  addProfessionRegistrationType,
} from "../Redux/actionCreator";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const PRTDialog = ({ handleDialogClose }) => {
  // const validationSchema = yup.object().shape({
  //   roleName: yup
  //     .string()
  //     .required("Name is Required")
  //     .matches(/^[aA-zZ\s]+$/, "Invalid Name")
  // });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PRTDialogValidation),
  });
  const selectOptions = [
    {
      key: "Organization",
      value: "Organization",
    },
    {
      key: "SuperAdmin",
      value: "Super_Admin",
    },
    {
      key: "Applicant",
      value: "Applicant",
    },
  ];

  const dispatch = useDispatch();

  const onSubmit = (data) => {

    dispatch(addProfessionRegistrationType(data));
    handleDialogClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("name")}
              error={errors?.name?.message}
              helperText={errors?.name?.message}
            />
          </Grid>
        </Grid>

        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
        >
          create
        </MDButton>
        {/* <MDButton variant="text" color="info" onClick={handleCloseDialog}>
                cancel
              </MDButton> */}
      </form>
    </div>
  );
};

export default PRTDialog;
