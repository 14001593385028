const state = {
  loading: false,
  error: "",
  Transaction_Orders_Data: {
    transactionOrderLoading: true,
    transactionOrderError: "",
    transactionOrderInfo: [],
  },
  Transaction_Billers_Data: {
    transactionBillerLoading: true,
    transactionBillerError: "",
    transactionBillerInfo: [],
  },
  Transaction_Data: {
    transactionsLoading: true,
    transactionsError: "",
    transactionsInfo: [],
  },
};

export default state;
