import {
    CircularProgress,
    DialogContent,
    FormControlLabel,
    FormGroup,
    Link,
    Typography,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  import Button from "@mui/material/Button";
  import Checkbox from "@mui/material/Checkbox";
  import Grid from "@mui/material/Grid";
  import { getValue } from "@mui/system";
  import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
  import DashboardNavbar from "examples/Navbars/DashboardNavbar";
  import { useEffect, useState } from "react";
  import { Controller, useForm } from "react-hook-form";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import { getAvalibilityListId } from "services/availabilityServices";
  import { updateCity } from "services/commonServices";
  import MDBox from "../../../components/MDBox/index";
  import MDInput from "../../../components/MDInput/index";
  import SelectDropDown from "../../../examples/DropDown/index";
  
  import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
  import moment from "moment";
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  
  const PermaJobDetailByID = () => {
    const [passwordButton, setPasswordButton] = useState(true);
  
    // const validationSchema=yup.object().shape({
    //   value:yup.string()
    //   .required("Value is Required")
    //   .matches(/^[0-9\s]+$/, "Invalid Value")
    // })
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [responsedata, setResponseData] = useState([]);
    const [isLaunched, setIsLaunched] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const url = window.location.href.split("id")[1].split("/")[1];
    const getData = async () => {
      let response = await getAvalibilityListId(url);
      console.log(response?.data?.docs, "response");
      setResponseData(response?.data?.docs);
    };
  
    useEffect(() => {
      if (url) {
        getData();
      }
    }, []);
  
    const handleBack = () => {
      navigate(-1);
    };
  
    return (
      <>
        <DashboardLayout>
          <DashboardNavbar />
          {responsedata ? (
            <MDBox margin={2}>
              <Grid item xs={12}>
                <div style={{ display: "flex", width: "70px" }}>
                  <Link onClick={handleBack}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <ArrowBackIos fontSize="small" />
                      back
                    </div>
                  </Link>
                </div>
              </Grid>
              {/* <Divider /> */}
              {responsedata.map((item, i) => (
                <DialogContent>
                  <Grid container spacing={3}>
                    {/* Name Title */}
                    <Grid item xs={8}></Grid>
                    {/* ID */}
                    <Grid item xs={12}>
                      <Typography variant="body"></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {item.createdBy}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Available Day
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {moment(item.slotStart).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Availability Start
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {moment(item.availabilityStart).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Availability End
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {moment(item.availabilityEnd).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Created At
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {moment(item.createdAt).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Updated At
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {moment(item.createdAt).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Department
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {item.department}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        Role
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {item.role}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        City
                      </Typography>
                    </Grid>
  
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {item.city}
                      </Typography>
                    </Grid>
                    {item?.jobDetails.length && (
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Current Job Status
                        </Typography>
                      </Grid>
                    )}
  
                    <Grid item xs={8}>
                      <Typography
                        variant="subtitle1"
                        sx={{ width: "80%", marginTop: "0.8rem" }}
                      >
                        {item?.jobDetails?.map((item, i) => (
                          <>
                            {item?.pendingInterviewDetails && (
                              <>
                                {" "}
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ width: "80%", marginTop: "0.8rem" }}
                                  >
                                    Pending Interview Details
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ width: "80%", marginTop: "0.8rem" }}
                                  >
                                    {
                                      item?.pendingInterviewDetails
                                        ?.interviewStatus
                                    }
                                  </Typography>
                                </Grid>
                              </>
                            )}
                            {item?.interviewDetails && (
                              <>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ width: "80%", marginTop: "2rem" }}
                                  >
                                    Interview Details
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ width: "80%", marginTop: "0.8rem" }}
                                  >
                                    {item?.interviewDetails?.interviewStatus}
                                  </Typography>
                                </Grid>
                              </>
                            )}
  
                            {item?.jobDetails && (
                              <>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ width: "80%", marginTop: "2rem" }}
                                  >
                                    Accepted Job
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ width: "80%", marginTop: "0.8rem" }}
                                  >
                                    {item?.jobDetails?.code}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContent>
              ))}
            </MDBox>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "24%",
                marginTop: "20%",
              }}
            >
              <CircularProgress disableShrink />
            </div>
          )}
        </DashboardLayout>
      </>
    );
  };
  export default PermaJobDetailByID;
  