import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    TextField,
  } from "@mui/material";
  import React from "react";
  import MDButton from "../../../components/MDButton/index";
  import Grid from "@mui/material/Grid";
  import { useState } from "react";
  import { yupResolver } from "@hookform/resolvers/yup";
  import Checkbox from "@mui/material/Checkbox";
  import { useForm } from "react-hook-form";
  import MDInput from "../../../components/MDInput";
  import { ExperienceDialogValidation } from "util/validations/commonValidations";
  import { addExperience, addGCCExperience, getGCCExperience } from "../Redux/actionCreator";
  import { useDispatch } from "react-redux";
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  
  const GCCExperienceDialog = ({ handleDialogClose }) => {
    
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(ExperienceDialogValidation),
    });
    const selectOptions = [
      {
        key: "Organization",
        value: "Organization",
      },
      {
        key: "SuperAdmin",
        value: "Super_Admin",
      },
      {
        key: "Applicant",
        value: "Applicant",
      },
    ];
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = (event) => setOpenDialog(event.currentTarget);
    const dispatch = useDispatch();
    const handleCloseDialog = () => setOpenDialog(false);
    const onSubmit = (data) => {
    
    //   dispatch(addGCCExperience(data));
    dispatch(addGCCExperience(data)).then(() => {
        dispatch(getGCCExperience());
      });
      handleDialogClose();
    };
  
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputLabel fullWidth className="input-labels">
                Name
              </InputLabel>
            </Grid>
  
            <Grid item xs={8}>
              <MDInput
                placeholder=""
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("name")}
                error={errors?.name?.message}
                helperText={errors?.name?.message}
              />
            </Grid>
          </Grid>
  
          <MDButton
            variant="contained"
            color="info"
            type="submit"
            sx={{ marginTop: "1.5rem" }}
          >
            create
          </MDButton>
         
        </form>
      </div>
    );
  };
  
  export default GCCExperienceDialog;