import { IconButton, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MDButton from "./../../../components/MDButton/index";
import SelectDropDown from "./../../../examples/DropDown/index";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setDepartmentType } from "layouts/events/Redux/actionCreator";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { createDepartment, HospitalBranchList } from "../Redux/actionCreator";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";
import SelectDropDownNew from "examples/DropDownNew";
import { DepartmentDialogValidation } from "util/validations/hospitalPanelValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText } from "@material-ui/core";

const CreateDepartmentDialog = ({ handleDialogClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DepartmentDialogValidation),
  });
  const selectOptions = [
    {
      key: "1",
      value: "1",
    },
  ];

  const { Department_Type_Data } = useSelector((state) => state.events);
  const { OrganizationsData } = useSelector((state) => state.common);
  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  const departmentData = Department_Type_Data.departmentInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;
  const branchData = hospital_branch_Data?.branchInfo;

  //Drop Down Options
  const departmentOptions = [];
  const organizationOptions = [];
  const branchOptions = [];

  departmentData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  //Payload IDS
  const[showDprt,setShowDprt]=useState(false);
  const [branchPayloadID, setBranchID] = useState("");
  const [organizationPayloadID, setOrganizationID] = useState("");
  const [departmentTypePayloadID, setDepartmentTypeID] = useState("");
  const [count, setCount] = useState(0);

  //Handlers
  const handleOrganizationChange = (e) => {
    if(errors?.company?.message)
    errors.company.message="";
    setShowDprt(true);
    setValue("company", e.target.value);
    setValue("branch","none")
    let organizationID;
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        organizationID = obj.key;
      }
    });
    setCount(1);
    setOrganizationID(organizationID);
  };


  useEffect(() => {
   if(count==1)
    dispatch(HospitalBranchList([organizationPayloadID]))
  }, [organizationPayloadID]);

  const handleDepartmentChange = (e) => {
    if(errors?.departmentType?.message)
    errors.departmentType.message="";
    setValue("departmentType", e.target.value);
    let departmentID;
    //Searching for the name in the Users array to find the ID
    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        departmentID = obj.key;
      }
    });
    setDepartmentTypeID(departmentID);
  };

  const handleBranchChange = (e) => {
    if(errors?.branch?.message)
    errors.branch.message="";
    setValue("branch", e.target.value);
    let branchID;

    //Searching for the name in the Users array to find the ID
    branchOptions.map((obj) => {
      if (obj.value == e.target.value) {
        branchID = obj.key;
      }
    });
    setBranchID(branchID);
  };

  const dispatch = useDispatch();

  //Creating on Submit
  const onSubmit = (data) => {
   

      let payload = {
        organization: organizationPayloadID,
        branch: branchPayloadID,
        departmentType: departmentTypePayloadID,
      };
    
      dispatch(createDepartment(payload));

       handleDialogClose();
  
  };

  useEffect(() => {
    dispatch(setDepartmentType());
    dispatch(setOrganizationsDropdown());
  
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel fullWidth>Company</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={organizationOptions}
              placeholder="Select"
              name="company"
              defaultValue="none"
              control={control}
              getValues={getValues}
              customOnChange={handleOrganizationChange}
            />
            <FormHelperText>{errors?.company?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Branch</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
            disabled={showDprt?false:true}
              options={branchOptions}
              placeholder="Select"
              name="branch"
              defaultValue="none"
              control={control}
              getValues={getValues}
              customOnChange={handleBranchChange}
            />
            <FormHelperText>{errors?.branch?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Department Type</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={departmentOptions}
              placeholder="Select"
              name="departmentType"
              defaultValue="none"
              control={control}
              getValues={getValues}
              customOnChange={handleDepartmentChange}
            />
            <FormHelperText>{errors?.departmentType?.message}</FormHelperText>
          </Grid>
          <MDButton
            variant="contained"
            color="info"
            type="submit"
            sx={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            Create
          </MDButton>
        </Grid>
      </form>
    </div>
  );
};

export default CreateDepartmentDialog;