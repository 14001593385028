import authReducer from "layouts/beforeAuth/login/Redux/reducer";
import billsReducer from "../layouts/billing/Redux/reducer";
import commonReducer from "../layouts/common/Redux/reducer";
import eventsReducer from "../layouts/events/Redux/reducer";
import invoiceReducer from "../layouts/userInvoices/Redux/reducer";
import hospitalReducer from "../layouts/hospital/Redux/reducer";
import jobReducer from "../layouts/job/Redux/reducer";
import adminReducer from "../layouts/dashboards/Redux/reducer";
import transactionsReducer from "../layouts/transaction/Redux/reducer";
import userReducer from "../layouts/users/Redux/reducer";
import availabilityReducer from "../layouts/availbility/Redux/reducer";

export const rootReducer = {
  admin: adminReducer,
  events: eventsReducer,
  common: commonReducer,
  bills: billsReducer,
  invoice: invoiceReducer,
  hospital: hospitalReducer,
  job: jobReducer,
  user: userReducer,
  auth: authReducer,
  transactions: transactionsReducer,
  availability: availabilityReducer,
};
