/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import {   InputBase, Paper} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarIconButton,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { Typography } from "@mui/material";
import {
  setMiniSidenav,
  setTransparentNavbar,
  useMaterialUIController,
} from "context";
import { logout } from "layouts/beforeAuth/login/Redux/actionCreator";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const useStyles=makeStyles((theme)=>({
  topNavbar:{
    position: "sticky",
    top: "0px",
    marginTop: "0px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "55px",
    minHeight: "55px",
  },
  rightLogoutDiv:{
    [theme.breakpoints.up("md")]: {
      display: "flex",
  justifyContent: "flex-end",
  alignItems:"center"
    },
  }

}))
function DashboardNavbar({ absolute, light, isMini }) {
  const classes=useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [navbarType, setNavbarType] = useState();
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
 

  const handleLogoutClick = () => {
    dispatch(logout());
    setTimeout(() => {
      navigate("/login");
    }, 100);
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("fixed");
      //absolute
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const routeName = route[route.length - 1];
  const testRouteName = routeName.toUpperCase();
  return (
    <AppBar
      // position={absolute ? "fixed" : navbarType}
      className={classes.topNavbar}
      // className="top-navbar"
      // static
      // style={{
      //   top: "0px",
      //   width: "82%",
      //   backgroundColor: "white",
      //   borderRadius: "0px",
      // }} //
      color="inherit"
      sx={
        (theme) => navbar(theme, { light, darkMode })
        // transparentNavbar, absolute,
      }
    >
       
      <Toolbar>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
          className="navbar-items"
        >
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
          {/* <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton> */}
           {/* <Paper
          component="form"
          elevation={4}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height:"40px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
           
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <Typography sx={{fontSize:"20px"}}>JOBIZO SUPER ADMIN</Typography>
          {/* <IconButton onClick={() => navigate(-1)}>
            <Icon fontSize="small" sx={iconsStyle}>
              arrow_back_ios_new
            </Icon>
            <Typography>Back</Typography>
          </IconButton> */}

          {/* {testRouteName} */}
          {/* <Breadcrumbs icon="home" title={""} route={route} light={light} /> */}
        </MDBox>
        {isMini ? null : (
          <MDBox
            sx={(theme) => navbarRow(theme, { isMini })}
            className={classes.rightLogoutDiv}
            // className="right-logout-div"
          >
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}

              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              <Tooltip disableArrow title="logout">
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  // onClick={handleOpenMenu}
                >
                  {/* <MDBadge badgeContent={9} color="error" size="xs" circular> */}

                  <Icon sx={iconsStyle} onClick={handleLogoutClick}>
                    logout
                  </Icon>
                  {/* </MDBadge> */}
                </IconButton>
              </Tooltip>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
