import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import IsDeletedComponent from "examples/isDeletedComponent";
import ReusableDialog from "examples/ReusableDialog";
import UserDialog from "examples/UserDialog";
import { getCityList } from "layouts/common/Redux/actionCreator";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getCities } from "shared/services";
import AutoCombobox from "../AutoCombobox";
import "./styles.css";
import MDInput from "components/MDInput";

function FilterBar({
  showFilter = true,
  showColumns = true,
  showDownloads = true,
  showDialog = true,
  showisDeleted = true,
  showUpload = false,
  title,
  title1,
  subtitle,
  dialogContent,
  uploadDialogContent,
  isOpenUpload,
  handleUploadDialogClose,
  handleUploadDialogOpen,
  isOpen,
  customDialogOnClick,
  handleDialogOpen,
  handleDialogClose,
  handleDeleteApi,
}) {
  

  return (
    <div className="BarContainer">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "35px" }}>{title1}</Typography>
        {/* Search Bar */}

        {/* User Dialog Box */}
        {showDialog && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <>
              <MDBox sx={{ marginLeft: "1rem" }}>
                <MDButton
                  variant="contained"
                  color="info"
                  // size="medium"
                  onClick={
                    customDialogOnClick ? customDialogOnClick : handleDialogOpen
                  }
                >
                  <Icon>add</Icon>
                  <MDBox style={{ fontWeight: "100", color: "white" }}>
                    &nbsp;{title}
                  </MDBox>
                </MDButton>
              </MDBox>

              <ReusableDialog
                title={title}
                subtitle={subtitle}
                isOpen={isOpen}
                children={
                  dialogContent
                    ? dialogContent
                    : "Please Pass the Dialog Content"
                }
                handleClose={handleDialogClose}
              />
            </>

            {showUpload && (
              <>
                <MDBox sx={{ marginLeft: "1rem" }}>
                  <MDButton
                    variant="contained"
                    color="info"
                    // size="medium"
                    onClick={handleUploadDialogOpen}
                  >
                    <Icon>upload</Icon>

                    <MDBox style={{ fontWeight: "100", color: "white" }}>
                      &nbsp; Upload File
                    </MDBox>
                  </MDButton>
                </MDBox>
                <ReusableDialog
                  title={`Upload ${title1} Card`}
                  subtitle={subtitle}
                  isOpen={isOpenUpload}
                  children={
                    uploadDialogContent
                      ? uploadDialogContent
                      : "Please Pass the Dialog Content"
                  }
                  handleClose={handleUploadDialogClose}
                />
              </>
            )}
          </div>
        )}
      </Box>

      {showisDeleted && (
        <IsDeletedComponent handleDeleteApi={handleDeleteApi} />
      )}
    </div>
  );
}

export default FilterBar;
