import { Grid, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SelectDropDown from "examples/DropDown";
import SelectDropDownNew from "examples/DropDownNew";
import React from "react";
import { useFieldArray } from "react-hook-form";

const DynamicPermissions = ({
  control,
  selectOptionslevel,
  selectOptionsAri,
  getValues,
  setValue,
  name,
}) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "permissions", // unique name for your Field Array
  });

  const handleAriChange = (e, registeredName) => {
 
    let AriID;
    //Searching for the name in the Users array to find the ID
    selectOptionsAri.map((obj) => {
      if (obj.value == e.target.value) {
        AriID = obj.key;
      }
    });
    setValue(registeredName, AriID);
  };

  return (
    <div>
      <Grid container>
        {" "}
        <Grid item xs={12}>
          <Paper sx={{ padding: "1rem" }}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              Permissions
            </Typography>
            <ul style={{ listStyleType: "none" }}>
              {fields.map((field, index) => (
                <li>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Ari</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <SelectDropDownNew
                        options={selectOptionsAri}
                        control={control}
                        defaultValue="none"
                        getValues={getValues}
                        name={
                          name
                            ? `${name}.permissions.${index}.ari.name`
                            : `permissions.${index}.ari.name`
                        }
                        placeholder="Select"
                        customOnChange={(e) =>
                          handleAriChange(
                            e,
                            name
                              ? `${name}.permissions.${index}.ari`
                              : `permissions.${index}.ari`
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Level</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <SelectDropDownNew
                        options={selectOptionslevel}
                        name={
                          name
                            ? `${name}.permissions.${index}.level`
                            : `permissions.${index}.level`
                        }
                        control={control}
                        defaultValue="none"
                        getValues={getValues}
                        placeholder="Select"
                        customOnChange={(e) =>{
                          
                          setValue(
                            name
                              ? `${name}.permissions.${index}.level`
                              : `permissions.${index}.level`,
                            e.target.value
                          )
                        }
                         
                        }
                      />
                    </Grid>
                  </Grid>

                  <MDBox sx={{ textAlign: "right" }}>
                    <MDButton
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <Typography color="error" variant="body">
                        Remove
                      </Typography>
                    </MDButton>
                  </MDBox>
                  {/* </MDBox>  */}
                </li>
              ))}
              <MDButton
                variant="contained"
                color="info"
                onClick={(e) => {
                  append({});
                }}
              >
                + Add
              </MDButton>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DynamicPermissions;
