import { Grid, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";

const DynamicCoords = ({ control, getValues, setValue, name }) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: name ? name : "address.address.coords", // unique name for your Field Array
  });
  return (
    <div>
      {" "}
      <Grid container>
        {/* Coords */}
        <Grid item xs={12}>
          <Paper sx={{ padding: "1rem" }}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              Address Coords
            </Typography>
            <hr style={{ marginBottom: "2rem" }} />
            <ul style={{ listStyleType: "none" }}>
              {fields.map((field, index) => (
                <li>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Coords</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        control={control}
                        name={
                          name
                            ? `${name}.${index}`
                            : `address.address.coords.${index}`
                        }
                        render={({ field }) => (
                          <MDInput
                            placeholder=""
                            type="number"
                            sx={{ width: "100%", marginTop: "0.4rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            value={
                              getValues(
                                name
                                  ? `${name}.${index}`
                                  : `address.address.coords.${index}`
                              )
                                ? getValues(
                                    name
                                      ? `${name}.${index}`
                                      : `address.address.coords.${index}`
                                  )
                                : ""
                            }
                            onChange={(e) => {
                            
                              setValue(
                                name
                                  ? `${name}.${index}`
                                  : `address.address.coords.${index}`,
                                e.target.value
                              );
                             
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </Grid>

                  <MDBox sx={{ textAlign: "right" }}>
                    <MDButton
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <Typography color="error" variant="body">
                        Remove
                      </Typography>
                    </MDButton>
                  </MDBox>
                  {/* </MDBox>  */}
                </li>
              ))}
              <MDButton
                variant="contained"
                color="info"
                onClick={(e) => {
                
                  append({});
                }}
              >
                + Add Item
              </MDButton>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DynamicCoords;
