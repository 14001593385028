import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import Moment from 'moment';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SelectDropDown from "examples/DropDown";
import DynamicCoords from "examples/DynamicCoords";
import DynamicDocuments from "examples/DynamicDocuments";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { setDepartmentType } from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentByIdService } from "services/hospitalServices";
import { HospitalBranchList, updateDepartment } from "../Redux/actionCreator";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import SelectDropDownNew from "examples/DropDownNew";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import { getDocumentType } from "../../common/Redux/actionCreator";

// const ID = "123";

const DepartmentDetailsForm = () => {
  const ID = window.location.href.split("department")[1].split("/")[1];
  
  const getData = async () => {
    let response = await getDepartmentByIdService(ID);
   
    return response[0];
  };


  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });
  const navigate = useNavigate();

  const { Department_Type_Data } = useSelector((state) => state.events);
  const { OrganizationsData, UsersData } = useSelector((state) => state.common);
  const { hospital_branch_Data } = useSelector((state) => state.hospital);
  const { DocumentType_Data } = useSelector((state) => state.common);
  const documents = DocumentType_Data?.documentTypeInfo;

  const departmentData = Department_Type_Data.departmentInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;
  const branchData = hospital_branch_Data?.branchInfo;
  const userInfo = UsersData?.usersInfo;

  //Drop Down Options
  const departmentOptions = [];
  const organizationOptions = [];
  const branchOptions = [];
  const userOptions = [];


  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "billingAddress.address.coords" // unique name for your Field Array
  });


  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  departmentData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  //Payload IDS

  const [branchPayloadID, setBranchPayloadID] = useState("");
  const [organizationPayloadID, setOrganizationID] = useState("");
  const [departmentTypePayloadID, setDepartmentTypeID] = useState("");

  //Handlers
  const handleOrganizationChange = (e) => {
    setValue("organization.name", e.target.value);
    setValue("branch.name", "none");
    let organizationID;
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        organizationID = obj.key;
      }
    });
    setOrganizationID(organizationID);
  };

  const handleDepartmentChange = (e) => {
    setValue("departmentType.name", e.target.value);
    let departmentID;
    //Searching for the name in the Users array to find the ID
    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        departmentID = obj.key;
      }
    });
    setDepartmentTypeID(departmentID);
  };

  const handleBranchChange = (e) => {
    setValue("branch.name", e.target.value);
    let branchID;

    //Searching for the name in the Users array to find the ID
    branchOptions.map((obj) => {
      if (obj.value == e.target.value) {
        branchID = obj.key;
      }
    });
    setBranchPayloadID(branchID);
  };

  const selectOptions = [
    {
      key: "1",
      value: "1",
    },
    {
      key: "2",
      value: "2",
    },
  ];


const docTypeOptions=[];
  documents.map((elem)=>{
     var obj={
      key:elem?._id,
      value:elem?.name
     }
     docTypeOptions.push(obj)
  })
 

  

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    
     let coordsData=[];
     data?.billingAddress?.address?.coords.map((obj)=>{
        if(typeof obj=="object")
        coordsData.push("");
        else
        coordsData.push(obj);
     })

    

     let documentsData=[];
     data?.documents.map((obj)=>{
       
        let DocObj={
          
          docType:typeof obj?.docType=="object"? obj?.docType?.name=="undefined"?"":obj?.docType?._id:obj?.docType,
          files:obj?.files,
          uploadedAt:obj?.uploadedAt
        }
        
        documentsData.push(DocObj);
     })
    let payload = {
      billingAddress: {
        address: {
          coords: coordsData,
          address: data?.billingAddress?.address?.address,
          city: data?.billingAddress?.address?.city,
          country: data?.billingAddress?.address?.country,
          pin: data?.billingAddress?.address?.pin,
          state: data?.billingAddress?.address?.state,
        },
        isGstRegistered: data?.billingAddress?.isGstRegistered,
        gstNumber: data?.billingAddress?.gstNumber,
      },
      organization: organizationPayloadID
        ? organizationPayloadID
        : data?.organization?._id,
      departmentType: departmentTypePayloadID
        ? departmentTypePayloadID
        : data?.departmentType?._id,
      branch: branchPayloadID ? branchPayloadID : data?.branch?._id,
      isBillingEntity: data?.isBillingEntity,
      isDeleted: data?.isDeleted,
     
      documents: documentsData,
    };

    dispatch(updateDepartment({ payload: payload, id: ID })).then((res) => {
      
      if (!res?.error) {
        navigate("/hospital/department");
      }
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const[organizationID,setOrganizationnID]=useState();

  useEffect(() => {
    dispatch(setDepartmentType());
    dispatch(setOrganizationsDropdown());
    dispatch(setUsersDropdown());
    dispatch(getDocumentType());
   
     }, []);
     useEffect(()=>{
      dispatch(HospitalBranchList([organizationID]))
     },[organizationID])
     useEffect(()=>{
      dispatch(HospitalBranchList([organizationPayloadID]))
     },[organizationPayloadID])
 
  const [updateCreated, setUpdateCreated] = useState(false);
  const [updateUpdated, setUpdateUpdated] = useState(false);
  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");
    const reverse = getValues("reverseChargesApplicable");
    const organization=getValues("organization._id");
    setOrganizationnID(organization);
    if (created !== undefined) {
      if (!updateCreated) {
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
        setUpdateCreated(true);
      }
    }
   

    if (updated !== undefined) {
      if (!updateUpdated) {
        setValue("updatedAt", Moment(created).format("DD/MM/YYYY"));
        setUpdateUpdated(true);
      }
    }
  
    
  }, [
    getValues("createdAt"),
    getValues("updatedAt"),
    getValues("reverseChargesApplicable"),
    getValues("organization._id")
  ]);

  const formValues = getValues();
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
              
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ width: "100%", marginBottom: "10px", marginTop: "8px" }}
                >
                  <Typography variant="h4">ID: {ID}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="isDeleted"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("isDeleted")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="isDeleted"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Company</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    control={control}
                    getValues={getValues}
                    name="organization.name"
                    defaultValue="none"
                    options={organizationOptions}
                    customOnChange={handleOrganizationChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Branch</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                  options={branchOptions}
                  control={control}
                  getValues={getValues}
                  name="branch.name"
                  defaultValue="none"
                  customOnChange={handleBranchChange}
                />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Department Type</Typography>
                </Grid>
                <Grid item xs={9}>
                  <SelectDropDownNew
                    options={departmentOptions}
                    control={control}
                    getValues={getValues}
                    name="departmentType.name"
                    defaultValue="none"
                    customOnChange={handleDepartmentChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="isBillingEntity"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("isBillingEntity")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="is Billing Entity"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Billing Address</Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.address")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Billing Address City
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.city")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Billing Address State
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.state")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Billing Address Pin
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.pin")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Billing Address Country
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.country")}
                  />
                </Grid>

                <Grid item xs={12}>
                <Grid container>
        {/* Coords */}
        <Grid item xs={12}>
          <Paper sx={{ padding: "1rem" }}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              Address Coords
            </Typography>
            <hr style={{ marginBottom: "2rem" }} />
            <ul style={{ listStyleType: "none" }}>
              {fields.map((field, index) => {
               
                return(
                  <li>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Coords</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        control={control}
                        name={`billingAddress.address.coords.${index}`}
                        // name={
                        //   name
                        //     ? `${name}.${index}`
                        //     : `address.address.coords.${index}`
                        // }
                        render={({ field }) => (
                          <MDInput
                          onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            placeholder=""
                            type="number"
                            sx={{ width: "100%", marginTop: "0.4rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            value={getValues(`billingAddress.address.coords.${index}`)}
                            // value={
                            //   getValues(
                            //     name
                            //       ? `${name}.${index}`
                            //       : `address.address.coords.${index}`
                            //   )
                            //     ? getValues(
                            //         name
                            //           ? `${name}.${index}`
                            //           : `address.address.coords.${index}`
                            //       )
                            //     : ""
                            // }
                            onChange={(e) => {
                              setValue(`billingAddress.address.coords.${index}`,e.target.value)
                              // setValue(
                              //   name
                              //     ? `${name}.${index}`
                              //     : `address.address.coords.${index}`,
                              //   e.target.value
                              // );
                             
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </Grid>

                  <MDBox sx={{ textAlign: "right" }}>
                    <MDButton
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <Typography color="error" variant="body">
                        Remove
                      </Typography>
                    </MDButton>
                  </MDBox>
                  {/* </MDBox>  */}
                </li>
                )
               
                    })}
              <MDButton
                variant="contained"
                color="info"
                onClick={(e) => {
                
                  append({});
                }}
              >
                + Add Item
              </MDButton>
            </ul>
          </Paper>
        </Grid>
      </Grid>
                  {/* <DynamicCoords
                    control={control}
                    name="billingAddress.address.coords"
                    setValue={setValue}
                    getValues={getValues}
                  /> */}
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    Billing Address Gst Number
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    fullWidth
                    {...register("billingAddress.gstNumber")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="billingAddress.isGstRegistered"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("billingAddress.isGstRegistered")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Billing Address is Gst Registered"
                    />
                  </FormGroup>
                </Grid>
                {/* 
              Documents */}
                <Grid item xs={12}>
                  <DynamicDocuments
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                    selectOptions={docTypeOptions}
                  />
                </Grid>
                   {/* createdAt */}
                   <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Created_at
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              disabled
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("createdAt")}
                            />
                          </Grid>

                          {/* updatedAt */}
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Updated_at
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <MDInput
                              disabled
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("updatedAt")}
                            />
                          </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Created By</Typography>
                </Grid>
                <Grid item xs={9}>
                <MDInput
                              disabled
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("createdBy")}
                            />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle1">Updated By</Typography>
                </Grid>
                <Grid item xs={9}>
                <MDInput
                              disabled
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("updatedBy")}
                            />
                </Grid>
                <Grid xs={12} sx={{ width: "100%", margin: "10px" }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="contained" color="info" type="submit">
                    Save
                  </MDButton>
                  {/* <MDButton variant="text" color="info">
                    Reset
                  </MDButton> */}
                </Grid>
              </Grid>
            </form>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default DepartmentDetailsForm;
