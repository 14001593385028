import jsPDF from "jspdf";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Grid, Link, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceData } from "../Redux/actionCreator";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import "./UserInvoice.css";

const UserInvoice = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { Invoice_Data } = useSelector((state) => state.invoice);
  var invoiceData = Invoice_Data?.invoiceDataInfo;
  // console.log("<>>", invoiceData)
  const [responseData, setResponseData] = useState(invoiceData);

  const path = window.location.pathname.split("view/")[1];
  useEffect(() => {
    dispatch(fetchInvoiceData(path));
  }, [path, dispatch]);


        async function processInvoiceData(invoiceData) {
          const invoiceName = invoiceData?.user?.name;
          // console.log("1--->", invoiceName);
          const isDr = invoiceName?.startsWith('Dr.');
          // console.log("2--->", isDr);
          const result = isDr ? invoiceName.slice(4).trim() : invoiceName;
          // console.log("3--->", result);
          const nameParts = result.split(' ');
          // console.log("4--->", nameParts);
          const firstName = nameParts.length > 0 ? nameParts[0] : '';
        
          async function formatInvoiceJobDate(rawDate) {
            if (!rawDate) {
              return null;
            }

            
        
            const dateObject = new Date(rawDate);
            const day = String(dateObject.getDate()).padStart(2, '0');
            const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const year = dateObject.getFullYear();
        
            return `${day}_${month}_${year}`;
          }
        
          // console.log("invoiceData>>",invoiceData)
          // console.log("1-->",invoiceData?.job?.consequtiveTime)
          // console.log("2--->",invoiceData?.job?.nonConsecutiveTime?.start?.datetime)
          // const invoiceJobDate = invoiceData?.job?.consequtiveTime?.start?.datetime || invoiceData?.job?.nonConsequtiveTime?.start?.datetime;
          const invoiceJobDate = invoiceData?.shiftDate
          // console.log(">!>", invoiceJobDate)
          const formattedDate = await formatInvoiceJobDate(invoiceJobDate);
          // console.log("6-->", formattedDate);
          const lastPart = invoiceData?.invoiceNumber.split("/").pop();
          const fileName = `${firstName}_${formattedDate}_${lastPart}`;
          // console.log("Invoice", fileName);
          return fileName
        }
        
        // Call the function with invoiceData[0]
      //  const finalInvoice =  await processInvoiceData(invoiceData[0]);
      //  console.log("Invoice", finalInvoice);
        

  async function printDocument() {
    const finalInvoice =  await processInvoiceData(invoiceData[0]);
    // console.log("Invoice", finalInvoice);
    let jsPdf = new jsPDF("p", "pt", "letter");
    var htmlElement = document.getElementById("invoiceContainer");
    const opt = {
      callback: function (jsPdf) {
        jsPdf.save(`${finalInvoice}.pdf`);
        // jsPdf.save("User Invoice.pdf");
        window.open(jsPdf.output("bloburl"));
      },
      padding: 200,
      // marginRight: 50,
      margin: [20, 30, 20, 10],
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.48,
      },
    };

    jsPdf.html(htmlElement, opt);
  }
  const handleBack = () => {
    navigate(-1);
  };

  // console.log("Invoice.js", invoiceData);

  ///   const dateTimeString = invoiceData[0]?.shiftDate;
  //   console.log("DateTimeString", dateTimeString)
  //   if(dateTimeString) {
  //     var [dateString, timeString] = dateTimeString.split("T");
  // const date = new Date(dateString);
  // const year = date.getFullYear();
  // const month = date.getMonth() + 1;
  // const day = date.getDate();
  // var dateOnly = year+"/"+month+"/"+day;
  // //var timeOny = timeString.split(".");
  // console.log("pathName3: ", window.location.pathname)
  // console.log("Date:", dateOnly);
  // //console.log("Time:", timeOny[0]);
  //   }
  // console.log("=====>", invoiceData[0]);
  // console.log("=====>", invoiceData.data[0]?.amount);
  const referredUsersTotal = invoiceData[0]?.referredUsers.reduce(
    (acc, user) => acc + user.amount,
    0
  );
  // console.log("referredUsersTotal>>", referredUsersTotal)
  // const totalAmount = invoiceData[0]?.finalAmount + referredUsersTotal;
  const totalAmount = invoiceData[0]?.amount + referredUsersTotal;
  // console.log("=====>", totalAmount);

  const newMarginCost = `${invoiceData[0]?.costToCandidate ? - (invoiceData[0]?.amount - invoiceData[0]?.costToCandidate) : "0"}`;
// console.log("newMarginCost>>", newMarginCost);


  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>

            <MDButton variant="contained" color="info" onClick={printDocument}>
              Export as PDF
            </MDButton>
          </Grid>
        </MDBox>
        <div id="invoiceContainer">
          <Paper sx={{ marginTop: "1rem", width: "100%" }}>
            <MDBox className={"invoicesBox"}>
              <div
                style={{
                  textAlign: "center",
                  margin: "10px",
                  padding: "10px",
                }}
              >
                <h2>Invoice</h2>
              </div>
              <div className="invoiceHeader">
                <div>
                  <small>
                    {moment(invoiceData[0]?.shiftDate).format("DD/MM/YYYY")}
                  </small>{" "}
                  <br />
                  <small>Name: {invoiceData[0]?.user?.name}</small> <br />
                  <small>
                    Address:
                    {invoiceData[0]?.user?.address?.address
                      ? invoiceData[0]?.user?.address?.address
                      : "NA"}
                    {/* {invoiceData[0]?.user?.address?.city} */}
                    {/* {invoiceData[0]?.user?.address?.state}
                    <br />
                    {invoiceData[0]?.user?.address?.country} */}
                    {/* {invoiceData[0]?.user?.address?.pin} */}
                  </small>
                  <div style={{ marginTop: "25px" }}>
                    <small>
                      Client Name:{" "}
                      IFANFLEX PRIVATE LIMITED {" "}
                    </small>{" "}
                    <br />
                    <small>
                      Address:{" "}
                      PLOT 89, SECTOR 44, GURUGRAM, Haryana-122003
                      {/* {invoiceData[0]?.client?.address?.address} */}
                      {/* {invoiceData[0]?.client?.address?.city}
                      {invoiceData[0]?.client?.address?.state}
                      <br />
                      {invoiceData[0]?.client?.address?.country}
                      {invoiceData[0]?.client?.address?.pin} */}
                    </small>
                  </div>
                </div>
                <div className="content">
                  <small>Invoice No: {invoiceData[0]?.invoiceNumber}</small>{" "}
                  <br />
                  <small>PAN: {invoiceData[0]?.user?.pan}</small>
                  <br />
                  <small>Job Id: {invoiceData[0]?.jobId}</small>
                  <br />
                  <small>
                    Shift Date:{" "}
                    {moment(invoiceData[0]?.shiftDate).format("DD/MM/YYYY")}
                  </small>
                  <br />
                  <small>
                    Shift Start Time:{" "}
                    {moment(new Date(+invoiceData[0]?.shiftStartDate)).format(
                      "HH:mm"
                    )}
                  </small>
                  <br />
                  <small>
                    Shift End Time:{" "}
                    {moment(new Date(+invoiceData[0]?.shiftEndDate)).format(
                      "HH:mm"
                    )}
                  </small>
                </div>
              </div>
              <br />

              <div className="tableContainer">
                <table>
                  <tbody>
                    <tr>
                      <th>Particulars</th>
                      <th>Hours Worked</th>
                      <th>Rate Per Hour</th>
                      <th>Amount (INR)</th>
                    </tr>
                    <tr>
                      <td>Consultancy Fees for {invoiceData[0]?.jobId}</td>
                      <td>{invoiceData[0]?.numberOfHours}</td>
                      <td>{invoiceData[0]?.ratePerHour}</td>
                      <td>{invoiceData[0]?.amount}</td>
                    </tr>
                    {invoiceData[0]?.referredUsers.map((data, index) => (
                      <tr key={index}>
                        <td colspan={3}>
                          Referral Amount ({data.phoneNumber})
                        </td>
                        <td>{data.amount}</td>
                      </tr>
                    ))}
                    {/* <tr>
                      <td colspan={3}>Jobizo Service Charge</td>
                      <td>{newMarginCost}</td>
                    </tr> */}
                    <tr>
                      <td colspan={3}>Total</td>
                      {/* <td>{invoiceData[0]?.finalAmount}</td> */}
                      <td>{totalAmount}</td>
                    </tr>
                    
                  </tbody>
                </table>

                <div className="wireDetails">
                  <h4>Wire Details:</h4>
                  <table className="wireTable">
                    <tbody>
                      <tr>
                        <th>Name of Beneficiary</th>
                        <td>{invoiceData[0]?.user?.name}</td>
                      </tr>
                      <tr>
                        <th>Bank Name</th>
                        <td>{invoiceData[0]?.bank?.bankName}</td>
                      </tr>
                      <tr>
                        <th>Branch Address</th>
                        <td>{invoiceData[0]?.bank?.branchAddress}</td>
                      </tr>
                      <tr>
                        <th>IBAN/ACC NUMBER</th>
                        <td>{invoiceData[0]?.bank?.accountNumber}</td>
                      </tr>
                      <tr>
                        <th>IFSC</th>
                        <td>{invoiceData[0]?.bank?.ifsc}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <p className="footerText">
                      *All the payments are subjected to a standard deduction of
                      10% TDS
                      <br />
                      *This is system generated invoice issued by Professional
                      with assistance of Ifanflex Pvt. Ltd.
                    </p>
                  </div>
                </div>
              </div>
            </MDBox>
          </Paper>
        </div>
      </DashboardLayout>
    </>
  );
};

export default UserInvoice;
