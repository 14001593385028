import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    TextField,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import { toast } from "react-toastify";
  
  import { useState } from "react";
  import { useForm } from "react-hook-form";
  const EditAmountRecieveDialog = ({
    handleConfirmation,
    message,
    render,
    ...props
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
  
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const handleEdit = () => {
    //   handleConfirmation(formData);
    const amountToSend = parseFloat(formData.amountReceived);
    handleConfirmation({ ...formData, amountReceived: amountToSend });
      setIsPopupOpen(false);
    };
    const inputFieldData = {
      _id: props._id,
      amountReceived: props.amountReceived,
    };
    const [formData, setFormData] = useState(inputFieldData);
  
    const handleChange = (event) => {
    //   setFormData({ ...formData, [event.target.name]: event.target.value });
    const newAmount = event.target.value;
    setFormData({ ...formData, amountReceived: newAmount });
    };
  
    const handleClose = () => {
      setFormData(inputFieldData);
      setIsPopupOpen(false);
    };
  
    const handlePopupOpen = () => setIsPopupOpen(true);
  
    return (
      <>
        <Dialog open={isPopupOpen}>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Edit Amount Received
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid
                item
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"column"}
                gap={"5px"}
                width={"90%"}
                margin={"10px"}
              >
                <InputLabel fullWidth>New Amount Received</InputLabel>
                <TextField
                  type="number"
                  placeholder="New Amount Receive"
                  name="amountReceived"
                  value={formData.amountReceived}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>cancel</Button>
            <Button
              type="submit"
              onClick={() => {
                handleEdit();
              //   toast.success("Credit Limit updated successfully");
              }}
            >
              save
            </Button>
          </DialogActions>
        </Dialog>
        {render(handlePopupOpen, isPopupOpen)}
      </>
    );
  };
  
  export default EditAmountRecieveDialog;
  