import { yupResolver } from "@hookform/resolvers/yup";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AdditionalDialogValidation } from "util/validations/commonValidations";
import MDButton from "./../../components/MDButton/index";
import { addAdditionalTrainings } from "./Redux/actionCreator";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const AdditionalTrainingDialogContent = ({ handleDialogClose }) => {
  const dispatch = useDispatch();
  // const validationSchema = yup.object().shape({
  //   roleName: yup
  //     .string()
  //     .required("Name is Required")
  //     .matches(/^[aA-zZ\s]+$/, "Invalid Name")
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AdditionalDialogValidation),
  });
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (event) => setOpenDialog(event.currentTarget);
  const handleCloseDialog = () => setOpenDialog(false);
  const onSubmit = (data) => {
   
    dispatch(addAdditionalTrainings(data));
    handleDialogClose();
  };
  return (
    <div>
      {/* <DialogTitle>Create A New Additional Training</DialogTitle> */}
      {/* <Divider /> */}
      {/* <DialogContent> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("name")}
              error={errors?.name?.message}
              helperText={errors?.name?.message}
            />
          </Grid>
        </Grid>

        <MDButton variant="contained" color="info" type="submit">
          create
        </MDButton>
        {/* <MDButton variant="text" color="info" onClick={handleCloseDialog}>
              cancel
            </MDButton> */}
      </form>
      {/* </DialogContent> */}
      {/* <Divider /> */}
    </div>
  );
};
export default AdditionalTrainingDialogContent;
