import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  getValues,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Controller } from "react-hook-form";
import { fontWeight } from "@mui/system";

const MultiSelectDropdown = ({
  options,
  name,
  control,
  setValue,
  getValues,
  customOnChange,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      margin: 0,
    },
    selectStyle: {
      height: 40,
    },

    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        overflowX: "scroll",
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(true);
  const optionsValues = [];
  const defaultValue = getValues(name);
  const [updateFlag, setUpdateFlag] = useState(false);

  if (options) {
    options?.map((option) => {
      optionsValues.push(option.value);
    });
  }
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
   
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : optionsValues);

      customOnChange(optionsValues);
      return;
    }
    setSelected(value);

    customOnChange(value);
  };

  useEffect(() => {
    if (!defaultValue) {
    
      return;
    }

    if (defaultValue == []) {
      setSelected([]);
    }

    let values = [];
    if (defaultValue !== undefined && options.length > 0) {
      defaultValue?.map((obj) => {
        values.push(obj?.name ? obj?.name?.full? obj?.name?.full :obj?.name:obj);
       
      });
     

      if (!updateFlag) {
        setSelected(values);
        setUpdateFlag(true);
      }
    }
  }, [defaultValue, options]);

  return (
    <FormControl className={classes.formControl}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            multiple
            value={selected}
            onChange={handleChange}
            className={classes.selectStyle}
            placeholder="Select"
            onOpen={() => setOpen(!open)}
            onClose={(e) => {
              setOpen(!open);
            }}
            IconComponent={() =>
              open ? (
                <KeyboardArrowDownIcon fontSize="medium" />
              ) : (
                <KeyboardArrowUpIcon fontSize="medium" />
              )
            }
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < options.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option?.key} value={option?.value}>
                <ListItemIcon>
                  <Checkbox checked={selected.indexOf(option?.value) > -1} />
                </ListItemIcon>
                <ListItemText primary={option?.value} />
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default MultiSelectDropdown;
