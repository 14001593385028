const state = {
  Invoice_Details_Data: {
    invoiceDetailsLoading: true,
    invoiceDetailsError: "",
    invoiceDetailsInfo: [],
    total: 0,
  },
  Invoice_Filtered_Data: {
    invoiceFilteredLoading: true,
    invoiceFilteredError: "",
    invoiceFilteredInfo: [],
  },
  Invoice_FinanceBy_Data: {
    invoiceFinanceByLoading: true,
    invoiceFinanceByError: "",
    invoiceFinanceByInfo: [],
  },
  Invoice_OperationBy_Data: {
    invoiceOperationByLoading: true,
    invoiceOperationByError: "",
    invoiceOperationByInfo: [],
  },
  Invoice_Data: {
    invoiceDataLoading: true,
    invoiceDataError: "",
    invoiceDataInfo: [],
  },

  Bank_Details_Data: {
    bankDetailsLoading: true,
    bankDetailsError: "",
    bankDetailsInfo: [],
  },

  Invoice_Tax_Details_Data: {
    invoiceTaxDetailsLoading: true,
    invoiceTaxDetailsError: "",
    invoiceTaxDetailsInfo: [],
    total: 0,
  },

  Invoice_Tax_Data: {
    invoiceTaxDataLoading: true,
    invoiceTaxDataError: "",
    invoiceTaxDataInfo: [],
  },

  Invoice_Tax_Filtered_Data: {
    invoiceTaxFilteredLoading: true,
    invoiceTaxFilteredError: "",
    invoiceTaxFilteredInfo: [],
  },
  currentPage: 0,
  pageSize: 10,
  searchKey: "",
  branch: "",
  startDate: "",
  endDate: "",
  startDueDate: "",
  endDueDate: "",

};

export default state;
