// import { IconButton, InputLabel, TextField } from "@mui/material";
// import { FormHelperText } from "@material-ui/core";
// import React, { useEffect } from "react";
// import MDButton from "./../../../components/MDButton/index";
// import Grid from "@mui/material/Grid";
// import { useState } from "react";
// import SelectDropDown from "./../../../examples/DropDown/index";
// import CloseIcon from "@mui/icons-material/Close";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
// import { addJobApplication, getJobJobsList } from "../Redux/actionCreator";
// import { toast } from "react-toastify";
// import { ApplicationDialogValidation } from "util/validations/hospitalPanelValidation";
// import SelectDropDownNew from "examples/DropDownNew";

// const CreateApplicationDialog = ({ handleDialogClose }) => {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     getValues,
//     control,
//     formState: { errors },
//   } = useForm({
//      resolver: yupResolver(ApplicationDialogValidation),
//   });

//   console.log("errors", errors)

//   const selectOptions = [
//     {
//       key: "OPEN",
//       value: "OPEN",
//     },
//     {
//       key: "ACCEPTED",
//       value: "ACCEPTED",
//     },
//     {
//       key: "REJECTED",
//       value: "REJECTED",
//     },
//     {
//       key: "PENDING_TEST",
//       value: "PENDING_TEST",
//     },
//     {
//       key: "REVIEW",
//       value: "REVIEW",
//     },
//     {
//       key: "PENDING_INTERVIEW",
//       value: "PENDING_INTERVIEW",
//     },
//     {
//       key: "FINISHED",
//       value: "FINISHED",
//     },
//     {
//       key: "WITHDRAW",
//       value: "WITHDRAW",
//     },
//   ];

//   //Fetching Organization Dropdown Details
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { OrganizationsData } = useSelector((state) => state.common);
//   const organizationInfo = OrganizationsData?.organizationsInfo;
  
//   const organizationOptions = [];

//   const { UsersData } = useSelector((state) => state.common);
//   const userInfo = UsersData?.usersInfo;
 
//   const[organization,setOrganizationn]=useState();
//   const[job,setJob]=useState();
//   const[professional,setProfession]=useState();
//   const[state,setState]=useState();

//   const userOptions = [];

//   userInfo.map((ele) => {
//     let obj = {
//       key: ele._id,
//       value: ele.name,
//     };

//     userOptions.push(obj);
//   });

//   const handleProfessionalChange = (e) => {
//     if(errors?.professional?.message)
//     errors.professional.message="";
  
//     // setValue("professional", e.target.value);
//     let userID;
//     //Searching for the name in the Users array to find the ID
//     userOptions.map((obj) => {
//       if (obj.value == e.target.value) {
//         userID = obj.key;
//       }
//     });
//     setValue("professional", userID);
//     setProfession(userID);
//   };

//   organizationInfo.map((ele) => {
//     let obj = {
//       key: ele._id,
//       value: ele.name,
//     };

//     organizationOptions.push(obj);
//   });

//   const handleOrganizationChange = (e) => {
//     // setValue("organization", e.target.value);
//     if(errors?.organization?.message)
//     errors.organization.message="";
//     let userID;
//     //Searching for the name in the Users array to find the ID
//     organizationOptions.map((obj) => {
//       if (obj.value == e.target.value) {
//         userID = obj.key;
//       }
//     });

    
//     setValue("organization", userID);
//     setOrganizationn(userID);
//   };

//   const { job_Data } = useSelector((state) => state.job);
//   const jobCode = job_Data?.job_Data_info;

//   let jobOptions = [];

//   jobCode.map((ele) => {
//     let obj = {
//       key: ele._id,
//       value: ele.code,
//     };

//     jobOptions.push(obj);
//   });

//   const handleJobChange = (e) => {
//     if( errors?.job?.message)
//     errors.job.message="";
//     // setValue("organization", e.target.value);
//     let userID;
//     //Searching for the name in the Users array to find the ID
//     jobOptions.map((obj) => {
//       if (obj.value == e.target.value) {
//         userID = obj.key;
//       }
//     });

   
//     setValue("job", userID);
//     setJob(userID);
//   };

//   useEffect(() => {
//     dispatch(setOrganizationsDropdown());
//     dispatch(setUsersDropdown());
//     dispatch(getJobJobsList());
//   }, []);

//   const onSubmit = (data) => {
   
  
//       dispatch(addJobApplication(data));
//       handleDialogClose();
    
   
   
   
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <Grid container spacing={2}>
//           <Grid item xs={4}>
//             <InputLabel fullWidth>Organization</InputLabel>
//           </Grid>
//           <Grid item xs={8}>
//             <SelectDropDownNew
//               options={organizationOptions}
//               placeholder="Organization"
//               control={control}
//               name="organization"
//               defaultValue="none"
//               getValues={getValues}
//               // {...register("organization")}
//               customOnChange={handleOrganizationChange}
//             />
//             <FormHelperText>{errors?.organization?.message}</FormHelperText>
//           </Grid>
//           <Grid item xs={4}>
//             <InputLabel fullWidth>Job</InputLabel>
//           </Grid>
//           <Grid item xs={8}>
//             <SelectDropDownNew
//               options={jobOptions}
//               placeholder="Job"
//               control={control}
//               name="job"
//               defaultValue="none"
//               getValues={getValues}
//               // {...register("job")}
//               customOnChange={handleJobChange}
//             />
//             <FormHelperText>{errors?.job?.message}</FormHelperText>
//           </Grid>
//           <Grid item xs={4}>
//             <InputLabel fullWidth>Professional</InputLabel>
//           </Grid>
//           <Grid item xs={8}>
//             <SelectDropDownNew
//               options={userOptions}
//               placeholder="Professional"
//               control={control}
//               name="professional"
//               defaultValue="none"
//               getValues={getValues}
//               // {...register("professional")}
//               customOnChange={handleProfessionalChange}
//             />
//             <FormHelperText>{errors?.professional?.message}</FormHelperText>
//           </Grid>
//           <Grid item xs={4}>
//             <InputLabel fullWidth>State</InputLabel>
//           </Grid>
//           <Grid item xs={8}>
//             <SelectDropDownNew
//               options={selectOptions}
//               placeholder="State"
//               control={control}
//               name="state"
//               defaultValue="none"
//               getValues={getValues}
//               customOnChange={(e) => {
//                 if(errors?.state?.message)
//                 errors.state.message="";
//                 setValue("state", e.target.value);
//                 setState(e.target.value);
                
//               }}
//             />
//             <FormHelperText>{errors?.state?.message}</FormHelperText>
//           </Grid>
//           <MDButton
//             variant="contained"
//             color="info"
//             sx={{ marginTop: "2rem", marginLeft: "1rem" }}
//             type="submit"
//           >
//             create
//           </MDButton>
//         </Grid>
//       </form>
//     </div>
//   );
// };

// export default CreateApplicationDialog;



import React, { useEffect, useState } from "react";
import { Grid, InputLabel, FormHelperText, Select, MenuItem } from "@mui/material";
import MDButton from "./../../../components/MDButton/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setUsersDropdown, setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addJobApplication, getJobJobsList , getAllJobsList} from "../Redux/actionCreator";
import { toast } from "react-toastify";
import { ApplicationDialogValidation } from "util/validations/hospitalPanelValidation";
import SearchableDropdown from "./SearchableDropdown";
import SelectDropDownNew from "examples/DropDownNew";


const CreateApplicationDialog = ({ handleDialogClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ApplicationDialogValidation),
  });

  const [organization, setOrganization] = useState("");
  const [job, setJob] = useState("");
  const [professional, setProfessional] = useState("");
  const [state, setState] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { OrganizationsData } = useSelector((state) => state.common);
  const organizationInfo = OrganizationsData?.organizationsInfo;

  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;

  const { job_Data, job_All_Data } = useSelector((state) => state.job);
  const jobCode = job_All_Data?.job_All_Data_info;

    const selectOptions = [
    {
      key: "OPEN",
      value: "OPEN",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "PENDING_TEST",
      value: "PENDING_TEST",
    },
    {
      key: "REVIEW",
      value: "REVIEW",
    },
    {
      key: "PENDING_INTERVIEW",
      value: "PENDING_INTERVIEW",
    },
    {
      key: "FINISHED",
      value: "FINISHED",
    },
    {
      key: "WITHDRAW",
      value: "WITHDRAW",
    },
  ];

  const organizationOptions = organizationInfo?.map((ele) => ({
    key: ele._id,
    value: ele.name,
  })) || [];

  const userOptions = userInfo?.map((ele) => ({
    key: ele._id,
    value: ele.name,
  })) || [];

  const jobOptions = jobCode?.map((ele) => ({
    key: ele._id,
    value: ele.code,
  })) || [];

  useEffect(() => {
    dispatch(setOrganizationsDropdown());
    dispatch(setUsersDropdown());
    dispatch(getAllJobsList());
    // dispatch(getJobJobsList());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addJobApplication(data));
    handleDialogClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel fullWidth>Organization</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SearchableDropdown
              options={organizationOptions}
              selectedValue={organization}
              onChange={(value) => {
                setValue("organization", value);
                setOrganization(value);
              }}
              placeholder="Select Organization"
            />
            <FormHelperText>{errors?.organization?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Job</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SearchableDropdown
              options={jobOptions}
              selectedValue={job}
              onChange={(value) => {
                setValue("job", value);
                setJob(value);
              }}
              placeholder="Select Job"
            />
            <FormHelperText>{errors?.job?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Professional</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SearchableDropdown
              options={userOptions}
              selectedValue={professional}
              onChange={(value) => {
                setValue("professional", value);
                setProfessional(value);
              }}
              placeholder="Select Professional"
            />
            <FormHelperText>{errors?.professional?.message}</FormHelperText>
          </Grid>

          <Grid item xs={4}>
            <InputLabel fullWidth>State</InputLabel>
           </Grid>
           <Grid item xs={8}>
             <SelectDropDownNew
               options={selectOptions}
               placeholder="State"
               control={control}
               name="state"
               defaultValue="none"
               getValues={getValues}
               customOnChange={(e) => {
                 if(errors?.state?.message)
                 errors.state.message="";
                 setValue("state", e.target.value);
                 setState(e.target.value);
                
               }}
             />
             <FormHelperText>{errors?.state?.message}</FormHelperText>
           </Grid>

          
          
          {/* <Grid item xs={4}>
            <InputLabel fullWidth>State</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <Select
              fullWidth
              value={state || ''}
              onChange={(e) => {
                if (errors?.state?.message) errors.state.message = "";
                setValue("state", e.target.value);
                setState(e.target.value);
              }}
              displayEmpty
            >
              {selectOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.state?.message}</FormHelperText>
          </Grid> */}
          <MDButton
            variant="contained"
            color="info"
            sx={{ marginTop: "2rem", marginLeft: "1rem" }}
            type="submit"
          >
            Create
          </MDButton>
        </Grid>
      </form>
    </div>
  );
};

export default CreateApplicationDialog;
