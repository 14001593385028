import { IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import EditCreditLimitDialog from "./EditCreditLimitDialog";
import EditTDSDialog from "./EditTDSDialog";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { hospitalBranch } from "services/hospitalServices";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";
import moment from "moment";
import { UpdateHospital } from "../hospital/Redux/actionCreator";

const LocumCreditLimit = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  function CreditId(params) {
    // console.log("1-> ", params);
    var a;
    var rowName = params?.row?._id;
    // console.log("2-> ", rowName);
    return rowName;
    // if (rowName) {
    //   console.log("3-> ", `${a}`);
    //   return `${a}`;
    // } else {
    //   a = "NA";
    //   return `${a}`;
    // }
  }

  function getBranchName(params) {
    var a;
    var rowName = params?.row?.jobLocation?.branchName;
    return rowName;
    // if (rowName) {
    //   return `${a}`;
    // } else {
    //   a = "NA";
    //   return `${a}`;
    // }
  }

  function getLimit(params) {
    var a;
    var rowName = params?.row?.creditLimit;
    return rowName;
    // if (rowName) {
    //   return `${a}`;
    // } else {
    //   a = "NA";
    //   return `${a}`;
    // }
  }

  function getRLimit(params) {
    // var a;
    var rowName = params?.row?.utilizedLimit;
    return rowName;
    // if (rowName) {
    //   return `${a}`;
    // } else {
    //   a = "NA";
    //   return `${a}`;
    // }
  }

  function getTDS(params) {
    var a;
    var rowName = params?.row?.tds;
    const b = "%"
    return rowName+b;
    // if (rowName) {
    //   return `${a}%`;
    // } else {
    //   a = "NA";
    //   return `${a}`;
    // }
  }

  function getCreatedAT(params) {
    var a;
    var rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  function getUpdatedAT(params) {
    var a;
    var rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowID = cellValues?.row?._id;
        // return <Link to={`${rowID}`}>{rowID}</Link>;
        return rowID;
      },
      flex: 1.2,
      valueGetter: CreditId,
    },
    {
      field: "branchName",
      headerName: `BranchName`,
      renderCell: (cellValues) => {
        console.log(cellValues);
        const branchName = cellValues?.row?.jobLocation?.branchName;
        // return branchName;
        return (
          <Tooltip title={branchName} arrow>
            {/* <div>{branchName}</div> */}
             <Link to={`/hospital/branch/${cellValues?.row?._id}`}>{branchName}</Link>
          </Tooltip>
        );
      },
      flex: 1.2,
      valueGetter: getBranchName,
    },
    {
      field: "creditLimit",
      headerName: `Limit`,
      renderCell: ({ row }) => (
        <>
          {row.creditLimit ? (
            <>
              <span>{row.creditLimit}</span>
              <EditCreditLimitDialog
                handleConfirmation={(formData) => {
                  handleEditJobDetail(formData);
                }}
                _id={row._id}
                creditLimit={row.creditLimit}
                render={(handlePopupOpen) => (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ padding: 4, marginTop: -4, fontSize: 16 }}
                  >
                    <Edit fontSize="small" onClick={handlePopupOpen} />
                  </IconButton>
                )}
              />
            </>
          ) : (
            <span>NA</span>
          )}
        </>
      ),
      flex: 1.2,
      valueGetter: getLimit,
    },
    { 
      field: "utilizedLimit",
      headerName: `Utilized Limit`,
      renderCell: (cellValues) => {
        // console.log("1--->", cellValues)
        // let amount = "NA";
        let remainingLimit = cellValues?.row?.utilizedLimit;
        return remainingLimit
        // if (amount) {
        //   return `${amount}`;
        // } else {
        //   amount = "NA";
        //   return `${amount}`;
        // }
      },
      flex: 1.2,
      valueGetter: getRLimit,
    },

    {
      field: "tds",
      headerName: `TDS`,
      renderCell: ({ row }) => (
        <>
          {row.tds ? (
            <>
              <span>{row.tds}%</span>
              <EditTDSDialog
                handleConfirmation={(formData) => {
                  handleEditTDSDetail(formData);
                }}
                _id={row._id}
                tds={row.tds}
                render={(handlePopupOpen) => (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ padding: 4, marginTop: -4, fontSize: 16 }}
                  >
                    <Edit fontSize="small" onClick={handlePopupOpen} />
                  </IconButton>
                )}
              />
            </>
          ) : (
            <span>NA</span>
          )}
        </>
      ),
      // renderCell: (cellValues) => {
      //     console.log("render", cellValues)
      //   let amount = "NA"
      //   amount = cellValues?.row?.tds;
      //   if(amount){
      //       return `${amount}`
      //   }else{
      //       amount = "NA"
      //       return `${amount}`
      //   }
      // },
      flex: 1.2,
      valueGetter: getTDS,
    },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        var rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      type: "date",

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a;
        var rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      type: "date",

      valueGetter: getUpdatedAT,
    },
  ];

  const handleEditJobDetail = async (formData) => {
    // console.log("formData>>", formData)
    const { _id, creditLimit } = formData;

    try {
      // Dispatch an action to update credit limit in the backend
      const response = await dispatch(
        UpdateHospital({ id: _id, payload: { creditLimit } })
      );
      if (response && response.error) {
        console.error("Failed to update credit limit:", response.error);
      } else {
        dispatch(HospitalBranchList([]));
        // console.log("Credit limit updated successfully");
      }
    } catch (error) {
      console.error("Error updating credit limit:", error);
    }
  };

  const handleEditTDSDetail = async (formData) => {
    // console.log("formData>>", formData)
    const { _id, tds } = formData;

    try {
      // Dispatch an action to update credit limit in the backend
      const response = await dispatch(
        UpdateHospital({ id: _id, payload: { tds } })
      );
      if (response && response.error) {
        console.error("Failed to update credit limit:", response.error);
      } else {
        dispatch(HospitalBranchList([]));
        // console.log("Credit limit updated successfully");
      }
    } catch (error) {
      console.error("Error updating credit limit:", error);
    }
  };

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  let BranchData = hospital_branch_Data?.branchInfo;
  //   console.log("---->", BranchData);

  useEffect(() => {
    dispatch(HospitalBranchList([]));
  }, []);

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  return (
    <DashboardLayout>
      {" "}
      <DashboardNavbar />
      <FilterBar
        title1="Credit Limit"
        showDialog={false}
        showisDeleted={false}
      />{" "}
      <MDBox sx={{ marginTop: "2rem" }}>
        {" "}
        <DataGrid
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              csvOptions: {
                fileName: `CreditLimit-${formatDate()}`,
              },
            },
          }}
          columns={columns}
          rows={[...BranchData].reverse()}
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />{" "}
      </MDBox>{" "}
    </DashboardLayout>
  );
};
export default LocumCreditLimit;
