import axiosInstance from "../axios";

export const fetchJobDetails = async () => {
  try {
    const response = await axiosInstance.get(`/jdRule/getAllJdRules`);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.msg };
  }
};

export const uploadJobDescriptionFile = async (payload) => {
  try {
    var formData = new FormData();
    formData.append("image", payload);
    const response = await axiosInstance.post(
      `jdRule/uploadJdRules`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

export const DeleteJobDetail = async (payload) => {
  try {
    const response = await axiosInstance.delete("/jdRule/deleteJd", {
      data: payload,
    });
    return { response: response };
  } catch (e) {
    return { error: e.response.data.msg };
  }
};

export const editJobDetail = async (payload) => {
  try {
    const response = await axiosInstance.patch("/jdRule/updateJd", payload);
    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

export const addSingleJobDetail = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/jdRule/uploadSingleJD`,
      payload
    );
    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};
