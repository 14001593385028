import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import {
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MDBox from "../../components/MDBox/index";
import MDInput from "../../components/MDInput";
import SelectDropDown from "../../examples/DropDown/index";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAdditionalTrainingById } from "services/commonServices";
import { setUsersDropdown, UpdateAdditionall } from "./Redux/actionCreator";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const AdditionalTrainingForm = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const ID = window.location.href.split("additionalTrainings")[1].split("/")[1];
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
  
    let response = await getAdditionalTrainingById(ID);
   
    setResponseData(response);
  
    return response[0];
  };

  const navigate = useNavigate();

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];
  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: () => getData() });

  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;
 
  const userOptions = [];
  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    userOptions.push(obj);
  });
  const onSubmit = (data) => {
   
    const payload = {
      name: data.name,
      isDeleted: data.isDeleted,
      
    };
    dispatch(UpdateAdditionall({ payload: payload, id: ID })).then(() => {
      navigate("/common/additionalTrainings");
    });
  };
  const handleReset = () => {
    reset();
  };


  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID; //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;

    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

   
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setUsersDropdown());
  }, []);

  const formValues = getValues();
 
 
  const [created, setCreated] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {


    let createdAT = getValues("createdAt");
    let updatedAT = getValues("updatedAt");
  
    if(createdAT=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updatedAT=="Invalid date")
    {
      setValue("updatedAt","");
    }
    if (createdAT != undefined && createdAT !=="") {
      if (!created) {
        setValue("createdAt", Moment(createdAT).format("DD/MM/YY"));

        setCreated(true);
      }
    }
   
   

    if (updatedAT != undefined && updatedAT!=="") {
      if(!updated) {
        setValue("updatedAt", Moment(updatedAT).format("DD/MM/YY"));

        setUpdated(true);
      }
    }
   
  }, [getValues("createdAt"),getValues("updatedAt")]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex" ,width:"70px"}}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body">ID: {ID}</Typography>
                  </Grid>

                  {/* is Deleted */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isDeleted"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Is Deleted"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdBy.name.full")}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedBy.name.full")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default AdditionalTrainingForm;
