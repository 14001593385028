import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  clearError as clearErrorReducer,
  login,
  logout,
} from "./actionCreator";
import initialState from "./state";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearError: clearErrorReducer,
  },
  extraReducers: (builder) => {
    //LOGIN
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.t_id = action?.payload;
      state.loading = false;
      state.error = "";
      toast.success("Login Successfully", {
        position: "top-right",
      });
      localStorage.removeItem("key");

    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      if (localStorage.getItem("key") == 1) {
        toast.error("Invalid Credentials", {
          position: "top-right",

        });
        localStorage.setItem("key", "0")
      }

    });

    //LOGOUT
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.t_id = "";
      state.loading = false;
      state.error = "";
      state.success = true;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action?.payload;
    });
  },
});

export const { clearError } = authSlice.actions;
export default authSlice.reducer;
