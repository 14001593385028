//*******Job Actions*********

import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getJobApplicationByIdService } from "shared/services";
import { getJobJobsService } from "shared/services";
import { addNewJobJobsService } from "shared/services";
import { updateJobJobsService } from "shared/services";
import { deletePermanentJobService } from "shared/services";
import { getPermanentJobByIdService } from "shared/services";
// import { updatePermanentJobService } from "shared/services";
import { addNewPermanentJobService } from "shared/services";
import { getPermanentJobService } from "shared/services";
import { getJobJobsByIdService } from "shared/services";
import { deleteJobJobsService } from "shared/services";
import { updateJobApplicationService } from "shared/services";
import { addNewJobApplicationService } from "shared/services";
import { deleteJobApplicationService } from "shared/services";
import { getJobApplicationService } from "shared/services";
import {getFilteredService} from "shared/services";
import {getFilteredApp} from "shared/services";
import {getAllJobApplicationService} from "shared/services";
import {getAllJobsData} from "shared/services";

//--> Create Job
export const createJob = createAsyncThunk(
  "hospital/createJob",
  async (payload, thunkAPI) => {
    
  }
);

//--> Update Job
export const updateJob = createAsyncThunk(
  "hospital/updateJob",
  async (payload, thunkAPI) => {
    
  }
);

//--> Delete Job
export const deleteJob = createAsyncThunk(
  "hospital/deleteJob",
  async (payload, thunkAPI) => {
    
  }
);

//******************Job Application************************////

// List Job Application

export const getJobApplicationList = createAsyncThunk(
  "/JobApplication/listJobApplication",
  async (payload, thunkAPI) => {
    // console.log("51-->", payload)
    const response = await getJobApplicationService(payload);
    // console.log(response)
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

//filter service application
export const getFilteredListApp = createAsyncThunk(
  "/JobApplication/filteredJobsApp",
  async (payload, thunkAPI) => {
    // console.log("121-->",payload)
    const response = await getFilteredApp(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

//get all service application
export const getAllListApp = createAsyncThunk(
  "/JobApplication/listAll",
  async (payload, thunkAPI) => {
    // console.log("121-->",payload)
    const response = await getAllJobApplicationService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

// Delete Job Application
export const deleteJobApplication = createAsyncThunk(
  `/JobApplication/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteJobApplicationService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getJobApplicationList());
    }
    return response;
  }
);
//Add Job Application
export const addJobApplication = createAsyncThunk(
  "/JobApplication",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewJobApplicationService(payload);
    thunkAPI.dispatch(getJobApplicationList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getJobApplicationByID = createAsyncThunk(
  "/JobApplication/listJobApplication/id",
  async (payload, thunkAPI) => {
    const response = await getJobApplicationByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
export const updateJobApplication = createAsyncThunk(
  "/JobApplication/update",
  async (payload, thunkAPI) => {
  
    const {error,response} = await updateJobApplicationService(payload);
    if (error) {
     
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//******************Job Job************************////

// List Job Job
// export const setCurrentPage = createAction('setCurrentPage');
// export const setPageSize = createAction('setPageSize');
export const getJobJobsList = createAsyncThunk(
  "/JobJobs/listJobJobs",
  async (payload, thunkAPI) => {
    // console.log("3-->",payload)
    const response = await getJobJobsService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//getAllJobsData

export const getAllJobsList = createAsyncThunk(
  "/ALlJobs/listAllobs",
  async (payload, thunkAPI) => {
    // console.log("3-->",payload)
    const response = await getAllJobsData(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);


export const getFilteredList = createAsyncThunk(
  "/JobJobs/filteredJobs",
  async (payload, thunkAPI) => {
    // console.log("121-->",payload)
    const response = await getFilteredService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

// Delete Job Job
export const deleteJobJobs = createAsyncThunk(
  `/JobJobs/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteJobJobsService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getJobJobsList());
    }
    return response;
  }
);
//Add Job Job
export const addJobJobs = createAsyncThunk(
  "/JobJobs",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewJobJobsService(payload);
    thunkAPI.dispatch(getJobJobsList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getJobJobsByID = createAsyncThunk(
  "/JobJobs/listJobJobs/id",
  async (payload, thunkAPI) => {
    const response = await getJobJobsByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
export const updateJobJobs = createAsyncThunk(
  "/JobJobs/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updateJobJobsService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//******************Job Permanent Job************************////

// List Job Permanent Job

// export const getPermanentJobList = createAsyncThunk(
//   "/PermanentJob/listPermanentJob",
//   async (payload, thunkAPI) => {
//     const response = await getPermanentJobService(payload);
//     // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
//     return response;
//   }
// );
// // Delete Job Permanent Job
// export const deletePermanentJob = createAsyncThunk(
//   `/PermanentJob/delete`,
//   async (payload, thunkAPI) => {
//     const response = await deletePermanentJobService(payload);
//     if (response?.status === 200) {
//       thunkAPI.dispatch(getPermanentJobList());
//     }
//     return response;
//   }
// );
// //Add Job Permanent Job
// export const addPermanentJob = createAsyncThunk(
//   "/PermanentJob",
//   async (payload, thunkAPI) => {
//     const { response, error } = await addNewPermanentJobService(payload);
//     thunkAPI.dispatch(getPermanentJobList());
//     if (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//     return response;
//   }
// );
// export const getPermanentJobByID = createAsyncThunk(
//   "/PermanentJob/listPermanentJob/id",
//   async (payload, thunkAPI) => {
//     const response = await getPermanentJobByIdService(payload);
  
//     return response;
//   }
// );
// export const updatePermanentJob = createAsyncThunk(
//   "/PermanentJob/update",
//   async (payload, thunkAPI) => {
   
//     const response = await updatePermanentJobService(payload);
   
//     return response;
//   }
// );
