

import { Checkbox, CircularProgress, styled } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { setRoleType } from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation } from "react-router-dom";
import { Link, useLocation, useParams } from "react-router-dom";
import { getAdminUsers } from "services/adminServices";
// import { formatDate } from "util/Helper";
import { formatDate } from "../../util/Helper";
import MDBox from "../../components/MDBox/index";
import { Box } from "@mui/system";
// import { getAdminUser } from "../Redux/actionCreator";
import { getAdminUser } from "../dashboards/Redux/actionCreator"; 
import { getreduxAvalibilityList, getPermanentJobList } from "./Redux/actionCreator";
import { getUsers } from "services/userServices"; 
import AddAdminUserDialogContent from "./JobFillForm";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

function PermanentJob({ dialog, title }) {
    const { state } = useLocation();
  const { id } = useParams();
  // console.log(id, "Id");
  const dispatch = useDispatch();


  function getPermanentJobId(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getJobCode(params) {
    return `${params?.row?.code || "NA"}`;
  }

  function getAddress(params) {
    return `${params?.row?.address?.city || "NA"}`;
  }

  function getClientName(params) {
    return `${params?.row?.client?.name || "NA"}`;
  }

  function getProfessionalName(params) {
    return `${params?.row?.candidateType?.name || "NA"}`;
  }

  function getDepartment(params) {
    return `${params?.row?.department?.name || "NA"}`;
  }

  function getState(params) {
    return `${params?.row?.state || "NA"}`;
  }

  function getCreatedAt(params) {
    const roleName = params?.row?.createdAt;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }
  function getUpdatedAt(params) {
    const roleName = params?.row?.updatedAt;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }
  function getType(params) {
    return `${params?.row?.type || "NA"}`;
  }
  
  function getLTCStartDate(params) {
    // return `${params?.row?.type || "NA"}`;
    var a
        const rowName = params?.row?.ltcDateRange?.startDate;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
  }
  
  function getLTCEndDate(params) {
    // return `${params?.row?.type || "NA"}`;
    var a
        const rowName = params?.row?.ltcDateRange?.startDate;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
  }
  
  function getNoOfJob(params) {
    return `${params?.row?.numberOfJobs || "NA"}`;
  }
  


  const columns = [
    {
      field: "ID",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?._id;
        return roleName;
        // return <Link to={`/availbilityid/${roleName}`}>{roleName}</Link>;
      },
      valueGetter: getPermanentJobId,
    },
    {
      field: "code",
      headerName: `Job ID`,
      renderCell: (cellValues) => {
        const roleCode = cellValues?.row?.code;
        const rowCode = cellValues?.row?.code;
        const rowId = cellValues?.row?._id
        // return roleCode;
        return <Link to={`code/${rowCode}/${rowId}`}>{rowCode}</Link>;
      },
      flex: 1,
      valueGetter: getJobCode,
    },

    {
      field: "address",
      headerName: `City`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.address?.city;
        return rowName;
      },
      flex: 1,
      valueGetter: getAddress,
    },

    {
      field: "client",
      headerName: `Client Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.client?.name;
        // return rowName;
        return (
          <Tooltip title={rowName} arrow>
            <div>{rowName}</div>
          </Tooltip>
        );
      },
      flex: 1,
      valueGetter: getClientName
    },

    {
      field: "candidateType",
      headerName: `Professional Type`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.candidateType?.name;
        return roleName;
      },
      flex: 1.5,
      valueGetter: getProfessionalName,
    },

    {
      field: "department",
      headerName: "Department",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.department?.name;

        // return roleName;
        return (
          <Tooltip title={roleName} arrow>
            <div>{roleName}</div>
          </Tooltip>
        );
      },
      flex: 1,
      valueGetter: getDepartment,
    },

    // {
    //   field: "Joining Date",
    //   headerName: "Joining Date",
    //   renderCell: (cellValues) => {
    //     const roleName = cellValues?.row;

    //     return roleName;
    //   },
      // type: "date",
      // valueGetter: getavailabilityEnd,
    // },
    {
      field: "state",
      headerName: "State",
      renderCell: (cellValues) => {
        // console.log("1-->", cellValues)
        const roleName = cellValues?.row?.state;
        return roleName;
      },
      flex: 1,
      valueGetter: getState,
    },
    {
      field: "numberOfJobs",
      headerName: "Number Of Jobs",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.numberOfJobs;
        return roleName;
      },
      flex: 1,
      valueGetter: getNoOfJob,
    },
    {
      field: "type",
      headerName: "Job Type",
      renderCell: (cellValues) => {
        // console.log("celllvalues>>>",cellValues)
        const roleName = cellValues?.row?.type;
        // return roleName;
        return (
          <Tooltip title={roleName} arrow>
            <div>{roleName}</div>
          </Tooltip>
        );
      },
      flex: 1,
      valueGetter: getType,
    },
    {
      field: "jobTier",
      headerName: "Job Tier",
      renderCell: (cellValues) => {
        // console.log("celllvalues>>>",cellValues)
        const roleName = cellValues?.row?.jobTier;
        const rowName = roleName || "NA";
        return (
          <Tooltip title={rowName} arrow>
            <div>{rowName}</div>
          </Tooltip>
        );
      },
      flex: 1,
      // valueGetter: getType,
    },
    {
      field: "LTCstartdate",
      headerName: "LTC Start Date",
      renderCell: (cellValues) => {
        // const roleName = cellValues?.row?.ltcDateRange?.startDate;
        // return roleName;
        var a
        const rowName = cellValues?.row?.ltcDateRange?.startDate;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          // return `${a}`;
          return (
            <Tooltip title={a} arrow>
              <div>{a}</div>
            </Tooltip>
          );
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      flex: 1,
      valueGetter: getLTCStartDate,
    },
    {
      field: "LTCenddate",
      headerName: "LTC End Date",
      renderCell: (cellValues) => {
        // const roleName = cellValues?.row?.ltcDateRange?.endDate;
        // return roleName;
        var a
        const rowName = cellValues?.row?.ltcDateRange?.endDate;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          // return `${a}`;
          return (
            <Tooltip title={a} arrow>
              <div>{a}</div>
            </Tooltip>
          );
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      flex: 1,
      valueGetter: getLTCEndDate,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.createdAt;
        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getCreatedAt,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.updatedAt;

        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getUpdatedAt,
    },
    
  ];

  const [isOpen, setisOpen] = useState(false);
  const [loading, setLoading] = useState(true);

 
 
  const { Permanent_Job_Data } = useSelector((state) => state.availability);
  // console.log("Permanent_Job_Data", Permanent_Job_Data)
  const softwares = Permanent_Job_Data?.Permanent_DataInfo;
  // console.log("permanentJobs", permanentJobs);
  var permanentLoading = Permanent_Job_Data?.Permanent_DataLoading;
  
  const { Availabilitys_Data } = useSelector((state) => state.availability);
  const users = Availabilitys_Data?.Availabilitys_DataInfo;
  // console.log(Availabilitys_Data, "availability");
  // const totalCount = Availabilitys_Data?.total;
  // const currentPage = useSelector((state) => state.availability.currentPage);
  // const pageSize = useSelector((state) => state.availability.pageSize);

  useEffect(() => {
    setLoading(permanentLoading);
  }, [permanentLoading]);


  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  useEffect(()=>{
    dispatch(getPermanentJobList())
  },[])



  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     if(id){
  //       await dispatch(getreduxAvalibilityList({ jobId: id }));  
  //     }else{
  //       await dispatch(
  //         getreduxAvalibilityList({
  //           currentPage: currentPage,
  //           pageSize: pageSize,
  //         })
  //       );
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  // ]);

  // const softwares = Availabilitys_Data?.Availabilitys_DataInfo;
  // console.log("251-->", softwares)

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
  }

  return (
    <DashboardLayout className="dashboardLayout">
      <DashboardNavbar />
      <FilterBar
        title="Create Job"
        title1="Permanent Job"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        showisDeleted={false}
        // handleDeleteApi={handleDeleteApi}
        dialogContent={
          <AddAdminUserDialogContent handleDialogClose={handleDialogClose} />
        }
      />
      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `PermanentJob-${formatDate()}`,
            },
          },
        }}
        density="compact"
        initialState={{
          columns: {
            columnVisibilityModel: {
              // _id: true,
              ID: false,
              // code: true,
             
            },
          },
        }}
        // isRowSelectable
        loading={permanentLoading ? true : false}
        columns={columns}
        rows={[...softwares]}
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
}

export default PermanentJob;

