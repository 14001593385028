import { Grid, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import SelectDropDown from "examples/DropDown";
import SelectDropDownNew from "examples/DropDownNew";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { uploadPhoto } from "services/eventServices";
import { position } from "stylis";

const DynamicDocuments = ({
  control,
  selectOptions,
  getValues,
  setValue,

  defaultValue,
  register,
}) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "documents", // unique name for your Field Array
  });

  //File Upload Handler
  const handleFileChange = async (e, index) => {
  
    const loadingToast = toast.loading("File Uploading", {
      position: "top-right",
    });
    const response = await uploadPhoto([e.target.files[0]]);
    if (response?.status == 200) {
     
      toast.dismiss(loadingToast);
      toast.success("File Uploaded Successfully", {
        position: "top-right",
      });
      setValue(`documents.${index}.files`, response?.data?.data[0]);
      setValue(`documents.${index}.uploadedAt`, "");
    } else {
      toast.error("Error in Uploading", {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      {/* Documents */}
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ padding: "1rem" }}>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              Documents
            </Typography>
            <hr style={{ marginBottom: "2rem" }} />
            <ul style={{ listStyleType: "none" }}>
              {fields.map((field, index) => (
                <li>
                  <Grid container spacing={2}>
                    {/* Doc TYpe */}
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Doc Type</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <SelectDropDownNew
                        options={selectOptions}
                        name={`documents.${index}.docType.name`}
                        getValues={getValues}
                        control={control}
                        defaultValue="none"
                        placeholder="Select"
                        customOnChange={(e) =>{
                          selectOptions.map((elem)=>{
                          
                            if(elem?.value==e.target.value)
                            setValue(`documents.${index}.docType`, `${elem?.key}`)
                          })
                        }
                          
                        }
                      />
                    </Grid>

                    {/* Files */}
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Files</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDInput
                            placeholder="Add +"
                            type="file"
                            sx={{
                              width: "100%",
                              marginTop: "0.4rem",
                              marginBottom: "0.4rem",
                              border: "none",
                            }}
                            inputProps={{
                              style: {
                                fontSize: 15,
                                fontWeight: "bold",
                                height: "2rem",
                                border: "none",
                              },
                            }}
                            onChange={(e) => handleFileChange(e, index)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MDInput
                            sx={{
                              width: "100%",
                              marginTop: "0.4rem",
                              marginBottom: "0.4rem",
                              display: "none",
                            }}
                            inputProps={{
                              style: {
                                fontSize: 15,
                                fontWeight: "bold",
                                height: "2rem",
                                display: "none",
                              },
                            }}
                            {...register(`documents.${index}.files`)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Uploaded AT */}
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Uploaded At</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Controller
                        control={control}
                        name={`documents.${index}.uploadedAt`}
                        render={({ field }) => (
                          <MDInput
                            placeholder=""
                            disabled
                            sx={{ width: "100%", marginTop: "0.4rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            onChange={(e) =>
                              setValue(
                                `documents.${index}.uploadedAt`,
                                e.target.value
                              )
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </Grid>

                  <MDBox sx={{ textAlign: "right" }}>
                    <MDButton
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <Typography color="error" variant="body">
                        Remove
                      </Typography>
                    </MDButton>
                  </MDBox>
                  {/* </MDBox>  */}
                </li>
              ))}
              <MDButton
                variant="contained"
                color="info"
                onClick={(e) => {
                 
                  append({});
                }}
              >
                + Add
              </MDButton>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DynamicDocuments;
