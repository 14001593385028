import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { uploadPhoto } from "services/userServices";
import { getRateByIdService } from "services/hospitalServices";
import { getCompany } from "services/hospitalServices";
import { updateCompanyService } from "services/hospitalServices";
import { deleteCompanyService } from "services/hospitalServices";
import { getDepartmentByIdService } from "services/hospitalServices";
import { addNewDepartment } from "services/hospitalServices";
import { updateHospital } from "services/hospitalServices";
import { DeleteHospitalBranch } from "services/hospitalServices";
import { hospitalBranch } from "services/hospitalServices";
import { getHospitalCompany } from "services/hospitalServices";
import { addNewHopitalCompany } from "services/hospitalServices";
import { uploadHospitalRateFile } from "services/hospitalServices";
import { updateHospitalCompanyService } from "services/hospitalServices";
import { getCompaniesService } from "services/hospitalServices";
import { DeleteHospitalCompany } from "services/hospitalServices";
import { fetchDocumentTypes } from "services/hospitalServices";
import { hospitalDropdownService } from "services/hospitalServices";
import { CreateHospitalBranch } from "services/hospitalServices";
import { getHospitalBranchById } from "services/hospitalServices";
import { getDepartments } from "services/hospitalServices";
import { updateDepartmentService } from "services/hospitalServices";
import { deleteDepartmentService } from "services/hospitalServices";
import { getCompanyByIdService } from "services/hospitalServices";
import { addNewCompanyHospital } from "services/hospitalServices";
import { getRates } from "services/hospitalServices";
import { deleteRateService } from "services/hospitalServices";
import { addNewRate } from "services/hospitalServices";
import { updateRateService } from "services/hospitalServices";

//*****Hospital Branch Actions******

//  get all hospital branch
export const HospitalBranchList = createAsyncThunk(
  "/branch/listbranch",
  async (payload, thunkAPI) => {
    const response = await hospitalBranch(payload);

    return response;
  }
);

//--> Create Branch

export const createhospitalBranch = createAsyncThunk(
  "/branch",
  async (payload, thunkAPI) => {
    const { response, error } = await CreateHospitalBranch(payload);

    thunkAPI.dispatch(HospitalBranchList([]));
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Update Branch
export const updatehospitalBranch = createAsyncThunk(
  "hospital/updateBranchDetails",
  async (payload, thunkAPI) => {}
);

//--> Delete Branch
export const deletehospitalBranch = createAsyncThunk(
  "/branch/id",
  async (payload, thunkAPI) => {
    const response = await DeleteHospitalBranch(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(HospitalBranchList());
    }
    return response;
  }
);

//get branch data by id
export const GetHospitalBranchById = createAsyncThunk(
  `/branch/list`,
  async (payload, thunkAPI) => {
    const response = await getHospitalBranchById(payload);

    if (response?.status === 200) {
      thunkAPI.dispatch(HospitalBranchList());
    }
    return response;
  }
);

//update ranch data by id
export const updateHospitalBranch = createAsyncThunk(
  "/branch/list/vg",
  async (payload, thunkAPI) => {
    const { response, error } = await updateHospitalBranch(payload);
    thunkAPI.dispatch(HospitalBranchList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//update Hospital
export const UpdateHospital = createAsyncThunk(
  "/branch/id/vg",
  async (payload, thunkAPI) => {
    const { response, error } = await updateHospital(payload);
    thunkAPI.dispatch(HospitalBranchList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

// //--> Delete Compnay
export const deletehospitalCompany = createAsyncThunk(
  "hospital/deleteCompanyDetails",
  async (payload, thunkAPI) => {}
);

//*****Hospital Department Actions******

//--> Create Department
export const createdepartmentCompany = createAsyncThunk(
  "hospital/createDepartmentDetails",
  async (payload, thunkAPI) => {}
);

//--> Update Department
export const updatedepartmentCompany = createAsyncThunk(
  "hospital/updateDepartmentDetails",
  async (payload, thunkAPI) => {}
);

//--> Delete Department
export const deletedepartmentCompany = createAsyncThunk(
  "hospital/deleteDepartmentDetails",
  async (payload, thunkAPI) => {}
);

//*****Hospital Rate Actions******

//List Rates
export const getRatesList = createAsyncThunk(
  "/hospital/listRates",
  async (payload, thunkAPI) => {
    const response = await getRates(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
//--> Create Rate
export const createRate = createAsyncThunk(
  "hospital/createrateDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewRate(payload);

    if (response?.status == 200) {
      toast.success("Created Successfully", {
        position: "top-right",
      });
    }
    thunkAPI.dispatch(getRatesList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Update Rate
export const updateRate = createAsyncThunk(
  "hospital/updaterateDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await updateRateService(payload);

    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getRateByID = createAsyncThunk(
  "/hospital/listRates/id",
  async (payload, thunkAPI) => {
    const response = await getRateByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
//--> Delete Rate
export const deleteRate = createAsyncThunk(
  "hospital/deleterateDetails",

  async (payload, thunkAPI) => {
    const response = await deleteRateService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getRatesList());
    }
    return response;
  }
);

//List Company
export const getCompanyList = createAsyncThunk(
  "/hospital/listCompany",
  async (payload, thunkAPI) => {
    const response = await getCompany(payload);

    return response;
  }
);

//--> Create Company
export const createOrganization = createAsyncThunk(
  "hospital/createorganizationDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewCompanyHospital(payload);
    thunkAPI.dispatch(getCompanyList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Create Corporate
export const createCorporate = createAsyncThunk(
  "hospital/createcorporateDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewCompanyHospital(payload);
    thunkAPI.dispatch(getCompanyList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Update Corporate
export const updateCorporate = createAsyncThunk(
  "hospital/updateCorporateDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await updateCompanyService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Delete Corporate
export const deleteCorporate = createAsyncThunk(
  "hospital/deletecorporateDetails",

  async (payload, thunkAPI) => {
    const response = await deleteCompanyService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getCompanyList());
    }
    return response;
  }
);

//LIST HOSPITAL COMPANY
export const getHospitalCompanyList = createAsyncThunk(
  "/hospital/listHospitalCompany",
  async (payload, thunkAPI) => {
    const response = await getHospitalCompany(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//--> Update HOSPITAL COMPANY
export const updateHospitalCompany = createAsyncThunk(
  "hospital/updateHOSPITALCOMPANY",
  async (payload, thunkAPI) => {
    const { response, error } = await updateHospitalCompanyService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//SET HOSPITAL COMPANY LOGO
export const setCompanyLogo = createAsyncThunk(
  "hospital/logoUpload",
  async (payload, thunkAPI) => {
    const response = await uploadPhoto([payload]);
    console.log("actionCreatorResponse", response);

    return response;
  }
);

//--> Delete HOSPITAL COMPANY
export const deleteHospitalCompany = createAsyncThunk(
  "hospital/deletecHospitalCompany",

  async (payload, thunkAPI) => {
    const { response, error } = await DeleteHospitalCompany(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getHospitalCompanyList());
    }
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//LIST HOSPITAL COMPANY
export const setCompanies = createAsyncThunk(
  "/hospital/listCOMPANIES",
  async (payload, thunkAPI) => {
    const response = await getCompaniesService(payload);

    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//--> Create HospitalCompany
export const createHospitalCompany = createAsyncThunk(
  "hospital/createHospitalCompany",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewHopitalCompany(payload);
    thunkAPI.dispatch(getHospitalCompanyList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Update Company
export const updateOrganization = createAsyncThunk(
  "hospital/updateOrganizationDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await updateCompanyService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const getOrganizationByID = createAsyncThunk(
  "/hospital/organzization/id",
  async (payload, thunkAPI) => {
    const response = await getCompanyByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
//--> Delete Company
export const deleteOrganization = createAsyncThunk(
  "hospital/deleteorganizationDetails",

  async (payload, thunkAPI) => {
    const response = await deleteCompanyService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getCompanyList());
    }
    return response;
  }
);

//*****Hospital Department Actions******

//List Department
export const getDepartmentsList = createAsyncThunk(
  "/hospital/listDepartments",
  async (payload, thunkAPI) => {
    const response = await getDepartments(payload);

    return response;
  }
);
//--> Create Department
export const createDepartment = createAsyncThunk(
  "hospital/createdepartmentDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewDepartment(payload);

    thunkAPI.dispatch(getDepartmentsList([]));
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//--> Update Department
export const updateDepartment = createAsyncThunk(
  "hospital/updatedepartmentDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await updateDepartmentService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
export const getDepartmentByID = createAsyncThunk(
  "/hospital/listDepartment/id",
  async (payload, thunkAPI) => {
    const response = await getDepartmentByIdService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
//--> Delete Department
export const deleteDepartment = createAsyncThunk(
  "hospital/deletedepartmentDetails",

  async (payload, thunkAPI) => {
    const response = await deleteDepartmentService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getDepartmentsList());
    }
    return response;
  }
);

export const setDocumentType = createAsyncThunk(
  "hospital/documentType/listDocumentTypes",
  async (payload) => {
    const { response, error } = await fetchDocumentTypes(payload);

    if (error) {
      return error;
    }
    return response;
  }
);

//Upload Rate Card

export const uploadRateCard = createAsyncThunk(
  "hospital/uploadRateCard",
  async (payload, thunkAPI) => {
    const { response, error } = await uploadHospitalRateFile(payload);
    thunkAPI.dispatch(getRatesList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
