const state = {
  Bill_Details_Data: {
    billDetailsLoading: true,
    billDetailsError: "",
    billDetailsInfo: [],
    total: 0,
  },
  Bill_Filtered_Data: {
    billFilteredLoading: true,
    billFilteredError: "",
    billFilteredInfo: [],
  },
  Bill_Data: {
    billLoading: true,
    billError: "",
    billInfo: [],
  },
  currentPage: 0,
  pageSize: 10,
  searchKey: "",
  filterAll: {
    billNumber: "",
    JobId: "",
    HospitalBranch: "",
    DepartmentName: "",
    invoiceNumber: ""
  },
  startDate: "",
  endDate: "",
};

export default state;
