import { Delete } from "@mui/icons-material";
import { CircularProgress, IconButton, styled } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getUsers } from "services/userServices";
import { getreduxAvalibilityList } from "./Redux/actionCreator";
// import {
//   pageChanged,
//   pageLimit,
// } from "./Redux/reducer";
import { Box } from "@mui/system";
import { capitalize } from '@mui/utils';

function Availability() {
  const { state } = useLocation();
  const { id } = useParams();
  // console.log(id, "Id");
  const dispatch = useDispatch();

  function getAvailibityId(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getDepartment(params) {
    return `${params?.row?.department || "NA"}`;
  }

  function getRole(params) {
    return `${params?.row?.role || "NA"}`;
  }

  function getACity(params) {
    return `${params?.row?.city || "NA"}`;
  }

  function getCreatedAt(params) {
    const roleName = params?.row?.created_at;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }
  function getUpdatedAt(params) {
    const roleName = params?.row?.updated_at;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }

  function getavailabilityStart(params) {
    const roleName = params?.row?.availabilityStart;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }

  // function getSlotStart(params) {
  //   let slotTime = params?.row?.slotStart;
  //   const a = moment(slotTime).format("YYYY/MM/DD");

  //   return a;
  // }
  function getavailabilityEnd(params) {
    const roleName = params?.row?.availabilityEnd;
    const a = moment(roleName).format("YYYY/MM/DD");

    return a;
  }

  function getCreatedBy(params) {
    return `${params?.row?.name?.full || "NA"}`;
  }
  function getUpdatedBy(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }

  function getFullName(params) {
    return `${params?.row?.name?.full || "NA"}`;
  }

  const columns = [
    {
      field: "ID",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?._id;
        return <Link to={`/availbilityid/${roleName}`}>{roleName}</Link>;
      },
      valueGetter: getAvailibityId,
    },
    {
      field: "role",
      headerName: `Role`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.role;
        return roleName;
      },
      valueGetter: getRole,
    },

    {
      field: "fullName",
      headerName: `Full Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.full;
        return rowName;
      },
      valueGetter: getFullName,
    },

    {
      field: "City",
      headerName: `City`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.city;
        return rowName;
      },
      valueGetter: getACity,
    },

    {
      field: "Department",
      headerName: `Department`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.department;
        return roleName;
      },
      valueGetter: getDepartment,
    },

    {
      field: "Availability Start",
      headerName: "Availability Start",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.availabilityStart;
        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getavailabilityStart,
    },

    {
      field: "Availability End",
      headerName: "Availability End",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.availabilityEnd;
        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getavailabilityEnd,
    },
    {
      field: "Available Day ",
      headerName: "Available Day",
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.slotStart;
        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      // valueGetter: getSlotStart,
    },
    {
      field: "created_at",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.created_at;
        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getCreatedAt,
    },
    {
      field: "updated_at",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.updated_at;

        const a = moment(roleName).format("YYYY/MM/DD");

        return a;
      },
      type: "date",
      valueGetter: getUpdatedAt,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.createdBy?.name?.full;
        return roleName;
      },
      valueGetter: getCreatedBy,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.updatedBy?.name?.full;
        return roleName;
      },
      valueGetter: getUpdatedBy,
    },
  ];

  const [isOpen, setisOpen] = useState(false);

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  // const [loading, setLoading] = useState(false);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);

      const response = await getUsers(checked);
      setDeletedRecords(response?.data?.data);
    } else {
      setShowDeletedRecords(false);

      const response = await getUsers(checked);
    }
  };

  const { Availabilitys_Data } = useSelector((state) => state.availability);
  const users = Availabilitys_Data?.Availabilitys_DataInfo;
  // console.log(Availabilitys_Data, "availability");
  // const totalCount = Availabilitys_Data?.total;
  // const currentPage = useSelector((state) => state.availability.currentPage);
  // const pageSize = useSelector((state) => state.availability.pageSize);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  useEffect(() => {
    if (id) {
      dispatch(getreduxAvalibilityList({ jobId: id }));
    } else {
      dispatch(getreduxAvalibilityList());
    }
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     if(id){
  //       await dispatch(getreduxAvalibilityList({ jobId: id }));  
  //     }else{
  //       await dispatch(
  //         getreduxAvalibilityList({
  //           currentPage: currentPage,
  //           pageSize: pageSize,
  //         })
  //       );
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  // ]);

  const softwares = Availabilitys_Data?.Availabilitys_DataInfo;
  // console.log("251-->", softwares)
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          No candidate has marked availability respective this Job requirements
        </Box>
      </StyledGridOverlay>  
    );
  }
  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        showDialog={false}
        title1="Job Availability"
        showisDeleted={false}
        handleDeleteApi={handleDeleteApi}
      />

      {softwares.length > 0 ? (
        <>
          <MDBox sx={{ marginTop: "2rem" }}>
            <DataGrid
              density="compact"
              getRowId={(row) => row._id}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              // loading={softwares.length > 0 ? false : true}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: `Availability-${formatDate()}`,
                  },
                },
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    _id: true,
                    fullName: true,
                    ID: true,
                    department: false,
                    created_at: true,
                    createdBy: false,
                    updated_at: false,
                    updatedBy: false,
                  },
                },
              }}
              columns={columns}
              rows={showDeltedRecords ? deletedRecords : [...softwares]}
          //     pageSize={pageSize}
          // rowCount={totalCount}
          // paginationMode="server"
          // pagination
          // page={currentPage}
          // onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          // onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          // rowsPerPageOptions={[10, 25, 50, 100]}
              pagination
              pageSize={100}
              rowsPerPageOptions={[100]}
              
              //isRowSelectable
              sx={{
                backgroundColor: "#ffff",
                color: "grey",
                height: "35rem",
                margin: "1rem",
                borderRadius: "0.5rem",
                "& .MuiDataGrid-cell": {
                  padding: "15px",
                  fontSize: "12px",
                },
                borderColor: "light-grey",
                "& .MuiDataGrid-cell:hover": {
                  color: "info",
                },
              }}
            />
          </MDBox>
        </>
      ) : (
        <>
          {" "}
          <MDBox
            sx={{
              marginTop: "2rem",
              paddingBottom: "18rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomNoRowsOverlay />{" "}
          </MDBox>{" "}
        </>
      )}
    </DashboardLayout>
  );
}

export default Availability;
