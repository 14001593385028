import axiosInstance from "../axios";

export const getCities = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/city/listCity`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// List Qualification
export const getQualifications = async (payload) => {
  try {
    // console.log("payload service>>>", payload);
    if (payload === undefined) payload = false;
    // let criteria = { criteria: { isDeleted: payload,} };
    let criteria = {
            criteria: {
              isDeleted: false,
              listName: payload.listName,
              profession: payload.profession,
              
            }
          };
    const response = await axiosInstance.post(
      `/qualificationTypes/listQualificationType`,
      criteria
    );
    return response;
  } catch (e) {
    console.log("error", e.response)
    return { error: e.response.data.message };
  }
};


export const deleteQualificationService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/qualificationTypes/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//ADD A Qualification

export const addNewQualification = async (payload) => {
  try {
    const response = await axiosInstance.post(`/qualificationTypes`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//get Qualification By ID service
export const getQualificationByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(
      `/qualificationTypes/listQualificationType`,
      criteria
    );

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Update Qualification
export const updateQualificationService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/qualificationTypes/${payload.id}`,
      payload.payload
    );
    return response;
  } catch (e) {
    return { error: "error" };
  }
};

// List Professions
export const getProfessions = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `/professionalTypes/listProfessionalType`,
      criteria
    );
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Profession
export const deleteProfessionService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/professionalTypes/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Profession
export const addNewProfession = async (payload) => {
  try {
    const response = await axiosInstance.post(`/professionalTypes`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get Profession By Id

export const getProfessionByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(
      `/professionalTypes/listProfessionalType`,
      criteria
    );

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const updateProfessionService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/professionalTypes/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: "error" };
    // return { error: e.response.data.message };
  }
};

//***************Softwares Services*******************//

// List Softwares
export const getSoftwares = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/software/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Software
export const deleteSoftwareService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/software/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Software
export const addNewSoftware = async (payload) => {
  try {
    const response = await axiosInstance.post(`/software`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get Software By Id

export const getSoftwareByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/software/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Software
export const updateSoftwareService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/software/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//****************USER DROPDOWN SERVICE ***************/

export const fetchUsersDropdownService = async () => {
  try {
    const response = await axiosInstance.get(`/user`);

    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//****************ORGANIZATION DROPDOWN SERVICE ***************/

export const fetchOrganizationsDropdownService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/organization/list`, payload);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//***************Profession Registration Services*******************//

// List Profession Registration Types
export const getProfessionRegistrationTypes = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `professionalregistrationtypes/list`,
      criteria
    );
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a  Profession Registration Type
export const deleteProfessionRegistrationTypeService = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/professionalregistrationtypes/${id}`
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add  Profession Registration Type
export const addNewProfessionRegistrationType = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/professionalregistrationtypes`,
      payload
    );
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get  Profession Registration Type by ID

export const getProfessionRegistrationTypeByIDService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(
      `professionalregistrationtypes/list`,
      criteria
    );

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update  Profession Registration Type
export const updateProfessionRegistrationTypeService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/professionalregistrationtypes/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// company

export const getCompanies = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: { isDeleted: false },
      limit: payload[0],
      offset: payload[1],
    };
    const response = await axiosInstance.post(
      `/companyTypes/listCompanyType`,
      criteria
    );

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//***************Job Application Services*******************//

// List Job Application
export const getJobApplicationService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    const { limit = 10, offset = 1 } = payload;
    const { currentPage = 1, pageSize = 10 } = payload || {};
    let criteria = {
      criteria: {
        isDeleted: false,
        // keyWord: payload.searchKey,
        keyWord: payload.searchKeyApp,
        filterAll: payload.filterAll,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      // limit: payload[0],
      // offset: payload[1],
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/application/list`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getAllJobApplicationService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/application/listAll`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//filter Application
export const getFilteredApp = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        isDeleted: false,
        // keyWord: payload.searchKey,
        keyWord: payload.searchKeyApp,
        filterAll: payload.filterAll,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/application/filtered`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Job Application
export const deleteJobApplicationService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/application/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Job Application
export const addNewJobApplicationService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/application`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Get Job Application By Id

export const getJobApplicationByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/application/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Job Application
export const updateJobApplicationService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/application/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response?.data };
  }
};

//***************Job Job Services*******************//

// List Job Job
export const getJobJobsService = async (payload) => {
  try {
    // console.log("1-->", payload);
    if (payload === undefined) payload = false;
    const { limit = 10, offset = 1 } = payload;
    const { currentPage = 1, pageSize = 10 } = payload || {};
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        candidateType: payload.candidateType,
        branch: payload.branch,
        specialization: payload.specialization,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/job/list`, criteria);
    console.log("response", response)
    return response;
    // const { data, totalCount } = response.data;
    // return { data, totalCount };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//listAllJobs
export const getAllJobsData = async (payload) => {
  try {
    // console.log("1-->", payload);
    if (payload === undefined) payload = false;
    const response = await axiosInstance.get(`/job/listAllJobs`);
    console.log("response", response)
    return response;
    // const { data, totalCount } = response.data;
    // return { data, totalCount };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getFilteredService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
       criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        candidateType: payload.candidateType,
        branch: payload.branch,
        specialization: payload.specialization,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/job/filtered`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Job Job
export const deleteJobJobsService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/job/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Job Job
export const addNewJobJobsService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/job`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Get Job Job  By Id

export const getJobJobsByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/job/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Job Job
export const updateJobJobsService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/job/${payload.id}`,
      payload.payload
    );
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//***************Job Permanent Job Services*******************//

// List Job Permanent Job
export const getPermanentJobService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/permanentjob/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Job Permanent Job
export const deletePermanentJobService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/permanentjob/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Job Permanent Job
export const addNewPermanentJobService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/permanentjob`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Get Job Permanent Job By Id

export const getPermanentJobByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/permanentjob/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Job Permanent Job
export const updatePermanentJobService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/permanentjob/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: "error" };
    // return { error: e.response.data.message };
  }
};
