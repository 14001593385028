import { IconButton, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import {MenuItem, Select, TextField } from '@material-ui/core';
import MDInput from "components/MDInput";
import { useEffect,useRef, useState } from "react";
import { get, useForm } from "react-hook-form";
import MDButton from "../../../components/MDButton/index";
import SelectDropDown from "../../../examples/DropDown/index";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { createRate, HospitalBranchList } from "../../hospital/Redux/actionCreator";
import { createMonetization , getMonetizationList} from "../Redux/actionCreator";
import {setProfessionType, setExperience,setSpecialisationsType, setCityList,setDepartmentType } from "layouts/events/Redux/actionCreator";
import {
  getDepartmentType,
  getExperienceList,
  getSpecializationsList,
  getProfessionsList,
} from "../../../layouts/common/Redux/actionCreator";
import { toast } from "react-toastify";
import { monetizationValidation } from "util/validations/hospitalPanelValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import SelectDropDownNew from "examples/DropDownNew";
import { FormHelperText } from "@material-ui/core";


const CreateMonetizationRate = ({ handleDialogClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(monetizationValidation),
  });

  //Fetching Drop Downs Data from Events Redux Rest will be created in Hospital Redux
  const {
    // Experience_Data,
    Profession_Type_Data,
    Specialization_Type_Data,
    Department_Type_Data,
    City_List_Data,
  } = useSelector((state) => state.events);


  const {
    DepartmentType_Data,
    Experience_Data,
    Specialization_Data,
    ProfessionsData,
  } = useSelector((state) => state.common);
  const departmentTypeData = DepartmentType_Data?.departmentTypeInfo;
  const experienceData = Experience_Data?.experienceInfo;
  const specializationsData = Specialization_Data?.specializationInfo;
  const professionData = ProfessionsData?.professionsInfo;

  // console.log("departmentTypeData", departmentTypeData)
  // console.log("experienceData", experienceData)
  // console.log("specializationsData", specializationsData)
  // console.log("professionData", professionData)

  //Branch Dropown Data

  // const departmentTypeData = Department_Type_Data.departmentInfo;

  // const experienceData = Experience_Data?.experienceInfo;
  // const professionData = Profession_Type_Data?.professionInfo;
  // const specializationsData = Specialization_Type_Data?.specializationInfo;
  const cityData = City_List_Data.cityListInfo; 

  const experienceOptions = [];
  const professionOptions = [];
  const specializationOptions = [];
  const cityOptions = [];
  const departmentTypeOptions = [];



  experienceData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  professionData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });



  cityData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    cityOptions.push(obj);
  });

  departmentTypeData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentTypeOptions.push(obj);
  });

  specializationsData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  const dispatch = useDispatch();

  const [valueType, setValueType] = useState('');
  const [value, setValueState] = useState('');
  const valueInputRef = useRef(null);

  const selectValueOptions = [
    {
      key: "Percentage",
      value: "Percentage",
    },
    {
      key: "Value",
      value: "Value",
    },
  ];  

//   const handleValueTypeChange = (event) => {
//     const selectedValue = event.target.value;
//     if (selectedValue === "Percentage") {
//       setValue("valueType", "AbsoluteByPercentage");
//     } else if (selectedValue === "Value") {
//       setValue("valueType", "AbsoluteByValue");
//     }
//   };

const handleValueTypeChange = (event) => {
    if(errors?.valueType?.message)
        errors.valueType.message="";
    const selectedValue = event.target.value;
    setValueType(selectedValue);
    if (selectedValue === "Percentage") {
      setValue("valueType", "AbsoluteByPercentage");
    } else if (selectedValue === "Value") {
      setValue("valueType", "AbsoluteByValue");
    }
    setValue("value", "");
    // clearErrors('value');
  };

  const handleValueChange = (e) => {
    if (errors?.value?.message) errors.value.message = "";
    
    let newValue = e.target.value.replace(/[^0-9.]/g, '');
    
    // Ensure there's only one decimal point
    if ((newValue.match(/\./g) || []).length > 1) {
        newValue = newValue.replace(/\.+$/, "");
    }
    
    if (valueType === 'Percentage' && newValue) {
      newValue += '%';
    }
    
    setValueState(newValue);
    setValue("value", newValue);
    // clearErrors('value');

    // Set cursor position to before the percentage symbol
    if (valueType === 'Percentage' && newValue.includes('%')) {
        const inputElement = valueInputRef.current;
        const position = newValue.length - 1;
        setTimeout(() => {
            inputElement.setSelectionRange(position, position);
        }, 0);
    }
};



  // const handleValueChange = (e) => {
  //   if(errors?.value?.message)
  //       errors.value.message="";
  //   let newValue = e.target.value.replace(/[^0-9]/g, '');
  //   if (valueType === 'Percentage' && newValue) {
  //     newValue += '%';
  //   }
  //   setValueState(newValue);
  //   setValue("value", newValue);
  //   // clearErrors('value');
    

  //   // Set cursor position to before the percentage symbol
  //   if (valueType === 'Percentage' && newValue.includes('%')) {
  //     const inputElement = valueInputRef.current;
  //     const position = newValue.length - 1;
  //     setTimeout(() => {
  //       inputElement.setSelectionRange(position, position);
  //     }, 0);
  //   }

  // };

//   const handleValueTypeChange = (event) => {
//     const selectedValue = event.target.value;
//     if (selectedValue === "Percentage") {
//         setValueType("valueType", "AbsoluteByPercentage");
//     } else if (selectedValue === "Value") {
//         setValueType("valueType", "AbsoluteByValue");
//     }
//   };

  const onSubmit = async(data) => {

    // console.log("data", data)
   
      let payload = {
        city: cityPayloadID,
        role: professionpayloadID,
        department: departmentTypePayloadID,
        specialization: specializationPayloadID,
        numExperience: experiencePayloadID,
        valueType: data.valueType,
        value: valueType === "Percentage" ? data.value.replace('%', '') : data.value,
        maximumJob: data?.maximumJob,
        relativeDiscount: data?.relativeDiscount
      };
      // console.log("payload", payload)
        // dispatch(createMonetization(payload));
        // handleDialogClose();
        // dispatch(createMonetization(payload)).then(() => {  
        //     dispatch(getMonetizationList());
        //     handleDialogClose();
        //   });

        try {
          const response = await dispatch(createMonetization(payload));
          // console.log("response", response)
          if (response && response?.payload?.status === 200) {
            await dispatch(getMonetizationList());
            handleDialogClose();
          } else {
            toast.error("This Rule is already exists", {
              position: "top-right",
            });
          }
      } catch (error) {
          console.error("Error creating monetization:", error)
      }
     
 
  };    

  //IDS
  const [professionpayloadID, setProfessionID] = useState("");
  const [specializationPayloadID, setSpecializationID] = useState("");
  const [experiencePayloadID, setExperienceID] = useState("");
  const [cityPayloadID, setCityID] = useState("");
  const [departmentTypePayloadID, setDepartmentTypeID] = useState("");


  //Handler Functions
  const handleProfessionChange = (e) => {
    if(errors?.role?.message)
    errors.role.message="";
    setValue("role", e.target.value);
    let professionID;

    professionOptions?.map((obj) => {
      if (obj.value == e.target.value) {
        professionID = obj.key;
      }
    });

    setProfessionID(professionID);
  };


  const handleCityChange = (e) => {
    if(errors?.city?.message)
    errors.city.message="";
    setValue("city", e.target.value);
    let cityPayloadID;

    cityOptions.map((obj) => {
      if (obj.value == e.target.value) {
        cityPayloadID = obj.key;
      }
    });

    setCityID(cityPayloadID);
  };

  const handleDepartmentTypeChange = (e) => {
    if(errors?.department?.message)
    errors.department.message="";
    setValue("department", e.target.value);
    let departmentTypePayloadID;

    departmentTypeOptions?.map((obj) => {
      if (obj.value == e.target.value) {
        departmentTypePayloadID = obj.key;
      }
    });

    setDepartmentTypeID(departmentTypePayloadID);
  };

  const handleSpecializationChange = (e) => {
    if(errors?.specialization?.message)
    errors.specialization.message="";
    setValue("specialization", e.target.value);
    let specializationID;

    specializationOptions?.map((obj) => {
      if (obj.value == e.target.value) {
        specializationID = obj.key;
      }
    });

    setSpecializationID(specializationID);
  };

  const handleExperienceChange = (e) => {
    if(errors?.numExperience?.message)
    errors.numExperience.message="";
    setValue("numExperience", e.target.value);
    let experienceID;
    experienceOptions?.map((obj) => {
      if (obj.value == e.target.value) {
        experienceID = obj.key;
      }
    });
    setExperienceID(experienceID);
  };


  useEffect(() => {
    if(professionpayloadID){
       dispatch(getDepartmentType({
        profession: [professionpayloadID]
        }));
        dispatch(getSpecializationsList({
          profession: [professionpayloadID]
        }));
    }
    dispatch(getProfessionsList());
    // dispatch(setSpecialisationsType());
    dispatch(getExperienceList());
    dispatch(setCityList());
    // dispatch(setDepartmentType());
  }, [professionpayloadID]);


  //Dispatching DropDown APIS
  // useEffect(() => {
  //   if(professionpayloadID){
  //      dispatch(setDepartmentType({
  //       listName: true,
  //       profession: [professionpayloadID]
  //       }));
  //       dispatch(setSpecialisationsType({
  //         profession: [professionpayloadID]
  //       }));
  //   }
  //   dispatch(setProfessionType());
  //   // dispatch(setSpecialisationsType());
  //   dispatch(setExperience());
  //   dispatch(setCityList());
  //   // dispatch(setDepartmentType());
  // }, [professionpayloadID]);
  

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel fullWidth>City</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={cityOptions}
              control={control}
              name="city"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleCityChange}
            />
            <FormHelperText>{errors?.city?.message}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <InputLabel fullWidth>Role</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              control={control}
              name="role"
              defaultValue="none"
              getValues={getValues}
              options={professionOptions}
              customOnChange={handleProfessionChange}
            />
             <FormHelperText>{errors?.role?.message}</FormHelperText>
          </Grid>
          {/* <Grid item xs={4}>
            <InputLabel fullWidth>Branch</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              control={control}
              name="branch"
              defaultValue="none"
              getValues={getValues}
              options={branchOptions}
              customOnChange={handleBranchChange}
            />
             <FormHelperText>{errors?.branch?.message}</FormHelperText>
          </Grid> */}

          {/* Department */}
          <Grid item xs={4}>
            <InputLabel fullWidth>Department</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
            control={control}
            name="department"
            defaultValue="none"
            getValues={getValues}
            options={departmentTypeOptions}
            customOnChange={handleDepartmentTypeChange}

            />
             <FormHelperText>{errors?.department?.message}</FormHelperText>
          </Grid>

          {/* Specialization */}
          <Grid item xs={4}>
            <InputLabel fullWidth>Specialization</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={specializationOptions}
              control={control}
              name="specialization"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleSpecializationChange}
            />
             <FormHelperText>{errors?.specialization?.message}</FormHelperText>
          </Grid>

          {/* Experience */}
          <Grid item xs={4}>
            <InputLabel fullWidth>Experience</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={experienceOptions}
              control={control}
              name="numExperience"
              defaultValue="none"
              getValues={getValues}
              customOnChange={handleExperienceChange}
            />
             <FormHelperText>{errors?.numExperience?.message}</FormHelperText>
          </Grid>

          <Grid item xs={4}>
          <InputLabel fullWidth>Value Type</InputLabel>
        </Grid>
        <Grid item xs={8}>
          <SelectDropDownNew
            fullWidth
            options={selectValueOptions}
            control={control}
            name="valueType"
            defaultValue="none"
            error={!!errors?.valueType}
            helperText={errors?.valueType?.message}
            getValues={getValues}
            customOnChange={handleValueTypeChange}
          >
          </SelectDropDownNew>
          {/* <FormHelperText>{errors?.valueType?.message}</FormHelperText> */}
          {valueType ? (
              <></>
            ) : (
              <FormHelperText>{errors?.valueType?.message}</FormHelperText>
            )}

        </Grid>

        {/* value */}
        <Grid item xs={4}>
            <InputLabel fullWidth>Value</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
                           fullWidth
                           {...register("value")}
                           error={!!errors?.value}
                           helperText={errors?.value?.message}
                           type="text"
                           inputMode="decimal"
                           pattern="[0-9]*\.?[0-9]*"
                          //  inputMode="numeric"
                          //  pattern="[0-9]*"
                           value={value}
                           onChange={handleValueChange}
                           inputRef={valueInputRef} 
           
            />
          </Grid>

          {/* maximumJob */}
          {/* <Grid item xs={4}>
            <InputLabel fullWidth>Maximum Job</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              fullWidth
              {...register("maximumJob")}
            //   error={errors?.marginCandidate?.message}
            //   helperText={errors?.marginCandidate?.message}
            />
          </Grid> */}

          {/* relativeDiscount */}
          {/* <Grid item xs={4}>
            <InputLabel fullWidth>Discount</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              fullWidth
              {...register("relativeDiscount")}
            //   error={errors?.marginCandidate?.message}
            //   helperText={errors?.marginCandidate?.message}
            />
          </Grid> */}

          <MDButton
            variant="contained"
            color="info"
            type="submit"
            sx={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            Create
          </MDButton>
        </Grid>
      </form>
    </div>
  );
};

export default CreateMonetizationRate;