import { FormHelperText, InputLabel } from "@mui/material";
import React from "react";
import MDButton from "./../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import SelectDropDown from "./../../../examples/DropDown/index";
import Checkbox from "@mui/material/Checkbox";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import hospitalPanelValidation, {
  corporateDialogValidation,
} from "../../../util/validations/hospitalPanelValidation";
import SelectDropDownNew from "examples/DropDownNew";
import { getCompanyList } from "layouts/common/Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addNewCompanyHospital } from "services/hospitalServices";
import { createCorporate } from "../Redux/actionCreator";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const HospitalCorporateDialog = ({ handleDialogClose }) => {
  const[corporateType,setCorporateType]=useState();
  const[logoLocation,setLogoLocation]=useState();
  //   const ValidationSchema=yup.object().shape({
  //     corporateName:yup.string().required("Corporate Name is Required")
  // })
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(corporateDialogValidation),
  });
  const dispatch = useDispatch();
  
  const { Company_List_Data } = useSelector((state) => state.common);
  const companyTypeData = Company_List_Data?.companyListInfo;



  const companyTypeOptions = [];
  companyTypeData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyTypeOptions.push(obj);
  });


  const logoOptions = [
    {
      key: "AWS_S3",
      value: "AWS_S3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];
  const handleCompanyTypeChange = (e) => {
    if(errors?.corporateType?.message)
    errors.corporateType.message="";
    let companyTypeID;
    //Searching for the name in the Users array to find the ID
    companyTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        companyTypeID = obj.key;
      
      }
    });
    setCorporateType(companyTypeID);
    setValue("corporateType", companyTypeID);
  };

  

  
  const onSubmit = (data) => {
   
    const payload = {
      name: data?.name,
      companyType: corporateType,
      logo: {
        location: logoLocation,
      },
    };
    dispatch(createCorporate(payload));
    handleDialogClose();
  };

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("name")}
              error={errors?.name?.message}
              helperText={errors?.name?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Corporate Type
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={companyTypeOptions}
              placeholder="Select"
              name="corporateType"
              defaultValue="none"
              control={control}
              getValues={getValues}
              customOnChange={handleCompanyTypeChange}
              // customOnChange={(e) => {
              //   setValue("companyType", e.target.value);
              // }}
            />
            <FormHelperText>{errors?.corporateType?.message}</FormHelperText>
          </Grid>

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Logo Location
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={logoOptions}
              placeholder="Select"
              control={control}
              getValues={getValues}
              name="logoLocation"
              customOnChange={(e) => {
                setLogoLocation(e.target.value);
                if( errors?.logoLocation?.message)
                 errors.logoLocation.message="";
                setValue("logoLocation", e.target.value);
              }
            }
            />
            <FormHelperText>{errors?.logoLocation?.message}</FormHelperText>
          </Grid>
        </Grid>

        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "2rem" }}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default HospitalCorporateDialog;