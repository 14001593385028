import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import SelectDropDown from "examples/DropDown";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DynamicForm from "examples/UserDetailsDynamicForm";
import React from "react";
import { useForm } from "react-hook-form";

const JobDetailsByDepartment = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    
  };
  const selectOptions = [
    {
      key: "Proposed",
      value: "Proposed",
    },
    {
      key: "Rejected",
      value: "Rejected",
    },
    {
      key: "Approved",
      value: "Approved",
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form onSubmit={handleSubmit(onSubmit)}>
        <MDBox mt={4}>
          {/* <MDBox sx={{ marginTop: "2rem", marginBottom: "3rem" }}>
            <FilterBar
              showFilter={false}
              showColumns={false}
              showDownloads={false}
              showDialog={true}
              title="Create Profeesion"
            />
          </MDBox> */}

          <Grid container>
            <Grid item xs={12} md={10}>
              {/* Nested Grid */}
              <Grid container>
                {/* New Profession Name */}
                <Grid item xs={12} md={12}>
                  <Paper elevation={1}>
                    <MDInput
                      {...register("ProfessionName")}
                      error={Boolean(errors.ProfessionName)}
                      sx={{ width: "100%" }}
                      inputProps={{
                        style: { fontSize: 25, fontWeight: "bold" },
                      }}
                      value="General Ward"
                    />
                  </Paper>
                </Grid>
                {/* User ID - Item 2 */}
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: "1.5rem", fontWeight: 400, color: "gray" }}
                  >
                    ID : 619c80978eb8d6c0021854d7
                  </Typography>
                </Grid>
                {/* is deleted */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Is Deleted ?"
                      sx={{ marginTop: "1rem" }}
                    />
                  </FormGroup>
                </Grid>
                {/* Organization */}
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "50%", marginTop: "0.8rem" }}
                  >
                    Organization
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Organization"
                  />
                </Grid>
                {/* branch */}
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "50%", marginTop: "0.8rem" }}
                  >
                    Branch
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Branch"
                  />
                </Grid>
                {/* department type */}
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "50%", marginTop: "0.8rem" }}
                  >
                    Department
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Department"
                  />
                </Grid>
                {/* is billling entity */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Is Billing Entity?"
                      sx={{ marginTop: "1rem" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Address
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address City
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address State
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Pin
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Country
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Coords
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDButton variant="contained" color="info">
                    Add item
                  </MDButton>
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Billing Address Gst Number
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                {/* Billing Address Is Gst Registered */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Billing Address Is Gst Registered"
                      sx={{ marginTop: "1rem" }}
                    />
                  </FormGroup>
                </Grid>
                {/* documents */}
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Documents
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDButton variant="contained" color="info">
                    Add Document
                  </MDButton>
                </Grid>
                {/* created and updated timestamps */}
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Created At
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "70%", marginTop: "0.8rem" }}
                  >
                    Updated At
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <MDInput
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "50%", marginTop: "0.8rem" }}
                  >
                    Created By
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Created By"
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "50%", marginTop: "0.8rem" }}
                  >
                    Updated By
                  </Typography>
                </Grid>
                <Grid xs={7} sx={{ marginTop: "2rem" }}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Updated By"
                  />
                </Grid>
                {/* divider */}
                <Grid xs={12} md={12}>
                  <Divider sx={{ width: "100%" }} />
                </Grid>

                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Submit
                  </Button>

                  <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                  >
                    Reset
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </form>
    </DashboardLayout>
  );
};

export default JobDetailsByDepartment;
