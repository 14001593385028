import { createAsyncThunk } from "@reduxjs/toolkit";
import { addNewAriService } from "services/adminServices";
import { updateAriService } from "services/adminServices";
import { getAriIdService } from "services/adminServices";
import { deleteAriService } from "services/adminServices";
import { updateAdminUserService } from "services/adminServices";
import { addNewAdminUser } from "services/adminServices";
import { deleteAdminUserService } from "services/adminServices";
import { getAdminUserByIdService } from "services/adminServices";
import { getAriService } from "services/adminServices";
import { getAdminUsers } from "services/adminServices";
import { addNewRole } from "services/adminServices";
import { updateRoleService } from "services/adminServices";
import { getRoleByIdService } from "services/adminServices";
import { deleteRoleService } from "services/adminServices";
import { getRoles } from "services/adminServices";

export const getAdminUser = createAsyncThunk(
  "/adminuser/list",
  async (payload) => {
    const response = await getAdminUsers(payload);
   
    return response;
  }
);

// Delete Admin User
export const deleteAdminUser = createAsyncThunk(
  `/adminuser/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteAdminUserService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getAdminUser());
    }
    return response;
  }
);
//Add Admin User
export const addAdminUser = createAsyncThunk(
  "/adminuser",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewAdminUser(payload);
    thunkAPI.dispatch(getAdminUser());
    if (error) {
      
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
//Get Admin USer By ID
export const getAdminUserByID = createAsyncThunk(
  "/adminuser/list/id",
  async (payload, thunkAPI) => {
    const response = await getAdminUserByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//Update Admin USer
export const updateAdminUSer = createAsyncThunk(
  "/adminuser/update",
  async (payload, thunkAPI) => {
    
    const {response,error} = await updateAdminUserService(payload);
     if (error) {  
         return thunkAPI.rejectWithValue(error);  
          }
    return response;
  }
);

//******************ARI************************////

// List ARI

export const getAriList = createAsyncThunk(
  "/ari/listAri",
  async (payload, thunkAPI) => {
    const response = await getAriService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
// Delete ARI
export const deleteAri = createAsyncThunk(
  `/ari/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteAriService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getAriList());
    }
    return response;
  }
);
//Add ARI
export const addAri = createAsyncThunk("/ari", async (payload, thunkAPI) => {
  const { response, error } = await addNewAriService(payload);
  thunkAPI.dispatch(getAriList());
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return response;
});
export const getAriByID = createAsyncThunk(
  "/ari/listAri/id",
  async (payload, thunkAPI) => {
    const {response,error} = await getAriIdService(payload);
     if (error) {    
       return thunkAPI.rejectWithValue(error);    }
    return response;
  }
);
export const updateAri = createAsyncThunk(
  "/ari/update",
  async (payload, thunkAPI) => {

    const {response,error} = await updateAriService(payload);
    if (error) {    
       return thunkAPI.rejectWithValue(error);    
      }
    return response;
  }
);

//******************Admin Roles************************////
// List Roles
export const getRolesList = createAsyncThunk(
  "/roles/listRoles",
  async (payload, thunkAPI) => {
    const response = await getRoles(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
// Delete Role
export const deleteRole = createAsyncThunk(
  `/roles/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteRoleService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getRolesList());
    }
    return response;
  }
);
//Add Role
export const addRole = createAsyncThunk("/roles", async (payload, thunkAPI) => {
  const { response, error } = await addNewRole(payload);
  thunkAPI.dispatch(getRolesList());
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return response;
});
//Get Role By ID
export const getRoleByID = createAsyncThunk(
  "/roles/listRoles/id",
  async (payload, thunkAPI) => {
    const response = await getRoleByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//Update Role
export const updateRole = createAsyncThunk(
  "/roles/update",
  async (payload, thunkAPI) => {
  
    const {response,error} = await updateRoleService(payload);
   if (error) {    
     return thunkAPI.rejectWithValue(error);    
    }
    return response;
  }
);
