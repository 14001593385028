import { createSlice } from "@reduxjs/toolkit";
import {
  addUser,
  deleteUser,
  getUsersList,
  setProfilePhoto,
  updateUser,
  getUsersListSearch,
  getFilteredListUser,
  getSearchedListUser,
  getPremiumUsersList,
  getFilteredDocsDetails,
  getMonetizationList,
  getMonetizationDiscountList,
  uploadMonetizationCard
} from "./actionCreator";

import initialState from "./state";
import { toast } from "react-toastify";

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    pageLimit(state, action) {
      state.pageSize = action.payload;
    },
    searchKeyword(state, action){
      state.searchKey = action.payload;
    },
    filterRole(state, action){
      state.role = action.payload;
    },
    filterCity(state, action){
      state.workCity = action.payload;
    },
    filterSpecialization(state, action){
      state.specialization = action.payload;
    },
    filterOrganization(state, action){
      state.organization = action.payload
    },
    updateStartDate(state, action){
      state.startDate = action.payload;
    },
    updateEndDate(state, action){
      state.endDate = action.payload;
    },
    updateFilterProfessionalType(state, action){
      state.filterProfessionalType = action.payload;
    },
    updateFilterPastPremium(state, action){
      state.filterPastPremium = action.payload;
    },
    updateFilterPresentPremium(state, action){
      state.filterPresentPremium = action.payload;
    },  
    updateFilterByStatus(state, action){
      state.filterByStatus = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    //Users Reducers
    //GET User
    builder.addCase(getUsersList.pending, (state) => {});
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.Users_Data.Users_DataLoading = false;
      state.Users_Data.Users_DataInfo = action?.payload?.data?.data;
      state.Users_Data.total = action?.payload?.data?.total;
      
    });
    builder.addCase(getUsersList.rejected, (state, action) => {
     
    });

    //GET User filtered
    builder.addCase(getFilteredListUser.pending, (state) => {});
    builder.addCase(getFilteredListUser.fulfilled, (state, action) => {
      state.Users_Filtered_Data.Users_Filtered_DataLoading = false;
      state.Users_Filtered_Data.Users_Filtered_DataInfo = action?.payload?.data;
      
    });
    builder.addCase(getFilteredListUser.rejected, (state, action) => {
     
    });

    //GET User Searched
    builder.addCase(getSearchedListUser.pending, (state) => {});
    builder.addCase(getSearchedListUser.fulfilled, (state, action) => {
      state.Users_Searched_Data.Users_Searched_DataLoading = false;
      state.Users_Searched_Data.Users_Searched_DataInfo = action?.payload?.data;
      
    });
    builder.addCase(getSearchedListUser.rejected, (state, action) => {
     
    });
    
    //Delete User
    builder.addCase(deleteUser.pending, (state) => {});
    builder.addCase(deleteUser.fulfilled, (state, action) => {
     
      toast.success("Deleted Successfully !", {
        position: "top-right",
      });
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    //Add User
    builder.addCase(addUser.pending, (state) => {});
    builder.addCase(addUser.fulfilled, (state, payload) => {
      
    });
    builder.addCase(addUser.rejected, (state, action) => {
      
    });
    //Update User
    builder.addCase(updateUser.pending, (state) => {});
    builder.addCase(updateUser.fulfilled, (state, payload) => {
      
      toast.success("Updated Successfully !", { position: "top-right" });
    });
    builder.addCase(updateUser.rejected, (state, action) => {
   
      toast.error(action?.payload?.msg, { position: "top-right" });
    });

    //photo upload
    builder.addCase(setProfilePhoto.pending, (state, action) => {
  
      const loadingToast = toast.loading("Image Uploading", {
        position: "top-right",
      });
      //Dismissing toast after 3 seconds
      setTimeout(() => {
        toast.dismiss(loadingToast);
      }, 3000);
    });
    builder.addCase(setProfilePhoto.rejected, (state, action) => {
      toast.error("Error Upoading Image", {
        position: "top-right",
      });
    });
    builder.addCase(setProfilePhoto.fulfilled, (state, action) => {

      state.User_profile_image_Data.User_profile_image_info =
        action?.payload?.data?.data;
      if (action?.payload?.status == 200) {
        toast.success("Image Uploaded", {
          position: "top-right",
        });
      }
    });

    //GET  premium User
    builder.addCase(getPremiumUsersList.pending, (state) => {});
    builder.addCase(getPremiumUsersList.fulfilled, (state, action) => {
      state.Premium_Users_Data.Premium_Users_DataLoading = false;
      state.Premium_Users_Data.Premium_Users_DataInfo = action?.payload?.data?.data;
      state.Premium_Users_Data.total = action?.payload?.data?.total;
      
    });
    builder.addCase(getPremiumUsersList.rejected, (state, action) => {
     
    });

    //filtered premium
    //list docverification filtered
    builder.addCase(getFilteredDocsDetails.pending, (state) => {});
     builder.addCase(getFilteredDocsDetails.fulfilled, (state, action) => {
       state.Premium_Filtered_Users_Data.Premium_Filtered_Users_DataLoading = false;
      // console.log("action", action);
       state.Premium_Filtered_Users_Data.Premium_Filtered_Users_DataInfo = action?.payload?.data;
       
     });
     builder.addCase(getFilteredDocsDetails.rejected, (state, action) => {
       
     });

     //monetization
    //get monetization list
    builder.addCase(getMonetizationList.pending, (state) => {});
    builder.addCase(getMonetizationList.fulfilled, (state, action) => {
      state.Monetization_MLP.Monetization_MLP_DataLoading = false;
      state.Monetization_MLP.Monetization_MLP_DataInfo = action?.payload?.data?.data;
      state.Monetization_MLP.total = action?.payload?.data?.total;
      
    });
    builder.addCase(getMonetizationList.rejected, (state, action) => {
     
    });

    //getMonetizationDiscountList
    builder.addCase(getMonetizationDiscountList.pending, (state) => {});
    builder.addCase(getMonetizationDiscountList.fulfilled, (state, action) => {
      state.Monetization_Discount.Monetization_Discount_DataLoading = false;
      state.Monetization_Discount.Monetization_Discount_DataInfo = action?.payload?.data?.data;
      state.Monetization_Discount.total = action?.payload?.data?.total;
      
    });
    builder.addCase(getMonetizationDiscountList.rejected, (state, action) => {
     
    });

    //Upload Monetiation Files
    builder.addCase(uploadMonetizationCard.pending, (state) => {});
    builder.addCase(uploadMonetizationCard.fulfilled, (state, action) => {
    
      toast.success("Uploaded Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(uploadMonetizationCard.rejected, (state, action) => {
      
      toast.error(action?.payload, {
        position: "top-right",
      });
    });
  },
});

export const {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterRole,
  filterCity,
  filterSpecialization,
  filterOrganization,
  updateStartDate,
  updateEndDate,
  updateFilterProfessionalType,
  updateFilterPastPremium,
  updateFilterPresentPremium,
  updateFilterByStatus
} = userSlice.actions;

export default userSlice.reducer;
