import axiosInstance from "../axios";
import { toast } from "react-toastify";

//***************GCC Services*******************//


//add GCC job
export const addGCCNewJob = async (payload) => {
  try {
    // console.log("payload", payload)
    const response = await axiosInstance.post(`/gccJob/create`, payload);
    // console.log("res>>>", response);
    // console.log("res>>>", response.data.msg);
    if(response.status === 201) {
      toast.success("Created Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
    console.log("error", e.response.data)
    return { error: e.response.data };
  }
};

export const getGCCJob = async (id) => {
  
  try {
    const response = await axiosInstance.post(`/gccJob/list`);
    // console.log("res", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Update Permanent Job
export const updateGCCJobService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/gccJob/${payload.id}`,
      payload.payload
    );
    // console.log("fetched", response);
    if(response.data.message === "Job updated successfully") {
      toast.success("Updated Successfully",{
        position:"top-right"
      });
    }
   
    return response;
  } catch (e) {
    console.log("error", e)
    if(e.response.status === 400) {
      toast.error(e.response.data.msg,{
        position:"top-right"
      });
    }else if(e.response.status === 500){
      toast.success("Updated Successfully",{
            position:"top-right"
          });
    }
    return { error: e.response.data };
  }
};

export const getGCCJobsByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/gccJob/list`, criteria);
    // console.log("res", response);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};



//list permanent applications
export const getPermanentApplication = async (id) => {
  
  try {
    
    let criteria;

    
      // criteria = {
      //   criteria: { isDeleted: false, _id: id },
      //   limit: 0,
      //   offset: 1,
      // };
    const response = await axiosInstance.post(`/permanentapplication/list`);
    // console.log("res", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};


export const getPermanentApplicationByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/permanentapplication/list`, criteria);
    // console.log("res", response);

    return response?.data?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Permanent Application
export const updatePermanentApplicationService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/permanentapplication/${payload.id}`,
      payload.payload
    );
    console.log("fetched", response);
    if(response.data.status === true) {
      toast.success("Updated Successfully",{
        position:"top-right"
      });
    }
   
    return response;
  } catch (e) {
    console.log("updating error", e);
    return { error: e.response.data };
  }
};
