import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { FormHelperText } from "@material-ui/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useFieldArray, useForm } from "react-hook-form";

// import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import { Edit } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Link, Box, InputLabel } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";

import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import {
  getDepartmentType,
  getQualificationsList,
  getExperienceList,
  getSpecializationsList,
  getProfessionsList,
} from "../../../layouts/common/Redux/actionCreator";
import { setPhoto } from "../../../layouts/events/Redux/actionCreator";
// import MDButton from "./../../components/MDButton/index";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigationType } from "react-router-dom";
import { getPermanentJobsByIdService } from "../../../services/permanentServices";
import { updatePermanentJobs } from "../Redux/actionCreator";
import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { toast } from "react-toastify";

function PermanentJobDetailsByJobCode() {
  const navigation = useNavigationType();
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [salaryType, setSalaryType] = useState();
  var [isSalaryTypedValid, setIsSalaryTypedValid] = useState(true);
  const [fileLogo, setFileLogo] = useState();
  const [joiningDate, setJoiningDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const selectStateOptions = [
    {
      key: "OPEN",
      value: "OPEN",
    },
    {
      key: "ARCHIVED",
      value: "ARCHIVED",
    },
    {
      key: "HIRED",
      value: "HIRED",
    },
  ];

  const selectTypeOptions = [
    {
      key: "DOMESTIC",
      value: "DOMESTIC",
    },
    {
      key: "LTC",
      value: "LTC",
    },
    {
      key: "INTERNATIONAL",
      value: "INTERNATIONAL",
    },
  ];

  const selectSalaryTypeOptions = [
    {
      key: "ANNUAL",
      value: "ANNUAL",
    },
    {
      key: "MONTHLY",
      value: "MONTHLY",
    },
  ];

  const selectJoiningDatesOptions = [
    {
      key: "Immediate",
      value: "Immediate",
    },
    {
      key: "Within 7 days",
      value: "Within 7 days",
    },
    {
      key: "Within 15 days",
      value: "Within 15 days",
    },
    {
      key: "Within 30 days",
      value: "Within 30 days",
    },
    {
      key: "Within 45 days",
      value: "Within 45 days",
    },
    {
      key: "Within 60 days",
      value: "Within 60 days",
    },
  ];

  const selectTierTypeOptions = [
    {
      key: "FILLING_FAST",
      value: "FILLING_FAST",
    },
    {
      key: "HOT_VACANCY",
      value: "HOT_VACANCY",
    },
    {
      key: "TOP_TIER",
      value: "TOP_TIER",
    },
  ];

  const [responseData, setResponseData] = useState([]);
  // console.log(">>>",responseData)

  const code = window.location.href.split("code")[1].split("/")[1];
  const ID = window.location.href.split("code")[1].split("/")[2];
  // console.log("id>>>>>", ID);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getData = async () => {
    // let response = await getJobJobsByIdService(ID);
    let response = await getPermanentJobsByIdService(ID);

    setResponseData(response[0]);
    setLogoName(response[0].client.logo);

    return response[0];
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: async () => await getData(),
  });

  const [updated, setUpdated] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [value, setValue] = useState(null);
  // console.log("errors", errors)

  isSalaryTypedValid = selectSalaryTypeOptions.some(
    (option) => option.value === responseData?.salary?.type
  );
  let isLTCType = responseData.type === "LTC";
  const onSubmit = async (data) => {
    // console.log("data>>", data)
    // console.log("Before trigger");
    await trigger();
    // console.log("After trigger");

    // console.log("Before clearing errors");
    clearErrors("client.logo");
    clearErrors("jobDescription");
    clearErrors("numberOfJobs");
    clearErrors("salary.type");
    clearErrors("salary.min");
    clearErrors("salary.max");
    clearErrors("salary.currency");
    clearErrors("salary.monthly");
    clearErrors("address.address");
    clearErrors("address.city");
    clearErrors("address.country");
    clearErrors("joiningDate");

    // setError("client.logo", {
    //   type: "manual",
    //   message: data.client.logo ? "" : "Logo  is required",
    // });
    // setError("qualification", {
    //   type: "manual",
    //   message: "Qualification is required",
    // });
    // setError("numExperience", {
    //   type: "manual",
    //   message: "numExperience is required",
    // });
    setError("jobDescription", {
      type: "manual",
      message: data.jobDescription ? "" : "JobDescription  is required",
    });
    setError("numberOfJobs", {
      type: "manual",
      message: data.numberOfJobs ? "" : "Number Of Jobs  is required",
    });
    setError("salary.type", {
      type: "manual",
      message: "CTC type is required",
    });

    setError("salary.min", {
      type: "manual",
      message: data.salary.min ? "" : "Salary min is required",
    });
    setError("salary.max", {
      type: "manual",
      message: data.salary.max ? "" : "Salary max is required",
    });
    setError("salary.currency", {
      type: "manual",
      message: data.salary.currency ? "" : "Salary currency is required",
    });

     setError("salary.monthly", {
      type: "manual",
      message: data.salary.monthly ? "" : "Monthly Salary  is required",
    });

    setError("address.address", {
      type: "manual",
      message: data.address.address ? "" : "Address is required",
    });
    setError("address.city", {
      type: "manual",
      message: data.address.city ? "" : "Address city is required",
    });
    setError("address.country", {
      type: "manual",
      message: data.address.country ? "" : "Country is required",
    });
    setError("joiningDate", {
      type: "manual",
      message: data.joiningDate ? "" : "Joining Date is required",
    });

    // console.log("After setting errors");

    try {
      const payload = {
        state: data.state,
        type: data.type,
        jobTier: data.jobTier,
        ltcDateRange: {
          startDate: data.ltcDateRange?.startDate,
          endDate: data.ltcDateRange?.endDate,
        },
        client: {
          name: data.client.name,
          logo: data.client.logo,
        },
        department: departmentID ? departmentID : data?.department?._id,
        postedBy: data?.postedBy ? data?.postedBy : "NA",
        qualification: qualificationID
          ? qualificationID
          : data?.qualification?._id,
        numExperience: experienceID ? experienceID : data?.numExperience?._id,
        specialization: specializationID
          ? specializationID
          : data?.specialization?._id,
        candidateType: candidateTypeID
          ? candidateTypeID
          : data?.candidateType?._id,
        jobDescription: data.jobDescription,
        numberOfJobs: data.numberOfJobs,
        salary: {
          type: data.salary.type,
          min: data.salary.min,
          max: data.salary.max,
          currency: data.salary.currency,
          monthly: data.salary.monthly,
        },
        joiningDate: data.joiningDate,
        address: {
          address: data.address.address,
          city: data.address.city,
          country: data.address.country,
        },
        code: code,
      };
      console.log("payload", payload);
        dispatch(updatePermanentJobs({ payload: payload, id: ID })).then(() => {
        // console.log("payload", payload);
        navigate("/permanent/job");
      });
      

      // var requireLTC;
      // if(isLTCType){
      //    requireLTC =
      //    !data.salary.monthly
      // }

      // const isRequiredFieldsFilled =
      //   // !data.client.logo ||
      //   !data.jobDescription ||
      //   !data.numberOfJobs ||
      //   (!isLTCType && (!data.salary.type || !data.salary.min || !data.salary.max)) ||
      //   // !data.salary.type ||
      //   // !data.salary.min ||
      //   // !data.salary.max ||
      //   (isLTCType && (!data.salary.monthly)) ||
      //   !data.salary.currency ||
      //   !data.address.address ||
      //   !data.address.city ||
      //   !data.address.country ||
      //   !data.joiningDate;

      //   console.log("isRequiredFieldsFilled>>",isRequiredFieldsFilled)
      //   console.log("isLTCType>>",isLTCType)
      //   if (requireLTC) {
      //     if (isRequiredFieldsFilled){
      //       toast.warning("All required fields must be filled.", {
      //         position: "top-right",
      //     });
      //     }
      //   }else if (isRequiredFieldsFilled) {
      //   toast.warning("Required fields must be filled.", {
      //     position: "top-right",
      //   });
      // } else {
      //   try {
      //     // Dispatch updatePermanentJobs
      //     await dispatch(updatePermanentJobs({ payload, id: ID }));

      //     // If the update is successful, navigate to the specified route
      //     navigate("/permanent/job");
      //   } catch (error) {
      //     // Handle errors here, display an error message, or perform any necessary actions
      //     console.error("Error updating permanent jobs:", error);
      //     toast.error("Error updating permanent jobs", {
      //       position: "top-right",
      //     });
      //   }
      // }

      // setIsJobCreated(true);
      // handleDialogClose();
    } catch (error) {
      console.error("Error during form submission:", error);
      // Handle the error appropriately
    }
  };

  const departmentOptions = [];
  const qualificationOptions = [];
  const experienceOptions = [];
  const specializationOptions = [];
  const professionOptions = [];

  const {
    DepartmentType_Data,
    QualificationsData,
    Experience_Data,
    Specialization_Data,
    ProfessionsData,
  } = useSelector((state) => state.common);
  const department_Info = DepartmentType_Data?.departmentTypeInfo;
  const qualifications = QualificationsData?.qualificationsInfo;
  // console.log("qualifications: ", qualifications)
  const experiences = Experience_Data?.experienceInfo;
  // console.log("experiences: ", experiences);
  const specializations = Specialization_Data?.specializationInfo;
  // console.log("specializations: ", specializations);
  const professions = ProfessionsData?.professionsInfo;
  // console.log("professions: ", professions);

  department_Info.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentOptions.push(obj);
  });

  qualifications.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    qualificationOptions.push(obj);
  });

  experiences.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  specializations.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    specializationOptions.push(obj);
  });

  professions.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  //IDS

  const [experienceID, setExperienceID] = useState("");
  const [departmentID, setDepartmentID] = useState("");
  const [qualificationID, setQualificationID] = useState("");
  const [specializationID, setSpecializationID] = useState("");
  const [candidateTypeID, setCandidateTypeID] = useState("");
  const [logoName, setLogoName] = useState("");
  const [qualification, setQualification] = useState();
  const [experience, setExperience] = useState();

  const handleDepartmentChange = (e) => {
    setValue("department.name", e.target.value);
    let userID;

    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setDepartmentID(userID);
  };

  const handleQualificationChange = (e) => {
    // if (errors?.qualification?.message) errors.qualification.message = "";
    setValue("qualification.name", e.target.value);
    let userID;

    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setQualificationID(userID);
    // setQualification(userID);
  };

  const handleExperienceChange = (e) => {
    // if (errors?.numExperience?.message) errors.numExperience.message = "";
    setValue("numExperience.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setExperienceID(userID);
    // setExperience(userID);
  };

  const handleSpecializationChange = (e) => {
    setValue("specialization.name", e.target.value);
    let userID;
    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setSpecializationID(userID);
  };

  const handleProfessionChange = (e) => {
    setValue("candidateType.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setCandidateTypeID(userID);
  };

  const handleStartDateChange = (date) => {
    // if (errors?.ltcDateRange?.startDate?.message)
    //   errors.ltcDateRange.startDate.message = "";
    setValue("ltcDateRange.startDate", date);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    // if (errors?.ltcDateRange?.endDate?.message)
    //   errors.ltcDateRange.endDate.message = "";
    setValue("ltcDateRange.endDate", date);
    setSelectedEndDate(date);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const profession = responseData?.candidateType?._id;

  useEffect(() => {
    if (profession) {
      dispatch(
        getQualificationsList({
          listName: true,
          profession: [profession],
        })
      );
      // dispatch(getSpecializationsList({
      //   profession: [profession]
      // }));
    } else {
      dispatch(getSpecializationsList());
      dispatch(getDepartmentType());
      dispatch(getExperienceList());
      dispatch(getProfessionsList());
    }
  }, [dispatch, profession]);

  // useEffect(() => {
  //   dispatch(getDepartmentType());
  //   dispatch(getQualificationsList());
  //   dispatch(getExperienceList());
  //   dispatch(getSpecializationsList());
  //   dispatch(getProfessionsList());
  // }, [dispatch]);

  const formValues = getValues();
  // console.log("1074-->", formValues);
  // console.log("122-->",getValues())

  const formattedCreatedAt = getValues("createdAt")
    ? dayjs(getValues("createdAt"))?.format("YYYY/MM/DD")
    : null;

  const formattedUpdatedAt = getValues("updatedAt")
    ? dayjs(getValues("updatedAt"))?.format("YYYY/MM/DD")
    : null;

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    if (errors?.client?.logo?.message) errors.client.logo.message = "";
    const file = event.target.files[0];
    // console.log("file>>", file)
    setSelectedFileName(file.name);
    dispatch(setPhoto(file))
      .then((response) => {
        // Assuming the response contains the URL or other information
        // console.log("File Upload Response:", response.payload.data.data[0].url);
        const logoUrl = response.payload.data.data[0].url;
        // console.log("url", logoUrl);
        // Assuming data.client.logo is an array
        setValue("client.logo", logoUrl);
        setFileLogo(logoUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };


  // const startDateLTC = responseData?.ltcDateRange?.startDate
  // console.log("startDateLTC", startDateLTC)
//   const formattedStartDate = startDateLTC.toISOString().slice(0, 10); // Extracts the YYYY-MM-DD part
// console.log(formattedStartDate);



//   function startLTC(startDateLTC) {
//     var abc;
//     // const startDateLTC = responseData?.ltcDateRange?.startDate
//    console.log("startDateLTC", startDateLTC)
//     if (startDateLTC) {
//       abc = moment(startDateLTC).format("YYYY/MM/DD");
//       return `${abc}`;
//     } else {
//       abc = "NA";
//       return `${abc}`;
//   }
// }


  // console.log("logoName", errors);
  // console.log("responseData", responseData);

  // const isLTCType = responseData.type === "LTC";
  // console.log("isLTCType", isLTCType);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "50px" }}>
                    <Grid container>
                      <Grid item xs={8}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("code")}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>

                      {/* State */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job State
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job State"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.state}
                        />
                      </Grid> */}
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={selectStateOptions}
                          placeholder="State"
                          control={control}
                          name="state"
                          defaultValue="OPEN"
                          getValues={getValues}
                          customOnChange={(e) =>
                            setValue("state", e.target.value)
                          }
                        />
                      </Grid>

                      {/* Type */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Type"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.type}
                        />
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={selectTypeOptions}
                          placeholder="Job Type"
                          control={control}
                          name="type"
                          defaultValue="DOMESTIC"
                          getValues={getValues}
                          customOnChange={(e) =>
                            setValue("type", e.target.value)
                          }
                        />
                      </Grid> */}


                      {/* jobTier */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Tier
                        </Typography>
                      </Grid>
                     
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={selectTierTypeOptions}
                          placeholder="jobTier"
                          control={control}
                          name="jobTier"
                          // defaultValue="OPEN"
                          getValues={getValues}
                          customOnChange={(e) =>
                            setValue("jobTier", e.target.value)
                          }
                        />
                      </Grid>

                      <>
                        {isLTCType && (
                          <>
                            {/* LTC Start Date */}
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "50%", marginTop: "0.8rem" }}
                              >
                                LTC Start Date
                              </Typography>
                            </Grid>
                            <Grid item xs={7} sx={{ marginTop: "2rem" }}>
                              <Controller
                                name={"LTC Start Date"}
                                control={control}
                                // getValues={getValues}
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      {...field}
                                      inputFormat="YYYY/MM/DD"
                                      inputProps={{
                                        style: {
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        },
                                      }}
                                      // value={startDateLTC}
                                      // onChange={handleDateChange}

                                      value={
                                        getValues("ltcDateRange.startDate")
                                          ? getValues("ltcDateRange.startDate")
                                          : null
                                      }

                                      {...register("ltcDateRange.startDate")}
                                      // onChange={(date) => handleStartDateChange(date)}
                                      onChange={(date) =>
                                        handleStartDateChange(date)
                                      }
                                      // onChange={(date) => {
                                      //   setValue("ltcDateRange.startDate", date);
                                      // }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                        />
                                      )}
                                      minDate={new Date()}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </Grid>

                            {/* LTC End Date */}
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "50%", marginTop: "0.8rem" }}
                              >
                                LTC End Date
                              </Typography>
                              <InputLabel
                                fullWidth
                                className="input-labels"
                              ></InputLabel>
                            </Grid>
                            <Grid item xs={7} sx={{ marginTop: "2rem" }}>
                              <Controller
                                name={"LTC End Date"}
                                control={control}
                                // getValues={getValues}
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      {...field}
                                      inputFormat="YYYY/MM/DD"
                                      inputProps={{
                                        style: {
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        },
                                      }}
                                      // value={selectedEndDate}
                                      // onChange={handleDateChange}
                                      value={
                                        getValues("ltcDateRange.endDate")
                                          ? getValues("ltcDateRange.endDate")
                                          : null
                                      }
                                      {...register("ltcDateRange.endDate")}
                                      onChange={(date) =>
                                        handleEndDateChange(date)
                                      }
                                      // onChange={(date) => {
                                      //   setValue("ltcDateRange.endDate", date);
                                      // }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                        />
                                      )}
                                      minDate={new Date()}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </>

                      {/* Client Name*/}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Client Name
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <form noValidate>
                          <MDInput
                            placeholder="Client Name"
                            sx={{ width: "100%", marginTop: "2rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            value={responseData?.client?.name}
                            // error={errors?.adminUserName?.message}
                            // helperText={errors?.adminUserName?.message}
                            // {...register("client.name")}
                          />
                        </form>
                      </Grid>

                      {/* Client Logo*/}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Client Logo
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <form noValidate>
                          <Box display="flex" alignItems="center">
                            <MDInput
                              // onKeyDown={(e) => {
                              //     e.key === "Enter" && e.preventDefault();
                              //   }}
                              placeholder="Upload Client Logo"
                              sx={{ width: "100%", marginTop: "2rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              //   value={logoName ? logoName : selectedFileName}
                              value={
                                selectedFileName ? selectedFileName : logoName
                              }
                              readOnly
                            />

                            <AttachFileIcon
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginTop: "2rem",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={handleFileIconClick}
                            />
                            <input
                              ref={fileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              // accept=".img, .png"
                              onChange={handleFileChange}
                            />
                          </Box>
                        </form>
                        {/* {fileLogo ? (
                          <></>
                        ) : (
                          <FormHelperText>
                            {errors?.client?.logo?.message}
                          </FormHelperText>
                        )} */}
                      </Grid>

                      {/* Department */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Department
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Department"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.department?.name}
                        />
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={departmentOptions}
                          placeholder="Department"
                          control={control}
                          name="department.name"
                          defaultValue="none"
                          getValues={getValues}
                          // {...register("department")}
                          customOnChange={handleDepartmentChange}
                        />
                      </Grid> */}

                      {/* CandidateType */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Candidate Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Candidate Type"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.candidateType?.name}
                        />
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={professionOptions}
                          placeholder="CandidateType"
                          control={control}
                          name="candidateType.name"
                          defaultValue="none"
                          getValues={getValues}
                          // {...register("department")}
                          customOnChange={handleProfessionChange}
                        />
                      </Grid> */}

                      {/* Qualification */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Qualification
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={qualificationOptions}
                          placeholder="Qualification"
                          control={control}
                          name="qualification.name"
                          defaultValue="none"
                          getValues={getValues}
                          // {...register("department")}
                          customOnChange={handleQualificationChange}
                        />
                        {/* {qualificationID ? (
              <></>
            ) : (
              <FormHelperText>{errors?.qualification?.message}</FormHelperText>
            )} */}
                      </Grid>

                      {/* Specialization */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Specialization
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Specialization"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.specialization?.name}
                        />
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={specializationOptions}
                          placeholder="Specialization"
                          control={control}
                          name="specialization.name"
                          defaultValue="none"
                          getValues={getValues}
                          // {...register("department")}
                          customOnChange={handleSpecializationChange}
                        />
                      </Grid> */}

                      {/* NumExperience */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          NumExperience
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={experienceOptions}
                          placeholder="NumExperience"
                          control={control}
                          name="numExperience.name"
                          defaultValue="none"
                          getValues={getValues}
                          // {...register("department")}
                          customOnChange={handleExperienceChange}
                        />
                        {/* {experienceID ? (
              <></>
            ) : (
              <FormHelperText>{errors?.numExperience?.message}</FormHelperText>
            )} */}
                      </Grid>

                      {/* JobDescription */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Description
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                        multiline
                        rows={5}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Description"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("jobDescription")}
                          error={errors?.jobDescription?.message}
                          helperText={errors?.jobDescription?.message}
                        />
                      </Grid>

                      {/* NumberOfJobs */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Number Of Jobs
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Number Of Jobs"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("numberOfJobs")}
                          error={errors?.numberOfJobs?.message}
                          helperText={errors?.numberOfJobs?.message}
                        />
                      </Grid>

                      {/* Joining Date */}
                      <>
                        {!isLTCType && (
                          <>
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              {/* <InputLabel fullWidth className="input-labels"> */}
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "50%", marginTop: "0.8rem" }}
                              >
                                Tentative Joining date
                              </Typography>

                              {/* </InputLabel> */}
                            </Grid>
                            <Grid item xs={7} sx={{ marginTop: "2rem" }}>
                              <SelectDropDownNew
                                options={selectJoiningDatesOptions}
                                placeholder="Tentative Joining date"
                                control={control}
                                name="joiningDate"
                                // defaultValue="OPEN"
                                getValues={getValues}
                                customOnChange={(e) => {
                                  if (errors?.joiningDate?.message)
                                    errors.joiningDate.message = "";
                                  // handleSalaryChange()
                                  // console.log(">>>", e.target.value)
                                  setValue("joiningDate", e.target.value);
                                  setJoiningDate(e.target.value);
                                }}
                              />
                              {joiningDate ? (
                                <></>
                              ) : (
                                <FormHelperText>
                                  {errors?.joiningDate?.message}
                                </FormHelperText>
                              )}
                            </Grid>
                          </>
                        )}
                      </>

                      {/* monthly CTC type */}
                      {isLTCType && (
                        <>
                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "50%", marginTop: "0.8rem" }}
                            >
                              CTC Type
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <MDInput
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              placeholder="CTC Type"
                              sx={{ width: "100%", marginTop: "2rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              value={responseData?.salary?.type}
                            />
                          </Grid>
                        </>
                      )}

                       {/* monthly salary */}
                       {isLTCType && (
                        <>
                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "50%", marginTop: "0.8rem" }}
                            >
                              Monthly Salary
                            </Typography>

                          </Grid>
                          <Grid item xs={7}>
                              <MDInput
                                onKeyDown={(e) => {
                                  if (
                                    !(
                                      (e.key >= "0" && e.key <= "9") ||
                                      e.key === "Backspace"
                                    )
                                  ) {
                                   
                                    e.preventDefault();
                                  }
                                }}
                              
                                placeholder="Monthly Salary"
                                sx={{ width: "100%", marginTop: "2rem" }}
                                inputProps={{
                                  style: { fontSize: 15, fontWeight: "bold" },
                                }}
                                {...register("salary.monthly")}
                                // onChange={() => clearErrors("salary.monthly")}
                                error={errors?.salary?.monthly?.message}
                                helperText={errors?.salary?.monthly?.message}
                              />
                            </Grid>
                        </>
                      )}

                      {!isLTCType && (
                        <>
                          {/* Salary Type */}
                          <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "50%", marginTop: "0.8rem" }}
                            >
                              CTC Type
                            </Typography>
                          </Grid>
                          <Grid xs={7} sx={{ marginTop: "2rem" }}>
                            <SelectDropDownNew
                              options={selectSalaryTypeOptions}
                              placeholder="CTC Type"
                              control={control}
                              name="salary.type"
                              defaultValue="none"
                              getValues={getValues}
                              // customOnChange={(e) =>
                              //   setValue("salary.type", e.target.value)
                              // }
                              customOnChange={(e) => {
                                // if (errors?.salary?.type?.message) {
                                //   errors.salary.type.message = "";
                                // }
                                // setValue("salary.type", e.target.value)
                                // setIsSalaryTypedValid(true)
                                const selectedValue = e.target.value;
                                if (
                                  selectedValue &&
                                  errors?.salary?.type?.message
                                ) {
                                  setError("salary.type", {
                                    type: "manual",
                                    message: "",
                                  });
                                  // setIsSalaryTypedValid(true);
                                }

                                setValue("salary.type", selectedValue);
                              }}
                            />
                            {responseData?.salary?.type ? null : (
                              <FormHelperText>
                                {errors?.salary?.type?.message}
                              </FormHelperText>
                            )}
                          </Grid>
                        </>
                      )}

                      {/* min and max */}
                      <>
                        {!isLTCType && (
                          <>
                            {/* salary min */}
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "50%", marginTop: "0.8rem" }}
                              >
                                Minimum Salary
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <MDInput
                                onKeyDown={(e) => {
                                  if (
                                    !(
                                      (e.key >= "0" && e.key <= "9") ||
                                      e.key === "Backspace"
                                    )
                                  ) {
                                    // Invalid input, prevent the key press
                                    e.preventDefault();
                                  }
                                }}
                                // onKeyDown={(e) => {
                                //   e.key === "Enter" && e.preventDefault();
                                // }}
                                placeholder="Minimum Salary"
                                sx={{ width: "100%", marginTop: "2rem" }}
                                inputProps={{
                                  style: { fontSize: 15, fontWeight: "bold" },
                                }}
                                {...register("salary.min")}
                                error={errors?.salary?.min?.message}
                                helperText={errors?.salary?.min?.message}
                              />
                            </Grid>

                            {/* salary max */}
                            <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{ width: "50%", marginTop: "0.8rem" }}
                              >
                                Maximum Salary
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <MDInput
                                onKeyDown={(e) => {
                                  if (
                                    !(
                                      (e.key >= "0" && e.key <= "9") ||
                                      e.key === "Backspace"
                                    )
                                  ) {
                                    // Invalid input, prevent the key press
                                    e.preventDefault();
                                  }
                                }}
                                // onKeyDown={(e) => {
                                //   e.key === "Enter" && e.preventDefault();
                                // }}
                                placeholder="Maximum Salary"
                                sx={{ width: "100%", marginTop: "2rem" }}
                                inputProps={{
                                  style: { fontSize: 15, fontWeight: "bold" },
                                }}
                                {...register("salary.max")}
                                error={errors?.salary?.max?.message}
                                helperText={errors?.salary?.max?.message}
                              />
                            </Grid>
                          </>
                        )}
                      </>

                      {/* Salary min */}
                      {/* <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Minimum Salary
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            if (
                              !(
                                (e.key >= "0" && e.key <= "9") ||
                                e.key === "Backspace"
                              )
                            ) {
                              // Invalid input, prevent the key press
                              e.preventDefault();
                            }
                          }}
                          // onKeyDown={(e) => {
                          //   e.key === "Enter" && e.preventDefault();
                          // }}
                          placeholder="Minimum Salary"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("salary.min")}
                          error={errors?.salary?.min?.message}
                          helperText={errors?.salary?.min?.message}
                        />
                      </Grid> */}

                      {/* Salary max */}
                      {/* <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Maximum Salary
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            if (
                              !(
                                (e.key >= "0" && e.key <= "9") ||
                                e.key === "Backspace"
                              )
                            ) {
                              // Invalid input, prevent the key press
                              e.preventDefault();
                            }
                          }}
                          // onKeyDown={(e) => {
                          //   e.key === "Enter" && e.preventDefault();
                          // }}
                          placeholder="Maximum Salary"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("salary.max")}
                          error={errors?.salary?.max?.message}
                          helperText={errors?.salary?.max?.message}
                        />
                      </Grid> */}

                      {/* Salary currency */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Currency
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Currency"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                            maxLength: 3,
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z]/g,
                              ""
                            );
                          }}
                          {...register("salary.currency")}
                          error={errors?.salary?.currency?.message}
                          helperText={errors?.salary?.currency?.message}
                        />
                      </Grid>

                      {/* Address */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Client Address
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Client Address"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("address.address")}
                          error={errors?.address?.address?.message}
                          helperText={errors?.address?.address?.message}
                        />
                      </Grid>

                      {/* country */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Client Address City
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Client Address City"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("address.city")}
                          error={errors?.address?.city?.message}
                          helperText={errors?.address?.city?.message}
                        />
                      </Grid>

                      {/* country */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Country
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Country"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("address.country")}
                          error={errors?.address?.country?.message}
                          helperText={errors?.address?.country?.message}
                        />
                      </Grid>

                      {/* Posted By */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Posted By
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Posted By"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("postedBy")}
                          value={responseData?.postedBy}
                        />
                      </Grid>

                      {/* created and updated timestamps */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Created At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedCreatedAt}
                          {...register("createdAt")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Updated At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedUpdatedAt}
                          {...register("updatedAt")}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Created By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          placeholder=""
                          disabled
                          {...register(`createdBy.name`)}
                          sx={{ width: "100%", border: "none" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Updated By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          placeholder=""
                          disabled
                          {...register(`updatedBy.name`)}
                          sx={{ width: "100%", border: "none" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={12}>
                        <Divider sx={{ width: "100%" }} />
                      </Grid>

                      <Grid item xs={12}>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default PermanentJobDetailsByJobCode;
