import MDInput from "components/MDInput";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useForm } from 'react-hook-form';
import MDButton from "components/MDButton";
import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, Typography, keyframes } from "@mui/material";
import MDBox from "components/MDBox";

import SelectDropDownNew from "examples/DropDownNew";





export default ({ nestIndex, control, register, setValue,getValues,nestedIndex }) => {
    
    const { fields: field, remove: remove, append: append } = useFieldArray({
        control,
        name: `testPapers[${nestIndex}].questions.[${nestedIndex}].options`,
    });



    return (
        
            
             <ul style={{ listStyleType: "none" }}>
            {field.map((item, k) => {
                return (
                    <li>
                        <Grid container item={12}>
                        



                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Option
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`testPapers[${nestIndex}].questions.[${nestedIndex}].options[${k}].option`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>

                            {/* checkbox */}

                            <Grid item xs={12} md={12}>
                            <Grid item xs={2}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Controller
                                      defaultValue={false}
                                      
                                      name={`testPapers[${nestIndex}].questions.[${nestedIndex}].options[${k}].isCorrect`}
                                      control={control}
                                      render={({ field: props }) => (
                                        <Checkbox
                                          {...props}
                                          checked={props.value}
                                          onChange={(e) =>
                                            props.onChange(e.target.checked)
                                          }
                                        />
                                      )}
                                    />
                                  }
                                  label="Is Correct"
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>



                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Negative Marks
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`testPapers[${nestIndex}].questions.[${nestedIndex}].options[${k}].negativeMarks`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>



                            <Grid item xs={3}>
                                <Typography variant="subtitle1">
                                    Marks
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    type='text'
                                    placeholder=""
                                    sx={{ width: "100%", marginTop: "0.4rem" }}
                                    inputProps={{
                                        style: { fontSize: 15, fontWeight: "bold" },
                                    }}
                                    {...register(`testPapers[${nestIndex}].questions.[${nestedIndex}].options[${k}].marks`)}


                                    style={{ marginRight: "25px" }}
                                />
                            </Grid>

                          
                          


                            <MDBox
                                sx={{
                                    textAlign: "right",
                                    background: "transparent",
                                    width: "100%",
                                }}
                            >
                                <MDButton
                                    variant="outlined"
                                    sx={{ border: "none", height: "10px", width: "30px" }}
                                    onMouseHover={{ color: "black" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        remove(k);
                                    }}
                                >
                                    <Typography
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                        onHover={{ color: "black" }}
                                    >
                                        Remove
                                    </Typography>
                                </MDButton>
                            </MDBox>
                            
                        </Grid>
                    </li>
                );
            })}

            <MDButton
                variant="contained"
                color="info"
                type="button"
                onClick={() =>
                    append({})
                }
            >
                +Add
            </MDButton>
        </ul>
             
      
       
    );
};
