import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addJobApplication,
  addJobJobs,
  addPermanentJob,
  createJob,
  deleteJob,
  deleteJobApplication,
  deleteJobJobs,
  deletePermanentJob,
  getJobApplicationList,
  getJobJobsList,
  getPermanentJobList,
  updateJob,
  updateJobApplication,
  updateJobJobs,
  // updatePermanentJob,
  getFilteredList,
  getFilteredListApp,
  getAllListApp,
  getAllJobsList
} from "./actionCreator";
import initialState from "./state";

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    pageLimit(state, action) {
      state.pageSize = action.payload;
    },
    searchKeyword(state, action){
      state.searchKey = action.payload;
    },
    searchKeywordApp(state, action){
      state.searchKeyApp = action.payload;
    },
    filterData(state, action){
      state.filterAll = action.payload;
    },
    filterCandidateTypes(state, action){
      state.candidateType = action.payload;
    },
    filterBranch(state, action){
      state.branch = action.payload;
    },
    filterSpecialization(state, action){
      state.specialization = action.payload;
    },
    updateStartDate(state, action){
      state.startDate = action.payload;
    },
    updateEndDate(state, action){
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Create Job
    builder.addCase(createJob.pending, (state) => {});
    builder.addCase(createJob.fulfilled, (state, action) => {});
    builder.addCase(createJob.rejected, (state, action) => {});
    //Update Job
    builder.addCase(updateJob.pending, (state) => {});
    builder.addCase(updateJob.fulfilled, (state, action) => {});
    builder.addCase(updateJob.rejected, (state, action) => {});
    //Delete Job
    builder.addCase(deleteJob.pending, (state) => {});
    builder.addCase(deleteJob.fulfilled, (state, action) => {});
    builder.addCase(deleteJob.rejected, (state, action) => {});

    // JobApplication //

    //GET JobApplication
    builder.addCase(getJobApplicationList.pending, (state) => {});
    builder.addCase(getJobApplicationList.fulfilled, (state, action) => {
      state.job_Application_Data.job_Application_Data_loading = false;
      state.job_Application_Data.job_Application_Data_info =
        action?.payload?.data?.docs;
        state.job_Application_Data.total = action?.payload?.data?.total;
     
    });
    builder.addCase(getJobApplicationList.rejected, (state, action) => {
    
    });
    //GET Filtered Application

    builder.addCase(getFilteredListApp.pending, (state) => {});
    builder.addCase(getFilteredListApp.fulfilled, (state, action) => {
      state.application_Filtered_Data.application_Filtered_Data_loading = false;
      state.application_Filtered_Data.application_Filtered_Data_info =
        action?.payload?.data;
     
    });
    builder.addCase(getFilteredListApp.rejected, (state, action) => {
    
    });

     //GET All list JobApplication
     builder.addCase(getAllListApp.pending, (state) => {});
     builder.addCase(getAllListApp.fulfilled, (state, action) => {
       state.job_All_Application_Data.job_All_Application_Data_loading = false;
       state.job_All_Application_Data.job_All_Application_Data_info =
         action?.payload?.data?.docs;
         state.job_All_Application_Data.total = action?.payload?.data?.total;
      
     });
     builder.addCase(getAllListApp.rejected, (state, action) => {
     
     });

    //Delete JobApplication
    builder.addCase(deleteJobApplication.pending, (state) => {});
    builder.addCase(deleteJobApplication.fulfilled, (state, action) => {
    
    });
    builder.addCase(deleteJobApplication.rejected, (state, action) => {
    
    });
    //Add JobApplication
    builder.addCase(addJobApplication.pending, (state) => {});
    builder.addCase(addJobApplication.fulfilled, (state, action) => {
    
      toast.success("Added Successfully",{
        position:"top-right"
      })
    });
    builder.addCase(addJobApplication.rejected, (state, action) => {
      
      toast.error(action?.payload?.error,{
         position:"top-right"
      })
    });
    //Update JobApplication
    builder.addCase(updateJobApplication.pending, (state) => {});
    builder.addCase(updateJobApplication.fulfilled, (state, action) => {
      
      toast.success("Updated Successfully",{
        position:"top-right"
      });
    });
    builder.addCase(updateJobApplication.rejected, (state, action) => {
   
      toast.error(action?.payload?.msg,{
        position:"top-right"
      })
    });

    // Job Jobs //

    //GET Job Jobs
    builder.addCase(getJobJobsList.pending, (state) => {});
    builder.addCase(getJobJobsList.fulfilled, (state, action) => {
      state.job_Data.job_Data_loading = false;
      state.job_Data.job_Data_info = action?.payload?.data?.docs;
      state.job_Data.total = action?.payload?.data?.total;
     
    });
    builder.addCase(getJobJobsList.rejected, (state, action) => {
     
    });

    //getAllJobsList
    builder.addCase(getAllJobsList.pending, (state) => {});
    builder.addCase(getAllJobsList.fulfilled, (state, action) => {
      state.job_All_Data.job_All_Data_loading = false;
      state.job_All_Data.job_All_Data_info = action?.payload?.data?.docs;
      state.job_All_Data.total = action?.payload?.data?.total;
     
    });
    builder.addCase(getAllJobsList.rejected, (state, action) => {
     
    });

    //GET Filtered Job
    builder.addCase(getFilteredList.pending, (state) => {});
    builder.addCase(getFilteredList.fulfilled, (state, action) => {
      state.job_Filtered_Data.job_Filtered_Data_loading = false;
      state.job_Filtered_Data.job_Filtered_Data_info = action?.payload?.data;
      // state.job_Filtered_Data.total = action?.payload?.data?.total;
     
    });
    builder.addCase(getFilteredList.rejected, (state, action) => {
     
    });

    //Delete Job Jobs
    builder.addCase(deleteJobJobs.pending, (state) => {});
    builder.addCase(deleteJobJobs.fulfilled, (state, action) => {
     
    });
    builder.addCase(deleteJobJobs.rejected, (state, action) => {
  
    });
    //Add Job Jobs
    builder.addCase(addJobJobs.pending, (state) => {});
    builder.addCase(addJobJobs.fulfilled, (state, payload) => {

    });
    builder.addCase(addJobJobs.rejected, (state, action) => {
      
    });
    //Update Job Jobs
    builder.addCase(updateJobJobs.pending, (state) => {});
    builder.addCase(updateJobJobs.fulfilled, (state, payload) => {
      
      toast.success("Updated Successfully", { position: "top-right" });
    });
    builder.addCase(updateJobJobs.rejected, (state, action) => {
      
      toast.error(action?.payload?.msg, { position: "top-right" });
    });

    // Permanent Jobs //

    // //GET Permanent Jobs
    // builder.addCase(getPermanentJobList.pending, (state) => {});
    // builder.addCase(getPermanentJobList.fulfilled, (state, action) => {
    //   state.permanent_Job_Data.permanent_Job_Data_loading = false;
    //   state.permanent_Job_Data.permanent_Job_Data_info =
    //     action?.payload?.data?.docs;
    
    // });
    // builder.addCase(getPermanentJobList.rejected, (state, action) => {
      
    // });
    // //Delete Permanent Jobs
    // builder.addCase(deletePermanentJob.pending, (state) => {});
    // builder.addCase(deletePermanentJob.fulfilled, (state, action) => {
      
    // });
    // builder.addCase(deletePermanentJob.rejected, (state, action) => {
    
    // });
    // //Add Permanent Jobs
    // builder.addCase(addPermanentJob.pending, (state) => {});
    // builder.addCase(addPermanentJob.fulfilled, (state, payload) => {
     
    // });
    // builder.addCase(addPermanentJob.rejected, (state, action) => {
 
    // });
    // //Update Permanent Jobs
    // builder.addCase(updatePermanentJob.pending, (state) => {});
    // builder.addCase(updatePermanentJob.fulfilled, (state, payload) => {
     
    // });
    // builder.addCase(updatePermanentJob.rejected, (state, action) => {
      
    // });

    
  },
  
});
export const {
  pageChanged,
  pageLimit,
  searchKeyword,
  searchKeywordApp,
  filterData,
  filterCandidateTypes,
  filterBranch,
  filterSpecialization,
  updateStartDate,
  updateEndDate
} = jobSlice.actions;

export default jobSlice.reducer;
