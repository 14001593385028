import { Checkbox, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { setRoleType } from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getAdminUsers } from "services/adminServices";
import { formatDate } from "util/Helper";
import MDBox from "../../../components/MDBox/index";
import { getAdminUser } from "../Redux/actionCreator";
import AddAdminUserDialogContent from "./AddAdminUserDialogContent";

function getUpdatedByName(params) {
  return `${params?.row?.updatedBy?.name?.full || "NA"}`;
}
function getCreatedByName(params) {
  return `${params?.row?.createdBy?.name?.full || "NA"}`;
}

function getCreatedAT(params) {
  var rowName = formatDate(params?.row?.createdAt);
  var dateNOW = new Date(rowName);
  return dateNOW;
}

function getUpdatedAT(params) {
  var rowName = formatDate(params?.row?.createdAt);
  var dateNOW = new Date(rowName);
  return dateNOW;
}
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const columns = [
  {
    field: "_id",
    headerName: `_id`,
  },
  {
    field: "name",
    headerName: `Name`,

    renderCell: (cellValues) => {
      const rowUserName =
        cellValues?.row?.name?.first + " " + cellValues?.row?.name?.last;
      const rowUserId = cellValues?.row?._id;

      return <Link to={`${rowUserId}`}>{rowUserName}</Link>;
    },
    valueGetter: (cellValues) =>
      cellValues?.row?.name?.first + cellValues?.row?.name?.last,
    flex: 1,
  },
  {
    field: "email",
    headerName: `Email`,
    flex: 1,
  },
  {
    field: "isAdmin",
    headerName: `Can Login To Admin`,
    renderCell: (cellValues) => {
      const rowCode = cellValues?.row?.code;
      return (
        <Checkbox
          {...label}
          checked={cellValues?.row?.isAdmin}
          disabled
          // defaultChecked={cellValues?.row?.isAdmin}
          sx={{ alignItems: "flex-start" }}
        />
      );
    },
    valueGetter: (cellValues) => cellValues?.row?.isAdmin,
  },
  // {
  //   field: "isAdmin",
  //   headerName: `Is Admin`,
  //   renderCell: (cellValues) => {
  //     // const rowCode = cellValues?.row?.code;
  //     return (
  //       <Checkbox
  //         {...label}
  //         defaultChecked={cellValues?.row?.isAdmin}
  //         sx={{ alignItems: "flex-start" }}
  //       />
  //     );
  //   },

  //
  // },
  {
    field: "password",
    headerName: `Password`,
  },
  {
    field: "role",
    headerName: `Role`,
    flex: 1,
  },
  // {
  //   field: "createdAt",
  //   headerName: `Created At`,
  //
  // },
  {
    field: "createdAt",
    headerName: `Created At`,
    type: "date",

    valueGetter: getCreatedAT,
  },
  {
    field: "updatedAt",
    headerName: `Updated At`,
    type: "date",
    valueGetter: getUpdatedAT,
  },
  {
    field: "createdBy",
    headerName: `Created By`,
    renderCell: (cellValues) => {
      return cellValues?.row?.createdBy?.name?.full;
    },
    valueGetter: getCreatedByName,
  },
  {
    field: "updatedBy",
    headerName: `Updated By`,

    renderCell: (cellValues) => {
      return cellValues?.row?.updatedBy?.name?.full;
    },
    valueGetter: getUpdatedByName,
  },
];

// const handleCellClick = (event) => {
//   event.originalEvent.stopPropagation();
// };

// const handleRowClick = (event) => {
//   event.originalEvent.stopPropagation();
// };

function AdminUsers({ dialog, title }) {
  const { state } = useLocation();

  const dispatch = useDispatch();
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getAdminUsers(checked);

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getAdminUsers(checked);
    }
  };
  const { Admin_User_Data } = useSelector((state) => state.admin);
  const adminData = Admin_User_Data?.adminUserInfo;

  useEffect(() => {
    dispatch(getAdminUser());
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout className="dashboardLayout">
      <DashboardNavbar />
      <FilterBar
        title="Create Admin"
        title1="Admin User"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        showisDeleted={false}
        handleDeleteApi={handleDeleteApi}
        dialogContent={
          <AddAdminUserDialogContent handleDialogClose={handleDialogClose} />
        }
      />
      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              csvOptions: {
                fileName: `AdminUsers-${formatDate()}`,
              },
            },
          }}
          getRowId={(row) => row._id}
          initialState={{
            columns: {
              columnVisibilityModel: {
                _id: false,
                password: false,
                isAdmin: false,
                createdAt: false,
                updatedAt: false,
                createdBy: false,
                updatedBy: false,
              },
            },
          }}
          components={{ Toolbar: GridToolbar }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          loading={adminData.length > 0 ? false : true}
          columns={columns}
          rows={showDeltedRecords ? deletedRecords : [...adminData].reverse()}
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminUsers;
