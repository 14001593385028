import { InputLabel } from "@mui/material";
import React, { useEffect } from "react";
import MDButton from "./../../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import SelectDropDown from "./../../../examples/DropDown/index";
import Checkbox from "@mui/material/Checkbox";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import hospitalPanelValidation, {
  CompanyValidation,
  corporateDialogValidation,
} from "../../../util/validations/hospitalPanelValidation";
import SelectDropDownNew from "examples/DropDownNew";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCompanyList } from "layouts/common/Redux/actionCreator";
import { createHospitalCompany, setCompanies } from "../Redux/actionCreator";
import MultiSelectDropdown from "examples/Multi Select Dropdown";
import { FormHelperText } from "@material-ui/core";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CreateCompanyDialog = ({ handleDialogClose }) => {
  const[company,setCompany]=useState();
  //   const ValidationSchema=yup.object().shape({
  //     corporateName:yup.string().required("Corporate Name is Required")
  // })
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CompanyValidation),
  });

  const { Company_List_Data } = useSelector((state) => state.common);
  const { companies_DropDown_Data } = useSelector((state) => state.hospital);
  const companyTypeData = Company_List_Data?.companyListInfo;
  const companiesData = companies_DropDown_Data?.companiesDropDownInfo;

  const companyTypeOptions = [];
  const companiesOptions = [];

  companyTypeData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyTypeOptions.push(obj);
  });

  companiesData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companiesOptions.push(obj);
  });
  const [companiesIDPayload, setCompaniesIDPayload] = useState([]);
  const handleCompaniesChange = (e) => {
   
    //Searching for the name in the qualification array to find the ID
   
    if(errors?.companies?.message)
    errors.companies.message="";
    let companyID;
    //Searching for the name in the Users array to find the ID
    companiesOptions.map((obj) => {
      if (obj.value == e.target.value) {
        companyID = obj.key;
      }
    });
    setValue("companies", companyID);
    setCompaniesIDPayload(companyID);
  };

  
  const handleCompanyTypeChange = (e) => {
    if(errors?.companyType?.message)
    errors.companyType.message="";
    let companyTypeID;
    //Searching for the name in the Users array to find the ID
    companyTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        companyTypeID = obj.key;
      }
    });
    setValue("companyType", companyTypeID);
    setCompany(companyTypeID);
  };

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    
    let payload = {
      name: data?.corporateName,
      companyType:company,
      companies: companiesIDPayload,
    };
   
      dispatch(createHospitalCompany(payload));
      handleDialogClose();
  
  };

  useEffect(() => {
    dispatch(getCompanyList());
    dispatch(
      setCompanies({
        criteria: {
          listName: true,
        },
      })
    );
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("corporateName")}
              error={errors?.corporateName?.message}
              helperText={errors?.corporateName?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Company Type
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              control={control}
              getValues={getValues}
              defaultValue="none"
              name="companyType"
              options={companyTypeOptions}
              placeholder="Select"
              customOnChange={handleCompanyTypeChange}
            />
            <FormHelperText>{errors?.companyType?.message}</FormHelperText>
          </Grid>

          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Company
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <SelectDropDownNew
              options={companiesOptions}
              name="companies"
              getValues={getValues}
              defaultValue="none"
              control={control}
              customOnChange={handleCompaniesChange}
            />
            <FormHelperText>{errors?.companies?.message}</FormHelperText>
            {/* {companiesIDPayload.length>0?<></>:<FormHelperText>{errors?.companies?.message}</FormHelperText>} */}
             
            
          </Grid>
        </Grid>

        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "2rem" }}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default CreateCompanyDialog;