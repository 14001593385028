import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getEventList } from "services/eventServices";
import { formatDate } from "util/Helper";
import { eventDeletion, eventList } from "./Redux/actionCreator";
import moment from "moment";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EventListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Delete API
  const handleDelete = (ID) => {
    dispatch(eventDeletion(ID));
  };

  //VIEW
  const handleView = (id) => {
    navigate(`/eventDetails/update/${id}`);
  };

  //VALUE GETTERS

  function getProfessionalType(params) {
    const rowarray = params?.row?.professionaltType;

    const values = [];

    rowarray?.map((obj) => {
      values.push(obj?.name);
    });

    return values;
  }

  function getQualification(params) {
    const rowarray = params?.row?.qualification;

    const values = [];

    rowarray?.map((obj) => {
      values.push(obj?.name);
    });

    return values;
  }

  function getSpecialization(params) {
    const rowarray = params?.row?.specialization;

    const values = [];

    rowarray?.map((obj) => {
      values.push(obj?.name);
    });

    return values;
  }

  function getExperience(params) {
    const rowarray = params?.row?.numExperience;

    const values = [];

    rowarray?.map((obj) => {
      values.push(obj?.name);
    });

    return values;
  }

  function getCity(params) {
    const rowarray = params?.row?.workCity;

    const values = [];

    rowarray?.map((obj) => {
      values.push(obj?.name);
    });

    return values;
  }

  function getCreatedAt(params) {
    const roleName = params?.row?.createdAt;
    const a = moment(roleName).format("YYYY/MM/DD");
    return a;
  }
  function getUpdatedAt(params) {
    const roleName = params?.row?.updatedAt;
    const a = moment(roleName).format("YYYY/MM/DD");
    return a;
  }

  //Events Listing Columns
  const columns = [
    {
      field: "eventName",
      headerName: `Name`,
      flex: 1,
    },
    {
      field: "hyperLink",
      headerName: `Hyperlink`,
      flex: 1,
    },

    {
      field: "professionalType",

      headerName: "Profession",

      renderCell: (cellValues) => {
        const rowarray = cellValues?.row?.professionalType;

        const values = [];

        rowarray?.map((obj) => {
          values.push(obj?.name);
        });

        return values;
      },
      valueGetter: getProfessionalType,
    },
    {
      field: "qualification",

      headerName: "Qualification",

      renderCell: (cellValues) => {
        const rowarray = cellValues?.row?.qualification;

        const values = [];

        rowarray?.map((obj) => {
          values.push(obj?.name);
        });

        return values;
      },
      valueGetter: getQualification,
    },
    {
      field: "specialization",

      headerName: "Specialization",

      renderCell: (cellValues) => {
        const rowarray = cellValues?.row?.specialization;

        const values = [];

        rowarray?.map((obj) => {
          values.push(obj?.name);
        });

        return values;
      },
      valueGetter: getSpecialization,
    },
    {
      field: "workCity",

      headerName: "City",

      renderCell: (cellValues) => {
        const rowarray = cellValues?.row?.workCity;

        const values = [];

        rowarray?.map((obj) => {
          values.push(obj?.name);
        });

        return values;
      },
      valueGetter: getCity,
    },
    {
      field: "numExperience",

      headerName: "Experience",

      renderCell: (cellValues) => {
        const rowarray = cellValues?.row?.numExperience;

        const values = [];

        rowarray?.map((obj) => {
          values.push(obj?.name);
        });

        return values;
      },
      valueGetter: getExperience,
    },
    {
      field: "adTarget",
      headerName: `Platform`,
      flex: 1,
    },
    {
      field: "adStartDate",
      headerName: `Ad Start Date`,
      valueGetter: (cellValues) => {
        const adStart = moment(
          cellValues?.row?.adStartDate,
          "DD/MM/YYYY"
        ).toDate();
        // console.log("210-->", adStart)
        // return adStart;
        var dateNOW = new Date(adStart);
        var day = dateNOW.getDate();
        var month = dateNOW.getMonth() + 1;
        var year = dateNOW.getFullYear();

        var formattedDate = ` ${year}/${
          month < 10 ? "0" + month : month
        }/${day < 10 ? "0" + day : day}`;

        return formattedDate;
      },
      type: "date",
      flex: 1,
    },
    {
      field: "adEndDate",
      headerName: `Ad End Date`,
      valueGetter: (cellValues) => {
        const adEnd = moment(cellValues?.row?.adEndDate, "DD/MM/YYYY").toDate();
        var dateNOW = new Date(adEnd);
        var day = dateNOW.getDate();
        var month = dateNOW.getMonth() + 1;
        var year = dateNOW.getFullYear();

        var formattedDate = ` ${year}/${
          month < 10 ? "0" + month : month
        }/${day < 10 ? "0" + day : day}`;

        return formattedDate;

        // return adEnd;
      },
      type: "date",
      flex: 1,
    },
    {
      field: "eventStartDate",
      headerName: `Event Start Date`,
      valueGetter: (cellValues) => {
        if (cellValues?.row?.eventStartDate == "") {
          return null;
        }
        const eventStart = moment(
          cellValues?.row?.eventStartDate,
          "DD/MM/YYYY"
        ).toDate();

        var dateNOW = new Date(eventStart);
        var day = dateNOW.getDate();
        var month = dateNOW.getMonth() + 1;
        var year = dateNOW.getFullYear();

        var formattedDate = ` ${year}/${
          month < 10 ? "0" + month : month
        }/${day < 10 ? "0" + day : day}`;

        return formattedDate;

        //return eventStart;
      },
      type: "date",
      flex: 1,
    },
    {
      field: "eventEndDate",
      headerName: `Event End Date`,
      valueGetter: (cellValues) => {
        if (cellValues?.row?.eventEndDate == "") {
          return null;
        }
        const eventEnd = moment(
          cellValues?.row?.eventEndDate,
          "DD/MM/YYYY"
        ).toDate();

        var dateNOW = new Date(eventEnd);
        var day = dateNOW.getDate();
        var month = dateNOW.getMonth() + 1;
        var year = dateNOW.getFullYear();

        var formattedDate = ` ${year}/${
          month < 10 ? "0" + month : month
        }/${day < 10 ? "0" + day : day}`;

        return formattedDate;

        //return eventEnd;
      },
      type: "date",
      flex: 1,
    },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        return created;
      },
      type: "date",
      valueGetter: getCreatedAt
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

        return updated;
      },
      type: "date",
      valueGetter: getUpdatedAt
    },
    {
      field: "Actions",
      headerName: `Actions`,

      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleView(row?._id)}
            >
              <Edit fontSize="small" />
            </IconButton>
            &nbsp;
            <DeleteDialog
              message={`Are you sure you want to delete ${row?.eventName}?`}
              handleConfirmation={() => {
                handleDelete(row?._id);
              }}
              render={(handlePopupOpen) => (
                <IconButton
                  aria-label="delete"
                  size="small"
                  // disabled={editDeleteVisibility}
                >
                  <Delete fontSize="small" onClick={handlePopupOpen} />
                </IconButton>
              )}
            />
          </>
        );
      },
    },
  ];
  const [loading, setLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const { Event_List_Data } = useSelector((state) => state.events);
  const eventListData = Event_List_Data?.eventListInfo;
  var eventLoading = Event_List_Data?.eventListLoading;

  useEffect(() => {
    setLoading(eventLoading);
  }, [eventLoading]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [notDeletedRecords, setNotDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getEventList(checked);
      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getEventList(checked);
      setNotDeletedRecords(response?.data?.docs);
    }
  };

  useEffect(() => {
    dispatch(eventList(false));
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Create Event"
        title1="Event Details"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        customDialogOnClick={() => {
          navigate(`/eventDetails/create`);
        }}
      />

      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `EventsList-${formatDate()}`,
            },
          },
        }}
        density="compact"
        initialState={{
          columns: {
            columnVisibilityModel: {
              isDeleted: false,
              professionalType: false,
              qualification: false,
              specialization: false,
              numExperience: false,
              workCity: false,
              createdAt: false,
              updatedAt: false,
              createdBy: false,
              updatedBy: false,
            },
          },
        }}
        // isRowSelectable
        isRowSelectable={(params) => {
          return true; 
        }}
        loading={eventLoading ? true : false}
        columns={columns}
        rows={showDeltedRecords ? deletedRecords : [...eventListData]}
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
};
export default EventListing;
