import { get_Token } from "util/Helper";
import axiosInstance from "../axios";

export const getAdminUsers = async (payload) => {
  try {
    const response = await axiosInstance.post(`/adminuser/list`, payload);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Admin User
export const deleteAdminUserService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/adminuser/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
// Add Admin USer
export const addNewAdminUser = async (payload) => {
  try {
    const response = await axiosInstance.post(`/adminuser`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};
//Get Admin User By Id
export const getAdminUserByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/adminuser/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Update Admin User
export const updateAdminUserService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/adminuser/${payload.id}`,
      payload.payload
    );
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};
// ---------------------- ARI ------------------- //

// ARI Services

// ARI list

export const getAriService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/ari/list`, criteria);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// ARI by id

export const getAriIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/ari/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// add ARI
export const addNewAriService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/ari`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Update ARI
export const updateAriService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/ari/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// Delete ARI
export const deleteAriService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/ari/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//***************Admin Roles Services*******************//

// List Roles
export const getRoles = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/role/list`, criteria, {
      headers: {
        Authorization: `Bearer ${get_Token()}`,
      },
    });
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Delete a Role
export const deleteRoleService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/role/${id}`, {
      headers: {
        Authorization: `Bearer ${get_Token()}`,
      },
    });
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
// Add Role
export const addNewRole = async (payload) => {
  try {
    const response = await axiosInstance.post(`/role`, payload, {
      headers: {
        Authorization: `Bearer ${get_Token()}`,
      },
    });
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};
//Get Role By Id
export const getRoleByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/role/list`, criteria, {
      headers: {
        Authorization: `Bearer ${get_Token()}`,
      },
    });
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//Update Role
export const updateRoleService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/role/${payload.id}`,
      payload.payload,
      {
        headers: {
          Authorization: `Bearer ${get_Token()}`,
        },
      }
    );
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};
