import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { css } from "glamor";
import {
  addAri,
  deleteAri,
  getAdminUser,
  getAriList,
  updateAri,
  addRole,
  deleteRole,
  getRolesList,
  updateRole,
  updateAdminUSer,
  addAdminUser,
  deleteAdminUser,
} from "./actionCreator";
import initialState from "./state";

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAdminUser.pending, (state) => {});
    builder.addCase(getAdminUser.fulfilled, (state, action) => {
      state.Admin_User_Data.adminUserInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getAdminUser.rejected, (state, action) => {
     
    });

    //Delete Admin USer
    builder.addCase(deleteAdminUser.pending, (state) => {});
    builder.addCase(deleteAdminUser.fulfilled, (state, action) => {
     
    });
    builder.addCase(deleteAdminUser.rejected, (state, action) => {
     
    });
    //Add AdminUser
    builder.addCase(addAdminUser.pending, (state) => {});
    builder.addCase(addAdminUser.fulfilled, (state, payload) => {
      
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addAdminUser.rejected, (state, action) => {
   
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    //Update Admin User
    builder.addCase(updateAdminUSer.pending, (state) => {});
    builder.addCase(updateAdminUSer.fulfilled, (state, payload) => {
  
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateAdminUSer.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    // Ari //

    //GET Ari
    builder.addCase(getAriList.pending, (state) => {});
    builder.addCase(getAriList.fulfilled, (state, action) => {
      state.Ari_data.ariLoading = false;
      state.Ari_data.ariInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getAriList.rejected, (state, action) => {
    
    });
    //Delete Ari
    builder.addCase(deleteAri.pending, (state) => {});
    builder.addCase(deleteAri.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteAri.rejected, (state, action) => {
    
    });
    //Add Ari test admin
    builder.addCase(addAri.pending, (state) => {});
    builder.addCase(addAri.fulfilled, (state, payload) => {
   
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(addAri.rejected, (state, action) => {
   
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    //Update Ari
    builder.addCase(updateAri.pending, (state) => {});
    builder.addCase(updateAri.fulfilled, (state, payload) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateAri.rejected, (state, action) => {
      
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //Roles Reducers
    //GET Role
    builder.addCase(getRolesList.pending, (state) => {});
    builder.addCase(getRolesList.fulfilled, (state, action) => {
      state.Admin_Roles_Data.adminRoleLoading = false;
      state.Admin_Roles_Data.adminRoleInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(getRolesList.rejected, (state, action) => {
    
    });
    //Delete Role
    builder.addCase(deleteRole.pending, (state) => {});
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteRole.rejected, (state, action) => {
     
    });
    //Add Role
    builder.addCase(addRole.pending, (state) => {});
    builder.addCase(addRole.fulfilled, (state, payload) => {
      
   
      toast.success("Created Successfully", {
        position: "top-right",
        fontSize: "2px",
      });
    });
    builder.addCase(addRole.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    //Update Role
    builder.addCase(updateRole.pending, (state) => {});
    builder.addCase(updateRole.fulfilled, (state, payload) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateRole.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
  },
});

export default adminSlice.reducer;
