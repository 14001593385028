import { yupResolver } from "@hookform/resolvers/yup";
import React from 'react';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Moment from "moment";
import {
  Button,
  Checkbox,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useForm } from "react-hook-form";
import userDetailsValidationSchema from "../../../util/validations/userDetailsValidation";
import SelectDropDown from "./../../../examples/DropDown/index";
import { DataGrid } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
//import { getJobApplicationByIdService } from "shared/services";
import { getInterviewListById } from "../../../services/eventServices";
//import { getJobJobsList, updateJobApplication } from "../../job/Redux/actionCreator";
import { getInterviewListId } from "../Redux/actionCreator";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import MDButton from "components/MDButton";
import moment from "moment";
import dayjs from "dayjs";
import SelectDropDownNew from "examples/DropDownNew";
import { formatDate } from "util/Helper";
//import { formatDate } from "../../../util/Helper";
function InterviewDetailsById() {
  const ID = window.location.href.split("id")[1].split("/")[1];
  console.log("ID", ID);
  const getData = async () => {
    let response = await getInterviewListById(ID);
    console.log("response", response);
    //let n = response[0].createdAt;

    setResponseData(response);

    return response[0];
  };
  console.log("getData", getData);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: () => getData(),
  });
  console.log("getvalues", getValues);
  const formValues = getValues();

  console.log("formValues", formValues);

  const selectOptions = [
    {
      key: "OPEN",
      value: "OPEN",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "PENDING_TEST",
      value: "PENDING_TEST",
    },
    {
      key: "REVIEW",
      value: "REVIEW",
    },
    {
      key: "PENDING_INTERVIEW",
      value: "PENDING_INTERVIEW",
    },
    {
      key: "FINISHED",
      value: "FINISHED",
    },
    {
      key: "WITHDRAW",
      value: "WITHDRAW",
    },
  ];

  // Applied At DateTime

  const [appliedAtDateTime, setAppliedAtDateTime] = useState(null);

  const handleAppliedAtDateTime = (newValue) => {
    setAppliedAtDateTime(newValue);
  };

  const handleAppliedAtDateTimeNow = () => {
    setAppliedAtDateTime(Date().now);
  };

  // Interview At DateTime

  const [interviewAtDateTime, setInterviewAtDateTime] = useState(null);

  const handleInterviewAtDateTime = (newValue) => {
    setInterviewAtDateTime(newValue);
  };

  const handleInterviewAtDateTimeNow = () => {
    setInterviewAtDateTime(Date().now);
  };

  // Test Request At DateTime

  const [testRequestAtDateTime, setTestRequestAtDateTime] = useState(null);

  const handleTestRequestAtDateTime = (newValue) => {
    setTestRequestAtDateTime(newValue);
  };

  const handleTestRequestAtDateTimeNow = () => {
    setTestRequestAtDateTime(Date().now);
  };

  // Interview Request At DateTime

  const [interviewRequestAtDateTime, setInterviewRequestAtDateTime] =
    useState(null);

  const handleInterviewRequestAtDateTime = (newValue) => {
    setInterviewRequestAtDateTime(newValue);
  };

  const handleInterviewRequestAtDateTimeNow = () => {
    setInterviewRequestAtDateTime(Date().now);
  };

  //Fetching Organization Dropdown Details
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { interviewList_Data } = useSelector((state) => state.events);
  //console.log("interviewList_Data", interviewList_Data)
  const eventListData = interviewList_Data?.interviewList_Data_info;
  //console.log("eventListData", eventListData.application)

  const { OrganizationsData } = useSelector((state) => state.common);
  const organizationInfo = OrganizationsData?.organizationsInfo;

  const organizationOptions = [];

  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;

  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  const { job_Data } = useSelector((state) => state.job);
  const jobCode = job_Data?.job_Data_info;

  let jobOptions = [];

  jobCode.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.code,
    };

    jobOptions.push(obj);
  });
  const [job, setJob] = useState("");
  const handleJobChange = (e) => {
    let userID;

    jobOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
        setJob(userID);
      }
    });
  };

  const [organization, setOrganization] = useState("");
  const handleOrganizationChange = (e) => {
    let userID;
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
        setOrganization(userID);
      }
    });
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const [pro, setPro] = useState("");
  const handleProfessionalChange = (e) => {
    let userID;
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
        setPro(userID);
      }
    });
  };

  useEffect(() => {
    dispatch(setOrganizationsDropdown());
    dispatch(setUsersDropdown());
    dispatch(getInterviewListId());
  }, []);

  const columns = [
    { field: "date", headerName: `Date`, flex: 1 },
    { field: "professionalName", headerName: `Professional Name`, flex: 1 },
    { field: "shiftStartTime", headerName: `Shift Start Time`, flex: 1 },
    { field: "shiftEndTime", headerName: `Shift End Time`, flex: 1 },
    {
      field: "checkInTime",
      renderHeader: () => (
        <strong>
          {`Check In Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      editable: true,
      flex: 1,
    },
    {
      field: "checkOutTime",
      renderHeader: () => (
        <strong>
          {`Check In Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),

      editable: true,
      flex: 1,
    },
  ];
  const rows = [
    {
      id: 1,
      date: "Date 1",
      professionalName: "Professional 1",
      shiftStartTime: "9:00",
      shiftEndTime: "18:00",
      checkInTime: "08:57",
      checkOutTime: "17:50",
    },
    {
      id: 2,
      date: "Date 1",
      professionalName: "Professional 1",
      shiftStartTime: "9:00",
      shiftEndTime: "18:00",
      checkInTime: "08:57",
      checkOutTime: "",
    },
  ];

  const [responseData, setResponseData] = useState([]);

  const onSubmit = (data) => {
    const payload = {
      appliedAt:
        typeof data?.appliedAt == "object"
          ? data?.appliedAt?.ts.length > 0
            ? new Date(parseInt(data.appliedAt.ts)).toISOString()
            : ""
          : data?.appliedAt,

      testRequestAt:
        typeof data?.testRequestAt == "object"
          ? data?.testRequestAt?.ts.length > 0
            ? new Date(parseInt(data.testRequestAt.ts)).toISOString()
            : ""
          : data?.testRequestAt,
      approvedAt:
        typeof data?.approvedAt == "object"
          ? data.approvedAt.ts.length > 0
            ? new Date(parseInt(data.approvedAt.ts)).toISOString()
            : ""
          : data.approvedAt,

      interviewStart:
        typeof data?.interviewStart == "object"
          ? data?.interviewStart?.ts.length > 0
            ? new Date(parseInt(data.interviewStart.ts)).toISOString()
            : ""
          : data.interviewStart,

      interviewRequestAt:
        typeof data?.interviewRequestAt == "object"
          ? data?.interviewRequestAt?.ts.length > 0
            ? new Date(parseInt(data.interviewRequestAt.ts)).toISOString()
            : ""
          : data.interviewRequestAt,

      _id: ID,

      organization: organization,
      job: job,
      professional: pro,
      state: data.state,
      interviewFeedback: data.interviewFeedback,
      interviewLengthMinutes: data.interviewLengthMinutes,

      assignedCid: data.assignedCid,

      __v: 0,
    };

    // dispatch(updateJobApplication({ payload: payload, id: ID })).then(() => {
    //   navigate("/jobs/application");
    // });
  };
  const handleReset = () => {
    reset();
  };

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
  };

  const [updated1, setUpdated1] = useState(false);
  const [updated2, setUpdated2] = useState(false);
  const [updated3, setUpdated3] = useState(false);
  const [updated4, setUpdated4] = useState(false);
  const [updated5, setUpdated5] = useState(false);
  const [updated6, setUpdated6] = useState(false);
  const [updated7, setUpdated7] = useState(false);

  const [appliedd, setApplied] = useState(null);
  const [interviewAt, setInterviewAt] = useState(null);
  const [approvedAt, setApprovedAt] = useState(null);
  const [testRequest, setTestRequest] = useState(null);
  const [interviewRequest, setInterviewRequest] = useState(null);
  useEffect(() => {
    //let createdDate = getValues("createdAt");
    let updatedDate = getValues("updatedAt");
    let applied = getValues("appliedAt");

    // if (createdDate == "Invalid date") {
    //   setValue("createdAt", "");
    // }
    if (updatedDate == "Invalid date") {
      setValue("updatedAt", "");
    }
    let interviewAt = getValues("interviewStart");
    let interviewRequest = getValues("interviewRequestAt");

    let approved = getValues("approvedAt");
    let test = getValues("testRequestAt");

    if (test?.ts == "") setTestRequest(null);
    if (test != undefined) {
      if (!updated5) {
        setTestRequest(new Date(parseInt(test.ts)).toUTCString() + "+530");

        setUpdated5(true);
      }
    }
    if (approved?.ts == "") setApprovedAt(null);
    if (approved != undefined) {
      if (!updated6) {
        setApprovedAt(new Date(parseInt(approved.ts)).toUTCString() + "+530");

        setUpdated6(true);
      }
    }

    if (interviewRequest?.ts == "") setInterviewRequest(null);
    if (interviewRequest != undefined) {
      if (!updated6) {
        setInterviewRequest(
          new Date(parseInt(interviewRequest.ts)).toUTCString() + "+530"
        );

        setUpdated7(true);
      }
    }

    if (applied?.ts == "") setApplied(null);

    if (applied != undefined) {
      if (!updated3) {
        const res = applied.ts;
        setApplied(new Date(parseInt(res)).toUTCString() + "+530");

        setUpdated3(true);
      }
    }
    if (interviewAt?.ts == "") setInterviewAt(null);
    if (interviewAt != undefined) {
      if (!updated4) {
        setInterviewAt(
          new Date(parseInt(interviewAt.ts)).toUTCString() + "+530"
        );

        setUpdated4(true);
      }
    }

    // if (createdDate != undefined && createdDate !== "") {
    //   if (!updated1) {
    //     const a = Moment(createdDate).format("DD/MM/YYYY");

    //     //setValue("createdAt", a);

    //     setUpdated1(true);
    //   }
    // }

    if (updatedDate != undefined && updatedDate !== "") {
      if (!updated2) {
        const a = Moment(updatedDate).format("DD/MM/YYYY");
        setValue("updatedAt", a);

        setUpdated2(true);
      }
    }
  }, [
    getValues("updatedAt"),
    getValues("interviewRequestAt"),
    getValues("approvedAt"),
    getValues("interviewStart"),
    // getValues("createdAt"),
    getValues("appliedAt"),
    getValues("testRequestAt"),
  ]);

//   function getSlot() {
//     const rowarray = formValues.suggestedSlot;
//     console.log("rowarray", rowarray);
//     const values = [];
//     if (rowarray) {
//       rowarray.map((obj) => {
//         values.push(`${obj?.start}-${obj?.date}-${obj?.interviewLength}`);
//       });
//     }

//     return values;
//   }
//   console.log(getSlot());

function getSlot() {
    const rowarray = formValues.suggestedSlot;
    console.log("rowarray", rowarray);
    const values = [];
    if (rowarray) {
      rowarray.forEach((obj) => {
        values.push(`${obj?.start}-${obj?.date}-${obj?.interviewLength}`);
      });
    }
    return values.join(', '); // Join the values with a comma separator
  }

  function getCreatedAT() {
    var rowName = formatDate(formValues.createdAt);
    var dateNOW = new Date(rowName);
    return dateNOW;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Code
                        </Typography>
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={organizationOptions}
                          placeholder="Organization"
                          control={control}
                          getValues={getValues}
                          defaultValue="none"
                          name="organization.name"
                          customOnChange={handleOrganizationChange}
                          // customOnChange={(e) =>
                          //   setValue("organization.name", e.target.value)
                          // }
                        />
                      </Grid> */}
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.job?.name}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Application Id
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.application?._id}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Candidate Name
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.candidate?.name?.full}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          InterviewStatus
                        </Typography>
                      </Grid>

                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.interviewStatus}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Employer Name
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.employer?.name?.full}
                        />
                      </Grid>

                      {/* <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          SuggestedSlot
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={getSlot()}
                        />
                      </Grid> */}

              {formValues.suggestedSlot.map((slot, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ width: "70%", marginTop: "0.8rem" }}
                          >
                            Suggested Slot {index + 1}
                          </Typography>
                        </Grid>
                        <Grid xs={7} sx={{ marginTop: "2rem" }}>
                          <MDInput
                            disabled
                            sx={{ width: "100%", marginTop: "0.4rem" }}
                            inputProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            defaultValue={`${slot?.start}-${slot?.date}-${slot?.interviewLength}`}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Booked Slot Date
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.date}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          InterviewLength
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.interviewLength}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          StartTime
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={formValues?.startTime}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          CreatedAt
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          defaultValue={getCreatedAT()}
                        />
                      </Grid>

                      {/* <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          UpdatedAt
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        defaultValue={formValues.updatedAt}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default InterviewDetailsById;
