import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Grid, Link, Paper } from "@mui/material";
import authoritySignature from "assets/images/authoritySignature.png";
import IfanFlexLogo from "assets/images/ifanFlexLogo.png";
import jobizoLogo from "assets/images/jobizoLogo.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import jsPDF from "jspdf";
// import html2canvas from 'html2canvas';
//import htmlToPdf from 'html-pdf';
// import html2pdf from 'html2pdf.js';

import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchBillData } from "../Redux/actionCreator";
import "./GigBillStyle.css";

const GigBill = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { Bill_Data } = useSelector((state) => state.bills);
  var gigBillData = Bill_Data?.billInfo;

  // console.log("gigBillData>>>", gigBillData);

  const path = window.location.pathname.split("view/")[1];
  // const path = window.location.pathname;

  const userInvoiceData = gigBillData[0]?.userInvoices
    ? gigBillData[0]?.userInvoices
    : [];

  const convert = (str) => {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [day, month, date.getFullYear()].join("-");
  };

  const lastPart = gigBillData[0]?.billNumber.split("/").pop();
  console.log(lastPart);

  async function printDocument() {
    let jsPdf = new jsPDF("p", "pt", "letter");
    // let jsPdf = new jsPDF("p", "pt", "legal");

    var htmlElement = document.getElementById("invoiceContainer");
    const opt = {
      callback: function (jsPdf) {
        jsPdf.save(`D${lastPart}.pdf`);
        window.open(jsPdf.output("bloburl"));
      },
      margin: [134, 20, 20, 20],
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.45,
      },
    };

    jsPdf.html(htmlElement, opt);
  }

  const handleDownloadData = async () => {
    try {
      const jsPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        hotfixes: ["px_scaling"],
        format: [1200, 800],
      });

      const pdfContent = `
        
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Your Page Title</title>
          <style>
        .invoiceHeader{
            display: flex;
            justify-content: space-between;
            align-content: center;
        }
        .headerContent{
            text-align-last: end;
        }
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
            padding: 5px;
            height: 5px;
          }
          th {
            text-align: left;
            height: 5px;
        
          }
          .textAlignmentCenter{
            text-align-last: center;
          }
          .textAlignmentRight{
            text-align-last: end;
          }
          .textFit{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .notePoint{
            font-weight: bold;
          }
          * {
                margin:0;
                padding:0;
                font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                color:  #001f3f;
              }
              .hr-line {
width: 100%;
margin: 0.5em 0;
border-top: 1px solid #000;
}
  
        
          </style>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js"></script>
        </head>
        
        <body >
          <div style="max-width: fit-content; font-size: 12.8px;">
            <div  style= "page-break-before: always; margin-top: 1rem; border: 1px solid black;width: 800px;height: auto;padding: 15px; margin: auto;">
              <div class="invoiceHeader">
                <img src="${IfanFlexLogo}" alt="logo" style="width: 25%;height: 10%; align-self: end;"/>
                <div class="headerContent">
                  <h4>Duplicate Copy</h4><br />
                  <small>Plot No.89, 3Floor, Sector 44</small><br />
                  <small>Gurgaon - 122003, Haryana (India)</small><br />
                  <small>Email: info@ifanglobal.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small><br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small><br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <table>
                <tbody>
                  <tr>
                    <th colspan="3" style="background-color: lightgrey; text-align: center;width:800px;margin-right: 50px;">
                      COMMERICAL DEBIT NOTE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Debit Note No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>${debitNumber}</td>
                    <td>
                      ${moment(gigBillData[0]?.billingDate).format(
                        "DD-MM-YYYY"
                      )}
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan="2">
                      Tax Registration
                      <br />
                      PAN:AAFCI9183G
                      <br />
                      GSTN:06AAFCI9183G1ZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Buyer:</th>
                    <th colSpan="2">Instructions:</th>
                  </tr>
                  <tr>
                    <td>
                      ${gigBillData[0]?.buyer?.address?.hospitalName}, 
                      ${gigBillData[0]?.buyer?.address?.address?.address}, 
                      ${gigBillData[0]?.buyer?.address?.address?.city}, 
                      ${gigBillData[0]?.buyer?.address?.address?.state}, 
                      ${gigBillData[0]?.buyer?.address?.address?.country}
                      <br />
                      ${gigBillData[0]?.buyer?.address?.address?.pin}
                    </td>
        
                    <td rowSpan="1" colSpan="2">
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 402105000512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code: ${gigBillData[0]?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:${
                        gigBillData[0]?.buyer?.reverseChargesApplicable
                      }
                      <br />
                      GSTIN: ${gigBillData[0]?.buyer?.gst}
                    </td>
                    <td style="vertical-align: top;">
                      Reference Invoice No: <hr class="hr-line" />
                      Reference Invoice Date: <hr class="hr-line" />
                      Payment Due Date:
                    </td>
                    <td style="vertical-align: top;">
                      ${gigBillData[0]?.billNumber} <hr class="hr-line" />
                      ${moment(gigBillData[0]?.billingDate).format(
                        "DD-MM-YYYY"
                      )} <hr class="hr-line" />
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">Description of Service</th>
                    <th  className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Reimbursement of Fees Payable to the
                      Professional
        
                    </td>
                    <td className="textAlignmentRight">${
                      gigBillData[0]?.cost
                    }</td>
                  </tr>
        
                  <tr>
                    <td></td>
                    <td >Total</td>
                    <td className="textAlignmentRight">${(gigBillData[0]?.cost).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              <div style="margin-top: 10px;">
                <b>Note <br/> * TDS is not be deducted on reimbursement amount</b><br/><br/>
                 <p>Terms & Conditions</p>
                 <div style="padding: 20px;">
                
                 <p>1. Subject to Delhi Jurisdiction only</p>
      <p>2. Late Payment Fees of 1.5% per month would be charged after due date</p>
                 </div>
                 <div>
                   <p style="font-style: italic;">For IFAN Flex Pvt. Ltd.</p><br/>
                   <img
                     src=${authoritySignature}
                     alt="logosign"
                     style="width: 10%;height: 10%;align-self: end;margin: 6px;"
                   /><br/>
                   <p style=" padding: 5px;font-size: 15px;color: grey;font-style: italic;">
                    No Signature required as it's a system generated Commerical Debit Note
                   </p>
                 </div>
                 <hr />
                 <div style="font-style: italic; font-size: 13px; display: flex; display: flex;justify-content: space-around;color: rgb(175, 171, 171); padding: 5px; ">
                   <p>Office: +91 124.487 5800</p>
                   <p>Fax : +91 124.401 4035</p>
                   <p>CIN: U74999DL2020PTC373866</p>
                 </div>
               </div>
              <br />
            </div>
        
            <div style="page-break-before: always; margin-top: 1rem; border: 1px solid black;box-shadow: 20px;width: 800px;padding: 15px; margin: auto;">
              <div style="page-break-before: always; display: flex;justify-content: space-between;">
                <img src=${IfanFlexLogo} alt="logo" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
                <img src=${jobizoLogo} alt="logo2" style="width: 15%;height: 10%;align-self: end;margin: 10px;" />
              </div>
              <div>
                <div style="
                    background-color: grey;
                    text-align: center;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    color: #ffff;
                  ">
                  INV- ${gigBillData[0]?.billNumber} - Annexure
                </div>
                <table>
                  <thead>
                    <tr style="background-color: lightgrey;">
                      <th>Profession</th>
                      <th>Name</th>
                      <th>PAN Number</th>
                      <th>User Invoice No</th>
                      <th>Speciality</th>
                      <th>Shift Start Date</th>
                      <th>Shift End Date</th>
                      <th>Hours</th>
                      <th>Rate Per Hour</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${userInvoiceData
                      .map(
                        (obj) => `
                      <tr>
                        <td>${obj?.user?.professionalType}</td>
                        <td>${obj?.user?.name}</td>
                        <td>${obj?.user?.pan}</td>
                        <td>${obj?.userInvoiceNumber}</td>
                        <td>${obj?.user?.specialization}</td>
                        <td>
                          ${moment(obj?.checkinTime?.datetime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          ${moment(obj?.checkoutTime?.datetime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>${obj?.totalHours}</td>
                        <td>${obj?.ratePerHour}</td>
                        <td>${obj?.cost}</td>
                      </tr>
                    `
                      )
                      .join("")}
                  </tbody>
                  <tr>
                    <td colspan="9" style="text-align: end;">
                      <b>TOTAL</b>
                    </td>
                    <td>${(gigBillData[0]?.cost).toFixed(2)}</td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>
        </body>
        
        </html>
        
                  `;

      await new Promise((resolve, reject) => {
        jsPdf.html(pdfContent, {
          callback: (pdf) => {
            pdf.save(`D${lastPart}.pdf`);
            window.open(jsPdf.output("bloburl"));
            resolve();
          },
        });
      });
    } catch (error) {
      console.error("Error generating PDFs:", error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(fetchBillData(path));
  }, [path, dispatch]);

  const Part1 = gigBillData[0]?.billNumber.split("/").slice(-3, -2)[0];
  const Part2 = gigBillData[0]?.billNumber
    .split("/")
    .slice(-2, -1)[0]
    .replace("-", "");
  const Part3 = gigBillData[0]?.billNumber.split("/").pop();
  const transformedPart = isNaN(Part3) ? Part3 : "D" + Part3;
  const result = Part1 + "/" + Part2 + "/" + transformedPart;
  // let debitNumber = gigBillData[0]?.billNumber;
  let debitNumber = result;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>

            <MDButton
              variant="contained"
              color="info"
              onClick={() => {
                // printDocument();
                handleDownloadData();
              }}
            >
              Export as PDF
            </MDButton>
          </Grid>
        </MDBox>
        <div id="invoiceContainer">
          <Paper sx={{ marginTop: "1rem" }}>
            <MDBox className={"invoiceBox"}>
              <div className="invoiceHeader">
                <img src={IfanFlexLogo} alt="logo" className="logoStyle" />

                <div className="headerContent">
                  <h4>Duplicate Copy</h4>
                  <br />
                  <small>Plot No.89, 3Floor, Sector 44</small>
                  <br />
                  <small>Gurgaon - 122003, Haryana (India)</small>
                  <br />
                  <small>Email: info@ifanglobal.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small>
                  <br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small>
                  <br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <br />

              <table>
                <tbody>
                  <tr>
                    <th
                      colSpan={3}
                      style={{
                        backgroundColor: "lightgrey",
                        textAlign: "center",
                      }}
                    >
                      COMMERICAL DEBIT NOTE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Debit Note No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>{debitNumber}</td>
                    <td>
                      {moment(gigBillData[0]?.billingDate).format("DD-MM-YYYY")}{" "}
                      &nbsp; &nbsp; &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan={2}>
                      Tax Registration
                      <br />
                      PAN:AAFCI9183G
                      <br />
                      GSTN:06AAFCI9183G1ZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Buyer:</th>
                    <th colSpan={2}>Instructions:</th>
                  </tr>
                  <tr>
                    <td >
                      {gigBillData[0]?.buyer?.address?.hospitalName}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.address}, &nbsp;
                      {/* <br /> */}
                      {gigBillData[0]?.buyer?.address?.address?.city}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.state}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.country}
                      <br />
                      {gigBillData[0]?.buyer?.address?.address?.pin}
                    </td>

                    <td rowSpan={1} colSpan={2}>
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 402105000512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code: {gigBillData[0]?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:{" "}
                      {gigBillData[0]?.buyer?.reverseChargesApplicable}
                      <br />
                      GSTIN: {gigBillData[0]?.buyer?.gst}
                    </td>
                    <td style={{ paddingTop: "30px" }}>
                      Reference Invoice No: <hr class="hr-line" />
                      Reference Invoice Date: <hr class="hr-line" />
                      Payment Due Date:
                    </td>
                    <td>
                      {gigBillData[0]?.billNumber} <hr class="hr-line" />
                      {moment(gigBillData[0]?.billingDate).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      &nbsp; &nbsp; &nbsp; <hr class="hr-line" />
                    </td>
                  </tr>
                  <tr>
                    <th >S.no.</th>
                    <th className="textAlignmentCenter">
                      Description of Service
                    </th>
                    <th className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Reimbursement of Fees <br /> Payable to the Professional
                      {/* for the month of September-22 */}
                    </td>
                    <td className="textAlignmentRight">
                      {gigBillData[0]?.cost}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td className="textAlignmentRight">
                      {(gigBillData[0]?.cost+0).toFixed(2)}
                    </td>
                  </tr>

                  {/* <tr>
                    <td></td>
                    <td>Sub Total for reimbursements</td>
                    <td className="textAlignmentRight">
                      {gigBillData[0]?.reimbursement}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td className="textAlignmentCenter">2</td>
                    <td>Convenience Fee</td>
                    <td className="textAlignmentRight">
                      {gigBillData[0]?.convensionFee}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Sub Total for convenience Fee** </td>
                    <td className="textAlignmentRight">
                      {gigBillData[0]?.convensionFee}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td colSpan={2}>
                      <b>Add: CGST @ 9%</b>
                    </td>

                    <td>{gigBillData[0]?.gst}</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <b>Add: SGST @ 9%</b>
                    </td>

                    <td>{gigBillData[0]?.gst}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>Add: IGST @ 18%</b>
                    </td>

                    <td>{gigBillData[0]?.gst}</td>
                  </tr> */}

                  {/* {!gigBillData[0]?.cgst || !gigBillData[0]?.sgst ? (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td className="textAlignmentRight">
                          {gigBillData[0]?.gst}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">
                          {gigBillData[0]?.cgst}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">
                          {gigBillData[0]?.sgst}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td colSpan={2}>
                      <b>Total</b>
                    </td>
                    <td className="textAlignmentRight">
                      {gigBillData[0]?.amount}
                    </td>
                  </tr> */}
                </tbody>
              </table>

              <div className="invoiceFooter">
                <b>
                  Note <br /> * TDS is not be deducted on reimbursement amount
                </b>
                <br />
                <p>Terms & Conditions</p>
                <div className="invoiceList">
                  <ol>
                    <li>Subject to Delhi Jurisdiction only</li>
                    <li>
                      Late Payment Fees of 1.5% per month would be charged after
                      due date
                    </li>
                  </ol>
                </div>
                <div>
                  <p className="pStyle">For IFAN Flex Pvt. Ltd.</p>
                  <img
                    src={authoritySignature}
                    alt="logosign"
                    className="logosStyle"
                  />
                  <p className="footerText">
                    No Signature required as it's a system generated Commerical
                    Debit Note
                  </p>
                </div>

                <hr />
                <div className="footerStyle">
                  <p>Office: +91 124.487 5800</p>
                  <p>Fax : +91 124.401 4035</p>
                  <p>CIN: U74999DL2020PTC373866</p>
                </div>
              </div>

              <br />
            </MDBox>
          </Paper>
          {/* <br/><br/><br/><br/><br/><br/><br/><br/> */}
          {/* <br/><br/>
          <br/> */}

          <Paper>
            <MDBox className={"invoiceBox"}>
              <div className="logos">
                <img src={IfanFlexLogo} alt="logo" className="logosStyle" />
                <img src={jobizoLogo} alt="logo2" className="logosStyle" />
              </div>
              <div>
                <MDTypography
                  style={{
                    backgroundColor: "grey",
                    textAlign: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                    color: "#ffff",
                  }}
                >
                  INV- {gigBillData[0]?.billNumber} - Annexure
                </MDTypography>
              </div>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr style={{ backgroundColor: "lightgrey" }}>
                    <th>Profession</th>
                    <th>Name</th>

                    <th>PAN Number</th>
                    <th>User Invoice No</th>
                    <th>Speciality</th>
                    <th>Shift Start Date</th>
                    <th>Shift End Date</th>
                    <th>Hours</th>
                    <th>Rate Per Hour</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                {userInvoiceData.map((obj) => (
                  <tbody>
                    <tr>
                      <td>{obj?.user?.professionalType}</td>
                      <td>{obj?.user?.name}</td>
                      <td>{obj?.user?.pan}</td>
                      <td>{obj?.userInvoiceNumber}</td>
                      <td>{obj?.user?.specialization}</td>

                      <td>
                        {moment(obj?.checkinTime?.datetime).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {" "}
                        {moment(obj?.checkoutTime?.datetime).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{obj?.totalHours}</td>
                      <td>{obj?.ratePerHour}</td>
                      <td>{obj?.cost}</td>
                    </tr>
                  </tbody>
                ))}
                {/* <tr>
                  <td colSpan={9}>Sub Total</td>

                  <td>{gigBillData[0]?.cost}</td>
                </tr> */}
                {/* <tr>
                  <td colSpan={9}>
                    <b>Add: Convenience Fee @ 10% </b>
                  </td>

                  <td>{gigBillData[0]?.convensionFee}</td>
                </tr> */}
                <tr>
                  <td colSpan={9} style={{ textAlign: "end" }}>
                    <b>TOTAL</b>
                  </td>

                  <td>{(gigBillData[0]?.cost + 0).toFixed(2)}</td>
                </tr>
              </table>
              {/* </div> */}
            </MDBox>
          </Paper>
        </div>

        {/* <div id="invoiceContainer">
          <Paper sx={{ marginTop: "1rem"}}>
            <MDBox className={"invoiceBox"}>
              <div className="invoiceHeader">
                <img src={IfanFlexLogo} alt="logo" className="logoStyle" />

                <div className="headerContent">
                  <h4>Duplicate Copy</h4><br/>
                  <small>Plot No.89, 3Floor, Sector 44</small>
                  <br />
                  <small>Gurgaon - 122003, Haryana (India)</small>
                  <br />
                  <small>Email: info@ifanglobal.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small>
                  <br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small>
                  <br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <br />

              <table>
                <tbody>
                  <tr>
                    <th
                      colSpan={3}
                      style={{
                        backgroundColor: "lightgrey",
                        textAlign: "center",
                      }}
                    >
                      TAX INVOICE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Invoice No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>{gigBillData[0]?.billNumber}</td>
                    <td>
                      {moment(gigBillData[0]?.billingDate).format("DD-MM-YYYY")}{" "}
                      &nbsp; &nbsp; &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan={2}>
                      Tax Registration
                      <br />
                      PAN:AAFCI9I9183G
                      <br />
                      GSTN:06AAFCI9183GIZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Buyer:</th>
                    <th colSpan={2}>Instructions:</th>
                  </tr>
                  <tr>
                    <td>
                      {gigBillData[0]?.buyer?.address?.hospitalName}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.address}, &nbsp;
                      {/* <br /> */}
        {/* {gigBillData[0]?.buyer?.address?.address?.city}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.state}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.country}
                      <br />
                      {gigBillData[0]?.buyer?.address?.address?.pin}
                    </td>

                    <td rowSpan={2} colSpan={2}>
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 40210500512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code: {gigBillData[0]?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:{" "}
                      {gigBillData[0]?.buyer?.reverseChargesApplicable}
                      <br />
                      GSTIN: {gigBillData[0]?.buyer?.gst}
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">Description of Service</th>
                    <th  className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Reimbursement of Fees Payable to the
                      Professional
                      {/* for the month of September-22 */}
        {/* </td>
                    <td className="textAlignmentRight">{gigBillData[0]?.cost}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td >TDS to be deposited to professional*</td>
                    <td className="textAlignmentRight">{gigBillData[0]?.TDS}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Sub Total for reimbursements</td>
                    <td className="textAlignmentRight">{gigBillData[0]?.reimbursement}</td>
                  </tr>

                  <tr>
                    <td className="textAlignmentCenter">2</td>
                    <td>Convenience Fee</td>
                    <td className="textAlignmentRight">{gigBillData[0]?.convensionFee}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Sub Total for convenience Fee** </td>
                    <td className="textAlignmentRight">{gigBillData[0]?.convensionFee}</td>
                  </tr>


                  {!gigBillData[0]?.cgst || !gigBillData[0]?.sgst ? (
                    <>
                    <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>Add: IGST @ 18%</b>
                      </td>
                      <td className="textAlignmentRight">{gigBillData[0]?.gst}</td>
                    </tr>
                    </>
                    
                  ) : (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{gigBillData[0]?.cgst}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{gigBillData[0]?.sgst}</td>
                      </tr>
                      <tr>
                      <td colSpan={2}>
                        <b>Add: IGST @ 18%</b>
                      </td>
                      <td className="textAlignmentRight">{}</td>
                    </tr>
                    </>
                  )}

                  <tr>
                    <td colSpan={2}>
                      <b>Total</b>
                    </td>
                    <td className="textAlignmentRight">{gigBillData[0]?.amount}</td>
                  </tr>
                </tbody>
              </table>

              <div className="invoiceFooter">
               <b>Note <br/> * TDS on reimbursement has to be deposited against professionals PAN Number as mention in Annx. <br/> ** TDS on Convenience fee to be deposited to IFANFLEX.</b><br/>
                <p>Terms & Conditions</p>
                <div className="invoiceList">
                  <ol>
                    <li> Subject to Delhi Jurisdiction only</li>
                    <li> Please send the relevant TDS Certificate asap</li>
                    <li>
                      Category of Services: Permanent placement services, other
                      than executive search services
                    </li>
                    <li> Service Accounting Code: {gigBillData[0]?.sac}</li>
                    <li>Late Payment Fees of 1.5% per month would be charged after due date</li>
                  </ol>
                </div>
                <div>
                  <p className="pStyle">For IFAN Flex Pvt. Ltd.</p>
                  <img
                    src={authoritySignature}
                    alt="logosign"
                    className="logosStyle"
                  />
                  <p className="footerText">
                    No Signature required as it's a system generated Invoice
                  </p>
                </div>

                <hr />
                <div className="footerStyle">
                  <p>Office: +91 124.487 5800</p>
                  <p>Fax : +91 124.401 4035</p>
                  <p>CIN: U74999DL2020PTC373866</p>
                </div>
              </div>

              <br />
            </MDBox>
          </Paper> */}
        {/* <br/><br/>
          <br/> */}

        {/* <Paper>
            <MDBox className={"invoiceBox"}>
              <div className="logos">
                <img src={IfanFlexLogo} alt="logo" className="logosStyle" />
                <img src={jobizoLogo} alt="logo2" className="logosStyle" />
              </div>
              <div>
                <MDTypography
                  style={{
                    backgroundColor: "grey",
                    textAlign: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                    color: "#ffff",
                  }}
                >
                  INV- {gigBillData[0]?.billNumber} - Annexure
                </MDTypography>
              </div>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr style={{ backgroundColor: "lightgrey" }}>
                    <th>Profession</th>
                    <th>Name</th>

                    <th>PAN Number</th>
                    <th>User Invoice No</th>
                    <th>Speciality</th>
                    <th>Shift Start Date</th>
                    <th>Shift End Date</th>
                    <th>Hours</th>
                    <th>Rate Per Hour</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                {userInvoiceData.map((obj) => (
                  <tbody>
                    <tr>
                      <td>{obj?.user?.professionalType}</td>
                      <td>{obj?.user?.name}</td>
                      <td>{obj?.user?.pan}</td>
                      <td>{obj?.userInvoiceNumber}</td>
                      <td>{obj?.user?.specialization}</td>

                      <td>
                        {moment(obj?.checkinTime?.datetime).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>
                        {" "}
                        {moment(obj?.checkoutTime?.datetime).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                      <td>{obj?.totalHours}</td>
                      <td>{obj?.ratePerHour}</td>
                      <td>{obj?.cost}</td>
                    </tr>
                  </tbody>
                ))}
                <tr>
                  <td colSpan={9}>Sub Total</td>

                  <td>{gigBillData[0]?.cost}</td>
                </tr>
                <tr>
                  <td colSpan={9}>
                    <b>Add: Convenience Fee @ 10% </b>
                  </td>

                  <td>{gigBillData[0]?.convensionFee}</td>
                </tr>
                <tr>
                  <td colSpan={9} style={{ textAlign: "end" }}>
                    <b>TOTAL</b>
                  </td>

                  <td>{(gigBillData[0]?.cost+gigBillData[0]?.convensionFee).toFixed(2)}</td>
                </tr>
              </table>
              {/* </div> */}
        {/* </MDBox>
          </Paper>
        </div>  */}
      </DashboardLayout>
    </>
  );
};

export default GigBill;
