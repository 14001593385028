import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    TextField,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import MDButton from "../../../components/MDButton/index";
  import Grid from "@mui/material/Grid";
  import { useState } from "react";
  import { yupResolver } from "@hookform/resolvers/yup";
  import Checkbox from "@mui/material/Checkbox";
  import { useForm } from "react-hook-form";
  import MDInput from "../../../components/MDInput";
  import { DepartmentTypeDialogValidation } from "util/validations/commonValidations";
  import SelectDropDown from "./../../../examples/DropDown/index";
  import { addDepartmentType, addGCCDepartment, getGCCDepartment } from "../Redux/actionCreator";
  import { useDispatch, useSelector } from "react-redux";
  import { setProfessionType } from "layouts/events/Redux/actionCreator";
  import SelectDropDownNew from "examples/DropDownNew";
  import { toast } from "react-toastify";
  import { FormHelperText } from "@material-ui/core";
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  
  const GCCDepartmentDialog = ({ handleDialogClose }) => {
   
  
    const { Profession_Type_Data } = useSelector((state) => state.events);
    const professionData = Profession_Type_Data?.professionInfo;
    const professionOptions = [];
    professionData.map((ele) => {
      let obj = {
        key: ele._id,
        value: ele.name,
      };
  
      professionOptions.push(obj);
    });
   const[professionId,setPorfessionId]=useState();
    const handleProfessionChange = (e) => {
      
      let userID;
      //Searching for the name in the Users array to find the ID
      professionOptions.map((obj) => {
        if (obj.value == e.target.value) {
          userID = obj.key;
        }
      });
     if( errors?.profession?.message)
     errors.profession.message="";
      setValue("profession", e.target.value);
      setPorfessionId(userID);
    
    };
  
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setProfessionType());
    }, []);
    const {
      register,
      handleSubmit,
      setValue,
      control,
      getValues,
      formState: { errors },
      formState
    } = useForm({
      resolver: yupResolver(DepartmentTypeDialogValidation),
    });
  
    
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = (event) => setOpenDialog(event.currentTarget);
    const handleCloseDialog = () => setOpenDialog(false);
  
   
    const onSubmit = (data) => {
    
      const payload={
        name:data?.name,
        profession:professionId
      }
    //   dispatch(addGCCDepartment(payload));
      dispatch(addGCCDepartment(payload)).then(() => {
        dispatch(getGCCDepartment());
      });
      handleDialogClose();
     
      
    };
   
  
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputLabel fullWidth className="input-labels">
                Name
              </InputLabel>
            </Grid>
  
            <Grid item xs={8}>
              <MDInput
                placeholder=""
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("name")}
                error={errors?.name?.message}
                helperText={errors?.name?.message}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel fullWidth className="input-labels">
                Profession
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <SelectDropDownNew
                control={control}
                name={"profession"}
                {...register("profession")}
                defaultValue="none"
                options={professionOptions}
                getValues={getValues}
                placeholder="Select"
                customOnChange={handleProfessionChange}
               
              />
              <FormHelperText>{errors?.profession?.message}</FormHelperText>
             
              
            </Grid>
          </Grid>
  
         
         <MDButton
          
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
        >
          create
        </MDButton>
         
        </form>
      </div>
    );
  };
  
  export default GCCDepartmentDialog;
  