import { CircularProgress } from "@material-ui/core";
import { Delete } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getCities } from "shared/services";
import { getSkillsList } from "services/commonServices";
import { formatDate } from "util/Helper";
import {
  deleteSkillList,
  deleteCities,
  getSkillList,
} from "../../common/Redux/actionCreator";
// import AddNewCityDialogContent from "./AddNewCityDialogContent";
import AddNewSkillDialog from "../Skills/AddNewSkillDialog";
import moment from "moment";
// import AddNewSkillDialog from './AddNewSkillDialog';
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Skills = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleDelete = (ID) => {
    dispatch(deleteSkillList(ID));
  };
  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }

  function getCreatedAT(params) {
    var a;
    const rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    // return dateNOW;
  }

  function getUpdatedAT(params) {
    var a;
    const rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.updatedAt);
    // // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    // return dateNOW;
  }

  const columns = [
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name;
        const rowId = cellValues?.row?._id;
        return <Link to={`${rowId}`}>{rowName}</Link>;
      },
      valueGetter: (cellValues) => cellValues?.row?.name,
      flex: 1.5,
    },
    {
      field: "departmentType",
      headerName: `DepartmentType`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.departmentType?.name;
        return rowName;
      },
      valueGetter: (cellValues) => cellValues?.row?.departmentType?.name,
      flex: 1.5,
    },
    {
      field: "professionalType",
      headerName: `Professional Type`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.professionalType?.name;
        return rowName;
      },
      valueGetter: (cellValues) => cellValues?.row?.professionalType?.name,
      flex: 1.5,
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name}?`}
            handleConfirmation={() => {
              handleDelete(row?._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: "isDeleted",
      headerName: `Is Deleted`,
      renderCell: (cellValues) => {
        // const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isDeleted}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

        // return updated;
      },
      type: "date",
      valueGetter: getUpdatedAT,
      // flex: 1.5,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
      flex: 1,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
      flex: 1,
    },
  ];

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const [notDeletedRecords, setNotDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getSkillsList(checked);
      //   console.log("1-->",response);
      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Deleted Records
      const response = await getSkillsList(checked);
      // console.log("2-->",response);
      setNotDeletedRecords(response?.data?.docs);
    }
  };

  const { Skill_List_Data } = useSelector((state) => state.common);
  const skillData = Skill_List_Data?.skillListInfo;

  //   console.log("sliikData", skillData)

  useEffect(() => {
    dispatch(getSkillList());
  }, []);

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Add Skills"
        title1="Additional Skills"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        dialogContent={
          <AddNewSkillDialog handleDialogClose={handleDialogClose} />
        }
      />
      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `Skills-${formatDate()}`,
            },
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              isDeleted: false,
              createdAt: false,
              updatedAt: false,
              createdBy: false,
              updatedBy: false,
              Delete: false,
            },
          },
        }}
        slots={{
          loadingOverlay: <CircularProgress disableShrink />,
        }}
        loading={skillData?.length > 0 ? false : true}
        columns={columns}
        rows={showDeltedRecords ? deletedRecords : [...skillData]}
        // rows={[...skillData].reverse()}
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        //isRowSelectable
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
};
export default Skills;
