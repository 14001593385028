import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { FormHelperText } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SkillsDialogValidation } from "util/validations/commonValidations";

import { addCity, addSkill, getDepartmentType, getSkillList , getProfessionsList} from "../Redux/actionCreator";
import MDButton from "./../../../components/MDButton/index";
// import SelectDropDown from "./../../examples/DropDown/index";
import SelectDropDown from "./../../../examples/DropDown/index";

import CloseIcon from "@mui/icons-material/Close";

const AddNewSkillDialog = ({ handleDialogClose }) => {
  const dispatch = useDispatch();
  const [department, setDepartment] = useState();
  const [profession, setProfession] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(SkillsDialogValidation),
  });

//   console.log("error", errors)
  const onSubmit = async (data) => {

    await trigger();
    setError("departmentType", {
        type: "manual",
        message: "Department Type is required",
      });

      setError("professionalType", {
        type: "manual",
        message: "Professional Type is required",
      });

      setError("name", { 
        type: "manual",
        message: "Name is required",
      });

    const payload = {
        departmentType: data.departmentType,
        professionalType: data.professionalType,
        name: data.name,
        
      };
    //   console.log("payload", payload);

      // Dispatch the addNewPermanentJob action
      dispatch(addSkill(payload)).then(() => {
        // handleDialogClose();
        dispatch(getSkillList());
      });
    
    // dispatch(addSkill(data));

    // handleDialogClose();
    const isRequiredFieldsFilled =
       data.departmentType &&
       data.professionalType &&
       data.name

       if (isRequiredFieldsFilled) {
        handleDialogClose();
       }
  };

  const departmentOptions = [];
  const professionOptions = [];

  const { DepartmentType_Data, ProfessionsData } = useSelector((state) => state.common);
  const department_Info = DepartmentType_Data?.departmentTypeInfo;
  const professions = ProfessionsData?.professionsInfo;

  department_Info.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };  

    departmentOptions.push(obj);
  });

  professions.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  const handleDepartmentChange = (e) => {
    let userID;
    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    if( errors?.departmentType?.message)
    errors.departmentType.message="";
    setValue("departmentType", userID);
    setDepartment(userID);
    // setIsDepartmentSelected(true);
  };

  const handleProfessionChange = (e) => {
    if (errors?.professionalType?.message) errors.professionalType.message = "";
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setValue("professionalType", userID);
    setProfession(userID);
    // setIsProfessionSelected(true)
  };
  

  useEffect(() => {
   dispatch(getDepartmentType());
   dispatch(getProfessionsList());
    
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {/* Department */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Department Type
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <SelectDropDown
              options={departmentOptions}
              placeholder="Department Type"
              control={control}
              name="departmentType"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleDepartmentChange}
            />
            {/* <FormHelperText>{errors?.departmentType?.message}</FormHelperText> */}
            {department ? (
              <></>
            ) : (
              <FormHelperText>{errors?.departmentType?.message}</FormHelperText>
            )}
          </Grid>

                {/* Profession */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
            Professional Type
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <SelectDropDown
              options={professionOptions}
              placeholder="Professional Type"
              control={control}
              name="professionalType"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleProfessionChange}
            />
            {/* <FormHelperText>{errors?.departmentType?.message}</FormHelperText> */}
            {profession ? (
              <></>
            ) : (
              <FormHelperText>{errors?.professionalType?.message}</FormHelperText>
            )}
          </Grid>

          {/* Name */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("name")}
              error={errors?.name?.message}
              helperText={errors?.name?.message}
            />
          </Grid>
          {/* is Launched */}
          {/* <Grid item xs={10} md={10}>
            <Grid item xs={6} sx={{ marginLeft: "1rem" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      defaultValue={false}
                      name="isLaunched"
                      control={control}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="Is Launched"
                />
              </FormGroup>
            </Grid>
          </Grid> */}
        </Grid>
        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default AddNewSkillDialog;
