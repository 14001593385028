import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBillDetails,
  getBillDetailsById,
  getBillFilteredService,
  uploadBillPaidFile,
  updateBillService,
  updateBillAmountService
} from "./../../../services/invoiceService";
// export const getJobApplicationList = createAsyncThunk(
//   "/JobApplication/listJobApplication",
//   async (payload, thunkAPI) => {
//     console.log("51-->", payload)
//     const response = await getJobApplicationService(payload);
//     console.log(response)
//     // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
//     return response;
//   }
// );
export const fetchBillDetailsData = createAsyncThunk("bills/bill", async (payload) => {
  const response = await getBillDetails(payload);
  // console.log("action response", response);
  return response;
});

//filter Invoice service
export const getFilteredBillDetails = createAsyncThunk(
  "bills/bill/filteredBill",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getBillFilteredService(payload);
    // console.log("17>>>>", response)
    return response;
  }
);

export const fetchBillData = createAsyncThunk("bills/bill/id", async (id) => {
  const response = await getBillDetailsById(id);
  return response;
});


//bill file upload
export const uploadPaidBill = createAsyncThunk(
  "uploadPaid/bill",
  async (payload, thunkAPI) => {
    const response = await uploadBillPaidFile(payload);

    return response;
  }
);

//update invoice
export const updateBillById = createAsyncThunk(
  "/bill/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updateBillService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);


//update bill for amount receive
export const updateBillAmountReceived = createAsyncThunk(
  "/bill/amountReceive/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updateBillAmountService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
