import axiosInstance from "../axios";

export const fetchCityList = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(`/city/listCity`, criteria);

    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchCompanyType = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `/companyTypes/listCompanyType`,
      criteria
    );
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};
export const fetchExperience = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `/experience/listNumExperience`,
      criteria
    );
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};
export const fetchProfessionType = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = { criteria: { isDeleted: payload } };
    const response = await axiosInstance.post(
      `/professionalTypes/listProfessionalType`,
      criteria
    );
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};
export const fetchQualificationType = async (payload) => {
  try {
    if (payload === undefined) payload = { criteria: { isDeleted: false } };

    const response = await axiosInstance.post(
      `/qualificationTypes/listQualificationType`,
      payload
    );

    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Document Type
export const fetchDocumentType = async (payload) => {
  try {
    const response = await axiosInstance.post(`documenttype/list`, payload);

    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchSpecialisations = async (payload) => {
  try {
    if (payload === undefined) payload = { criteria: { isDeleted: false } };
    const response = await axiosInstance.post(
      `/specialization/listSpecialisations`,
      payload
    );
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchDepartments = async (payload) => {
  const criteria = { criteria: { listName: true } };
  try {
    const response = await axiosInstance.post(`/departmenttype/list`, criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchRoles = async (payload) => {
  const criteria = { criteria: { listName: true } };

  try {
    const response = await axiosInstance.post(`/role/list`, criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchAri = async (payload) => {
  const criteria = { criteria: { listName: true } };

  try {
    const response = await axiosInstance.post(`/ari/list`, criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchLanguages = async (payload) => {
  const criteria = { criteria: { listName: true } };

  try {
    const response = await axiosInstance.post(`/language/list`, criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const fetchProfessionalTypes = async (payload) => {
  const criteria = { criteria: { listName: true } };

  try {
    const response = await axiosInstance.post(
      `/professionalregistrationtypes/list`,
      criteria
    );
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// const responses = []
// for (let i = 0; i < payload.length; i++) {
//   const image = payload[i];

//   const formdata = new FormData();
//   formdata.append("file", image);

//   const response = await axiosInstance.post(`/common/upload`, formdata);
//   console.log(`Image ${i+1} response:`, response);

//   responses.push(response);
// }
export const uploadPhoto = async (payload) => {
  try {
    console.log(payload, "payload");
    var image = payload;

    var formdata = new FormData();

    //if we want upload multiple images
    // payload.forEach((image, index) => {
    //   formdata.append(`file${index}`, image);
    // });

    formdata.append("file", image);

    const response = await axiosInstance.post(`/common/upload`, formdata);
    //console.log("imageResponse", response);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Create Event Details
export const postEventDetails = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/upcomingEvents/createEvent`,
      payload
    );
    console.log("response", response)
    return { response: response };
  } catch (e) {
    console.log("error>>", e)
    return { error: e.response.data.msg };
  }
};

export const updateEventService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/upcomingEvents/${payload.id}`,
      payload.payload
    );

    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

export const getEventList = async (payload) => {
  if (payload === undefined) payload = false;
  let criteria = { criteria: { isDeleted: payload } };

  try {
    const response = await axiosInstance.post(`/upcomingEvents/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const deleteEvent = async (id) => {
  try {
    const response = await axiosInstance.delete(`/upcomingEvents/${id}`);
    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get Event By ID
export const getEventsByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/upcomingEvents/list`, criteria);
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.msg };
  }
};

export const updateInterviewService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/interviewslot/${payload.id}`,
      payload.payload
    );
    console.log("1-->", response);
    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

export const getInterviewList = async () => {
  try {
    
    const response = await axiosInstance.post(`/interviewslot/list`);
    // console.log("interview-->", response);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getInterviewListById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/interviewslot/list`, criteria);
    console.log("getInterviewListById:", response);
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.msg };
  }
};

export const getReferralList = async (payload) => {
  try {
    let criteria = { 
      limit: payload.pageSize,
      offset: payload.currentPage,
      };
    const response = await axiosInstance.post(`/refferal`, criteria);
    console.log("referralData-->", response);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const getReferralListById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/refferal`, criteria);
    //console.log("getReferralListById:", response)
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.msg };
  }
};
