const state = {
  loading: false,
  error: "",
  Availabilitys_Data: {
    Availabilitys_DataLoading: true,
    Availabilitys_DataError: "",
    Availabilitys_DataInfo: [],
    // total: 0,
  },
  Availability_profile_image_Data: {
    Availability_profile_image_loading: true,
    Availability_profile_image_info: [],
    Availability_profile_image_error: "",
  },
  Permanent_Job_Data: {
    Permanent_DataLoading: true,
    Permanent_DataError: "",
    Permanent_DataInfo: [],
  },  

  Permanent_Application_Data: {
    Permanent_Application_DataLoading: true,
    Permanent_Application_DataError: "",
    Permanent_Application_DataInfo: [],
  },  

  GCC_Job_Data: {
    GCC_DataLoading: true,
    GCC_DataError: "",
    GCC_DataInfo: [],
},
  // currentPage: 0,
  // pageSize: 10,
};

export default state;
