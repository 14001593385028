import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { useState } from "react";

const DeleteJobDetailDialog = ({ handleConfirmation, message, render }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleDelete = () => {
    handleConfirmation();
    setIsPopupOpen(false);
  };
  const handlePopupOpen = () => setIsPopupOpen(true);
  return (
    <>
      <Dialog open={isPopupOpen}>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button variant="dark" onClick={() => setIsPopupOpen(false)}>
            cancel
          </Button>
          <Button variant="dark" onClick={handleDelete}>
            delete
          </Button>
        </DialogActions>
      </Dialog>
      {render(handlePopupOpen, isPopupOpen)}
    </>
  );
};

export default DeleteJobDetailDialog;
