import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FilterBar from "examples/FilterBar/FilterBar";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MDBox from "../../../components/MDBox/index";
import { Delete } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useEffect, useState } from "react";
import DeleteDialog from "../../../components/DeleteDialogBox/DeleteDialog";
import PRTDialog from "./PRTDialog";
import {
  deleteProfessionRegistrationType,
  getProfessionRegistrationTypeList,
} from "../Redux/actionCreator";
import { getProfessionRegistrationTypes } from "shared/services";
import { formatDate } from "util/Helper";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// const handleCellClick = (event) => {
//   event.originalEvent.stopPropagation();
// };

// const handleRowClick = (event) => {
//   event.originalEvent.stopPropagation();
// };

function getUpdatedByName(params) {
  return `${params?.row?.updatedBy?.name?.full || "NA"}`;
}
function getCreatedByName(params) {
  return `${params?.row?.createdBy?.name?.full || "NA"}`;
}

function getCreatedAT(params) {
  var a
  const rowName = params?.row?.createdAt;
  if (rowName) {
    a = moment(rowName).format("YYYY/MM/DD");
    return `${a}`;
  } else {
    a = "NA";
    return `${a}`;
  }
  // var rowName = formatDate(params?.row?.createdAt);
  // // var dateNOW = new Date(rowName);
  // var dateNOW = moment(rowName).format("YYYY/MM/DD")
  // return dateNOW;
}

function getUpdatedAT(params) {
  var a
  const rowName = params?.row?.updatedAt;
  if (rowName) {
    a = moment(rowName).format("YYYY/MM/DD");
    return `${a}`;
  } else {
    a = "NA";
    return `${a}`;
  }
  // var rowName = formatDate(params?.row?.updatedAt);
  // // var dateNOW = new Date(rowName);
  // var dateNOW = moment(rowName).format("YYYY/MM/DD")
  // return dateNOW;
}
const PRT = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleDelete = (ID) => {
    dispatch(deleteProfessionRegistrationType(ID));
  };

  const columns = [
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name;
        const rowId = cellValues?.row?._id;
        return <Link to={`${rowId}`}>{rowName}</Link>;
      },

      flex: 1.5,
    },
    {
      field: "description",
      headerName: `Description`,
      flex: 1.5,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a
        const rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

        // return updated;
      },
      type: "date",
      valueGetter: getUpdatedAT,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
      flex: 1,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
      flex: 1,
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
      flex: 1,
    },
  ];
  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getProfessionRegistrationTypes(checked);

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getProfessionRegistrationTypes(checked);
    }
  };

  const { ProfessionRegistrationData } = useSelector((state) => state.common);
  const professionRegistrationRecords =
    ProfessionRegistrationData?.professionRegistrationInfo;

  useEffect(() => {
    dispatch(getProfessionRegistrationTypeList());
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <>
      <DashboardLayout className="dashboardLayout">
        <DashboardNavbar />
        <FilterBar
          title="Create Professional Registration Type"
          title1="Professional Registeration"
          isOpen={isOpen}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          handleDeleteApi={handleDeleteApi}
          dialogContent={<PRTDialog handleDialogClose={handleDialogClose} />}
        />

        <MDBox sx={{ marginTop: "2rem" }}>
          <DataGrid
            getRowId={(row) => row._id}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: {
                  fileName: `ProfessionRegisterationTypes-${formatDate()}`,
                },
              },
            }}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  description: false,
                  createdAt: false,
                  updatedAt: false,
                  createdBy: false,
                  Delete: false,
                  updatedBy: false,
                },
              },
            }}
            loading={professionRegistrationRecords.length > 0 ? false : true}
            columns={columns}
            rows={
              showDeltedRecords
                ? deletedRecords
                : [...professionRegistrationRecords].reverse()
            }
            pagination
            pageSize={100}
            rowsPerPageOptions={[100]}
            // onCellClick={handleCellClick}
            // onRowClick={handleRowClick}
            //isRowSelectable
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
          />
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default PRT;
