//*************Users *********************** */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadPhoto } from "services/eventServices";

import { getUserByIdService } from "services/userServices";
import { updateUserService } from "services/userServices";
import { addNewUser } from "services/userServices";
import { deleteUserService } from "services/userServices";
import { getAvalibilityList } from "services/permanentServices";
import {addNewJob} from "services/permanentServices";
import {getPermanentJob} from "services/permanentServices";
import {updatePermanentJobService} from "services/permanentServices";
import {getPermanentJobsByIdService} from "services/permanentServices";
import {getPermanentApplication} from "services/permanentServices";
import {updatePermanentApplicationService} from "services/permanentServices";

 
//create permannet job  
export const addNewPermanentJob = createAsyncThunk(
  "/createPermanentJob",  
  async (payload, thunkAPI) => {
    const { response, error } = await addNewJob(payload);
    // thunkAPI.dispatch(getAdminUser());
    if (error) {
      
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//get permanent job
export const getPermanentJobList = createAsyncThunk(
  "/permanentjob/list",
  async (payload, thunkAPI) => {
    const response = await getPermanentJob();
    // console.log("actionCreator>>", response);
    return response;
  }
);

//update permannet job
export const updatePermanentJobs = createAsyncThunk(
  "/PermannentJobs/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updatePermanentJobService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

//get permanent job
export const getPermanentApplicationList = createAsyncThunk(
  "/permanent/application/list",
  async (payload, thunkAPI) => {
    const response = await getPermanentApplication();
    // console.log("actionCreator>>", response);
    return response;
  }
);

//update permannet application
export const updatePermanentApplication = createAsyncThunk(
  "/Permannentapplication/update",
  async (payload, thunkAPI) => {
 
    const { response, error } = await updatePermanentApplicationService(payload);
    // console.log("actionCreator>>", response)
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);


// List USer
export const getreduxAvalibilityList = createAsyncThunk(
  "/availbility/listData",
  async (payload, thunkAPI) => {
    const response = await getAvalibilityList(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    // console.log(response, "response");
    return response;
  }
);
// Delete User
// export const deleteUser = createAsyncThunk(
//   `/user/delete`,
//   async (payload, thunkAPI) => {
//     const response = await deleteUserService(payload);
//     if (response?.status === 200) {
//       thunkAPI.dispatch(getreduxAvalibilityList());
//     }
//     return response;
//   }
// );
//Add user
// export const addUser = createAsyncThunk("/user", async (payload, thunkAPI) => {
//   const { response, error } = await addNewUser(payload);
//   thunkAPI.dispatch(getreduxAvalibilityList());
//   if (error) {
//     return thunkAPI.rejectWithValue(error);
//   }
//   return response;
// });
//Get User By ID
// export const getUserByID = createAsyncThunk(
//   "/user/listUsers/id",
//   async (payload, thunkAPI) => {
//     const response = await getUserByIdService(payload);
//     // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
//     return response;
//   }
// );
//Update User
// export const updateUser = createAsyncThunk(
//   "/user/update",
//   async (payload, thunkAPI) => {

//     const { response, error } = await updateUserService(payload);
//     if (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//     return response;
//   }
// );

// export const setProfilePhoto = createAsyncThunk(
//   "user/imageUpload",
//   async (payload, thunkAPI) => {

//     const response = await uploadPhoto([payload]);

//     return response;
//   }
// );
