import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FilterBar from "examples/FilterBar/FilterBar";
import { Link, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MDBox from "../../../components/MDBox/index";

import DeleteJobDetailDialog from "../../common/components/DeleteJobDetailDialog";
import { Delete } from "@mui/icons-material";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { DeleteJobs } from "../../common/Redux/actionCreator";
import DeleteDialog from "./../../../components/DeleteDialogBox/DeleteDialog";
import AddRolesDialogContent from "./AddRolesDialogContent";
import { useEffect, useState } from "react";
import { deleteRole, getRolesList } from "../Redux/actionCreator";
import { getRoles } from "services/adminServices";
import { formatDate } from "util/Helper";

// const handleCellClick = (event) => {

//   event.originalEvent.stopPropagation();
// };

// const handleRowClick = (event) => {
//   event.originalEvent.stopPropagation();
// };

function getUpdatedByName(params) {
  return `${params?.row?.updatedBy?.name?.full || "NA"}`;
}
function getCreatedByName(params) {
  return `${params?.row?.createdBy?.name?.full || "NA"}`;
}

function getCreatedAT(params) {
  var rowName = formatDate(params?.row?.createdAt);
  var dateNOW = new Date(rowName);
  return dateNOW;
}

function getUpdatedAT(params) {
  var rowName = formatDate(params?.row?.createdAt);
  var dateNOW = new Date(rowName);
  return dateNOW;
}

const Roles = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleDelete = (ID) => {
    dispatch(deleteRole(ID));
  };

  const columns = [
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name;
        const rowId = cellValues?.row?._id;
        return <Link to={`${rowId}`}>{rowName}</Link>;
      },
      valueGetter: (cellValues) => cellValues?.row?.name,

      flex: 1.5,
    },
    {
      field: "permissions",
      headerName: `Permissions`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.permissions;
        const rowLength = rowValues?.length;
        return `${rowLength} Values`;
      },
      valueGetter: (cellValues) => cellValues?.row?.permissions?.length,
      flex: 1,
    },
    {
      field: "isDeleted",
      headerName: `Is Deleted`,
      renderCell: (cellValues) => {
        // const rowCode = cellValues?.row?.code;
        return (
          <Checkbox
            {...label}
            defaultChecked={cellValues?.row?.isDeleted}
            sx={{ alignItems: "flex-start" }}
          />
        );
      },

      flex: 1.5,
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: "description",
      headerName: `Description`,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      type: "date",
      flex: 1.5,

      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      type: "date",
      valueGetter: getUpdatedAT,
      flex: 1.5,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
      flex: 1,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      flex: 1.2,
      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
      flex: 1,
    },
  ];

  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getRoles(checked);
      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getRoles(checked);
    }
  };

  const { Admin_Roles_Data } = useSelector((state) => state.admin);
  const roles = Admin_Roles_Data?.adminRoleInfo;

  useEffect(() => {
    dispatch(getRolesList());
  }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}


  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <FilterBar
          title="Create Role"
          title1="Roles"
          isOpen={isOpen}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          handleDeleteApi={handleDeleteApi}
          dialogContent={
            <AddRolesDialogContent handleDialogClose={handleDialogClose} />
          }
        />

        <MDBox sx={{ marginTop: "2rem" }}>
          <DataGrid
            getRowId={(row) => row._id}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  createdAt: false,
                  updatedAt: false,
                  createdBy: false,
                  updatedBy: false,
                  permissions: false,
                  isDeleted: false,
                  description: false,
                },
              },
            }}
            components={{ Toolbar: GridToolbar }}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            loading={roles.length > 0 ? false : true}
            columns={columns}
            rows={showDeltedRecords ? deletedRecords : [...roles].reverse()}
            pagination
            pageSize={100}
            rowsPerPageOptions={[100]}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: {
                  fileName: `Roles-${formatDate()}`,
                },
              },
            }}
            //isRowSelectable
            // onCellClick={handleCellClick}
            // onRowClick={handleRowClick}
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
          />
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default Roles;
