import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "../../../components/MDBox/index";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Link, Paper } from "@mui/material";
import SelectDropDown from "./../../../examples/DropDown/index";

import {
  DialogContent,
  Typography,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { ArrowBackIos, Diversity1 } from "@mui/icons-material";
import SelectDropDownNew from "examples/DropDownNew";
import DynamicCoords from "examples/DynamicCoords";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "services/hospitalServices";
import { useNavigate } from "react-router-dom";
import { getCompanyByIdService } from "services/hospitalServices";
import DynamicDocuments from "examples/DynamicDocuments";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import { setCompanyType } from "layouts/events/Redux/actionCreator";
import { getCompanyList } from "layouts/common/Redux/actionCreator";
import { updateCorporate } from "../Redux/actionCreator";
import Moment from "moment";
import { getDocumentType } from "layouts/common/Redux/actionCreator";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const HospitalCorporateForm = () => {
  const { DocumentType_Data } = useSelector((state) => state.common);
  const documents = DocumentType_Data?.documentTypeInfo;
  const docTypeOptions = [];
  documents.map((elem)=>{
    let obj={
      key:elem?._id,
      value:elem?.name
    }
    docTypeOptions.push(obj);
  })

  const [inputList, setInputList] = useState([]);

  //Finding Corporate ID
  const ID = window.location.href.split("corporate")[1].split("/")[1];

  //Getting Corparate By ID
  const getData = async () => {
    let response = await getCompanyByIdService(ID);
   
    return response[0];
  };

  const { Company_List_Data, UsersData } = useSelector((state) => state.common);

  const companyTypeData = Company_List_Data?.companyListInfo;
  const userInfo = UsersData?.usersInfo;

  const companyTypeOptions = [];
  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  companyTypeData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyTypeOptions.push(obj);
  });

  const logoOptions = [
    {
      key: "AWS_S3",
      value: "AWS_S3",
    },
    {
      key: "LOCAL",
      value: "LOCAL",
    },
    {
      key: "SELF_HOSTED",
      value: "SELF_HOSTED",
    },
    {
      key: "CLOUDINARY",
      value: "CLOUDINARY",
    },
  ];

  const [companyTypePayloadID, setCompanyTypeID] = useState("");

  //handler Functions
  const handleCompanyTypeChange = (e) => {
    setValue("companyType.name", e.target.value);
    let companyTypeID;
    //Searching for the name in the Users array to find the ID
    companyTypeOptions.map((obj) => {
      if (obj.value == e.target.value) {
        companyTypeID = obj.key;
      }
    });

    setCompanyTypeID(companyTypeID);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];

 

  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });

  const {
    fields: docField,
    append: docAppend,
    remove: docRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "doc", // unique name for your Field Array
  });

  const [created1, setCreated1] = useState(false);
  const [updated1, setUpdated1] = useState(false);

  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");
    
    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }
    if (created != undefined && created != "") {
      if (!created1) {
     
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
      }
      setCreated1(true);
    }

    if (updated !== undefined && updated != "") {
      if (!updated1) {
        setValue("updatedAt", Moment(updated).format("DD/MM/YYYY"));
      }
      setUpdated1(true);
    }
  }, [getValues("createdAt"), getValues("updatedAt")]);

  const onSubmit = (data) => {
    let documentspayload=[];
    data.documents.map((obj)=>{
      let object={
        docType:typeof obj.docType=== 'object' ? "" :obj?.docType,
        files:obj?.files,
        uploadedAt:obj?.uploadedAt
      }
      documentspayload.push(object);
    })

    let coordsData=[];
     data?.billingAddress?.address?.coords.map((obj)=>{
        if(typeof obj=="object")
        coordsData.push("");
        else
        coordsData.push(obj);
     })
    let payload = {
      logo: {
        location: data.logo.location,
        fileName: data.logo.fileName,
        uri: data.logo.uri,
        bucket: data.logo.bucket,
        region: data.logo.region,
        mimeType: data.logo.mimeType,
        secureUrl: data.logo.secureUrl,
        url: data.logo.url,
        isSecured: data.logo.isSecured,
        cdnUrl: data.logo.cdnUrl,
      },
      billingAddress: {
        address: {
          address: data.billingAddress.address.address,
          city: data.billingAddress.address.city,
          state: data.billingAddress.address.state,
          pin: data.billingAddress.address.pin,
          country: data.billingAddress.address.country,
          coords: coordsData,
        }, 
        gstNumber:data.billingAddress.gstNumber,
        isGstRegistered: data.billingAddress.isGstRegistered,
      },
      companyType: companyTypePayloadID
        ? companyTypePayloadID
        : data?.companyType?._id,
      name: data?.name,
      isEnabled: data?.isEnabled,
      documents: documentspayload,
      isDeleted: data?.isDeleted,
      cin:data?.cin,
      code:data?.code
      // createdAt: data?.createdAt,
      // updatedAt: data?.updatedAt,
    };
  

    dispatch(updateCorporate({ payload: payload, id: ID })).then((res) => {
    
      if (!res?.error) {
        navigate("/hospital/corporate");
      }
    });
  };
  const handleReset = () => {
    reset();
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(setUsersDropdown());
    dispatch(getCompanyList());
    dispatch(getDocumentType());
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={4}>
          <Grid item xs={12}>
           
              <div style={{ display: "flex",width:"70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
                </Link>
              </div>
            
          </Grid>
          {/* <Divider /> */}
          <DialogContent style={{ marginTop: "20px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                {/* Name Title */}

                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("name")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body">ID:{ID}</Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Grid item xs={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Controller
                            defaultValue={false}
                            name="isDeleted"
                            control={control}
                            render={({ field: props }) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                {...register("isDeleted")}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label="isDeleted"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                {/* code */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Code
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("code")}
                  />
                </Grid>

                {/* Corporate Type */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Corporate Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDownNew
                    options={companyTypeOptions}
                    control={control}
                    getValues={getValues}
                    name="companyType.name"
                    placeholder="Select"
                    customOnChange={handleCompanyTypeChange}
                  />
                </Grid>

                {/*Cin */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Cin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("cin")}
                  />
                </Grid>

                {/* Logo Location */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Location
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDownNew
                    options={logoOptions}
                    control={control}
                    getValues={getValues}
                    name="logo.location"
                    placeholder="Select"
                    customOnChange={(e) => {
                      setValue("logo.location", e.target.value);
                    }}
                  />
                </Grid>

                {/* Logo File Name */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo File Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.fileName")}
                  />
                </Grid>

                {/* Logo URI */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Uri
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.uri")}
                  />
                </Grid>

                {/* Logo Bucket */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Bucket
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.bucket")}
                  />
                </Grid>

                {/* Logo Region */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Region
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.region")}
                  />
                </Grid>

                {/* Logo Mime Type */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Mime Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.mimeType")}
                  />
                </Grid>

                {/* Logo Secure Url */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Secure Url
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.secureUrl")}
                  />
                </Grid>

                {/* Logo URL */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo Url
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.url")}
                  />
                </Grid>

                {/* logo is secured */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="logo.isSecured"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("logo.isSecured")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="is logo Secured ?"
                    />
                  </FormGroup>
                </Grid>

                {/* Logo cdn URL */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Logo CDN Url
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("logo.cdnUrl")}
                  />
                </Grid>

                {/* 
              Documents */}
                <Grid item xs={12}>
                  <DynamicDocuments
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                    selectOptions={docTypeOptions}
                  />
                </Grid>

                {/* checkbox */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="isEnabled"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("isEnabled")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="isEnabled"
                    />
                  </FormGroup>
                </Grid>

                {/* Billing Address Address Address */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Address
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.address")}
                  />
                </Grid>

                {/* Billing Address Address City */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address City
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.city")}
                  />
                </Grid>

                {/* Billing Address Address state */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address State
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.state")}
                  />
                </Grid>

                {/* Billing Address Address pin */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Pin
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.pin")}
                  />
                </Grid>

                {/* Billing Address Address Country */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Billing Address Address Country
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.address.country")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DynamicCoords
                    control={control}
                    name="billingAddress.address.coords"
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Grid>

                {/* Billing Address gst number */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Billing Address GST Number
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("billingAddress.gstNumber")}
                  />
                </Grid>

              
                {/* Billing Address is GST registered */}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Controller
                          defaultValue={false}
                          name="billingAddress.isGstRegistered"
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              {...register("billingAddress.isGstRegistered")}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label="Billing Address is Gst Registered"
                    />
                  </FormGroup>
                </Grid>

                {/* CreatedAt */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Created At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("createdAt")}
                  />
                </Grid>

                {/* Updated At */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Updated At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updatedAt")}
                  />
                </Grid>

                {/* CreatedBy */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Created By
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("createdBy.name.full")}
                  />
                </Grid>

                {/* updatedBy */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Updated By
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                    disabled
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updatedBy.name.full")}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: "2rem" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  Save
                </Button>

                <Button
                  variant="outlined"
                  sx={{ color: "#111", marginLeft: "1rem" }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
            </form>
          </DialogContent>
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default HospitalCorporateForm;