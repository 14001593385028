import { Delete, Edit } from "@mui/icons-material";
import { IconButton, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { getEventList } from "services/eventServices";
import { getInterviewList } from "services/eventServices";
import { formatDate } from "util/Helper";
import { interviewList, referralList } from "./Redux/actionCreator";
import moment from "moment";
import {pageChanged,pageLimit} from "./Redux/reducer"
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ReferralListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //VIEW
  const handleView = (id) => {
    navigate(`/eventDetails/update/${id}`);
  };

  //VALUE GETTERS

  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getRefferAmount(params) {
    return `${params?.row?.totalReferAmountInstallment || "NA"}`;
    //return `${params?.row?.job?._id|| "NA"}`;
  }

  function getReferredBy(params) {
    return `${params?.row?.referredBy?.name || "NA"}`;
  }
  // function getReferredBy(params) {
  //   return `${params?.row?.referredBy?.name}` ? `${params?.row?.referredBy?.name}` : "NA"};
  // }

  function getUser(params) {
    return `${params?.row?.user?.name || "NA"}`;
  }

  function getPaid(params) {
    return `${params?.row?.isPaid}` ? `${params?.row?.isPaid}` : "NA";
  }

  function getInvoice(params) {
    return `${params?.row?.invoices?.invoiceNumber || "NA"}`;
  }

  function getDate(params) {
    return `${params?.row?.date || "NA"}`;
  }

  function getInterviewLength(params) {
    return `${params?.row?.interviewLength || "NA"}`;
  }

  function getStartTime(params) {
    return `${params?.row?.startTime || "NA"}`;
  }

  // function getInvoice(params) {
  //   const rowarray = params?.row?.invoices;
  //   const values = [];
  //   if (rowarray) {
  //     rowarray.map((obj) => {
  //       values.push(
  //         `${obj?.invoiceNumber}`
  //       );
  //     });
  //   }

  //   return values;
  // }

    function getCreatedAT(params) {
      // console.log("getCreatedAT", params)
      var rowName = formatDate(params?.row?.createdAt);
      var dateNOW = new Date(rowName);
      return dateNOW;
    }

  //   function getUpdatedAT(params) {
  //     var rowName = formatDate(params?.row?.createdAt);
  //     var dateNOW = new Date(rowName);
  //     return dateNOW;
  //   }

  //InterviewsSlot Listing Columns
  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowId = cellValues?.row?._id;
        return <Link to={`/referralId/${rowId}`}>{rowId}</Link>;
        //return rowId;
      },

      valueGetter: getID,
      flex: 1.2,
    },
    {
      field: "totalReferAmountInstallment",
      headerName: `Referral Amount`,
      renderCell: (cellValues) => {
        const rowCode = cellValues?.row?.totalReferAmountInstallment;
        return rowCode;
      },
      valueGetter: getRefferAmount,
      flex: 1,
    },

    {
      field: "referredBy",
      headerName: `Referred By`,
      renderCell: (cellValues) => {
        //return `${params?.row?.referredBy?.name}` ? `${params?.row?.referredBy?.name}` : "NA"}
        //const rowName = cellValues?.row?.referredBy?.name;
        const rowName = cellValues?.row?.referredBy?.name || "NA";
        const rowId = cellValues?.row?.referredBy?._id;
        return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
        // return rowCode;
        //console.log("Application-->", cellValues.row);
      },
      valueGetter: getReferredBy,
      flex: 1,
    },

    {
      field: "user",
      headerName: "User Name",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.user?.name;
        const rowId = cellValues?.row?.user?._id;
        return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
        //console.log("Candidate-->", cellValues.row._id);
      },

      valueGetter: getUser,
      flex: 1,
    },
    {
      field: "invoices",

      headerName: "Invoice Number",

      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.invoices?.invoiceNumber || "NA";
        const rowId = cellValues?.row?.invoices?._id;
        return <Link to={`/userInvoice/view/${rowId}`}>{rowName}</Link>;
        //return rowarray;
      },
      flex: 1,
      valueGetter: getInvoice,
    },
    {
      field: "isPaid",
      headerName: "isPaid",
      renderCell: (cellValues) => {
        //const rowCode = cellValues?.row?.isPaid;
        //return <Link to={`job/${rowCode}`}>{rowCode}</Link>;
        return `${cellValues?.row?.isPaid}`
          ? `${cellValues?.row?.isPaid}`
          : "NA";
      },

      valueGetter: getPaid,
      flex: 1,
    },
    // {
    //   field: "employer",
    //   headerName: "Employer Name",
    //   renderCell: (cellValues) => {
    //     const rowName = cellValues?.row?.employer?.name?.full;
    //     const rowId = cellValues?.row?.employer?._id;
    //     return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
    //     //console.log("Employer-->", cellValues.row);
    //   },

    //   valueGetter: getEmployer,
    //   flex: 1,
    // },
    // {
    //   field: "suggestedSlot",

    //   headerName: "SuggestedSlot",

    //   renderCell: (cellValues) => {
    //     const rowarray = cellValues?.row?.suggestedSlot;

    //     const values = [];
    //     if (rowarray) {
    //       rowarray.map((obj) => {
    //         values.push(
    //           `${obj?.start}-${obj?.date}-${obj?.interviewLength}-${obj?.isSelected}`
    //         );
    //       });
    //     }

    //     return values;
    //   },
    //   flex: 2,
    //   valueGetter: getSlot,
    // },
    // {
    //   field: "date",
    //   headerName: "Date",
    //   renderCell: (cellValues) => {
    //     const rowCode = cellValues?.row?.date;
    //     return rowCode;
    //   },
    //   type: "date",
    //   valueGetter: getDate,
    //   flex: 1,
    // },
    // {
    //   field: "interviewLength",
    //   headerName: `InterviewLength`,
    //   renderCell: (cellValues) => {
    //     const rowCode = cellValues?.row?.interviewLength;
    //     return rowCode;
    //   },
    //   valueGetter: getInterviewLength,
    //   flex: 1,
    // },
    // {
    //   field: "startTime",
    //   headerName: `StartTime`,
    //   renderCell: (cellValues) => {
    //     const rowCode = cellValues?.row?.startTime;
    //     return rowCode;
    //   },
    //   valueGetter: getStartTime,
    //   flex: 1,
    // },

    {
      field: "createdAt",
      type: "date",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.createdAt;
        if(roleName){
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        }else{
          a = "NA";
          return `${a}`;
        }
      },
      flex: 1.5,

    valueGetter: getCreatedAT,
    },
    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    //   flex: 1.5,
    // },
  ];
  const [loading, setLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const { Referral_Details_Data } = useSelector((state) => state.events);
  const eventListData = Referral_Details_Data?.referralDetailsInfo;
  console.log("eventListData-->", eventListData);
  var referralLoading = Referral_Details_Data?.referralDetailsLoading;

  const totalCount = Referral_Details_Data?.total;
  const currentPage = useSelector((state) => state.events.currentPage);
  const pageSize = useSelector((state) => state.events.pageSize);


  
  
  useEffect(() => {
    setLoading(referralLoading);
  }, [referralLoading]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(referralList({ currentPage: currentPage, pageSize: pageSize }));
      setLoading(false);
    };

    fetchData();
  }, [dispatch, currentPage, pageSize]);

  // useEffect(() => {
  //   dispatch(referralList(false));
  // }, []);

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        //title="Create Event"
        title1="Referral Details"
        isOpen={isOpen}
        showDialog={false}
        showisDeleted={false}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        //handleDeleteApi={handleDeleteApi}
        // customDialogOnClick={() => {
        //   navigate(`/eventDetails/create`);
        // }}
      />

      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `Referral-${formatDate()}`,
            },
          },
        }}
        density="compact"
        initialState={{
          columns: {
            // columnVisibilityModel: {
            //   isDeleted: false,
            //   professionalType: false,
            //   qualification: false,
            //   specialization: false,
            //   numExperience: false,
            //   workCity: false,
            //   createdAt: false,
            //   updatedAt: false,
            //   createdBy: false,
            //   updatedBy: false,
            //},
          },
        }}
        //isRowSelectable
        // loading={referralLoading ? true : false}
        loading = {loading && <CircularProgress />}
        columns={columns}
        rows={[...eventListData]}
        pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
        // pagination
        // pageSize={100}
        // rowsPerPageOptions={[100]}
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
};
export default ReferralListing;
