import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import state from "../../../layouts/beforeAuth/login/Redux/state";
import {
  Box,
  Button,
  Input,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { clearError, login } from "./Redux/actionCreator";
import logo from "../../../assets/images/logo.png";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidation } from "util/validations/loginValidation";

const Login = () => {
  const { register, handleSubmit, formState: { errors }, formState } = useForm({
    resolver: yupResolver(loginValidation)
  });


  //for validating email
  const isValidEmail = (email) => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
    );
  };

  //for validating password
  const isValidPassword = (password) => {
    return password.length > 3;
  };

  const validation = {
    email: isValidEmail,
    password: isValidPassword,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, t_id, success } = useSelector((state) => state.auth);



  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    isRememberMe: true,
  });

  const [validationState, setValidationState] = useState({
    email: null,
    password: null,
  });

  const handleCredsChange = (e) => {

    const { name, value } = e.target;
    setValidationState((prev) => ({
      ...prev,
      [name]: validation[name](value),
    }));
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));

  };

  const onSubmit = (data) => {
    dispatch(login(data));
  }

  const toTop = () => {

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {

    if (t_id) {

      navigate("/home");
      toTop();
    }
  }, [t_id]);

  if (error) {
    setTimeout(() => {
      dispatch(clearError());
    }, 3000);
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            border: "2px solid #fff",
            padding: "1rem",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            background: "#fff",
            top: "30%",
            left: "20%",
          }}
        >
          <div className="containerLeft">
            <img src={logo} style={{ width: "20rem" }} />
          </div>
          <hr
            style={{
              width: "12rem",

              transform: "rotate(90deg)",
            }}
          />
          <div
            className="containerRight"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Email
              </Typography>
              <TextField
                placeholder="Enter Email"
                name="email"
                type="email"
                variant="outlined"
                style={{ margin: "0.5rem", marginTop: "0rem" }}
                onInput={() => localStorage.setItem("key", "1")}
                {...register("email")}
                errors={errors?.email?.message}
                helperText={errors?.email?.message}
              />
            </div>

            <div style={{ marginTop: "1rem", alignItems: "left" }}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Password
              </Typography>
              <TextField
                placeholder="Enter password"
                type="password"
                name="password"
                variant="outlined"
                onInput={() => localStorage.setItem("key", "1")}
                {...register("password")}
                errors={errors?.password?.message}
                helperText={errors?.password?.message}
              />
            </div>
            {formState.isValid ? <Button

              type="submit"
              variant="contained"
              style={{ marginTop: "2rem" }}
            >
              Sign In
            </Button> : <Button
              disabled
              type="submit"
              variant="contained"
              style={{ marginTop: "2rem" }}
            >
              Sign In
            </Button>}

          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
