import axiosInstance from "../axios";

//***************Hospital  Rates Services*******************//

// List Rates
export const getRates = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: { isDeleted: false },
      limit: payload[0],
      offset: payload[1],
    };
    const response = await axiosInstance.post(`/rate/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Delete a Rate
export const deleteRateService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/rate/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Rate
export const addNewRate = async (payload) => {
  try {
    const response = await axiosInstance.post(`/rate`, payload);

    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get Role By Id

export const getRateByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/rate/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Rate
export const updateRateService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/rate/${payload.id}`,
      payload.payload
    );

    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

//***************Hospital  Company  Services*******************//
export const fetchDocumentTypes = async (payload) => {
  const criteria = { criteria: { listName: true } };

  try {
    const response = await axiosInstance.post(`/documentType/list`, criteria);
    return { response: response };
  } catch (e) {
    return { error: e.response.data.message };
  }
};
//
// List Comapny
export const getCompany = async (payload) => {
  try {
    if (payload === undefined) payload = { criteria: { isDeleted: false } };
    const response = await axiosInstance.post(`/company/list`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Delete a Comapny
export const deleteCompanyService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/company/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Company
export const addNewCompanyHospital = async (payload) => {
  try {
    const response = await axiosInstance.post(`/company`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get Company By Id

export const getCompanyByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/company/list`, criteria);
    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Company
export const updateCompanyService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/company/${payload.id}`,
      payload.payload
    );

    return { response: response };
  } catch (e) {
    // return { error: "error hai bhyi" };
    return { error: e.response.data };
  }
};

//***************Hospital  Department Services*******************//

// List Departments
export const getDepartments = async (payload) => {
  
  try {
  
    if (payload.length==0) {
      
      
      payload = {
        criteria: {
          isDeleted: false,
      
          
        },
      };
      const response = await axiosInstance.post(`/departments/list`, payload);
      return response;
    } 
    if(payload.length==1) {
      
      payload = {
        criteria: {
          listName: true,
          branch: payload[0],
        },
      };
    
      const response = await axiosInstance.post(`/departments/list`, payload);
      return response;
    }

    if(typeof payload=="object")
    {
      if(payload?.criteria?.isDeletedd)
      {
        payload = {
          criteria: {
            isDeleted: true,
           
            
          },
        };
      }
     else{
      payload = {
        criteria: {
          isDeleted: false,
          listName:true
          
        },
      };
     }
     
      const response = await axiosInstance.post(`/departments/list`, payload);
      return response;
    }
  } catch (e) {
    return { error: e.response.data };
  }
};

//Delete a Department
export const deleteDepartmentService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/departments/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add Depatment
export const addNewDepartment = async (payload) => {
  try {
    const response = await axiosInstance.post(`/departments`, payload);
    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

//Get Department By Id

export const getDepartmentByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/departments/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Department
export const updateDepartmentService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/departments/${payload.id}`,
      payload.payload
    );

    return { response: response };
  } catch (e) {
    // return { error: "error hai bhyi" };
    return { error: e.response.data };
  }
};

//get hospital branch list
export const hospitalBranch = async (payload) => {
  try {
   
    if (payload.length == 1) {
      if(payload[0]==true)
      {
        payload = {
          criteria: {
            isDeleted:false,
            listName:true
          },
        };
  
        const response = await axiosInstance.post(`/branch/listbranch`, payload);
        return response;
      }
      else
      {
        payload = {
          criteria: {
            listName: true,
  
            organization: payload[0],
          },
        };
        const response = await axiosInstance.post(`/branch/listbranch`, payload);
        return response;
      }
    
    }
   
    else {
      payload = {
        criteria: {
          isDeleted:false,
          
        },
      };

      const response = await axiosInstance.post(`/branch/listbranch`, payload);
      return response;
    }
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//get hospital dropdown
export const hospitalDropdownService = async (payload) => {
  try {
    const response = await axiosInstance.post(`/branch`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//delete hospital branch
export const DeleteHospitalBranch = async (id) => {
  try {
    const response = await axiosInstance.delete(`/branch/${id}`);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//add hospital branch
export const CreateHospitalBranch = async (payload) => {
  try {
    const response = await axiosInstance.post(`/branch`, payload);

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//get hospital branch by id
export const getHospitalBranchById = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/branch/listbranch`, criteria);
    // console.log("res>>", response)

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const updateHospital = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/branch/${payload.id}`,
      payload.payload
    );
    // console.log("branch-Update",response);
    return response;
  } catch (e) {
    console.log("branch-Update-error",e)
    return { error: e.response.data };
  }
};

//HOSPITAL COMPANY SERVICES

// List HOSPITAL COMPANY
export const getHospitalCompany = async (payload) => {

  try {
    let criteria;
    if(payload)
    {
      criteria = {
        criteria: { isDeleted: false },
        limit: payload[0],
        offset: payload[1],
      };
    }
    else{
      criteria = {
        criteria: { isDeleted: false },
      };
    }
   
    const response = await axiosInstance.post(`/organization/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//delete hospital branch
export const DeleteHospitalCompany = async (id) => {
  try {
    const response = await axiosInstance.delete(`/organization/${id}`);

    return { response: response };
  } catch (e) {
    return { error: e.response.data.msg };
  }
};

export const addNewHopitalCompany = async (payload) => {
  try {
    const response = await axiosInstance.post(`/organization`, payload);
    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

//get hospital branch by id
export const getHospitalCompanyByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/organization/list`, criteria);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const updateHospitalCompanyService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/organization/${payload.id}`,
      payload.payload
    );

    return { response: response };
  } catch (e) {
    return { error: e.response.data };
  }
};

//GET COMPANIES
export const getCompaniesService = async (payload) => {
  try {
    if (payload === undefined) payload = { criteria: { isDeleted: false } };
    const response = await axiosInstance.post(`/company/list`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

export const uploadHospitalRateFile = async (payload) => {
  try {
    var formData = new FormData();
    formData.append("image", payload);
    const response = await axiosInstance.post(`rate/uploadRate`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { response: response };
  } catch (e) {
    return { error: e.response.data.msg };
  }
};
