import axiosInstance from "../axios";
import { toast } from "react-toastify";


//***************User Services Services*******************//

// List User
// export const getUsers = async (payload) => {
//   try {
//     if (payload === undefined) payload = false;
//     let criteria = {
//       criteria: {
//         isDeleted: false,
//         keyWord: payload.searchKey,
//         // filterAll: payload.filterAll,
//         startDate: payload.startDate,
//         endDate: payload.endDate,
//       },
//       limit: payload.pageSize,
//       offset: payload.currentPage,
//     };
//     const response = await axiosInstance.post(`/user/all`, criteria);
//     // console.log("response111", response);
//     return response;
//   } catch (e) {
//     return { error: e.response.data };
//   }
// };

export const getUsers = async (payload) => {
  // console.log("getUsers-->", payload);
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        role: payload.role,
        workCity: payload.workCity,
        specialization: payload.specialization,
        organization: payload.organization,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    // console.log("criteria-->", criteria);
    const response = await axiosInstance.post(`/user/search`, criteria);
    // console.log("response111", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// filtered user
export const getFilteredUserService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        role: payload.role,
        workCity: payload.workCity,
        specialization: payload.specialization,
        organization: payload.organization,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/user/filtered`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};
// export const getFilteredUserService = async (payload) => {
//   try {
//     // console.log("434-->", payload);
//     if (payload === undefined) payload = false;
//     let criteria = {
//        criteria: {
//         isDeleted: false,
//         keyWord: payload.searchKey,
//         filterAll: payload.filterAll,
//         startDate: payload.startDate,
//         endDate: payload.endDate,
//       }
//     };
//     // console.log("criteria>>", criteria);
//     const response = await axiosInstance.post(`/user/filtered`, criteria);
//     // console.log(">>>>>>>>>", response);
//     return response;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

export const getSearchedUserService = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        // filterAll: payload.filterAll,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/user/search`, criteria);
    // console.log("response111", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Delete a User
export const deleteUserService = async (id) => {
  try {
    const response = await axiosInstance.delete(`/user/${id}`);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

// Add User
export const addNewUser = async (payload) => {
  try {
    const response = await axiosInstance.post(`/user`, payload);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Get User By Id

// export const getUserByIdService = async (payload) => {
//   try {
//     let criteria = { criteria: { _id: payload } };
//     const response = await axiosInstance.post(`/user/all`, criteria);
//     return response?.data?.data;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

export const getUserByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/user/search`, criteria);
    return response?.data?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update User
export const updateUserService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/user/${payload.id}`,
      payload.payload
    );

    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

export const uploadPhoto = async (payload) => {
  try {
    console.log(payload, "payload");
    var image = payload[0];

    var formdata = new FormData();

    //if we want upload multiple images
    // payload.forEach((image, index) => {
    //   formdata.append(`file${index}`, image);
    // });

    formdata.append("file", image);

    const response = await axiosInstance.post(`/common/upload`, formdata);
    //console.log("imageResponse", response);

    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//get premium users
export const getPremiumUsers = async (payload) => {
  // console.log("getUsers-->", payload);
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        professionalType: payload.filterProfessionalType,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
      pastPremium: payload.filterPastPremium,
      presentPremium: payload.filterPresentPremium,
      filterByStatus: payload.filterByStatus,
    };
    // console.log("criteria-->", criteria);
    const response = await axiosInstance.post(`/subscription/list`, criteria);
    // console.log("response111", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//get filtered premium user
export const getDocsFilteredService = async (payload) => {
  try {
    // console.log("434-->", payload);
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
        isDeleted: false,
        keyWord: payload.searchKey,
        professionalType: payload.filterProfessionalType,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
      pastPremium: payload.filterPastPremium,
      presentPremium: payload.filterPresentPremium,
      filterByStatus: payload.filterByStatus,
      
    };
    // console.log("criteria>>", criteria);
    const response = await axiosInstance.post(`/subscription/filtered`, criteria);
    // console.log(">>>>>>>>>", response);
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


//by id data
export const getPremiumUserByIdService = async (payload) => {
  try {
    // console.log("payload>>",payload)
    let criteria = { criteria: { _id: payload } };
    // console.log("criteria>>", criteria)
    const response = await axiosInstance.post(`/subscription/list`, criteria);
    // console.log("1--->",response)
    return response?.data?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


// Add Monetization
export const addMonetization = async (payload) => {
  try {
    // console.log("res payload", payload)
    const response = await axiosInstance.post(`/monetization`, payload);
    // console.log("resp",response)
    if (response?.status == 200) {
      toast.success("Created Successfully", {
        position: "top-right",
      });
    }

    return { response: response };
  } catch (e) {
    // console.log("error",e)
    // if (e?.response?.data?.msg === "This Rule is already exists") {
    //   toast.error("This Rule is already exists", {
    //     position: "top-right",
    //   });
    // }
    return { error: e.response.data };
  }
};

//get all monetization list
export const getMonetizationService = async (payload) => {
  // console.log("getUsers-->", payload);
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
  
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    // console.log("criteria-->", criteria);
    const response = await axiosInstance.post(`/monetization/list`, criteria);
    // console.log("response111", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};


//get monetization list
export const getMonetizationDiscount = async (payload) => {
  try {
    if (payload === undefined) payload = false;
    let criteria = {
      criteria: {
  
      },
      limit: payload.pageSize,
      offset: payload.currentPage,
    };
    const response = await axiosInstance.post(`/monetizationDiscount/list`, criteria);
    // console.log("1--->",response)
    return response;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//get monetization by id
export const getMonetizationByIdService = async (payload) => {
  try {
    // console.log("payload>>",payload)
    let criteria = { criteria: { _id: payload } };
    // console.log("criteria>>", criteria)
    const response = await axiosInstance.post(`/monetization/list`, criteria);
    // console.log("1--->",response)
    return response?.data?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};


//update monetization discount
export const updateMonetizationDiscount = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/monetizationDiscount/${payload.id}`,
      payload.payload
    );
    // console.log("branch-Update",response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};


//update monetization
export const updateMonetization = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/monetization/${payload.id}`,
      payload.payload
    );
    // console.log("responseUpdate",response)
    if (response.data.status === true) {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    }
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};



export const uploadMonetizationFile = async (payload) => {
  try {
    console.log("payload", payload)
    var formData = new FormData();
    formData.append("image", payload);
    const response = await axiosInstance.post(`/monetization/uploadMonetization`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
   console.log("response",response)
    return { response: response };
  } catch (e) {
    return { error: e.response.data.msg };
  }
};

