//******************Transaction Orders************************////

import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import { addNewOrder } from "services/transactionServices";
import { updateBillerservice } from "services/transactionServices";
import { getBillers } from "services/transactionServices";
import { getBillerByIdService } from "services/transactionServices";
import { getTransactionByIdService } from "services/transactionServices";
import { deleteTransactionservice } from "services/transactionServices";
import { addNewTransaction } from "services/transactionServices";
import { getTransactions } from "services/transactionServices";
import { updateTransactionservice } from "services/transactionServices";
import { addNewBiller } from "services/transactionServices";
import { deleteBillerservice } from "services/transactionServices";
import { getOrders } from "services/transactionServices";
import { deleteOrderservice } from "services/transactionServices";
import { updateOrderservice } from "services/transactionServices";
import { getOrderByIdService } from "services/transactionServices";

// List Orders
export const getOrdersList = createAsyncThunk(
  "/orders/listOrders",
  async (payload, thunkAPI) => {
    const response = await getOrders(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete Orders
export const deleteOrder = createAsyncThunk(
  `/orders/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteOrderservice(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getOrdersList());
    }
    return response;
  }
);
//Add Order
export const addOrder = createAsyncThunk(
  "/orders",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewOrder(payload);
    thunkAPI.dispatch(getOrdersList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
//Get Role By ID
export const getOrderByID = createAsyncThunk(
  "/orders/listOrders/id",
  async (payload, thunkAPI) => {
    const response = await getOrderByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//Update Order
export const updateOrder = createAsyncThunk(
  "/order/update",
  async (payload, thunkAPI) => {

    const response = await updateOrderservice(payload);
    // if (response.error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

//*************Transaction Billers *********************** */
// List Biller
export const getBillersList = createAsyncThunk(
  "/biller/listBillers",
  async (payload, thunkAPI) => {
    const response = await getBillers(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete Biller
export const deleteBiller = createAsyncThunk(
  `/biller/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteBillerservice(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getBillersList());
    }
    return response;
  }
);
//Add Biller
export const addBiller = createAsyncThunk(
  "/biller",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewBiller(payload);
    thunkAPI.dispatch(getBillersList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
//Get Biller By ID
export const getBillerByID = createAsyncThunk(
  "/biller/listBillers/id",
  async (payload, thunkAPI) => {
    const response = await getBillerByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//Update Biller
export const updateBiller = createAsyncThunk(
  "/biller/update",
  async (payload, thunkAPI) => {
   
    const response = await updateBillerservice(payload);
    // if (response.error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

//*************Transaction Billers *********************** */
// List Transacations
export const getTransactionsList = createAsyncThunk(
  "/transaction/listTransactions",
  async (payload, thunkAPI) => {
    const response = await getTransactions(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);
// Delete Transaction
export const deleteTransaction = createAsyncThunk(
  `/transaction/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteTransactionservice(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getTransactionsList());
    }
    return response;
  }
);
//Add Transaction
export const addTransaction = createAsyncThunk(
  "/transaction",
  async (payload, thunkAPI) => {
    const { response, error } = await addNewTransaction(payload);
    thunkAPI.dispatch(getTransactionsList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
//Get Transaction By ID
export const getTransactionByID = createAsyncThunk(
  "/transaction/listTransactions/id",
  async (payload, thunkAPI) => {
    const response = await getTransactionByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//Update Transaction
export const updateTransaction = createAsyncThunk(
  "/transaction/update",
  async (payload, thunkAPI) => {

    const response = await updateTransactionservice(payload);
    // if (response.error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
