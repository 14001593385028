import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useFieldArray, useForm } from "react-hook-form";

// import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import { Edit } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Link, Box } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  getDepartmentType,
  getQualificationsList,
  getExperienceList,
  getSpecializationsList,
  getProfessionsList,
} from "../../../layouts/common/Redux/actionCreator";
import { setPhoto } from "../../../layouts/events/Redux/actionCreator";
// import MDButton from "./../../components/MDButton/index";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigationType } from "react-router-dom";
// import { getPermanentJobsByIdService } from "../../../services/permanentServices";
import { getPermanentApplicationByIdService } from "../../../services/permanentServices";
// import { updatePermanentJobs } from "../Redux/actionCreator";
import { updatePermanentApplication } from "../Redux/actionCreator";
import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import AttachFileIcon from "@mui/icons-material/AttachFile";

function PermaAppDetailByID() {
  const navigation = useNavigationType();
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const [responseData, setResponseData] = useState([]);

  const selectStateOptions = [
    {
      key: "APPLIED",
      value: "APPLIED",
    },
    {
      key: "HIRED",
      value: "HIRED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "INPROGRESS",
      value: "INPROGRESS",
    },
  ];

  // const code = window.location.href.split("code")[1].split("/")[1];
  // const ID = window.location.href.split("code")[1].split("/")[2];
  const ID = window.location.href
    .split("permanent/application")[1]
    .split("/")[1];
  // console.log("ID", ID);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getData = async () => {
    // let response = await getJobJobsByIdService(ID);
    let response = await getPermanentApplicationByIdService(ID);
    // console.log("426--->", response);

    setResponseData(response[0]);

    return response[0];
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: async () => await getData(),
  });

  const [updated, setUpdated] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [value, setValue] = useState(null);
  const onSubmit = (data) => {
    // console.log("onSubmit", data);

    try {
      const payload = {
        state: data.state,
      };
      // console.log("payload", payload);

      dispatch(updatePermanentApplication({ payload: payload, id: ID })).then(() => {
        navigate("/permanent/application");
      });

    } catch (error) {
      console.error("Error during form submission:", error);
      // Handle the error appropriately
    }
  };

  const {
    DepartmentType_Data,
    QualificationsData,
    Experience_Data,
    Specialization_Data,
    ProfessionsData,
  } = useSelector((state) => state.common);
  const department_Info = DepartmentType_Data?.departmentTypeInfo;

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getDepartmentType());
    dispatch(getQualificationsList());
    dispatch(getExperienceList());
    dispatch(getSpecializationsList());
    dispatch(getProfessionsList());
  }, [dispatch]);

  const formValues = getValues();
  // console.log("1074-->",formValues)
  // console.log("1-->",getValues("createdAt"))

  const formattedCreatedAt = getValues("createdAt")
    ? dayjs(getValues("createdAt"))?.format("YYYY/MM/DD")
    : null;

  const formattedUpdatedAt = getValues("updatedAt")
    ? dayjs(getValues("updatedAt"))?.format("YYYY/MM/DD")
    : null;

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // console.log("responseData", responseData)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>

                      {/* Job id */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Id
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Id"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={responseData?.permanentJobDetails?.code}
                        />
                      </Grid>

                      {/* Job status */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job Status
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Status"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobDescription")}
                          value={responseData?.permanentJobDetails?.state}
                        />
                      </Grid>

                      {/* Professional Name */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Professional Name
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Professional Name"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("numberOfJobs")}
                          value={responseData?.professionalDetails?.name?.full}
                        />
                      </Grid>

                        {/* Mobile Number */}
                        <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Mobile Number
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Mobile Number"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          // value={responseData[0]?.professional?.phoneNumbers[0]?.number || "NA"}
                          value={responseData?.professionalDetails?.phoneNumbers ? responseData?.professionalDetails?.phoneNumbers[0]?.number : "NA"}
                        />
                      </Grid>

                      {/* Application Status */}
                      {/* <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Application Status 
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Application Status "
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("state")}
                        />
                      </Grid> */}

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Application Status
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={selectStateOptions}
                          placeholder="State"
                          control={control}
                          name="state"
                          defaultValue="APPLIED"
                          getValues={getValues}
                          customOnChange={(e) =>
                            setValue("state", e.target.value)
                          }
                        />
                      </Grid>

                      {/* Client Name */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Client Name
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Client Name"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={
                            responseData?.permanentJobDetails?.client?.name
                          }
                        />
                      </Grid>

                      {/* City */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          City
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="City"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={
                            responseData?.permanentJobDetails?.address?.city
                          }
                        />
                      </Grid>

                      {/* Type */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Type
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Type"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={
                            responseData?.permanentJobDetails?.type || "NA"
                          }
                        />
                      </Grid>

                      {/* created and updated timestamps */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Created At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedCreatedAt}
                          {...register("createdAt")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Updated At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedUpdatedAt}
                          {...register("updatedAt")}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Created By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          placeholder=""
                          disabled
                          // {...register(`createdBy.name`)}
                          value={responseData?.createdBy?.name?.full || "NA"}
                          sx={{ width: "100%", border: "none" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Updated By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          placeholder=""
                          disabled
                          // {...register(`updatedBy.name`)}
                          value={responseData?.updatedBy?.name?.full || "NA"}
                          sx={{ width: "100%", border: "none" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={12}>
                        <Divider sx={{ width: "100%" }} />
                      </Grid>

                      <Grid item xs={12}>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default PermaAppDetailByID;
