import {
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { getValue } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCitiesById } from "services/commonServices";
import { updateCity } from "services/commonServices";
import MDBox from "../../../components/MDBox/index";
import MDInput from "../../../components/MDInput/index";
import SelectDropDown from "../../../examples/DropDown/index";
import {
  getCitybyId,
  setUsersDropdown,
  updateCityy,
} from "../Redux/actionCreator";

import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import Moment from "moment";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CityDetailsForm = () => {
  const [passwordButton, setPasswordButton] = useState(true);

  // const validationSchema=yup.object().shape({
  //   value:yup.string()
  //   .required("Value is Required")
  //   .matches(/^[0-9\s]+$/, "Invalid Value")
  // })

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responsedata, setResponseData] = useState([]);
  const [isLaunched, setIsLaunched] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const url = window.location.href.split("cities")[1].split("/")[1];
  const getData = async () => {

    let response = await getCitiesById(url);
   
    setResponseData(response);
   
    return response[0];
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: () => getData() });

  const data1 = getValues();

  // const handleCheckboxChange = (isChecked) => {
  //   setIsLaunched(isChecked);

  //   // Show the popup when Checkbox is checked
  //   if (isChecked) {
  //     setShowPopup(true);
  //   }else{
  //     setShowPopup(false);
  //   }
  // };
  useEffect(() => {
    const storedData = localStorage.getItem("IsLaunched");
    if (storedData) {
      setIsLaunched(JSON.parse(storedData).isLaunched);
    }else{
      setIsLaunched(true)
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsLaunched(isChecked);
    setValue("isLaunched", isChecked)

    if (isChecked) {
      setShowPopup(true);
    } else {
      // Reset the showPopup state if the checkbox is unchecked
      setShowPopup(false);
    }
  };

  const handlePopupResponse = (response) => {
    setShowPopup(false);
    if (response === 'Yes') {
      setIsLaunched(true);
    } else {
      setIsLaunched(false);
    }
    
  };
  
  const onSubmit = async (data) => {
    const payload = {
      name: data.name,
      isDeleted: data.isDeleted,

      // createdBy: data.createdBy,
      // updatedBy: data.updatedBy,
    };
    // if (data.isLaunched) {
    //   // Show the popup when Checkbox is checked and submit is clicked
    //   setShowPopup(true);
    // } else {
    //   // Launch the city directly if "isLaunched" is not checked
    //   dispatch(updateCityy({ payload: payload, id: url })).then(() => {
    //     navigate('/common/cities');
    //   });
    // }
    localStorage.setItem("IsLaunched", JSON.stringify({ isLaunched: data.isLaunched }));
    dispatch(updateCityy({ payload: payload, id: url })).then(() => {
      navigate("/common/cities");
    });
  };
  const handleReset = () => {
    reset();
  };

  //Fetching User Dropdown Details
  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;
  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });


  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    
  };
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(setUsersDropdown());
  }, []);

  const formValues = getValues();
  

  const [created, setCreated] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {

    let createdAT = getValues("createdAt");
    let updatedAT = getValues("updatedAt");
    
    if(createdAT=="Invalid date")
    {
      setValue("createdAt","");
      setCreated(true);
    }
    if(updatedAT!=="Invalid date")
    {
      setValue("updatedAt","");
      setUpdated(true);
    }
    if (createdAT != undefined && createdAT!=="") {
      if (!created) {
        setValue("createdAt", Moment(createdAT).format("DD/MM/YY"));

        setCreated(true);
      }
    }


    if(updatedAT=="Invalid date")
    {
      setValue("updatedAt","");
      setUpdated(true);
    }
 
    if (updatedAT != undefined && updatedAT !=="") {
      if (!updated) {
        setValue("updatedAt", Moment(updatedAT).format("DD/MM/YY"));

        setUpdated(true);
      }
    }
  }, [getValues("createdAt"), getValues("updatedAt")]);
  console.log("162-->", )

 

 
  
  

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
              
            </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Name Title */}
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      name="name"
                      {...register("name")}
                    />
                  </Grid>
                  {/* ID */}
                  <Grid item xs={12}>
                    <Typography variant="body">
                      ID:{responsedata[0]?._id}
                    </Typography>
                  </Grid>
                  {/* is Deleted */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isDeleted"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Is Deleted"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/* is Launched */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isLaunched"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  // checked={props.value}
                                  // onChange={(e) =>
                                  //   props.onChange(e.target.checked)
                                  // }
                                  checked={isLaunched}
                                  onChange={(e) => handleCheckboxChange(e)}
                                />
                              )}
                            />
                          }
                          label="Is Launched"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/* Popup Component */}
                  <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle>Launch City</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to launch this city?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handlePopupResponse('Yes')}>Yes</Button>
          <Button onClick={() => handlePopupResponse('No')}>No</Button>
        </DialogActions>
      </Dialog>
                  {/* CreatedAt */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      name="createdAt"
                      // defaultValue={responsedata[0]?.createdAt}

                      {...register("createdAt")}
                    />
                  </Grid>
                  {/* Updated At */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      name="updatedAt"
                      {...register("updatedAt")}
                    />
                  </Grid>
                  {/* CreatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      name="createdBy"
                      {...register("createdBy.name.full")}
                    />
                  </Grid>
                  {/* updatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      name="updatedBy"
                      {...register("updatedBy.name.full")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    Save
                  </Button>
                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default CityDetailsForm;