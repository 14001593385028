const state = {
  Admin_User_Data: {
    adminUserLoading: true,
    adminUserError: "",
    adminUserInfo: [],
  },

  Ari_data: {
    ariLoading: true,
    ariError: "",
    ariInfo: [],
  },

  Admin_Roles_Data: {
    adminRoleLoading: true,
    adminRoleError: "",
    adminRoleInfo: [],
  },
};

export default state;
