import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid, Paper, Typography, Box } from "@mui/material";
import SmallCard from "examples/SmallCard";
import axios from "axios";
import axiosInstance from "../../axios";
const HomePage = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await axiosInstance.post("/common/list");
    console.log("res: ", res)

    setData(res?.data?.docs);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <div>
        <MDBox mt={10} ml={2}>
          {/* Heading of Jobizo */}
          <Box sx={{ borderBottom: "2px solid rgba(0,0,0,0.1)" }}>
            <Box sx={{ marginBlock: "10px" }}>
              <Typography
                sx={{
                  fontWeight: "300",
                  fontSize: "2rem",
                  color: "#000000",
                }}
              >
                The JOBIZO Super Admin
              </Typography>
            </Box>
          </Box>

          {/* Admin div */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Admin</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Admin Users"
                  subTitle={`${data[1]?.adminusers} Items`}
                  route="/dashboards/admin"
                />
              </Grid>

              <Grid item xs={3}>
                <SmallCard
                  title="Roles"
                  subTitle={`${data[28]?.roles} Items`}
                  route="/dashboards/roles"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Logs div */}
          {/* <Box style={{marginBlock:"40px"}}>
          <Box sx={{color:"#9a9a9a",marginBlock:"10px"}}>
            <Typography>Logs</Typography>
          </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SmallCard title="Audit Logs" subTitle="0 item"/>
          
          
          </Grid>
          
          
         
        </Grid>
        </Box> */}

          {/* Common div */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Common</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Job Description"
                  subTitle={`${data[17]?.jdrules} Items`}
                  route="/common/jobDescription"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Additional Trainings"
                  subTitle={`${data[0]?.additionaltrainings} Items`}
                  route="/common/additionalTrainings"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Cities"
                  subTitle={`${data[8]?.cities} Items`}
                  route="/common/cities"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Company Types"
                  subTitle={`${data[10]?.companytypes} Items`}
                  route="/common/companyTypes"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Department Types"
                  subTitle={`${data[12]?.departmenttypes} Items`}
                  route="/common/departmentTypes"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Document Types"
                  subTitle={`${data[13]?.documenttypes} Items`}
                  route="/common/documentTypes"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Languages"
                  subTitle={`${data[19]?.languages} Items`}
                  route="/common/languages"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Experience"
                  subTitle={`${data[14]?.numexperiences} Items`}
                  route="/common/experience"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Specialization"
                  subTitle={`${data[30]?.nursetypes} Items`}
                  route="/common/specialization"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Professional Registration Types"
                  subTitle={`${data[23]?.professionalregistrationtypes} Items`}
                  route="/common/registrationTypes"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Profession"
                  subTitle={`${data[24]?.professionaltypes} Items`}
                  route="/common/profession"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Qualification"
                  subTitle={`${data[25]?.qualificationtypes} Items`}
                  route="/common/qualification"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Softwares"
                  subTitle={`${data[29]?.softwares} Items`}
                  route="/common/softwares"
                />
              </Grid>
            </Grid>
          </Box>
          {/* events */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Events</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Event Listing"
                  subTitle={`${data[32]?.upcomingevents} Items`}
                  route="/eventDetails"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Interview"
                  subTitle={`${data[15]?.interviewslots} Items`}
                  route="/interviewDetails"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Referral"
                  subTitle={`${data[35]?.referrals} Items`}
                  route="/referralDetails"
                />
              </Grid>
            </Grid>
          </Box>
          {/* Hospital */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Hospital</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Hospital Branch"
                  subTitle={`${data[7]?.branches} Items`}
                  route="/hospital/branch"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Corporate"
                  subTitle={`${data[9]?.companies} Items`}
                  route="/hospital/corporate"
                />
              </Grid>

              <Grid item xs={3}>
                <SmallCard
                  title="Company"
                  subTitle={`${data[21]?.organizations} Items`}
                  route="/hospital/company"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Department"
                  subTitle={`${data[11]?.departments} Items`}
                  route="/hospital/department"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Rates"
                  subTitle={`${data[26]?.rates} Items`}
                  route="/hospital/rates"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Job */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Job</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Applications"
                  subTitle={`${data[2]?.applications} Items`}
                  route="/jobs/application"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Jobs"
                  subTitle={`${data[18]?.jobs} Items`}
                  route="/jobDetails"
                />
              </Grid>
              {/* <Grid item xs={3}>
                <SmallCard
                  title="Permanent Jobs"
                  subTitle={`${data[22]?.permanentjobs} Items`}
                  route="/jobs/permanentJobs"
                />
              </Grid> */}
            </Grid>
          </Box>

           {/*Permanent Job */}
           <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Permanent Job</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Permanent Applications"
                  subTitle={`${data[22]?.permanentapplications} Items`}
                  route="/permanent/application"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Permanent Jobs"
                  subTitle={`${data[23]?.permanentjobs} Items`}
                  route="/permanent/job"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Transactions */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Transactions</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Orders"
                  subTitle={`${data[20]?.orders} Items`}
                  route="/transaction/order"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Transaction"
                  subTitle={`${data[31]?.transactions} Items`}
                  route="/transaction/transactions"
                />
              </Grid>
            </Grid>
          </Box>

          {/* User */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>User</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Nurse/Hospital User"
                  subTitle={`${data[33]?.users} Items`}
                  route="/users"
                />
              </Grid>
            </Grid>
          </Box>
          {/* Bill */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>Billing</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="Locum Bill"
                  subTitle={`${data[5]?.bills} Items`}
                  route="/locumBill"
                />
              </Grid>
            </Grid>
          </Box>
          {/* invoice */}
          <Box style={{ marginBlock: "40px" }}>
            <Box sx={{ color: "#9a9a9a", marginBlock: "10px" }}>
              <Typography>User Invoices</Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SmallCard
                  title="User Invoice"
                  subTitle={`${data[16]?.invoices} Items`}
                  route="/invoiceDetails"
                />
              </Grid>
              <Grid item xs={3}>
                <SmallCard
                  title="Bank"
                  subTitle={`${data[36]?.banks} Items`}
                  route="/bankDetails"
                />
              </Grid>
            </Grid>
          </Box>

          {/* Others */}
        </MDBox>
      </div>
    </DashboardLayout>
  );
};

export default HomePage;
