import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

import { useState } from "react";
import { useForm } from "react-hook-form";
const EditJobDetailDialog = ({
  handleConfirmation,
  message,
  render,
  ...props
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleEdit = () => {
    handleConfirmation(formData);
    setIsPopupOpen(false);
  };
  const inputFieldData = {
    _id: props._id,
    profession: props.profession,
    experience: props.experience,
    specialization: props.specialization,
    description: props.description,
  };
  const [formData, setFormData] = useState(inputFieldData);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleClose = () => {


    setFormData(inputFieldData);
    setIsPopupOpen(false);
  };

  const handlePopupOpen = () => setIsPopupOpen(true);
  
  return (
    <>
      <Dialog open={isPopupOpen}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit Details
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid
              item
              display={"flex"}
              justifyContent={"flex-start"}
              flexDirection={"column"}
              gap={"5px"}
              width={"90%"}
              margin={"10px"}
            >
              <InputLabel fullWidth>Profession</InputLabel>
              <TextField
                placeholder="Profession"
                name="profession"
                value={formData.profession}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={"flex-start"}
              // alignItems={"center"}
              flexDirection={"column"}
              gap={"5px"}
              width={"90%"}
              margin={"10px"}
            >
              <InputLabel fullWidth>Experience</InputLabel>
              <TextField
                placeholder="Experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={"flex-start"}
              // alignItems={"center"}
              flexDirection={"column"}
              gap={"5px"}
              width={"90%"}
              margin={"10px"}
            >
              <InputLabel fullWidth>Specialization</InputLabel>
              <TextField
                placeholder="Specialization"
                name="specialization"
                value={formData.specialization}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              display={"flex"}
              justifyContent={"flex-start"}
              // alignItems={"center"}
              flexDirection={"column"}
              gap={"5px"}
              width={"90%"}
              margin={"10px"}
            >
              <InputLabel fullWidth>Job Description</InputLabel>
              <TextField
                placeholder="Job Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button type="submit" onClick={() => {
            handleEdit()
            toast.success("JD updated successfully");
          }}>
            save
          </Button>
        </DialogActions>
      </Dialog>
      {render(handlePopupOpen, isPopupOpen)}
    </>
  );
};

export default EditJobDetailDialog;
