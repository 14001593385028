const state = {
  loading: false,
  success: false,
  error: "",
  Job_Details_Data: {
    jobDetailsLoading: true,
    jobDetailsError: "",
    jobDetailsInfo: [],
    jobDetailsCount:0
  },
  Cities_Data: {
    cityLoading: true,
    cityError: "",
    cityInfo: [],
  },
  City_List_Data: {
    cityListLoading: true,
    cityListError: "",
    cityListInfo: [],
  },
  City_Data: {
    cityLoadingData: true,
    cityErrorData: "",
    cityInfoData: [],
  },
  QualificationsData: {
    qualificationsLoading: true,
    qualificationsError: "",
    qualificationsInfo: [],
  },
  ProfessionsData: {
    professionsLoading: true,
    professionsError: "",
    professionsInfo: [],
  },
  SoftwaresData: {
    softwaresLoading: true,
    softwaresError: "",
    softwaresInfo: [],
  },
  UsersData: {
    usersLoading: true,
    usersError: "",
    usersInfo: [],
  },
  OrganizationsData: {
    organizationsLoading: true,
    organizationsError: "",
    organizationsInfo: [],
  },
  LanguagesData: {
    languagesLoading: true,
    languagesError: "",
    languagesInfo: [],
  },
  Company_List_Data: {
    companyListLoading: true,
    companyError: "",
    companyListInfo: [],
  },
  Company_Data: {
    companyLoading: true,
    companyError: "",
    companyInfo: [],
  },
  Experience_Data: {
    experienceLoading: true,
    experienceError: "",
    experienceInfo: [],
  },
  ProfessionRegistrationData: {
    professionRegistrationLoading: true,
    professionRegistrationError: "",
    professionRegistrationInfo: [],
  },
  Specialization_Data: {
    specializationLoading: true,
    specializationError: "",
    specializationInfo: [],
  },
  DocumentType_Data: {
    documentTypeLoading: true,
    documentTypeError: "",
    documentTypeInfo: [],
  },
  DepartmentType_Data: {
    departmentTypeLoading: true,
    departmentTypeError: "",
    departmentTypeInfo: [],
  },
  Additional_Training: {
    additionalTrainingLoading: true,
    additionalTrainingError: "",
    additionalTrainingInfo: [],
  },

  Additional_Data: {
    additionalLoading: true,
    additionalError: "",
    additionalInfo: [],
  },

  Additional_Id_Data: {
    additionalIdLoading: true,
    additionalIdError: "",
    additionalIdInfo: [],
  },

  // Skills_Data: {
  //   skillLoading: true,
  //   skillError: "",
  //   skillInfo: [],
  // },
  Skill_List_Data: {
    skillListLoading: true,
    skillListError: "",
    skillListInfo: [],
  },
  // Skill_Data: {
  //   skillLoadingData: true,
  //   skillErrorData: "",
  //   skillInfoData: [],
  // },

  DocVerifaction_List_Data: {
    docverificationListLoading: true,
    docverificationListError: "",
    docverificationListInfo: [],
    total: 0,
  },

  DocVerifaction_Filtered_List_Data: {
    docverificationFilteredListLoading: true,
    docverificationFilteredListError: "",
    docverificationFilteredListInfo: [],
  },

  GCC_Department_Data: {
    departmentGCCLoading: true,
    departmentGCCError: "",
    departmentGCCInfo: [],
  },

  GCC_Experience_Data: {
    experienceGCCLoading: true,
    experienceGCCError: "",
    experienceGCCInfo: [],
  },

  currentPage: 0,
  pageSize: 10,
  searchKey: "",
  filterState: "",
  startDate: "",
  endDate: "",
};

export default state;
