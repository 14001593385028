import { createSlice } from "@reduxjs/toolkit";
import { fetchBillData,getFilteredBillDetails, fetchBillDetailsData } from "./actionCreator";
import initialState from "./state";

export const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    pageChanged(state, action) {
      state.currentPage = action.payload;
    },
    pageLimit(state, action) {
      state.pageSize = action.payload;
    },
    searchKeyword(state, action){
      state.searchKey = action.payload;
    },
    filterData(state, action){
      state.filterAll = action.payload;
    },
    updateStartDate(state, action){
      state.startDate = action.payload;
    },
    updateEndDate(state, action){
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {

    // get all details
    builder.addCase(fetchBillDetailsData.pending, (state) => {
      state.Bill_Details_Data.billDetailsLoading = true;
    });
    builder.addCase(fetchBillDetailsData.fulfilled, (state, action) => {
      // console.log("action>>", action)
      state.Bill_Details_Data.billDetailsLoading = false;

      const responseArray = JSON.stringify(
        action?.payload?.response?.data?.data
      );
      state.Bill_Details_Data.billDetailsInfo = JSON.parse(responseArray);
      // state.Bill_Details_Data.billDetailsInfo = action?.payload?.response?.data?.data
      state.Bill_Details_Data.total = action?.payload?.response?.data?.total;
    });
    builder.addCase(fetchBillDetailsData.rejected, (state, action) => {
      state.Bill_Details_Data.billDetailsLoading = false;
      state.Bill_Details_Data.billDetailsError = "Bill Details Error";
    });


    // get bill filtered details
    builder.addCase(getFilteredBillDetails.pending, (state) => {
      state.Bill_Filtered_Data.billFilteredLoading = true;
    });
    builder.addCase(getFilteredBillDetails.fulfilled, (state, action) => {
      // console.log("action>>", action)
      state.Bill_Filtered_Data.billFilteredLoading = false;

      const responseArray = JSON.stringify(
        action?.payload?.data
      );
      state.Bill_Filtered_Data.billFilteredInfo = JSON.parse(responseArray);
      // state.Bill_Details_Data.total = action?.payload?.response?.data?.total;
    });
    builder.addCase(getFilteredBillDetails.rejected, (state, action) => {
      state.Bill_Filtered_Data.billFilteredLoading = false;
      state.Bill_Filtered_Data.billFilteredError = "Bill Filtered Error";
    });


    // get details by id
    builder.addCase(fetchBillData.pending, (state) => {
      state.Bill_Data.billLoading = true;
    });
    builder.addCase(fetchBillData.fulfilled, (state, action) => {
      state.Bill_Data.billLoading = false;

      const responseArray = JSON.stringify(
        action?.payload?.response?.data?.data
      );
      state.Bill_Data.billInfo = JSON.parse(responseArray);
    });
    builder.addCase(fetchBillData.rejected, (state, action) => {
      state.Bill_Data.billLoading = false;
      state.Bill_Data.billError = "Bill Error";
    });
  },
});

export const {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterData,
  updateStartDate,
  updateEndDate
} = billsSlice.actions;

export default billsSlice.reducer;
