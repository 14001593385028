import { useForm, Controller } from "react-hook-form";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import SelectDropDown from "./../../../examples/DropDown/index";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import adminPanelValidation, {
  AriFormValidation,
} from "../../../util/validations/adminPanelValidation";
import {
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Link
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import { useDispatch } from "react-redux";
import { updateTransaction } from "../Redux/actionCreator";
import { getTransactionByIdService } from "services/transactionServices";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const PaymentTermDetailForm = () => {
  const selectOptions = [
    {
      key: "card",
      value: "card",
    },

    {
      key: "upi",
      value: "upi",
    },
    {
      key: "netbanking",
      value: "netbanking",
    },
  ];

  const selectOptionsStatus = [
    {
      key: "captured",
      value: "captured",
    },
  ];

  const ID = window.location.href.split("transactions")[1].split("/")[1];

  const getData = async () => {
    
    let response = await getTransactionByIdService(ID);
    

    return response[0];
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    

    let payload = {
      user: data.user.name.full,
      amount: data.amount,
      currency: data.currency,
      order_id: data.order_id,
      method: data.method,
      description: data.description,
      status: data.status,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };

    dispatch(updateTransaction({ payload: payload, id: ID })).then(() => {
      navigate("/transaction/transactions");
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleReset = () => {
    reset();
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox margin={2}>
            <Grid item xs={12}>
            
              <div style={{ display: "flex" ,width:"70px"}}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                 <ArrowBackIos fontSize="small" />
                
                   
                    back
                 
                </div>
                </Link>
              </div>
             
            </Grid>
          {/* <Divider /> */}
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                {/* Id */}
                <Grid item xs={12}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    disabled
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 25, fontWeight: "bold" },
                    }}
                    {...register("_id")}
                  />
                </Grid>

                {/* Currency */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "1rem" }}
                  >
                    Currency
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("currency")}
                  />
                </Grid>

                {/* User */}
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ marginTop: "1rem" }}>
                    {" "}
                    User Details
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Salutation (Mr. / Mrs.)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.salutation")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    First Name{" "}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.first")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Middle Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.middle")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Full Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.full")}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Last Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("user.name.last")}
                  />
                </Grid>

                {/* Description */}

                <Grid item xs={4}>
                  <Typography variant="subtitle1">Description</Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("description")}
                  />
                </Grid>
                {/* User */}
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Amount Details
                  </Typography>
                </Grid>
                {/* Amount */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("amount")}
                  />
                </Grid>

                {/* ORDER DETAILS */}
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ marginTop: "1rem" }}>
                    {" "}
                    Order Details
                  </Typography>
                </Grid>
                {/* Order ID */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Order ID
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("order_id")}
                  />
                </Grid>

                {/* Status */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDown
                    options={selectOptionsStatus}
                    placeholder="Select"
                    name="status"
                    control={control}
                    defaultValue={getValues("status")}
                    customOnChange={(e) => {
                      setValue("status", e.target.value);
                    }}
                  />
                </Grid>

                {/* Method */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Method
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <SelectDropDown
                    options={selectOptions}
                    placeholder="Select"
                    name="method"
                    control={control}
                    defaultValue="none"
                    customOnChange={(e) => {
                      setValue("method", e.target.value);
                    }}
                  />
                </Grid>

                {/* CreatedAt */}

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Created At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("createdAt")}
                  />
                </Grid>

                {/* Updated At */}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    sx={{ width: "80%", marginTop: "0.8rem" }}
                  >
                    Updated At
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <MDInput
                   onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    placeholder=""
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    inputProps={{
                      style: { fontSize: 15, fontWeight: "bold" },
                    }}
                    {...register("updatedAt")}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: "2rem" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  {" "}
                  Save
                </Button>

                {/* <Button
                  variant="outlined"
                  sx={{ color: "#111", marginLeft: "1rem" }}
                  onClick={handleReset}
                >
                  Reset
                </Button> */}
              </div>
            </form>
          </DialogContent>
        </MDBox>
      </DashboardLayout>
    </>
  );
};
export default PaymentTermDetailForm;
