import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Moment from "moment";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import SelectDropDown from "./../../../examples/DropDown/index";
import adminPanelValidation from "../../../util/validations/adminPanelValidation";
import { Button, CircularProgress, Link, Paper } from "@mui/material";

import MDButton from "components/MDButton";

import {
  DialogContent,
  Typography,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRoleByIdService } from "services/adminServices";
import { updateRole } from "../Redux/actionCreator";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import DynamicPermissions from "examples/DynamicPermissions";
import { setAriType } from "layouts/events/Redux/actionCreator";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const RoleUserForm = () => {
  const [passwordButton, setPasswordButton] = useState(true);
  const ID = window.location.href.split("roles")[1].split("/")[1];

  const getData = async () => {
 
    let response = await getRoleByIdService(ID);
    return response[0];
  };

  const [responseData, setResponseData] = useState({});


  const { Ari_Data } = useSelector((state) => state.events);
  const ariData = Ari_Data?.ariInfo;
  const ariOptions = [];

  ariData?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.ari,
    };

    ariOptions.push(obj);
  });

 
  const selectOptions = [
    {
      key: "WRITER",
      value: "WRITER",
    },
    {
      key: "READER",
      value: "READER",
    },
    {
      key: "NONE",
      value: "NONE",
    },
  ];

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: async () => await getData() });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "permissions", // unique name for your Field Array
  });

  const handleReset = () => {
    reset();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    let permissonsArray = [];

    data.permissions.map((ele) => {
      let obj = { level: "" ,ari:""};
      obj.level = ele?.level;
       obj.ari = typeof ele?.ari=="object"?"":ele?.ari;
      permissonsArray.push(obj);
    });
  

    let payload = {
      name: data?.name,
      permissions: permissonsArray,
      isDeleted: data?.isDeleted,
      description: data?.description,
      // createdAt: data?.createdAt,
      // updatedAt: data?.updatedAt,
    };

    dispatch(updateRole({ payload: payload, id: ID })).then(() => {
      navigate("/dashboards/roles");
    });
  };

  useEffect(() => {
    dispatch(setAriType());
  }, []);

  const formValues = getValues();
  const handleBack = () => {
    navigate(-1);
  };

  const [created1, setCreated1] = useState(false);
  const [updated1, setUpdated1] = useState(false);

  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");

    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }
 
    if (created != undefined && created != "") {
      if (!created1) {
       
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
      }
      setCreated1(true);
    }

    if (updated !== undefined && updated != "") {
      if (!updated1) {
        setValue("updatedAt", Moment(updated).format("DD/MM/YYYY"));
      }
      setUpdated1(true);
    }
  }, [getValues("createdAt"), getValues("updatedAt")]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
             
            </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Name Title */}
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name")}
                    />
                  </Grid>
                  {/* ID */}
                  <Grid item xs={12}>
                    <Typography variant="body">ID:{ID}</Typography>
                  </Grid>
                  {/* is Deleted */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isDeleted"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Is Deleted"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/* Description*/}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("description")}
                    />
                  </Grid>
                  {/* Permissions */}
                  <Grid item xs={12}>
                    <DynamicPermissions
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      selectOptionslevel={selectOptions}
                      selectOptionsAri={ariOptions}
                    />
                  </Grid>
                  {/* CreatedAt */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>
                  {/* Updated At */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Save
                  </Button>

                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default RoleUserForm;
