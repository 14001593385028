import { Autocomplete, InputAdornment, Paper, TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function AutoCombobox({ placeholder, icon }) {
  const Filters = [
    { label: "Filter 1", id: 1 },
    { label: "Filter 2", id: 2 },
    { label: "Filter 3", id: 3 },
    { label: "Filter 4", id: 4 },
    { label: "Filter 5", id: 5 },
    { label: "Filter 6", id: 6 },
  ];
  return (
    <div className="comboContainer">
      {" "}
      <Paper
        elevation={4}
        sx={{
          marginLeft: "1rem",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={Filters}
          sx={{ width: 160, borderRadius: "10px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position="start"> {icon}</InputAdornment>,
                disableUnderline: true,
              }}
            />
          )}
        />
      </Paper>
    </div>
  );
}
AutoCombobox.propTypes = {
  icon: PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
};
export default AutoCombobox;
