import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import SelectDropDown from "../../../examples/DropDown/index";
import MDButton from "components/MDButton";
import NestedArray from "./nestedFieldArray";
import Moment from "moment";
import {
  DialogContent,
  Typography,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Select,
  CircularProgress,
} from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import InputForm from "./InputForm";
import { Paper } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { hospitalBranchFormValidation } from "util/validations/hospitalPanelValidation";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  GetHospitalBranchById,
  setDocumentType,
  UpdateHospital,
} from "../Redux/actionCreator";
import { getCompanyList } from "layouts/hospital/Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import SelectDropDownNew from "examples/DropDownNew";
import { getHospitalBranchById } from "services/hospitalServices";
import DynamicCoords from "examples/DynamicCoords";
import DynamicDocuments from "examples/DynamicDocuments";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CompressOutlined, Edit } from "@mui/icons-material";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import { setSpecialisationsType } from "layouts/events/Redux/actionCreator";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import { id } from "date-fns/locale";
import MultiSelectDropdown from "examples/Multi Select Dropdown";
import Questions from "./Questions";
import { setCityList } from "layouts/events/Redux/actionCreator";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const BranchUserForm = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { hospital_Id_Data } = useSelector((state) => state.hospital);
  const { UsersData } = useSelector((state) => state.common);
  const { City_List_Data } = useSelector((state) => state.events);
  const [inputList, setInputList] = useState([]);

  const cityData = City_List_Data?.cityListInfo;
  const cityOptions = [];

  cityData.map((ele) => {
    let obj = {
      key: ele.name,
      value: ele.name,
    };
    cityOptions.push(obj);
  });

  useEffect(() => {
    dispatch(GetHospitalBranchById(params?.branchName));
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { firstName: "" }]);
  };
  const [passwordButton, setPasswordButton] = useState(true);

  const selectOptions = [
    {
      key: "Company",
      value: "Company",
    },
  ];

  const selectedReversedOption = [
    {
      key: "YES",
      value: "YES",
    },
    {
      key: "NO",
      value: "NO",
    },
  ];

  const shiftOptions = [
    {
      key: "EARLY_MORNING",
      value: "EARLY_MORNING",
    },
    {
      key: "MORNING",
      value: "MORNING",
    },
    {
      key: "AFTERNOON",
      value: "AFTERNOON",
    },
    {
      key: "EVENING",
      value: "EVENING",
    },
    {
      key: "NIGHT",
      value: "NIGHT",
    },
    {
      key: "DAY_NIGHT",
      value: "DAY_NIGHT",
    },
    {
      key: "ALL_DAY",
      value: "ALL_DAY",
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const notInterestedInfo = UsersData?.usersInfo;
  const notInterestedOptions = [];

  notInterestedInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    notInterestedOptions.push(obj.value);
  });

  const options = notInterestedOptions;

  const days = [
    { key: "Monday", value: "Monday" },
    { key: "Tuesday", value: "Tuesday" },
    { key: "Wednesday", value: "Wednesday" },
    { key: "Thursday", value: "Thursday" },
    { key: "Friday", value: "Friday" },
    { key: "Saturday", value: "Saturday" },
    { key: "Sunday", value: "Sunday" },
  ];

  const [selectedDays, setSelectedDays] = useState([]);
  const [daysID, setDaysID] = useState([]);

  const isAllSelectedDays =
    options.length > 0 && selectedDays.length === options.length;

  const handleChangeDays = (event, index) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedDays(selectedDays.length === options.length ? [] : options);
      return;
    }
    setSelectedDays(value);
    setDaysID((current) => [...current, value]);
  };

  const [companyID, setCompanyID] = useState("");
  const [organizationID, setOrganizationID] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [specializationID, setSpecializationID] = useState([]);

  const { Profession_Type_Data, Specialization_Type_Data } = useSelector(
    (state) => state.events
  );
  const { hospital_company_Data } = useSelector((state) => state.hospital);
  const { OrganizationsData } = useSelector((state) => state.common);

  const companyData = hospital_company_Data?.companyInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;
  const professionData = Profession_Type_Data?.professionInfo;

  const companyOptions = [];
  const organizationOptions = [];

  const professionOptions = [];
  const specializationOptions = [];

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  companyData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    companyOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  const testPapers = [];
  const [testPaper, setTestPaper] = useState([]);
  const handleSpecializationChange = (e) => {
    let userID;
    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        testPapers.push(obj.key);
        userID = obj.key;
        setTestPaper([...testPaper, obj.key]);
      }
    });

    setSpecializationID((current) => [...current, userID]);
  };

  const handleCandidateTypeChange = (e) => {
    setValue("candidateType.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setCandidateTypeID(userID);
  };

  const handleCompanyChange = (e) => {
    setValue("company.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    companyOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setCompanyID(userID);
  };

  const handleOrganizationChange = (e) => {
    setValue("organization.name", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    setOrganizationID(userID);
  };

  const convert = (str) => {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [day, month, date.getFullYear()].join("-");
  };

  const ID = window.location.href.split("branch")[1].split("/")[1];

  const [adStartDateChange, setAdStartDateChange] = useState(true);
  const [adEndDateChange, setAdEndDateChange] = useState(true);
  const [eventStartDateChange, setEventStartDateChange] = useState(false);
  const [eventEndDateChange, setEventEndDateChange] = useState(false);
  const [adStartDate, setAdStartDate] = useState(null);
  const [datee, setDate] = useState(null);
  const [adEndDate, setAdEndDate] = useState(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);

  const [eventDate, setEventDate] = useState(null);

  var dayyy = new Date(datee);
  var nextDay = new Date(dayyy);
  nextDay.setDate(dayyy.getDate() + 1);

  var eventDatee = new Date(eventDate);
  var nextDayy = new Date(eventDatee);
  nextDayy.setDate(eventDatee.getDate() + 1);

  const today = new Date();

  const getData = async () => {
    let response = await getHospitalBranchById(ID);

    setResponseData(response);

    return response[0];
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(hospitalBranchFormValidation),
    mode: "onSubmit",
    defaultValues: async () => await getData(),
  });
  const {
    fields: testField,
    append: testAppend,
    remove: testRemove,
  } = useFieldArray({
    control,
    name: "testPapers",
  });
  const {
    fields: shiftField,
    append: shiftAppend,
    remove: shiftRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "shifts", // unique name for your Field Array
  });
  const {
    fields: docField,
    append: docAppend,
    remove: docRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "documents", // unique name for your Field Array
  });
  const {
    fields: contactField,
    append: contactAppend,
    remove: contactRemove,
  } = useFieldArray({
    control,
    name: "contactDetails",
  });

  const {
    fields: coordField,
    append: coordAppend,
    remove: coordRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "billingAddress.address.coords", // unique name for your Field Array
  });

  const {
    fields: CoordInField,
    remove: CoordInRemove,
    append: CoordInAppend,
  } = useFieldArray({
    control,
    name: `contact.nestedArray`,
  });

  const [candidateTypeID, setCandidateTypeID] = useState("");
  const [daysData, setDaysData] = useState([]);
  const { documentTypeDropDown } = useSelector((state) => state.hospital);
  const docTypeData = documentTypeDropDown?.documentTypeDropDownInfo;
  const docTypeOptions = [];

  docTypeData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    docTypeOptions.push(obj);
  });

  // function isIsoDate(str) {
  //   if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  //   const d = new Date(str);
  //   return d instanceof Date && !isNaN(d) && d.toISOString()===str; // valid date
  // }

  const onSubmit = (data) => {

    
    const shiftData = [];
    const testData = [];
    let documentsData = [];
    data?.documents.map((obj) => {
      let DocObj = {
        docType:
          typeof obj?.docType == "object"
            ? obj?.docType?.name == "undefined"
              ? ""
              : obj?.docType?._id
            : obj?.docType,
        files: obj?.files,
        uploadedAt: obj?.uploadedAt,
      };
      documentsData.push(DocObj);
    });
    data?.testPapers.map((obj) => {
      let testPaperObj = {
        for: typeof obj?.for == "object" ? "" : obj?.for,
        questions: obj?.questions,
      };
      testData.push(testPaperObj);
    });
    const coordData = [];
    const addressCoords = [];
    data?.shifts.map((obj) => {
      let shiftObj = {
        shiftType: obj.shiftType,
        profession: typeof obj.profession == "object" ? "" : obj.profession,
        startTime: obj?.startTime,
        endTime: obj?.endTime,
      };
      shiftData.push(shiftObj);
    });
    data?.billingAddress.address.coords.map((elem) => {
      if (typeof elem == "object") {
        addressCoords.push("");
      } else {
        addressCoords.push(elem);
      }
    });
    const contactDetailPayload = [];
    data?.contactDetails.map((ele) => {
      if (
        new Date(Date.parse(ele?.contactTimeStart)) ==
        new Date(ele?.contactTimeStart).toUTCString()
      )
        ele.contactTimeStart = null;
      const addressCoords = [];

      ele?.address?.coords.map((elem) => {
        if (typeof elem == "object") {
          addressCoords.push("");
        } else {
          addressCoords.push(elem);
        }
      });
      let obj = {
        address: {
          address: ele?.address?.address,
          city: ele?.address?.city,
          coords: addressCoords,
          country: ele?.address?.country,
          pin: ele?.address?.pin,
          state: ele?.address?.state,
        },
        email: ele?.email,
        phone: ele?.phone,
        contactDays: ele?.contactDays,
        // contactTimeStart:isIsoDate(ele?.contactTimeStart) ?(ele?.contactTimeStart):null,
        // contactTimeEnd:isIsoDate(ele?.contactTimeEnd) ?(ele?.contactTimeEnd):null
        contactTimeStart: ele?.contactTimeStart,
        contactTimeEnd: ele?.contactTimeEnd,
      };
      contactDetailPayload.push(obj);
    });

    // console.log("data>>", data)
    const payload = {
      jobLocation: {
        branchName: data?.name,
      },
      // jobLocation: {
      //   branchName: data?.jobLocation?.branchName,
      // },
      isDeleted: data?.isDeleted,
      organization: organizationID, //
      company: companyID, //
      brand: data?.brand, //
      subBrand: data?.subBrand, //
      isBillingEntity: data?.isBillingEntity, //
      billingAddress: {
        address: {
          coords: addressCoords,
          address: data.billingAddress.address.address,
          city: data.billingAddress.address.city,
          state: data.billingAddress.address.state,
          country: data.billingAddress.address.country,
          pin: data.billingAddress.address.pin,
        },
        gstNumber: data?.billingAddress?.gstNumber,
        isGstRegistered: data?.billingAddress?.isGstRegistered,
      },
      jobLocation: {
        branchName: data?.jobLocation?.branchName,
        hospitalName: data?.jobLocation.hospitalName,
        address: {
          address: data?.jobLocation.address.address,
          city: data?.jobLocation.address.city,
          state: data?.jobLocation.address.state,
          pin: data?.jobLocation.address.pin,
          country: data?.jobLocation.address.country,
          lat: data?.jobLocation.address.lat,
          long: data?.jobLocation.address.long,
        },
      },
      isJobApprovalRequired: data?.isJobApprovalRequired,
      isTestRequired: data?.isTestRequired,
      reverseChargesApplicable: data?.reverseChargesApplicable,
      // created_at: data?.created_at,
      // updated_at: data?.updated_at,
      contactDetails: contactDetailPayload,
      documents: documentsData,
      shifts: shiftData,
      testPapers: testData,
    };

    // console.log("payload>>>", payload)

    dispatch(UpdateHospital({ payload: payload, id: params?.branchName })).then(
      () => {
        navigate("/hospital/branch");
      }
    );
  };
  const handleReset = () => {
    reset();
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    setData(hospital_Id_Data?.hospitalIdInfo);
  }, [hospital_Id_Data]);

  useEffect(() => {
    dispatch(getCompanyList());
    dispatch(setSpecialisationsType());
    dispatch(setProfessionType());
    dispatch(setDocumentType());
    dispatch(setOrganizationsDropdown());
    dispatch(setUsersDropdown());
    dispatch(setCityList());
  }, []);

  const [updateCreated, setUpdateCreated] = useState(false);
  const [updateUpdated, setUpdateUpdated] = useState(false);
  const [datee1, setDatee1] = useState("");
  const [datee2, setDatee2] = useState("");
  const [startDate, setStartDate] = useState([]);

  useEffect(() => {
    const created = getValues("createdAt");
    const updated = getValues("updatedAt");
    const reverse = getValues("reverseChargesApplicable");
    if (created == "Invalid date") {
      setValue("createdAt", "");
    }
    if (updated == "Invalid date") {
      setValue("updatedAt", "");
    }

    if (created !== undefined && created !== "") {
      if (!updateCreated) {
        setValue("createdAt", Moment(created).format("DD/MM/YYYY"));
        setUpdateCreated(true);
      }
    }

    if (updated !== undefined && updated !== "") {
      if (!updateUpdated) {
        setValue("updatedAt", Moment(created).format("DD/MM/YYYY"));
        setUpdateUpdated(true);
      }
    }
  }, [
    getValues("createdAt"),
    getValues("updatedAt"),
    getValues("reverseChargesApplicable"),

    // getValues("contactDetails")
  ]);

  useEffect(() => {
    const data = getValues("contactDetails");

    data.map((elem, index) => {
      if (elem?.contactTimeStart !== null && elem?.contactTimeEnd !== null) {
        if (elem?.contactTimeStart?.ts) {
          let startDate = new Date(
            parseInt(elem?.contactTimeStart?.ts)
          ).toISOString();

          setValue(`contactDetails[${index}].contactTimeStart`, startDate);
        }
        if (elem?.contactTimeEnd?.ts) {
          let endDate = new Date(
            parseInt(elem?.contactTimeEnd?.ts)
          ).toISOString();
          setValue(`contactDetails[${index}].contactTimeEnd`, endDate);
        }
      } else {
        setValue(`contactDetails[${index}].contactTimeStart`, null);
        setValue(`contactDetails[${index}].contactTimeEnd`, null);
      }
    });
  }, [getValues("contactDetails")]);

  const formValues = getValues();

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
              
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
            
            </Grid>

            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                {data.length > 0 &&
                  data.map((elem) => {
                    return (
                      <>
                        <Grid container spacing={3}>
                          {/* Name Title */}

                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              // {...register("name")}
                              {...register("jobLocation.branchName")}
                              // value={elem?.jobLocation?.branchName}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="body">
                              ID:{elem?._id}
                            </Typography>
                          </Grid>

                          {/* is Deleted */}
                          <Grid item xs={12} md={12}>
                            <Grid item xs={2}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Controller
                                      defaultValue={false}
                                      name="isDeleted"
                                      control={control}
                                      render={({ field: props }) => (
                                        <Checkbox
                                          {...props}
                                          checked={props.value}
                                          onChange={(e) =>
                                            props.onChange(e.target.checked)
                                          }
                                        />
                                      )}
                                    />
                                  }
                                  label="Is Deleted"
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>

                          {/* company */}
                          {/* CreatedBy */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Company
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <SelectDropDownNew
                              options={organizationOptions}
                              getValues={getValues}
                              placeholder="Organization"
                              control={control}
                              name="organization.name"
                              defaultValue="none"
                              customOnChange={handleOrganizationChange}
                            />
                          </Grid>

                          {/* Corporate */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Corporate
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <SelectDropDownNew
                              options={companyOptions}
                              getValues={getValues}
                              placeholder="Company"
                              control={control}
                              name="company.name"
                              defaultValue="none"
                              customOnChange={handleCompanyChange}
                            />
                          </Grid>

                          {/*Brand */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Brand
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("brand")}
                            />
                          </Grid>

                          {/* Sub Brand */}

                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Sub Brand
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("subBrand")}
                            />
                          </Grid>

                          {/* Contact Details */}
                          <Grid item xs={12}>
                            <Paper sx={{ padding: "1rem" }}>
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 500 }}
                              >
                                Contact Details
                              </Typography>
                              <ul style={{ listStyleType: "none" }}>
                                {contactField.map((field, index) => (
                                  <li>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Address Address
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.[${index}].address.address`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Address City
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.[${index}].address.city`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Address State
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.[${index}].address.state`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Address Pin
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.[${index}].address.pin`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Address Country
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.[${index}].address.country`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Address Coords
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <NestedArray
                                          nestIndex={index}
                                          {...{ control, register }}
                                        />
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Email
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.${index}.email`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Phone
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `contactDetails.[${index}].phone`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Contact Days
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MultiSelectDropdown
                                          options={days}
                                          name={`contactDetails.[${index}].contactDays`}
                                          getValues={getValues}
                                          setValue={setValue}
                                          control={control}
                                          customOnChange={(e) => {
                                            setValue(
                                              `contactDetails.[${index}].contactDays`,
                                              e
                                            );
                                          }}
                                        />
                                        {/* <Select
                                          {...register(
                                            `contactDetails.[${index}].contactDays.[${index}]`
                                          )}
                                          defaultValue={`contactDetails.[${index}].contactDays.[${index}]`}
                                          labelId="mutiple-select-label1"
                                          multiple
                                          value={selectedDays}
                                          onChange={(event, index) =>
                                            handleChangeDays(event, index)
                                          }
                                          renderValue={(selectedDays) =>
                                            selectedDays.join(", ")
                                          }
                                          MenuProps={MenuProps}
                                          sx={{
                                            height: "3rem",
                                            width: "37rem",
                                          }}
                                        >
                                          {days.map((option) => (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              <ListItemIcon>
                                                <Checkbox
                                                  checked={
                                                    selectedDays.indexOf(
                                                      option
                                                    ) > -1
                                                  }
                                                />
                                              </ListItemIcon>
                                              <ListItemText primary={option} />
                                            </MenuItem>
                                          ))}
                                        </Select> */}
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Contact Start DateTime
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={9}>
                                        {adStartDateChange ? (
                                          <Controller
                                            name={`contactDetails.[${index}].contactTimeStart`}
                                            control={control}
                                            render={({ field }) => (
                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <DatePicker
                                                  defaultValue={null}
                                                  // minDate={today}
                                                  {...field}
                                                  inputFormat="DD/MM/YYYY"
                                                  // value={adStartDate}
                                                  {...register(
                                                    `contactDetails.[${index}].contactTimeStart`
                                                  )}
                                                  onChange={(newValue) => {
                                                    setDate(newValue?.$d);

                                                    setAdStartDate(newValue);
                                                    setValue(
                                                      `contactDetails.[${index}].contactTimeStart`,
                                                      Moment(
                                                        newValue?.$d
                                                      ).format(
                                                        "YYYY-MM-DDTHH:mm:ss[Z]"
                                                      )
                                                    );
                                                    setValue(
                                                      `contactDetails.[${index}].contactTimeEnd`,
                                                      null
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField {...params} />
                                                  )}
                                                />
                                              </LocalizationProvider>
                                            )}
                                          />
                                        ) : (
                                          <>
                                            <MDInput
                                              value={startDate[index]}
                                              disabled
                                            />

                                            <IconButton
                                              onClick={() => {
                                                setAdStartDateChange(true);
                                              }}
                                            >
                                              <Edit />
                                            </IconButton>
                                          </>
                                        )}
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Contact End DateTime
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={9}>
                                        {adEndDateChange ? (
                                          <Controller
                                            name={`contactDetails.[${index}].contactTimeEnd`}
                                            control={control}
                                            render={({ field }) => (
                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <DatePicker
                                                  defaultValue={null}
                                                  minDate={getValues(
                                                    `contactDetails.[${index}].contactTimeStart`
                                                  )}
                                                  {...register(
                                                    `contactDetails.[${index}].contactTimeEnd`
                                                  )}
                                                  {...field}
                                                  inputFormat="DD/MM/YYYY"
                                                  // value={adEndDate}
                                                  onChange={(newValue) => {
                                                    setAdEndDate(newValue);
                                                    setValue(
                                                      `contactDetails.[${index}].contactTimeEnd`,
                                                      Moment(
                                                        newValue?.$d
                                                      ).format(
                                                        "YYYY-MM-DDTHH:mm:ss[Z]"
                                                      )
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField {...params} />
                                                  )}
                                                />
                                              </LocalizationProvider>
                                            )}
                                          />
                                        ) : (
                                          <>
                                            <MDInput value={datee2} disabled />

                                            <IconButton
                                              onClick={() => {
                                                setAdEndDateChange(true);
                                              }}
                                            >
                                              <Edit />
                                            </IconButton>
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>

                                    <MDBox sx={{ textAlign: "right" }}>
                                      <MDButton
                                        variant="contained"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          contactRemove(index);
                                        }}
                                      >
                                        <Typography
                                          color="error"
                                          variant="body"
                                        >
                                          Remove
                                        </Typography>
                                      </MDButton>
                                    </MDBox>
                                    {/* </MDBox>  */}
                                  </li>
                                ))}
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    contactAppend({});
                                  }}
                                >
                                  + Add
                                </MDButton>
                              </ul>
                            </Paper>
                          </Grid>

                          {/* checkbox */}
                          <Grid item xs={12} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Controller
                                    defaultValue={false}
                                    name="isBillingEntity"
                                    control={control}
                                    render={({ field: props }) => (
                                      <Checkbox
                                        {...props}
                                        checked={props.value}
                                        {...register("isBillingEntity")}
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                      />
                                    )}
                                  />
                                }
                                label="is Billing Entity?"
                              />
                            </FormGroup>
                          </Grid>

                          {/* Billing Address Address Address */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address Address Address
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(`billingAddress.address.address`)}
                            />
                          </Grid>

                          {/* Billing Address Address City */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address Address City
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(`billingAddress.address.city`)}
                            />
                          </Grid>

                          {/* Billing Address Address state */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address Address State
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(`billingAddress.address.state`)}
                            />
                          </Grid>

                          {/* Billing Address Address pin */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address Address Pin
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(`billingAddress.address.pin`)}
                            />
                          </Grid>

                          {/* Billing Address Address Country */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address Address Country
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(`billingAddress.address.country`)}
                            />
                          </Grid>

                          {/* Billing Address Address Coords */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address Address Coords
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <ul style={{ listStyleType: "none" }}>
                              {coordField.map((field, index) => (
                                <li>
                                  <Grid container spacing={2}>
                                    <MDInput
                                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                      type="number"
                                      placeholder=""
                                      sx={{
                                        width: "100%",
                                        marginTop: "0.4rem",
                                      }}
                                      inputProps={{
                                        style: {
                                          fontSize: 15,
                                          fontWeight: "bold",
                                        },
                                      }}
                                      {...register(
                                        `billingAddress.address.coords[${index}]`
                                      )}
                                    />
                                  </Grid>

                                  <MDBox
                                    sx={{
                                      textAlign: "right",
                                      background: "transparent",
                                    }}
                                  >
                                    <MDButton
                                      variant="outlined"
                                      sx={{
                                        border: "none",
                                        height: "10px",
                                        width: "30px",
                                      }}
                                      onMouseHover={{ color: "black" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        coordRemove(index);
                                      }}
                                    >
                                      <Typography
                                        color="error"
                                        sx={{ fontSize: "12px" }}
                                        onHover={{ color: "black" }}
                                      >
                                        Remove
                                      </Typography>
                                    </MDButton>
                                  </MDBox>
                                  {/* </MDBox>  */}
                                </li>
                              ))}
                              <MDButton
                                variant="contained"
                                color="info"
                                onClick={(e) => {
                                  e.preventDefault();
                                  coordAppend({});
                                }}
                              >
                                + Add
                              </MDButton>
                            </ul>
                          </Grid>

                          {/* Billing Address gst number */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Billing Address GST Number
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register(`billingAddress.gstNumber`)}
                            />
                          </Grid>

                          {/* Billing Address is GST registered */}
                          <Grid item xs={12} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Controller
                                    defaultValue={false}
                                    name="billingAddress.isGstRegistered"
                                    control={control}
                                    render={({ field: props }) => (
                                      <Checkbox
                                        {...props}
                                        checked={props.value}
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                      />
                                    )}
                                  />
                                }
                                label="Billing Address is GST Registered?"
                              />
                            </FormGroup>
                          </Grid>

                          {/* Documents */}
                          <Grid item xs={12}>
                            <DynamicDocuments
                              control={control}
                              getValues={getValues}
                              setValue={setValue}
                              register={register}
                              selectOptions={docTypeOptions}
                            />
                            {/* <Paper sx={{ padding: "1rem" }}> */}
                            {/* <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 500 }}
                              >
                                Document
                              </Typography> */}

                            {/* <ul style={{ listStyleType: "none" }}>
                                {docField.map((field, index) => (
                                  <li>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Doc Type
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <SelectDropDownNew
                                          control={control}
                                          options={docTypeOptions}
                                          placeholder="Doc Type"
                                          getValues={getValues}
                                          {...register(
                                            `documents.[${index}].docType.name`
                                          )}
                                          name={`documents.[${index}].docType.name`}
                                          customOnChange={(e) => {
                                            docTypeOptions.map((elem) => {
                                              if (
                                                elem.value == e.target.value
                                              ) {
                                                setValue(
                                                  `documents.[${index}].docType`,
                                                  `${elem.key}`
                                                );
                                              }
                                            });
                                          }}
                                        />
                                       
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Uploaded At
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `documents.[${index}].uploadedAt`
                                          )}
                                        />
                                      </Grid>
                                    </Grid>

                                    <MDBox sx={{ textAlign: "right" }}>
                                      <MDButton
                                        variant="contained"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          docRemove(index);
                                        }}
                                      >
                                        <Typography
                                          color="error"
                                          variant="body"
                                        >
                                          Remove
                                        </Typography>
                                      </MDButton>
                                    </MDBox>
                                    {/* </MDBox>  
                                  </li>
                                ))}
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  onClick={(e) => {
                                  
                                    e.preventDefault();
                                    docAppend({});
                                  }}
                                >
                                  + Add
                                </MDButton>
                              </ul> */}
                            {/* </Paper> */}
                          </Grid>

                          {/* Job Location Hospital Name */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Job Location Hospital Name
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.hospitalName")}
                              // error={errors?.jobLocationHospitalName?.message}
                              // helperText={
                              //   errors?.jobLocationHospitalName?.message
                              // }
                            />
                          </Grid>

                          {/* Job Location Address Address */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Job Location Address Address
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.address")}
                            />
                          </Grid>

                          {/* Job Location Address City */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Job Location Address City
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <SelectDropDownNew
                              options={cityOptions}
                              name={`jobLocation.address.city`}
                              getValues={getValues}
                              control={control}
                              defaultValue="none"
                              setValue={setValue}
                              placeholder="Select"
                              customOnChange={(e) => {
                                
                                setValue(
                                  `jobLocation.address.city`,
                                  e.target.value
                                );
                              }}
                            />
                            {/* <MDInput
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.city")}
                            /> */}
                          </Grid>

                          {/* Job Location Address State*/}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Job Location Address State
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.state")}
                            />
                          </Grid>

                          {/* Job Location Address pin */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Job Location Address Pin
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.pin")}
                            />
                          </Grid>
                          {/* Job Location Address Country */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Job Location Address Country
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.country")}
                            />
                          </Grid>

                          {/* Latitude */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Latitude
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.lat")}
                            />
                          </Grid>
                          {/* Longitude */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Longitude
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                             onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("jobLocation.address.long")}
                            />
                          </Grid>

                          {/* job approval */}
                          <Grid item xs={12} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Controller
                                    defaultValue={false}
                                    name="isJobApprovalRequired"
                                    control={control}
                                    render={({ field: props }) => (
                                      <Checkbox
                                        {...props}
                                        checked={props.value}
                                        {...register("isJobApprovalRequired")}
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                      />
                                    )}
                                  />
                                }
                                label="Is Job Approval required"
                              />
                            </FormGroup>
                          </Grid>

                          {/* test required */}
                          <Grid item xs={12} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Controller
                                    defaultValue={false}
                                    name="isTestRequired"
                                    control={control}
                                    render={({ field: props }) => (
                                      <Checkbox
                                        {...props}
                                        checked={props.value}
                                        {...register("isTestRequired")}
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                      />
                                    )}
                                  />
                                }
                                label="Is Test Required"
                              />
                            </FormGroup>
                          </Grid>

                          {/* shifts */}

                          <Grid item xs={12}>
                            <Paper sx={{ padding: "1rem" }}>
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 500 }}
                              >
                                Shifts
                              </Typography>
                              <ul style={{ listStyleType: "none" }}>
                                {shiftField.map((field, index) => (
                                  <li>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Shift Type
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <SelectDropDownNew
                                          control={control}
                                          options={shiftOptions}
                                          placeholder="Shift"
                                          getValues={getValues}
                                          {...register(
                                            `shifts.[${index}].shiftType`
                                          )}
                                          customOnChange={(e) =>
                                            setValue(
                                              `shifts.[${index}].shiftType`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Profession
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <SelectDropDownNew
                                          options={professionOptions}
                                          getValues={getValues}
                                          placeholder="CandidateType"
                                          control={control}
                                          name="candidateType.name"
                                          defaultValue="none"
                                          {...register(
                                            `shifts.[${index}].profession.name`
                                          )}
                                          customOnChange={(e) =>
                                            professionOptions.map((elem) => {
                                              if (
                                                elem.value == e.target.value
                                              ) {
                                                setValue(
                                                  `shifts.[${index}].profession`,
                                                  elem.key
                                                );
                                              }
                                            })
                                          }
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          Start Time
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `shifts.[${index}].startTime`
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          End Time
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDInput
                                         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                          placeholder=""
                                          sx={{
                                            width: "100%",
                                            marginTop: "0.4rem",
                                          }}
                                          inputProps={{
                                            style: {
                                              fontSize: 15,
                                              fontWeight: "bold",
                                            },
                                          }}
                                          {...register(
                                            `shifts.[${index}].endTime`
                                          )}
                                        />
                                      </Grid>
                                    </Grid>

                                    <MDBox sx={{ textAlign: "right" }}>
                                      <MDButton
                                        variant="contained"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          shiftRemove(index);
                                        }}
                                      >
                                        <Typography
                                          color="error"
                                          variant="body"
                                        >
                                          Remove
                                        </Typography>
                                      </MDButton>
                                    </MDBox>
                                    {/* </MDBox>  */}
                                  </li>
                                ))}
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    shiftAppend({});
                                  }}
                                >
                                  + Add
                                </MDButton>
                              </ul>
                            </Paper>
                          </Grid>

                          {/* test papers */}

                          <Grid item xs={12}>
                            <Paper sx={{ padding: "1rem" }}>
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 500 }}
                              >
                                Test Papers
                              </Typography>
                              <ul style={{ listStyleType: "none" }}>
                                {testField.map((field, index) => (
                                  <li>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                          For
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <SelectDropDownNew
                                          options={specializationOptions}
                                          getValues={getValues}
                                          placeholder="Specialization"
                                          control={control}
                                          {...register(
                                            `testPapers.[${index}].for.name`
                                          )}
                                          // name="specialization.name"
                                          defaultValue="none"
                                          customOnChange={(e) =>
                                            specializationOptions.map((obj) => {
                                              if (obj.value == e.target.value)
                                                setValue(
                                                  `testPapers.[${index}].for`,
                                                  obj.key
                                                );
                                            })
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Paper sx={{ padding: "1rem" }}>
                                        <Typography variant="h6">
                                          Questions
                                        </Typography>
                                        <hr
                                          style={{
                                            marginBottom: "2rem",
                                            marginTop: "1rem",
                                          }}
                                        />

                                        <Questions
                                          setValue={setValue}
                                          getValues={getValues}
                                          nestIndex={index}
                                          {...{ control, register }}
                                        />
                                      </Paper>
                                    </Grid>

                                    <MDBox
                                      sx={{
                                        textAlign: "right",
                                        background: "transparent",
                                        width: "100%",
                                      }}
                                    >
                                      <MDButton
                                        variant="outlined"
                                        sx={{
                                          border: "none",
                                          height: "10px",
                                          width: "30px",
                                        }}
                                        onMouseHover={{ color: "black" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          testRemove(index);
                                        }}
                                      >
                                        <Typography
                                          color="error"
                                          sx={{ fontSize: "12px" }}
                                          onHover={{ color: "black" }}
                                        >
                                          Remove
                                        </Typography>
                                      </MDButton>
                                    </MDBox>
                                    {/* </MDBox>  */}
                                  </li>
                                ))}
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    testAppend({});
                                  }}
                                >
                                  + Add
                                </MDButton>
                              </ul>
                            </Paper>
                          </Grid>

                          {/* Reverse Charges Applicable */}

                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Reverse Charges Applicable
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <SelectDropDownNew
                              control={control}
                              options={selectedReversedOption}
                              getValues={getValues}
                              name="reverseChargesApplicable"
                              placeholder="Select"
                              customOnChange={(e) => {
                                setValue(
                                  "reverseChargesApplicable",
                                  e.target.value
                                );
                              }}
                            />
                          </Grid>

                          {/* createdAt */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Created_at
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                              disabled
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("createdAt")}
                            />
                          </Grid>

                          {/* updatedAt */}
                          <Grid item xs={4}>
                            <Typography
                              variant="subtitle1"
                              sx={{ width: "80%", marginTop: "0.8rem" }}
                            >
                              Updated_at
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <MDInput
                              disabled
                              placeholder=""
                              sx={{ width: "100%", marginTop: "0.4rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              {...register("updatedAt")}
                            />
                          </Grid>
                        </Grid>
                        <div style={{ marginTop: "2rem" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Save
                          </Button>
                        </div>
                      </>
                    );
                  })}
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default BranchUserForm;
