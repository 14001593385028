const state = {
  loading: false,
  success: false,
  error: "",

  hospital_branch_Data: {
    branchLoading: true,
    branchError: "",
    branchInfo: [],
  },
  hospital_data: {
    branchDataLoading: true,
    branchDataError: "",
    branchDataInfo: [],
  },
  hospital_Id_Data: {
    hospitalIdLoading: true,
    hospitalIdError: "",
    hospitalIdInfo: [],
  },
  hospital_company_Data: {
    companyLoading: true,
    companyError: "",
    companyInfo: [],
  },
  hospital_rates_Data: {
    ratesLoading: true,
    ratesError: "",
    ratesInfo: [],
  },
  hospital_department_Data: {
    departmentLoading: true,
    departmentError: "",
    departmentInfo: [],
  },
  documentTypeDropDown: {
    documentTypeDropDownLoading: true,
    documentTypeDropDownError: "",
    documentTypeDropDownInfo: [],
  },
  companies_DropDown_Data: {
    companiesDropDownLoading: true,
    companiesDropDownError: "",
    companiesDropDownInfo: [],
  },
  Company_Logo_Data: {
    company_logo_loading: true,
    company_logo_info: [],
    company_logo_error: "",
  },
};

export default state;
