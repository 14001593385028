import axiosInstance from "../axios";
import { toast } from "react-toastify";

//***************Permanent Services*******************//


//add permanent job
export const addNewJob = async (payload) => {
  try {
    // console.log("payload", payload)
    const response = await axiosInstance.post(`/permanentjob/create`, payload);
    // console.log("res>>>", response);
    // console.log("res>>>", response.data.msg);
    if(response.status === 201) {
      toast.success("Created Successfully",{
        position:"top-right"
      });
    }
    return response;
  } catch (e) {
  //   toast.warning("All required fields must be filled.", {
  //     position: "top-right",
  // });
    console.log("error", e.response.data)
    // if(e.response.data.msg === "type is required"){
    //   toast.error("type is required",{
    //     position:"top-right"
    //   })
    // }else if(e.response.data.msg === "min is required"){
    //   toast.error("min is required",{
    //     position:"top-right"
    //   })
    // }else if(e.response.data.msg === "max is required"){
    //   toast.error("max is required",{
    //     position:"top-right"
    //   })
    // }else if(e.response.data.msg === "currency is required"){
    //   toast.error("currency is required",{
    //     position:"top-right"
    //   })
    // }else{ 
    //   // toast.error("error type is required, min is required, max is required, currency is required",{
    //   //   position:"top-right"
    //   // })
    //   toast.error("fill department,qualification,experiance,specialization and profession",{
    //     position:"top-right"
    //   })
    // }
    return { error: e.response.data };
  }
};

export const getPermanentJob = async (id) => {
  
  try {
    
    let criteria;

    
      // criteria = {
      //   criteria: { isDeleted: false, _id: id },
      //   limit: 0,
      //   offset: 1,
      // };
    
    // const response = await axiosInstance.post(`/avalibility/list`, criteria);
    const response = await axiosInstance.post(`/permanentjob/list`);
    // console.log("res", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

//Update Permanent Job
export const updatePermanentJobService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/permanentjob/${payload.id}`,
      payload.payload
    );
    // console.log("fetched", response);
    // if(response.data.message === "Job updated successfully") {
    //   toast.success("Updated Successfully",{
    //     position:"top-right"
    //   });
    // }
   
    return response;
  } catch (e) {
    console.log("error", e)
    if(e.response.status === 400) {
      toast.error(e.response.data.msg,{
        position:"top-right"
      });
    }else if(e.response.status === 500){
      toast.success("Updated Successfully",{
            position:"top-right"
          });
    }
    return { error: e.response.data };
  }
};

export const getPermanentJobsByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/permanentjob/list`, criteria);
    // console.log("res", response);

    return response?.data?.docs;
  } catch (e) {
    return { error: e.response.data.message };
  }
};



//list permanent applications
export const getPermanentApplication = async (id) => {
  
  try {
    
    let criteria;

    
      // criteria = {
      //   criteria: { isDeleted: false, _id: id },
      //   limit: 0,
      //   offset: 1,
      // };
    const response = await axiosInstance.post(`/permanentapplication/list`);
    // console.log("res", response);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};


export const getPermanentApplicationByIdService = async (payload) => {
  try {
    let criteria = { criteria: { _id: payload } };
    const response = await axiosInstance.post(`/permanentapplication/list`, criteria);
    // console.log("res", response);

    return response?.data?.data;
  } catch (e) {
    return { error: e.response.data.message };
  }
};

//Update Permanent Application
export const updatePermanentApplicationService = async (payload) => {
  try {
    const response = await axiosInstance.patch(
      `/permanentapplication/${payload.id}`,
      payload.payload
    );
    console.log("fetched", response);
    if(response.data.status === true) {
      toast.success("Updated Successfully",{
        position:"top-right"
      });
    }
   
    return response;
  } catch (e) {
    console.log("updating error", e);
    return { error: e.response.data };
  }
};


// List User
export const getAvalibilityList = async (payload) => {
  // console.log(payload, "payload");
  try {
    //  let page = 1;
    //  let pageSize = 100;
    //  let skip = (page - 1) * pageSize;
    if (payload === undefined) payload = false;
    let criteria;

    if (payload) {
      criteria = {
        criteria: { isDeleted: false, jobId: payload.jobId, _id: payload._id },
        limit: payload[0],
        offset: payload[1],
        // limit: payload.pageSize,
        // offset: payload.currentPage,
      };
    } else {
      criteria = {
        criteria: { isDeleted: false },
        limit: payload[0],
        offset: payload[1],
      //   limit: payload.pageSize,
      // offset: payload.currentPage,
      };
    }
    const response = await axiosInstance.post(`/avalibility/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

export const getAvalibilityListId = async (id) => {
  
  try {
    
    let criteria;

    
      criteria = {
        criteria: { isDeleted: false, _id: id },
        limit: 0,
        offset: 1,
      };
    
    const response = await axiosInstance.post(`/avalibility/list`, criteria);
    return response;
  } catch (e) {
    return { error: e.response.data };
  }
};

// //Delete a User
// export const deleteUserService = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`/user/${id}`);
//     return response;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

// // Add User
// export const addNewUser = async (payload) => {
//   try {
//     const response = await axiosInstance.post(`/user`, payload);
//     return response;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

//Get User By Id

// export const getUserByIdService = async (payload) => {
//   try {
//     let criteria = { criteria: { _id: payload } };
//     const response = await axiosInstance.post(`/user/all`, criteria);
//     return response?.data?.data;
//   } catch (e) {
//     return { error: e.response.data.message };
//   }
// };

//Update User
// export const updateUserService = async (payload) => {
//   try {
//     const response = await axiosInstance.patch(
//       `/user/${payload.id}`,
//       payload.payload
//     );

//     return response;
//   } catch (e) {
//     return { error: e.response.data };
//   }
// };
