import { yupResolver } from "@hookform/resolvers/yup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Moment from "moment";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDButton from "components/MDButton";
import SelectDropDown from "examples/DropDown";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  setCityList,
  setCompanyType,
  setDepartmentType,
  setExperience,
  setLanguageType,
  setProfessionType,
  setProfessionalRegistrationType,
  setQualificationType,
  setRoleType,
  setSpecialisationsType,
} from "layouts/events/Redux/actionCreator";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserByIdService } from "services/userServices";
import { getPremiumUserByIdService } from "services/userServices";
import userDetailsValidationSchema from "../../../util/validations/userDetailsValidation";
import { setProfilePhoto, updateUser } from "../Redux/actionCreator";

import SelectDropDownNew from "examples/DropDownNew";
import MultiSelectDropdown from "examples/Multi Select Dropdown";
import {
  GetAdditionalTrainingById,
  getSoftwaresList,
  setOrganizationsDropdown,
} from "layouts/common/Redux/actionCreator";
import { setDocumentType } from "layouts/events/Redux/actionCreator";
import { getDepartmentsList } from "layouts/hospital/Redux/actionCreator";
import moment from "moment";
import NestedArray1 from "./NestedArray1";
import NestedArray2 from "./NestedArray2";
import NestedArray3 from "./NestedArray3";
import "./styles.css";

function PremiumUserJobCount() {
  const [dateFormat, setDateFormat] = useState();
  const [dob, setDob] = useState("");
  const ID = window.location.href.split("premiumuser")[1].split("/")[1];

  const [organisationID, setOrganisationID] = useState();
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [responseData, setResponseData] = useState([]);

  console.log("responseData>>", responseData);

  
  const getData = async () => {
    let response = await getPremiumUserByIdService(ID);

    // console.log("getData", response[0]);

    setResponseData(response[0]);
    return response[0];
  };

 
 

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema),
    mode: "onChange",
    defaultValues: async () => await getData(),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const temp = getValues();
  // console.log("All data", temp)

  //Finding USER Object


  const onSubmit = (data) => {
    // console.log("data>>>", data);
  

    let payload = {
     
   
    };
    // console.log("payload>>", payload);
    // dispatch(updateUser({ payload: payload, id: ID })).then(() => {
    //   navigate("/users");
    // });
  };

  const handleBack = () => {
    navigate(-1);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };


  const formValues = getValues();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={4}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  placeholder=""
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 25, fontWeight: "bold" },
                  }}
                  {...register("name.full")}
                />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%", marginBottom: "20px" }}>
                <Typography variant="subtitle1">ID: {ID}</Typography>
              </Grid>

              {/* Password */}
              <Grid item xs={3}>
                <Typography variant="subtitle1">Password</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("password")}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle1">Profile DOB</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  // value={dob}
                  // defaultValue={dob}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.dob")}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Currently Employed</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.currentlyEmployed")}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Profile Gender</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.gender")}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Profile Pan</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.pan")}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">Profile Aadhar</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.aadhar")}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1">
                  Profile Social Facebook
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.social.facebook")}
                />
              </Grid>


              <Grid item xs={3}>
                <Typography variant="subtitle1">Profile Covid</Typography>
              </Grid>
              <Grid item xs={9}>
                <MDInput
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  fullWidth
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  {...register("profile.covid")}
                />
              </Grid>

              {/* testing  */}
              {/* <div style={{ marginTop: "2rem" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  Save
                </Button>
              </div> */}
            </Grid>
          </form>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default PremiumUserJobCount;
