import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createOrganization,
  createDepartment,
  createdepartmentCompany,
  createhospitalBranch,
  // createhospitalCompany,
  createRate,
  deleteOrganization,
  deleteDepartment,
  deletedepartmentCompany,
  deletehospitalBranch,
  // deletehospitalCompany,
  deleteRate,
  getCompanyList,
  getDepartmentsList,
  GetHospitalBranchById,
  getRatesList,
  HospitalBranchList,
  setDocumentType,
  updateDepartment,
  updatedepartmentCompany,
  UpdateHospital,
  updateHospitalBranch,
  updatehospitalBranch,
  // hospitalCompany,
  updateRate,
  updateOrganization,
  //HOSPITAL COMPANY ACTIONS
  getHospitalCompanyList,
  deleteHospitalCompany,
  createHospitalCompany,
  updateHospitalCompany,
  setCompanies,
  //Hospital Corporate Actions
  updateCorporate,
  createCorporate,
  deleteCorporate,
  setCompanyLogo,
  uploadRateCard,
} from "./actionCreator";
import initialState from "./state";

export const hospitalSlice = createSlice({
  name: "hospital",
  initialState,
  extraReducers: (builder) => {
    //get Branch
    //GET DepartmentType
    builder.addCase(HospitalBranchList.pending, (state) => {});
    builder.addCase(HospitalBranchList.fulfilled, (state, action) => {
   
      state.hospital_branch_Data.branchLoading = false;
      state.hospital_branch_Data.branchInfo = action?.payload?.data?.docs;
     
    });
    builder.addCase(HospitalBranchList.rejected, (state, action) => {
    
    });
    //Create Branch
    builder.addCase(createhospitalBranch.pending, (state) => {});
    builder.addCase(createhospitalBranch.fulfilled, (state, action) => {
      state.hospital_data.branchDataLoading = false;
      state.hospital_data.branchDataInfo = action?.payload?.data?.docs;
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(createhospitalBranch.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    //Update Branch
    builder.addCase(updatehospitalBranch.pending, (state) => {});
    builder.addCase(updatehospitalBranch.fulfilled, (state, action) => {});
    builder.addCase(updatehospitalBranch.rejected, (state, action) => {});
    //Delete Branch
    //delete Additional

    builder.addCase(deletehospitalBranch.pending, (state) => {});
    builder.addCase(deletehospitalBranch.fulfilled, (state, action) => {
      
    });
    builder.addCase(deletehospitalBranch.rejected, (state, action) => {
     
    });

    //get additional by id
    builder.addCase(GetHospitalBranchById.pending, (state) => {});
    builder.addCase(GetHospitalBranchById.fulfilled, (state, action) => {
      
      state.hospital_Id_Data.hospitalIdLoading = false;
     

      state.hospital_Id_Data.hospitalIdInfo = action?.payload;
   
    });
    builder.addCase(GetHospitalBranchById.rejected, (state, action) => {
   
    });

    //edit hospital Branch
    builder.addCase(updateHospitalBranch.pending, (state) => {});
    builder.addCase(updateHospitalBranch.fulfilled, (state, action) => {
     
    });
    builder.addCase(updateHospitalBranch.rejected, (state, action) => {
     
    });

    //update hospital branch
    builder.addCase(UpdateHospital.pending, (state) => {});
    builder.addCase(UpdateHospital.fulfilled, (state, action) => {
       

     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(UpdateHospital.rejected, (state, action) => {
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

  
    //Document Type
    builder.addCase(setDocumentType.pending, (state) => {});
    builder.addCase(setDocumentType.fulfilled, (state, action) => {
      state.documentTypeDropDown.documentTypeDropDownInfo =
        action?.payload?.data?.docs;
    
    });
    builder.addCase(setDocumentType.rejected, (state, action) => {
      
    });
    //Create Department
    builder.addCase(createdepartmentCompany.pending, (state) => {});
    builder.addCase(createdepartmentCompany.fulfilled, (state, action) => {
      // toast.success("Created Successfully", {
      //   position: "top-right",
      // });
    });
    builder.addCase(createdepartmentCompany.rejected, (state, action) => {});
    //Update Department
    builder.addCase(updatedepartmentCompany.pending, (state) => {});
    builder.addCase(updatedepartmentCompany.fulfilled, (state, action) => {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updatedepartmentCompany.rejected, (state, action) => {
      toast.error("Error Occurred", {
        position: "top-right",
      });
    });
    //Delete Department
    builder.addCase(deletedepartmentCompany.pending, (state) => {});
    builder.addCase(deletedepartmentCompany.fulfilled, (state, action) => {
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(deletedepartmentCompany.rejected, (state, action) => {
      toast.error("Error Occurred", {
        position: "top-right",
      });
    });

    //Get Rates
    builder.addCase(getRatesList.pending, (state) => {
     
    });
    builder.addCase(getRatesList.fulfilled, (state, action) => {
      state.hospital_rates_Data.ratesLoading = false;
      
      state.hospital_rates_Data.ratesInfo = action?.payload?.data?.docs;
    
    });
    builder.addCase(getRatesList.rejected, (state, action) => {
     
    });
    //Create Rate
    builder.addCase(createRate.pending, (state) => {});
    builder.addCase(createRate.fulfilled, (state, action) => {});
    
    builder.addCase(createRate.rejected, (state, action) => {
      
    });
    //Update Rate
    builder.addCase(updateRate.pending, (state) => {});
    builder.addCase(updateRate.fulfilled, (state, action) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateRate.rejected, (state, action) => {
    
      toast.error("Error in Updating", {
        position: "top-right",
      });
    });
    //Delete Rate
    builder.addCase(deleteRate.pending, (state) => {});
    builder.addCase(deleteRate.fulfilled, (state, action) => {
      
    });
    builder.addCase(deleteRate.rejected, (state, action) => {
     
    });

    //Get Company
    builder.addCase(getCompanyList.pending, (state) => {
    
    });
    builder.addCase(getCompanyList.fulfilled, (state, action) => {
      state.hospital_company_Data.companyLoading = false;
      state.hospital_company_Data.companyInfo = action?.payload?.data?.docs;
    
    });
    builder.addCase(getCompanyList.rejected, (state, action) => {
   
    });

    //Create Company
    builder.addCase(createOrganization.pending, (state) => {});
    builder.addCase(createOrganization.fulfilled, (state, action) => {});
   
    builder.addCase(createOrganization.rejected, (state, action) => {
      
    });
    
    //Delete Company
    builder.addCase(deleteOrganization.pending, (state) => {});
    builder.addCase(deleteOrganization.fulfilled, (state, action) => {
    
    });
    builder.addCase(deleteOrganization.rejected, (state, action) => {
   
    });
    //Get Departments
    builder.addCase(getDepartmentsList.pending, (state) => {
      
    });
    builder.addCase(getDepartmentsList.fulfilled, (state, action) => {
      state.hospital_department_Data.departmentLoading = false;
    
      state.hospital_department_Data.departmentInfo =
        action?.payload?.data?.docs;
      
    });
    builder.addCase(getDepartmentsList.rejected, (state, action) => {
    
    });
    //Create Deparmtent
    builder.addCase(createDepartment.pending, (state) => {});
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });

    builder.addCase(createDepartment.rejected, (state, action) => {
      toast.error("Error Occured", {
        position: "top-right",
      });
      
    });
    //Update Deparmtent
    builder.addCase(updateDepartment.pending, (state) => {});
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateDepartment.rejected, (state, action) => {
     
      toast.error("Error in Updating", {
        position: "top-right",
      });
    });
    //Delete Department
    builder.addCase(deleteDepartment.pending, (state) => {});
    builder.addCase(deleteDepartment.fulfilled, (state, action) => {
      
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(deleteDepartment.rejected, (state, action) => {
      
      toast.error("Error Occurred", {
        position: "top-right",
      });
    });

    //Create Corporate
    builder.addCase(createCorporate.pending, (state) => {});
    builder.addCase(createCorporate.fulfilled, (state, action) => {
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });

    builder.addCase(createCorporate.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });
    //Update Corporate
    builder.addCase(updateCorporate.pending, (state) => {});
    builder.addCase(updateCorporate.fulfilled, (state, action) => {
     
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(updateCorporate.rejected, (state, action) => {
    
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //Delete Corporate
    builder.addCase(deleteCorporate.pending, (state) => {});
    builder.addCase(deleteCorporate.fulfilled, (state, action) => {
      
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(deleteCorporate.rejected, (state, action) => {
    
    });

    //Hospital Company Reducers

    //GEt Hospital Company
    builder.addCase(getHospitalCompanyList.pending, (state) => {});
    builder.addCase(getHospitalCompanyList.fulfilled, (state, action) => {
     
      state.hospital_company_Data.companyLoading = false;
      state.hospital_company_Data.companyInfo = action?.payload?.data?.docs;
      
    });
    builder.addCase(getHospitalCompanyList.rejected, (state, action) => {
      
    });

    //Delete Hospital Company
    builder.addCase(deleteHospitalCompany.pending, (state) => {});
    builder.addCase(deleteHospitalCompany.fulfilled, (state, action) => {
     
      toast.success("Deleted Successfully", {
        position: "top-right",
      });
    });
    builder.addCase(deleteHospitalCompany.rejected, (state, action) => {
   
    });

    //Create Hospital Company
    builder.addCase(createHospitalCompany.pending, (state) => {});
    builder.addCase(createHospitalCompany.fulfilled, (state, action) => {
      toast.success("Created Successfully", {
        position: "top-right",
      });
    });

    builder.addCase(createHospitalCompany.rejected, (state, action) => {
     
      toast.error(action?.payload?.msg, {
        position: "top-right",
      });
    });

    //UPDATE Hospital Company
    builder.addCase(updateHospitalCompany.pending, (state) => {});
    builder.addCase(updateHospitalCompany.fulfilled, (state, action) => {
      toast.success("Updated Successfully", {
        position: "top-right",
      });
    });

    builder.addCase(updateHospitalCompany.rejected, (state, action) => {
     
      toast.error("Error in Updating", {
        position: "top-right",
      });
    });

    //photo upload
    builder.addCase(setCompanyLogo.pending, (state, action) => {
    
      const loadingToast = toast.loading("Image Uploading", {
        position: "top-right",
      });
      //Dismissing toast after 3 seconds
      setTimeout(() => {
        toast.dismiss(loadingToast);
      }, 3000);
    });
    builder.addCase(setCompanyLogo.rejected, (state, action) => {
      toast.error("Error Upoading Image", {
        position: "top-right",
      });
    });
    builder.addCase(setCompanyLogo.fulfilled, (state, action) => {
    
      state.Company_Logo_Data.company_logo_info = action?.payload?.data?.data;
      if (action?.payload?.status == 200) {
        toast.success("Image Uploaded", {
          position: "top-right",
        });
      }
    });

    //Set  Companies
    builder.addCase(setCompanies.pending, (state) => {});
    builder.addCase(setCompanies.fulfilled, (state, action) => {
      
      state.companies_DropDown_Data.companiesDropDownLoading = false;
      state.companies_DropDown_Data.companiesDropDownInfo =
        action?.payload?.data?.docs;
    });
    builder.addCase(setCompanies.rejected, (state, action) => {
      
    });

    //Upload Files
    //Set  Companies
    builder.addCase(uploadRateCard.pending, (state) => {});
    builder.addCase(uploadRateCard.fulfilled, (state, action) => {
    
      toast.success("Rate card uploaded successfully", {
        position: "top-right",
      });
    });
    builder.addCase(uploadRateCard.rejected, (state, action) => {
      
      toast.error(action?.payload, {
        position: "top-right",
      });
    });

    
  },
});

export default hospitalSlice.reducer;
