const state = {
  loading: false,
  success: false,
  error: "",
  job_Application_Data: {
    job_Application_Data_loading: true,
    job_Application_Data_error: "",
    job_Application_Data_info: [],
    job_Application_Data_status: "",
    total: 0,
  },
  application_Filtered_Data: {
    application_Filtered_Data_loading: true,
    application_Filtered_Data_error: "",
    application_Filtered_Data_info: [],
    // total: 0,
  },
  job_All_Application_Data: {
    job_All_Application_Data_loading: true,
    job_All_Application_Data_error: "",
    job_All_Application_Data_info: [],
    job_All_Application_Data_status: "",
    total: 0,
  },
  job_Data: {
    job_Data_loading: true,
    job_Data_error: "",
    job_Data_info: [],
    total: 0,
  },

  job_All_Data: {
    job_All_Data_loading: true,
    job_All_Data_error: "",
    job_All_Data_info: [],
    total: 0,
  },
  job_Filtered_Data: {
    job_Filtered_Data_loading: true,
    job_Filtered_Data_error: "",
    job_Filtered_Data_info: [],
    // total: 0,
  },
  currentPage: 0,
  pageSize: 10,
  searchKey: "",
  searchKeyApp: "",
  candidateType: "",
  branch: "",
  specialization: "",
  filterAll: {
    code: "",
    state: "",
    HospitalCity: "",
    HospitalBranch: "",
    DepartmentName: "",
    CandidateType: "",
    JobCode: "",
    JobStatus: "",
    ProfName: "",
    HospitalBranch: "",
    OrganizationName: "",
    state: ""
  },
 
  startDate: "",
  endDate: "",
  // permanent_Job_Data: {
  //   permanent_Job_Data_loading: true,
  //   permanent_Job_Data_error: "",
  //   permanent_Job_Data_info: [],
  // },
};

export default state;
