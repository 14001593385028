import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MDBox from "../../../components/MDBox/index";
import MDInput from "components/MDInput";
import Moment from 'moment';
import SelectDropDown from "./../../../examples/DropDown/index";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import adminPanelValidation, {
  AriFormValidation,
} from "../../../util/validations/adminPanelValidation";
import {
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Link
} from "@mui/material";
import { getSoftwareByIdService } from "shared/services";
import { setUsersDropdown, updateSoftware } from "../Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SoftwareForm = () => {
  let professionId = useParams();
  // const ValidationSchema=yup.object().shape({
  //   ari: yup
  //   .string()
  //   .required("Ari is Required")
  //   .matches(/^[aA-zZ\s]+$/, "Invalid Name")

  // })

  //Fetching User Dropdown Details
  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;
  
  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  const ID = window.location.href.split("softwares")[1].split("/")[1];
  const [responseData, setResponseData] = useState([]);

  const getData = async () => {
   
    let response = await getSoftwareByIdService(ID);
    
    setResponseData(response);
    
    return response[0];
  };

  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: () => getData() });
  const navigate = useNavigate();
  const onSubmit = (data) => {
  
    const payload = {
      name: data.name,
      isDeleted: data.isDeleted,
    
    };
    dispatch(updateSoftware({ payload: payload, id: ID })).then(() => {
      navigate("/common/softwares");
    });
  };
  const handleReset = () => {
    reset();
  };
  const dispatch = useDispatch();

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

    
  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });

   
  };

  useEffect(() => {
    dispatch(setUsersDropdown());
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  const formValues = getValues();
 


const[updateCreated,setUpdateCreated]=useState(false);
const[updateUpdated,setUpdateUpdated]=useState(false);
  useEffect(()=>{
    const created=getValues("createdAt");
    const updated=getValues("updatedAt");
    if(created=="Invalid date")
    {
      setValue("createdAt","");
    }
    if(updated=="Invalid date")
    {
      setValue("updatedAt","");
    }
    if(created!==undefined && created!=="")
    {
     if(!updateCreated)
     {
       // create=Moment(created).format("DD/MM/YYYY")
        setValue("createdAt",Moment(created).format("DD/MM/YYYY"));
       setUpdateCreated(true);
     }
    }
  


    if(updated!==undefined && updated!=="")
    {
     if(!updateUpdated)
     {
       setValue("updatedAt",Moment(created).format("DD/MM/YYYY"));
       setUpdateUpdated(true);
     }
    }
  



  },[getValues("createdAt"),getValues("updatedAt")])

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {formValues._id ? (
          <MDBox margin={2}>
            <Grid item xs={12}>
             
                <div style={{ display: "flex",width:"70px" }}>
                <Link onClick={handleBack}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackIos fontSize="small" />
                    back
                  </div>
                  </Link>
                </div>
              
            </Grid>
            {/* <Divider /> */}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Id */}
                  <Grid item xs={8}>
                    <MDInput
                     onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("name")}
                    />
                  </Grid>
                  {/* ID */}
                  <Grid item xs={12}>
                    <Typography variant="body">ID:{ID}</Typography>
                  </Grid>

                  {/* Checkbox */}
                  {/* is Deleted */}
                  <Grid item xs={12} md={12}>
                    <Grid item xs={2}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              defaultValue={false}
                              name="isDeleted"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Is Deleted"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/* Icon Cdn Url */}

                  {/* CreatedAt */}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("createdAt")}
                    />
                  </Grid>

                  {/* Updated At */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated At
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      placeholder=""
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedAt")}
                    />
                  </Grid>

                  {/* CreatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Created By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      {...register("createdBy")}
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                    />
                  </Grid>

                  {/* updatedBy */}
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ width: "80%", marginTop: "0.8rem" }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <MDInput
                      disabled
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                      inputProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                      {...register("updatedBy")}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: "2rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    {" "}
                    Save
                  </Button>

                  {/* <Button
                    variant="outlined"
                    sx={{ color: "#111", marginLeft: "1rem" }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </form>
            </DialogContent>
          </MDBox>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "24%",
              marginTop: "20%",
            }}
          >
            <CircularProgress disableShrink />
          </div>
        )}
      </DashboardLayout>
    </>
  );
};
export default SoftwareForm;
