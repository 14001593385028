import {
  Button,
  Card,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormLabel from "@mui/material/FormLabel";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import Compressor from "compressorjs";

import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SelectDropDown from "examples/DropDown";
import UploadFile from "react-material-file-upload";
import { useDispatch, useSelector } from "react-redux";
import {
  createEvent,
  setCityIDPayload,
  setCityList,
  setCompanyIDPayload,
  setCompanyType,
  setExperience,
  setExperienceIDPayload,
  setPhoto,
  setProfessionIDPayload,
  setProfessionType,
  setQualificationIDPayload,
  setQualificationType,
  setSpecialisationsType,
  setSpecializationIDPayload,
  updateEvent,
  setPremiumIDPayload,
} from "./Redux/actionCreator";
import MultiSelectDropdown from "examples/Multi Select Dropdown";
import { getEventsByIdService } from "services/eventServices";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { Link } from "@mui/material";
import { toast } from "react-toastify";
import SelectDropDownNew from "./../../examples/DropDownNew/index";

const EventCreation = () => {
  const url = window.location.pathname;

  const ID = window?.location?.pathname?.split("update")[1]?.split("/")[1];

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    getValues,
    control,
    handleSubmit,
  } = useForm();

  const dispatch = useDispatch();

  const {
    City_List_Data,
    Company_Type_Data,
    Experience_Data,
    Profession_Type_Data,
    Qualification_Type_Data,
    Specialization_Type_Data,
    Event_Details_Data,
  } = useSelector((state) => state.events);

  const [adStartDate, setAdStartDate] = useState(null);
  const [datee, setDate] = useState(null);
  const [adEndDate, setAdEndDate] = useState(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [platform, setPlatform] = useState("Mobile App");
  const [eventDate, setEventDate] = useState(null);
  const fileTypes = ["JPG", "PNG"];
  const [file, setFile] = useState(null);

  const [isPremiumValue, setIsPremiumValue] = useState("");

  const [specializationDefaultValue, setSpecializationDefaultValue] =
    useState("");
  const today = new Date();

  var dayyy = new Date(datee);
  var nextDay = new Date(dayyy);
  nextDay.setDate(dayyy.getDate() + 1);

  var eventDatee = new Date(eventDate);
  var nextDayy = new Date(eventDatee);
  nextDayy.setDate(eventDatee.getDate() + 1);

  const selectIsPremiumOptions = [
    {
      key: "false",
      value: "false",
    },
    {
      key: "true",
      value: "true",
    },
  ];

  const cityData = City_List_Data?.cityListInfo;
  const employerData = Company_Type_Data?.companyTypeInfo;
  const experienceData = Experience_Data?.experienceInfo;
  const professionData = Profession_Type_Data?.professionInfo;
  const qualificationsData = Qualification_Type_Data?.qualificationInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;
  const imageInfo = Event_Details_Data?.imageDetails;

  const cityOptions = [];
  const employerTypeOptions = [];
  const experienceOptions = [];
  const professionOptions = [];
  const qualificationOptions = [];
  const specializationOptions = [];

  cityData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    cityOptions.push(obj);
  });
  employerData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    employerTypeOptions.push(obj);
  });

  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  qualificationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    qualificationOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });

  // date formatter function
  const convert = (str) => {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [day, month, date.getFullYear()].join("-");
  };

  const handleRadioChange = (e) => {
    setPlatform(e.target.value);
  };

  //Profession Change
  var cityIDPayload = [];
  var experienceIDPayload = [];
  var qualificationIDPayload = [];
  var specializationIDPayload = [];
  var employerIDPayload = [];
  var premiumIDPayload = [];

  const professionIDPayloadSelector =
    Profession_Type_Data?.professionPayloadIDOptions;

  const cityIDPayloadSelector = City_List_Data?.cityPayloadIDOptions;

  const qulaificationIDPayloadSelector =
    Qualification_Type_Data?.qualificationPayloadIDOptions;
  const specializationIDPayloadSelector =
    Specialization_Type_Data?.specializationPayloadIDOptions;
  const experienceIDPayloadSelector =
    Experience_Data?.experiencePayloadIDOptions;

  const companyTypeIDPayloadSelector =
    Company_Type_Data?.companyTypePayloadIDOptions;

  //MULTI SELECT PROFESSION HANDLE SELECT
  const handleProfessionNewChange = (values) => {
    //Searching for the name in the professions array to find the ID
    let professionIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        professionOptions?.map((obj) => {
          if (obj.value == value) {
            professionIDPayload.push(obj.key);
            let data = {
              criteria: {
                profession: professionIDPayload,
                isDeleted: false,
              },
            };
            dispatch(setProfessionIDPayload(professionIDPayload));
            dispatch(setQualificationType(data));
            dispatch(setSpecialisationsType(data));
          }
        });
      });
    }
  };

  //MULTI SELECT City Mobile HANDLE SELECT
  const handleCityNewMobileChange = (values) => {
    //Searching for the name in the City array to find the ID
    cityIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        cityOptions?.map((obj) => {
          if (obj.value == value) {
            cityIDPayload.push(obj.key);

            dispatch(setCityIDPayload(cityIDPayload));
          }
        });
      });
    }
  };

  //MULTI SELECT City HANDLE SELECT
  const handleCityNewChange = (values) => {
    //Searching for the name in the City array to find the ID
    cityIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        cityOptions?.map((obj) => {
          if (obj.value == value) {
            cityIDPayload.push(obj.key);

            dispatch(setCityIDPayload(cityIDPayload));
          }
        });
      });
    }
  };

  const handlePremiumChange = (values) => {
    //Searching for the name in the City array to find the ID
    premiumIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        selectIsPremiumOptions?.map((obj) => {
          if (obj.value == value) {
            premiumIDPayload.push(obj.key);

            dispatch(setPremiumIDPayload(premiumIDPayload));
          }
        });
      });
    }
  };

  //MULTI SELECT Experience HANDLE SELECT
  const handleExperienceNewChange = (values) => {
    //Searching for the name in the professions array to find the ID
    experienceIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        experienceOptions?.map((obj) => {
          if (obj.value == value) {
            experienceIDPayload.push(obj.key);
            dispatch(setExperienceIDPayload(experienceIDPayload));
          }
        });
      });
    }
  };

  //MULTI SELECT Qualification HANDLE SELECT
  const handleQualificationNewChange = (values) => {
    //Searching for the name in the qualification array to find the ID
    qualificationIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        qualificationOptions?.map((obj) => {
          if (obj.value == value) {
            qualificationIDPayload.push(obj.key);
            dispatch(setQualificationIDPayload(qualificationIDPayload));
          }
        });
      });
    }
  };

  //MULTI SELECT Specialization HANDLE SELECT
  const handleSpecializationNewChange = (values) => {
    //Searching for the name in the specialization array to find the ID
    specializationIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        specializationOptions?.map((obj) => {
          if (obj.value == value) {
            specializationIDPayload.push(obj.key);
            dispatch(setSpecializationIDPayload(specializationIDPayload));
          }
        });
      });
    }
  };

  //MULTI SELECT Employer HANDLE SELECT
  const handleEmployerNewChange = (values) => {
    //Searching for the name in the Employer array to find the ID
    employerIDPayload = [];
    let selectedValues = values;

    if (selectedValues) {
      selectedValues?.map((value) => {
        employerTypeOptions?.map((obj) => {
          if (obj.value == value) {
            employerIDPayload.push(obj.key);

            dispatch(setCompanyIDPayload(employerIDPayload));
          }
        });
      });
    }
  };

  const navigate = useNavigate();

  const onSubmit = (data) => {
    // console.log(
    //   data,
    //   cityIDPayloadSelector,
    //   "data",
    //   professionIDPayloadSelector,
    //   imageInfo[0]
    // );

    // console.log("data>>",data)

    let isPremiumValue;
    if (data?.isPremium === "true") {
      isPremiumValue = true;
    } else if (data?.isPremium === "false") {
      isPremiumValue = false;
    } else {
      isPremiumValue = false; // Default value if nothing is selected
    }

    let payload = {
      files: [imageInfo[0]],
      eventName: data?.eventName,
      adStartDate: data?.adStartDate,
      adEndDate: data?.adEndDate,
      eventStartDate: data?.eventStartDate,
      eventEndDate: data?.eventEndDate,
      hyperLink: data?.hyperLink,
      adTarget: data?.adTarget,
      isPremium: isPremiumValue,
      // isPremium: Boolean(data?.isPremium),
      // isPremium: data?.isPremium,
      professionalType: professionIDPayloadSelector,
      workCity: cityIDPayloadSelector,
      qualification: qulaificationIDPayloadSelector,
      companyType: companyTypeIDPayloadSelector,
      numExperience: experienceIDPayloadSelector,
      specialization: specializationIDPayloadSelector,
      // companyType: data?.companyType,
    };
    // console.log("payload1>>", payload);
    if (
      //Mobile App Mandatory Fields
      (payload?.files[0]?.fileName &&
        payload?.adTarget == "Mobile App" &&
        payload?.adStartDate &&
        payload?.adEndDate &&
        payload?.professionalType.length > 0 &&
        payload?.workCity.length > 0) ||
      (payload?.adTarget == "Web App" &&
        payload?.files[0]?.fileName &&
        payload?.adStartDate &&
        payload?.adEndDate &&
        payload?.workCity.length > 0 &&
        payload?.companyType.length > 0) ||
      (payload?.files[0]?.fileName &&
        payload?.adTarget == "Both" &&
        payload?.adStartDate &&
        payload?.adEndDate &&
        payload?.professionalType.length > 0 &&
        payload?.companyType.length > 0 &&
        payload?.workCity.length > 0)
    ) {
      //Dispatch Create  Action
      console.log(payload, "payload");
      dispatch(createEvent(payload)).then((res) => {
        if (!res?.error) {
          dispatch(setProfessionIDPayload([]));
          dispatch(setCompanyIDPayload([]));
          dispatch(setCityIDPayload([]));
          dispatch(setPremiumIDPayload([]));
          navigate("/eventDetails");
        }
        //Clearing Values
        setValue("professionalType", []);
        setValue("qualification", []);
        setValue("workCity", []);
        setValue("numExperience", []);
        setValue("specialization", []);
        setValue("companyType", []);
        setValue("hyperLink", "");
        setAdEndDate(null);
        setAdStartDate(null);
        setEventStartDate(null);
        setEventEndDate(null);
        setValue("adStartDate", "");
        setValue("adEndDate", "");
        setValue("eventStartDate", "");
        setValue("eventEndDate", "");
        setValue("eventName", "");
      });
    } else {
      toast.warning("Fill All the Mandatory Fields", {
        position: "top-right",
      });
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   setAdEndDate(null);
  // }, [adStartDate]);
  // useEffect(() => {
  //   setEventEndDate(null);
  // }, [eventDate]);

  useEffect(async () => {
    dispatch(setCityList());
    dispatch(setCompanyType());
    dispatch(setExperience());
    dispatch(setProfessionType());
    setValue("adTarget", "Mobile App");
  }, []);

  // if (file !== null) {
  //   fileToBase64(file[0], (_, res) => {
  //     dispatch(setPhoto(res));
  //   });
  // }

  useEffect(() => {
    console.log(file);
    if (file != null) {
      new Compressor(file[0], {
        success: async (compressedResult) => {
          dispatch(setPhoto(compressedResult));
        },
      });
    }
  }, [file]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <div style={{ padding: "5px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox p={2}>
                <Typography>
                  Upload
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <Grid container spacing={1}>
                  {/* IMAGE UPLOADER */}

                  {imageInfo.length > 0 && file != null ? (
                    <>
                      <Grid item xs={12}>
                        <Card
                          raised
                          sx={{
                            maxWidth: 500,
                            maxHeight: 500,
                            width: 400,
                            height: 400,
                            marginLeft: "20%",
                            marginRight: "50%",
                            padding: "0.1em",
                          }}
                        >
                          <CancelIcon
                            sx={{
                              position: "relative",
                              // top: "1.8rem",
                              left: "90%",
                              color: "black",
                              cursor: "pointer",
                              marginBottom: "4px",
                              marginTop: "4px",
                            }}
                            onClick={() => {
                              setFile(null);
                            }}
                          />{" "}
                          <CardMedia
                            component="img"
                            image={imageInfo[0]?.url}
                            height="300px"
                            sx={{ objectFit: "contain" }}
                          />{" "}
                        </Card>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} md={12}>
                      {/* Uploader */}
                      <UploadFile
                        // name={file[0]?.name}
                        value={file}
                        maxSize={2621440}
                        accept=".png, .jpg, .jpeg, .webp"
                        onChange={setFile}
                        sx={{ color: "#111", margin: "2rem" }}
                        title="Upload Photo or Drag and drop photos "
                        buttonText="Select Photo"
                        buttonProps={{
                          styles: {
                            fontSize: "10rem",
                          },
                        }}
                        typographyProps={{ fontSize: "1.5rem" }}
                        // {...register("files")}
                      />
                    </Grid>
                  )}
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ marginBottom: "0.5rem" }}>
                      Event Name
                    </Typography>
                    <MDInput
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      sx={{ width: "100%" }}
                      {...register("eventName")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {/* <Paper elevation={1}> */}
                    <Typography sx={{ marginBottom: "0.5rem" }}>
                      {" "}
                      Hyperlink
                    </Typography>
                    <MDInput
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <InsertLinkIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...register("hyperLink")}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            fontWeight="medium"
                            color="text"
                          >
                            Ad Run Start Date
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                        </Grid>

                        <Controller
                          name={"adStartDate"}
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                minDate={today}
                                {...field}
                                inputFormat="DD/MM/YYYY"
                                value={adStartDate}
                                {...register("adStartDate")}
                                onChange={(newValue) => {
                                  setDate(newValue?.$d);

                                  setAdStartDate(newValue);
                                  setValue("adStartDate", convert(newValue));
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            fontWeight="medium"
                            color="text"
                          >
                            Ad Run End Date
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                        </Grid>

                        <Controller
                          name={"adEndDate"}
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                minDate={adStartDate}
                                disabled={adStartDate ? false : true}
                                {...field}
                                inputFormat="DD/MM/YYYY"
                                value={adEndDate}
                                onChange={(newValue) => {
                                  setAdEndDate(newValue);

                                  setValue("adEndDate", convert(newValue));
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            fontWeight="medium"
                            color="text"
                          >
                            Event Run Start Date
                          </Typography>
                        </Grid>

                        <Controller
                          name={"eventStartDate"}
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                minDate={today}
                                {...field}
                                inputFormat="DD/MM/YYYY"
                                value={eventStartDate}
                                onChange={(newValue) => {
                                  setEventDate(newValue?.$d);
                                  setEventStartDate(newValue);
                                  setValue("eventStartDate", convert(newValue));
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            fontWeight="medium"
                            color="text"
                          >
                            Event Run End Date
                          </Typography>
                        </Grid>

                        <Controller
                          name={"eventEndDate"}
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                minDate={eventStartDate}
                                disabled={eventStartDate ? false : true}
                                {...field}
                                inputFormat="DD/MM/YYYY"
                                value={eventEndDate}
                                onChange={(newValue) => {
                                  setEventEndDate(newValue);
                                  setValue("eventEndDate", convert(newValue));
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </LocalizationProvider>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel style={{ color: "#344767" }}>
                        On which platform do you want to run the ad?
                      </FormLabel>

                      <Controller
                        // rules={{ required: true }}
                        control={control}
                        name="adTarget"
                        render={({ field }) => {
                          return (
                            <RadioGroup
                              {...field}
                              row
                              display="flex"
                              justifyContent="space-evenly"
                            >
                              <FormControlLabel
                                value="Mobile App"
                                control={<Radio />}
                                label="Mobile App"
                                checked={platform === "Mobile App"}
                                onClick={(e) =>
                                  setValue("adTarget", "Mobile App")
                                }
                                onChange={handleRadioChange}
                              />
                              <FormControlLabel
                                value="Web App"
                                control={<Radio />}
                                label="Web App"
                                checked={platform === "Web App"}
                                onChange={handleRadioChange}
                                onClick={(e) => setValue("adTarget", "Web App")}
                              />
                              <FormControlLabel
                                value="Both"
                                control={<Radio name="adTarget.Both" />}
                                label="Both"
                                onClick={(e) => setValue("adTarget", "Both")}
                                checked={platform === "Both"}
                                onChange={handleRadioChange}
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                p={2}
                style={{
                  opacity: platform === "Web App" ? 0.55 : 1,
                  pointerEvents: platform === "Web App" ? "none" : "initial",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography> Mobile App Notification</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          Profession
                          <span style={{ color: "red" }}> *</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiSelectDropdown
                          options={professionOptions}
                          name="professionalType"
                          getValues={getValues}
                          setValue={setValue}
                          control={control}
                          customOnChange={handleProfessionNewChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          Qualification
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiSelectDropdown
                          options={qualificationOptions}
                          getValues={getValues}
                          name="qualification"
                          setValue={setValue}
                          control={control}
                          customOnChange={handleQualificationNewChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          Specialization
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiSelectDropdown
                          options={specializationOptions}
                          getValues={getValues}
                          name="specialization"
                          defaultValue={specializationDefaultValue}
                          setValue={setValue}
                          control={control}
                          customOnChange={handleSpecializationNewChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          Experience
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiSelectDropdown
                          options={experienceOptions}
                          getValues={getValues}
                          name="numExperience"
                          setValue={setValue}
                          control={control}
                          customOnChange={handleExperienceNewChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          City
                          <span style={{ color: "red" }}> *</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiSelectDropdown
                          options={cityOptions}
                          getValues={getValues}
                          name="workCity"
                          setValue={setValue}
                          control={control}
                          customOnChange={handleCityNewChange}
                        />
                      </Grid>

                    </Grid>
                  </Grid> 

                  {/* for isPremium flag */}
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          Is Premium
          
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <SelectDropDownNew
                          options={selectIsPremiumOptions}
                          getValues={getValues}
                          name="isPremium"
                          // setValue={setValue}
                          control={control}
                          // customOnChange={handlePremiumChange}
                          customOnChange={(e) =>{
                            // console.log("eeee>>", e.target.value)
                            setValue("isPremium", e.target.value)
                          }
                          }
                        />
                      </Grid>
                      
                    </Grid>
                  </Grid>

                </Grid>
              </MDBox>
              <MDBox
                p={2}
                style={{
                  opacity: platform === "Mobile App" ? 0.55 : 1,
                  pointerEvents: platform === "Mobile App" ? "none" : "initial",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography> Web App Notification</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          Employer Type
                          <span style={{ color: "red" }}> *</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <MultiSelectDropdown
                          options={employerTypeOptions}
                          getValues={getValues}
                          name="companyType"
                          setValue={setValue}
                          control={control}
                          customOnChange={handleEmployerNewChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        {" "}
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          color="text"
                        >
                          City
                          <span style={{ color: "red" }}> *</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        {" "}
                        <MultiSelectDropdown
                          options={cityOptions}
                          getValues={getValues}
                          name="workCity"
                          setValue={setValue}
                          control={control}
                          customOnChange={handleCityNewMobileChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid xs={12} md={12}>
                <Divider sx={{ width: "100%" }} />
              </Grid>

              <MDBox
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "#fff" }}
                >
                  Create
                </Button>
              </MDBox>
            </form>
          </div>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default EventCreation;