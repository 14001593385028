import { Delete, Edit, FileUpload } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, TextField } from "@mui/material";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MDBox from "components/MDBox";

import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addSingleJobDetails } from "layouts/common/Redux/actionCreator";
import DeleteJobDetailDialog from "./components/DeleteJobDetailDialog";
import EditJobDetailDialog from "./components/EditJobDetailDialog";
import {
  DeleteJobs,
  EditJobs,
  fetchJobDetailsData,
  uploadFile,
} from "./Redux/actionCreator";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import { setSpecialisationsType } from "layouts/events/Redux/actionCreator";
import { setExperience } from "layouts/events/Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";

function JobDetails() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const sortModel = [{ field: "createdAt", sort: "desc" }];
  const dispatch = useDispatch();

  const { Job_Details_Data } = useSelector((state) => state.common);
  const qualificationOptions = [];

  var gridData = Job_Details_Data?.jobDetailsInfo;

  const [file, setFile] = useState();

  const columns = [
    {
      field: "profession",
      headerName: `Profession`,
      sortable: false,
      flex: 1,
    },
    {
      field: "experience",
      headerName: `Experience`,
      sortable: false,
      flex: 1,
    },
    {
      field: "specialization",
      headerName: `Specialization`,
      flex: 1,
      sortable: false,
    },
    {
      field: "description",
      headerName: `Description`,
      sortable: false,
      renderCell: (cellValues) => (
        <div
          style={{
            minWidth: "100%",
            wordWrap: "break-word",
            // overflowX: "scroll",
            whiteSpace: "initial",
          }}
        >
          {cellValues.value}
        </div>
      ),
      flex: 2,
    },
    {
      field: "Edit/Delete",
      headerName: "Actions",
      renderCell: ({ row }) => (
        <>
          <EditJobDetailDialog
            handleConfirmation={(formData) => {
              handleEditJobDetail(formData);
            }}
            _id={row._id}
            profession={row.profession}
            experience={row.experience}
            specialization={row.specialization}
            description={row.description}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="edit"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Edit fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
          &nbsp;&nbsp;
          <DeleteJobDetailDialog
            message="Do you want to delete the job detail?"
            handleConfirmation={() => {
              handleDeleteJobDetail(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                <Delete fontSize="small" onClick={handlePopupOpen} />
              </IconButton>
            )}
          />
        </>
      ),
      sortable: false,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
    },
  ];

  const handleEditJobDetail = (formData) => {
    dispatch(EditJobs(formData));
  };

  const handleDeleteJobDetail = (ID) => {
    dispatch(DeleteJobs({ _id: ID }));
  };

  const onUploadFileChange = ({ target }) => {
    const file = target.files[0];
    setFile(file);
  };

  const handleFileSubmit = () => {
    dispatch(uploadFile(file));
    setFile(null);
  };

  const professionOptions = [];
  const specializationOptions = [];
  const experienceOptions = [];
  const { Profession_Type_Data, Experience_Data, Specialization_Type_Data } =
    useSelector((state) => state.events);

  const professionData = Profession_Type_Data?.professionInfo;
  const experienceData = Experience_Data?.experienceInfo;
  const specializationsData = Specialization_Type_Data?.specializationInfo;
  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  experienceData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };
    specializationOptions.push(obj);
  });
  const onSubmit = (data, error) => {
    dispatch(addSingleJobDetails(data));

    //AFTER SUBMIT SETTING STATES TO BLANK
    setValue("profession", "none");
    setValue("specialization", "none");
    setValue("experience", "none");
    setValue("description", "");
    setFile("");

    // reset();
  };

  const handleRemove = (e) => {
    setFile("");
  };

  useEffect(() => {
    dispatch(fetchJobDetailsData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setProfessionType());
    dispatch(setSpecialisationsType());

    dispatch(setExperience());
  }, []);

  const handleProfessionChange = (e) => {
    let professionID;
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        professionID = obj.value;
      }
    });

    setValue("profession", professionID);
  };

  const handleSpecializationChange = (e) => {
    let specializationID;

    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        specializationID = obj.value;
      }
    });
    setValue("specialization", specializationID);
  };

  const handleExperienceChange = (e) => {
    let experienceID;

    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) {
        experienceID = obj.value;
      }
    });

    setValue("experience", experienceID);
  };

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {/* <JobDetails /> */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBox m={1} p={2}>
                  <Paper elevation={1} sx={{ padding: "1rem" }}>
                    <MDTypography variant="h4" fontWeight="medium">
                      Job Details
                    </MDTypography>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      borderRadius="lg"
                      width="100%"
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      p={2}
                      mt={2}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Grid container>
                            <Grid item xs={12} md={4}>
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                                color="text"
                              >
                                Profession
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <SelectDropDownNew
                                options={professionOptions}
                                control={control}
                                name="profession"
                                getValues={getValues}
                                customOnChange={handleProfessionChange}
                              />
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                align="center"
                                sx={{ color: "rgba(242,90,90,1)" }}
                              >
                                {/* {errors.HospitalName?.message} */}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid item xs={12} md={4}>
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                                color="text"
                              >
                                Experience
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <SelectDropDownNew
                                options={experienceOptions}
                                control={control}
                                name="experience"
                                defaultValue="none"
                                getValues={getValues}
                                customOnChange={handleExperienceChange}
                              />
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                align="center"
                                sx={{ color: "rgba(242,90,90,1)" }}
                              >
                                {errors.Profession?.message}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid item xs={12} md={4}>
                              {" "}
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                                color="text"
                              >
                                Specialization
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <SelectDropDownNew
                                options={specializationOptions}
                                control={control}
                                name="specialization"
                                defaultValue="none"
                                getValues={getValues}
                                customOnChange={handleSpecializationChange}
                              />
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                align="center"
                                sx={{ color: "rgba(242,90,90,1)" }}
                              >
                                {/* {errors.Qualification?.message} */}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid item xs={12} md={4}>
                              <MDTypography
                                variant="caption"
                                fontWeight="medium"
                                color="text"
                              >
                                Job Description
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <TextField
                                {...register("description")}
                                multiline
                                rows="4"
                                sx={{ width: "32rem" }}
                                // error={Boolean(errors.Department)}
                              />
                              {/* <MDTypography
                              variant="caption"
                              fontWeight="regular"
                              align="center"
                              sx={{ color: "rgba(242,90,90,1)" }}
                            > */}
                              {/* {errors.Department?.message} */}
                              {/* </MDTypography> */}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            sx={{ color: "#fff" }}
                            type="submit"
                          >
                            ADD Details
                          </Button>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Paper>

                  {/* File Upload */}
                  <MDBox sx={{ marginTop: "2rem" }}>
                    <Paper elevation={1} sx={{ padding: "1rem" }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Upload file For Job Details
                          </MDTypography>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            variant="info"
                            color="secondary"
                            component="label"
                            sx={{ margin: "2rem", marginBottom: "0.2rem" }}
                            startIcon={<FileUpload sx={{ color: "#111" }} />}
                          >
                            Choose file
                            <input
                              type={"file"}
                              hidden
                              // id={"FileInput"}
                              accept=".xls,.xlsx"
                              style={{ marginTop: "1rem" }}
                              onChange={onUploadFileChange}
                            />
                          </Button>
                        </Grid>
                        <Grid xs={5}>
                          <MDTypography
                            variant="caption"
                            fontWeight="medium"
                            sx={{ margin: "2rem", align: "bottom" }}
                          >
                            {file?.name}
                            {file?.name ? (
                              <IconButton size="small" onClick={handleRemove}>
                                <Delete />
                              </IconButton>
                            ) : null}
                          </MDTypography>
                          <Button
                            variant="contained"
                            sx={{ color: "#FFF", marginTop: "1rem" }}
                            onClick={(e) => {
                              handleFileSubmit(e);
                            }}
                            disabled={file ? false : true}
                          >
                            upload
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </MDBox>
                </MDBox>
              </form>

              <MDBox sx={{ marginTop: "2rem" }}>
                {/* <Paper elevation={1} sx={{ padding: "1rem" }}> */}
                <DataGrid
                  getRowId={(row) => row._id}
                  columns={columns}
                  // checkboxSelection
                  rows={gridData}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      printOptions: { disableToolbarButton: true },
                      csvOptions: {
                        fileName: `JobDescription-${formatDate()}`,
                      },
                    },
                  }}
                  
                  getRowHeight={() => "auto"}
                  sx={{
                    backgroundColor: "#ffff",
                    height: "35rem",
                    margin: "1rem",
                    borderRadius: "0.5rem",
                    "& .MuiDataGrid-cell": {
                      padding: "15px",
                      fontSize: "12px",
                    },

                    borderColor: "light-grey",
                    "& .MuiDataGrid-cell:hover": {
                      color: "info",
                    },
                  }}
                  pagination
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  // onSelectionModelChange={(ids) => {
                  //   SetEditDeletVisibility((prevState) => !prevState);
                  // }}
                  disableSelectionOnClick
                  disableColumnMenu
                  disableColumnSelector
                  sortModel={sortModel}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        createdAt: false,
                      },
                    },
                  }}
                />
                {/* </Paper> */}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default JobDetails;
