import * as yup from 'yup';

export const permanentJobCreateValidations = yup.object().shape({
    department: yup.string().required('Department is required'),
    qualification: yup.string().required('qualification is required'),
    numExperience: yup.string().required('numExperience is required'),
    specialization: yup.string().required('specialization is required'),
    candidateType: yup.string().required('candidateType is required'),
    // "salary.type": yup.string().required('Salary Type is required'),
  // Other validation rules for other fields...
});

export default permanentJobCreateValidations;
