import { Delete } from "@mui/icons-material";
import Moment from "moment";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hospitalBranch } from "services/hospitalServices";
import MDBox from "../../../components/MDBox/index";
import { DeleteJobs } from "../../common/Redux/actionCreator";
import { deletehospitalBranch } from "../Redux/actionCreator";
import axiosInstance from "../../../axios";
import Pagination from "@mui/material/Pagination";


import AddHospitalBranchDialogContent from "./AddHospitalBranchDialogContent";

const HospitalBranch = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HospitalBranchList([]));
  }, []);

  function getCreate(params) {
    // var rowName = params?.row?.createdAt;
    // var dateNOW = new Date(rowName);
    // return dateNOW;
    // return (Moment(params?.row?.createdAt).format("DD/MM/YYYY"))
    var a = params.row.createdAt
          ? Moment(params?.row?.createdAt).format("YYYY/MM/DD")
          : "NA";
        return a;
  }

  function getUpdate(params) {
    // var rowName = params?.row?.updatedAt;
    // var dateNOW = new Date(rowName);
    // return dateNOW;
    // return (Moment(params?.row?.updatedAt).format("DD/MM/YYYY"))
    var a = params.row.updatedAt
    ? Moment(params?.row?.updatedAt).format("YYYY/MM/DD")
    : "NA";
  return a;
  }

  const columns = [
    {
      field: "jobLocationBranchName",
      headerName: `Job Location Branch Name`,
      renderCell: (cellValues) => {
        const branchName = cellValues?.row?.jobLocation?.branchName;
        return <Link to={`${cellValues?.id}`}>{branchName}</Link>;
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.branchName || "NA",
      flex: 2,
    },
    {
      field: "company",
      headerName: `Company`,
      renderCell: (cellValues) => {
        // console.log(cellValues);
        const branchName = cellValues?.row?.organization;
        return (
          <Link to={`/hospital/company/${branchName?._id}`}>
            {branchName?.name ? branchName?.name : "N.A."}
          </Link>
        );
      },
      valueGetter: (cellValues) => cellValues?.row?.company?.name || "NA",
    },
    {
      field: "corporate",
      headerName: `Corporate`,
      renderCell: (cellValues) => {
        return cellValues?.row?.company?.name;
      },
      valueGetter: (cellValues) => cellValues?.row?.company?.name || "NA",
    },

    {
      field: "brand",
      headerName: `Brand`,
      flex: 1,
    },
    {
      field: "subBrand",
      headerName: `SubBrand`,
      flex: 1,
    },
    {
      field: "contactDetails",
      headerName: `Contact Details`,
      renderCell: (cellValues) => {
        return cellValues?.row?.contactDetails?.length + " Value" || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.contactDetails?.length + " Value" || "NA",
    },

    {
      field: "billingAddressAddressAddress",
      headerName: `Billing Address Address Address`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address.address || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.address?.address || "NA",
    },
    {
      field: "billingAddressAddressCity",
      headerName: `Billing Address Address City`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address.city || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.address?.city || "NA",
    },

    {
      field: "billingAddressAddressState",
      headerName: `Billing Address Address State`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address.state || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.address?.state || "NA",
    },

    {
      field: "billingAddressAddressPin",
      headerName: `Billing Address Address Pin`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address.pin || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.address?.pin || "NA",
    },

    {
      field: "billingAddressAddressCountry",
      headerName: `Billing Address Address Country`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.address.country || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.address?.country || "NA",
    },

    {
      field: "billingAddressAddressCoords",
      headerName: `Billing Address Address Coords`,
      renderCell: (cellValues) => {
        return (
          cellValues?.row?.billingAddress?.address.coords.length + " Value" ||
          "NA"
        );
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.address?.length + " Value" || "NA",
    },

    {
      field: "billingAddressGstNumber",
      headerName: `Billing Address Gst Number`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.gstNumber || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.gstNumber || "NA",
    },

    {
      field: "billingAddressIsGstRegistered",
      headerName: `Billing Address Is Gst Registered`,
      renderCell: (cellValues) => {
        return cellValues?.row?.billingAddress?.isGstRegistered || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.billingAddress?.isGstRegistered || "NA",
    },

    {
      field: "documents",
      headerName: `Documents`,
      renderCell: (cellValues) => {
        return cellValues?.row?.documents?.length + " Value" || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.documents?.length + " Value" || "NA",
    },

    {
      field: "jobLocationHospitalName",
      headerName: `Job Location Hospital Name`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.hospitalName || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.hospitalName || "NA",
    },

    // {
    //   field: "jobLocationBranchName",
    //   headerName: `Job Location Branch Name`,
    //   renderCell: (cellValues) => {

    //     return (cellValues?.row?.jobLocation?.branchName || "NA");
    //   },
    //   valueGetter: (cellValues) => cellValues?.row?.jobLocation?.branchName || "NA",
    //
    // },

    {
      field: "jobLocationAddressAddress",
      headerName: `Job Location Address Address`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.address || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.address || "NA",
    },

    {
      field: "jobLocationAddressCity",
      headerName: `Job Location Address City`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.city || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.city || "NA",
    },

    {
      field: "jobLocationAddressState",
      headerName: `Job Location Address State`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.state || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.state || "NA",
    },

    {
      field: "jobLocationAddressPin",
      headerName: `Job Location Address Pin`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.pin || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.pin || "NA",
    },

    {
      field: "jobLocationAddressCountry",
      headerName: `Job Location Address Country`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.country || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.country || "NA",
    },

    {
      field: "jobLocationAddressCoords",
      headerName: `Job Location Address Coords`,
      renderCell: (cellValues) => {
        return (
          cellValues?.row?.jobLocation?.address?.coords?.length + " Value" ||
          "NA"
        );
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.coords?.length + " Value" ||
        "NA",
    },

    {
      field: "latitude",
      headerName: `Latitude`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.lat || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.lat || "NA",
      flex: 1,
    },

    {
      field: "longitude",
      headerName: `Longitude`,
      renderCell: (cellValues) => {
        return cellValues?.row?.jobLocation?.address?.long || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.jobLocation?.address?.long || "NA",
      flex: 1,
    },

    {
      field: "isJobApprovalRequired",
      headerName: `is Job Approval Required`,
    },

    {
      field: "isTestRequired",
      headerName: `is Test Required`,
    },

    {
      field: "shifts",
      headerName: `Shifts`,
      renderCell: (cellValues) => {
        return cellValues?.row?.shifts.length + " Value" || "NA";
      },

      valueGetter: (cellValues) =>
        cellValues?.row?.shifts.length + " Value" || "NA",
    },

    {
      field: "testPapers",
      headerName: `Test Papers`,
      renderCell: (cellValues) => {
        return cellValues?.row?.testPapers.length + " Value" || "NA";
      },
      valueGetter: (cellValues) =>
        cellValues?.row?.testPapers.length + " Value" || "NA",
    },

    {
      field: "reverseChargesApplicable",
      headerName: `Reverse Charges Applicable`,
    },

    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a = cellValues.row.createdAt
          ? Moment(cellValues?.row?.createdAt).format("YYYY/MM/DD")
          : "NA";
        return a;
      },
      // return (Moment(cellValues?.row?.createdAt).format("DD/MM/YYYY"))

      type: "date",
      valueGetter: getCreate,
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a = cellValues.row.updatedAt
          ? Moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD")
          : "NA";
        return a;
      },
      // return (Moment(cellValues?.row?.updatedAt).format("DD/MM/YYYY"))

      type: "date",
      valueGetter: getUpdate,
      flex: 1,
    },

    {
      field: "Delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          {" "}
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.jobLocation?.branchName}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                {" "}
                <Delete fontSize="small" onClick={handlePopupOpen} />{" "}
              </IconButton>
            )}
          />{" "}
        </>
      ),
      sortable: false,
    },
  ];
  const handleDelete = (ID) => {
    dispatch(deletehospitalBranch(ID));
  };
  const handleCellClick = (event) => {
    event.originalEvent.stopPropagation();
  };

  const handleRowClick = (event) => {
    event.originalEvent.stopPropagation();
  };

  const [isOpen, setisOpen] = useState(false);
  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  let BranchData = hospital_branch_Data?.branchInfo;
  BranchData = BranchData !== undefined ? BranchData : [];
  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await hospitalBranch({
        criteria: {
          isDeleted: checked,
        },
      });

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await hospitalBranch({
        criteria: {
          isDeleted: checked,
        },
      });
    }
  };

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <>
      {" "}
      <DashboardLayout>
        {" "}
        <DashboardNavbar />{" "}
        <FilterBar
          title="Create Branch"
          title1="Hospital Branch"
          isOpen={isOpen}
          handleDialogOpen={handleDialogOpen}
          handleDialogClose={handleDialogClose}
          handleDeleteApi={handleDeleteApi}
          dialogContent={
            <AddHospitalBranchDialogContent
              handleDialogClose={handleDialogClose}
            />
          }
        />{" "}
        <MDBox sx={{ marginTop: "2rem" }}>
          {" "}
          <DataGrid
            density="compact"
            getRowId={(row) => row._id}
            components={{ Toolbar: GridToolbar }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  //Name Columns
                  jobLocationBranchName: true,
                  jobLocationHospitalName: false,
                  longitude: true,
                  billingAddressAddressAddress: false,
                  billingAddressAddressCity: false,
                  billingAddressAddressState: false,
                  billingAddressAddressPin: false,
                  billingAddressAddressCountry: false,
                  billingAddressAddressCoords: false,
                  billingAddressGstNumber: false,
                  billingAddressIsGstRegistered: false,
                  jobLocationAddressAddress: false,
                  jobLocationAddressCity: true,
                  jobLocationAddressState: false,
                  jobLocationAddressPin: false,
                  jobLocationAddressCountry: false,
                  jobLocationAddressCoords: false,
                  company: true,
                  corporate: false,
                  contactDetails: false,
                  createdAt: true,
                  updatedAt: true,
                  isJobApprovalRequired: false,
                  isTestRequired: false,
                  Delete: false,
                  brand: false,
                  subBrand: false,
                  shifts: false,
                  testPapers: false,
                  reverseChargesApplicable: false,
                  documents: false,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: {
                  fileName: `Branch-${formatDate()}`,
                },
              },
            }}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            // loading={BranchData.length > 0 ? false : true}
            columns={columns}
            rows={
              showDeltedRecords
                ? deletedRecords
                : BranchData !== undefined
                ? [...BranchData].reverse()
                : []
            }
            pagination
            pageSize={100}
            rowsPerPageOptions={[100]}
            //isRowSelectable
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
          />{" "}
        </MDBox>{" "}
      </DashboardLayout>{" "}
    </>
  );
};
export default HospitalBranch;
