import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CitiesDialogValidation } from "util/validations/commonValidations";
import { addCity } from "../Redux/actionCreator";
import MDButton from "./../../../components/MDButton/index";

import CloseIcon from "@mui/icons-material/Close";

const AddNewCityDialogContent = ({ handleDialogClose }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CitiesDialogValidation),
  });

  const onSubmit = (data) => {
    dispatch(addCity(data));

    handleDialogClose();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {/* Name */}
          <Grid item xs={4}>
            <InputLabel fullWidth className="input-labels">
              Name
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <MDInput
              placeholder=""
              sx={{ width: "100%", marginTop: "0.4rem" }}
              inputProps={{
                style: { fontSize: 15, fontWeight: "bold" },
              }}
              {...register("name")}
              error={errors?.name?.message}
              helperText={errors?.name?.message}
            />
          </Grid>
          {/* is Launched */}
          <Grid item xs={10} md={10}>
            <Grid item xs={6} sx={{ marginLeft: "1rem" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      defaultValue={false}
                      name="isLaunched"
                      control={control}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="Is Launched"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default AddNewCityDialogContent;