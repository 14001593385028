/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { makeStyles } from "@mui/styles";
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import Footer from "examples/Footer";

const useStyles = makeStyles((theme) => ({
  dashboardLayout: {
    top: "0px",
    paddingTop: "0px",
    marginLeft:"0px",
    [theme.breakpoints.up("lg")]: {
      
      marginLeft: "15.6rem",
    },
  },
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      className={classes.dashboardLayout}
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        px: 0,
        py: 2,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
      <MDBox>
        <Footer />
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
