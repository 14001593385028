import axios from "axios";
import Cookies from "universal-cookie";
import { get_Token } from "util/Helper";
import { BASE_URL } from "./constants";

const cookies = new Cookies();

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = "Bearer " + get_Token();
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 403) {
      cookies.remove("t_id");
      window.location = "/login";
    } else return Promise.reject(error);
  }
);

export default axiosInstance;
